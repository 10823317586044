import React from "react";
import classNames from "classnames";

const EditDoseButton = props => {
  const { children, onClick, className } = props;

  return (
    <button
      className={classNames('btn', 'btn--apply', className)}
      onClick={onClick}
      style={{ display: 'block' }}
    >
      {children}
    </button>
  );
};

export default EditDoseButton;
