class FocusService {
  static focusableSelectors = [
      'a[href]',
      'area[href]',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'button:not([disabled])',
      'iframe',
      'object',
      '*[tabindex]:not([tabindex="-1"])',
      '*[contenteditable]'
  ];

  /**
   * Places focus on an element
   */
  static focusElement = element => {
      if (element) {
          window.setTimeout(() => {
              element.focus();
          }, 0);
      }
  };

  /**
   * Places focus on the first focusable element found in the element provided
   */
  static focusFirstElementIn = element => {
      if (!element) {
          return;
      }

      const focusableElements = FocusService.getFocusableElementsIn(element);
      const firstFocusableElement = focusableElements[0];
      if (firstFocusableElement) {
          FocusService.focusElement(firstFocusableElement);
      }
  };

  /**
   * Returns all of the focusable elements found in the element provided
   */
  static getFocusableElementsIn = element => {
      if (!element) {
          return [];
      }

      return [...element.querySelectorAll(FocusService.focusableSelectors.join(','))];
  };
}

export default FocusService;
