import React from 'react';
import PropTypes from 'prop-types';
import ToggleAllDoseCurves from '../ToggleAllDoseCurves/ToggleAllDoseCurves';
import ToggleSingleDoseCurves from '../ToggleSingleDoseCurves/ToggleSingleDoseCurves';
import Legend from '../Legend/Legend';
import LegendItems from '../Legend/LegendItems';
import { isDomainAtMaxZoom, isDomainAtMinZoom } from '../../modules/domains';
class Toolbar extends React.Component {

  static propTypes = {
    doses: PropTypes.array,
    metadata: PropTypes.object,
    onDoseChanges: PropTypes.func,
    onDomainChanges: PropTypes.func,
    zoomDomain: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    doses: [],
    metadata: {},
    onDoseChanges: () => {},
    onDomainChanges: () => {},
  };

  handleDoseChanges = doses => {
    const { onDoseChanges } = this.props;
    onDoseChanges(doses);
  };

  render() {
    const { doses, legendItems, metadata, onDomainChanges, zoomDomain } = this.props;
    const singleDoseCurveView = metadata.singleDoseCurveView;
    const pp3mScenario = metadata.pp3mScenario;
    const showToggleAllDoseCurvesCheckbox = !singleDoseCurveView && !pp3mScenario;

    return (
      <ul className="scenario-controls">
        <li>
          <Legend
            containerClass={
              singleDoseCurveView ? 'scenario-single-dose-controls' : 'scenario-legend-control'
            }
            buttonText={singleDoseCurveView ? 'Select Two Doses to Compare' : 'Legend'}
            dropdownClass={singleDoseCurveView ? 'single-dose-options' : 'scenario-legend'}
          >
            {singleDoseCurveView ? (
              <ToggleSingleDoseCurves doses={doses} onDoseChanges={this.handleDoseChanges} />
            ) : (
              <LegendItems legendItems={legendItems} />
            )}
          </Legend>
        </li>
        {showToggleAllDoseCurvesCheckbox && (
          <li>
            <ToggleAllDoseCurves doses={doses} onDoseChanges={this.handleDoseChanges} />
          </li>
        )}
        <li className="last scenario-zoom-control">
          <span>Zoom:</span>
          <button
            className="btn btn--zoom btn--zoom-increase"
            title="Increase Zoom"
            onClick={() => onDomainChanges({ zoom: 1 })}
            disabled={isDomainAtMaxZoom(zoomDomain, metadata)}
          >
            +
          </button>
          <button
            className="btn btn--zoom btn--zoom-decrease"
            title="Decrease Zoom"
            onClick={() => onDomainChanges({ zoom: -1 })}
            disabled={isDomainAtMinZoom(zoomDomain, metadata)}
          >
            −
          </button>
        </li>
      </ul>
    );
  }
}

export default Toolbar;
