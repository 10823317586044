import React, { createRef } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  buildCumulativeDoseCurves,
  getScenarioMetadata,
  getTooltipData,
  getSwitchLineData,
  normalizeDoses,
  editDoseData,
  updateDoseStartDay,
} from '../modules/helpers';
import { getEntireDomain, getInitialDomainForScenario, getPeriodByDomain, getRangeForDomain, updateDomain } from '../modules/domains';
import BaseChart from './BaseChart/BaseChart';
import Toolbar from './Toolbar/Toolbar';
import GraphContext from './GraphContext';
import Modal from '../../js/components/Modal';
class Graph extends React.Component {
  static propTypes = {
    legendItems: PropTypes.arrayOf(PropTypes.object),
    printMode: PropTypes.bool,
    scenario: PropTypes.object,
    translations: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    legendItems: [],
    printMode: false,
    scenario: {},
  };

  constructor(props) {
    super(props);

    const { printMode, scenario } = props;
    const metadata = getScenarioMetadata(scenario);
    const doses = normalizeDoses(scenario.doseData, scenario, true) || [];
    const entireDomain = getEntireDomain(doses, metadata);
    const zoomDomain = getInitialDomainForScenario(metadata);

    this.containerRef = createRef();

    this.state = {
      cumulativeDataLoaded: false,
      doses,
      entireDomain,
      handleDomainChanges: this.handleDomainChanges,
      handleUpdateStart: this.handleUpdateStart,
      metadata,
      modalContent: null,
      pixelsPerDay: 0,
      printMode: printMode,
      switchLineData: getSwitchLineData(metadata),
      tooltips: getTooltipData(metadata),
      translations: { ...props.translations },
      zoomDomain,
    };

    this.debouncedResizeHandler = debounce(this.handleResize.bind(this), 300);
  }

  componentDidMount() {
    const { doses, metadata, zoomDomain } = this.state;

    this.addEventListeners();

    // append cumulative dose curves to the array of individual doses
    // note: this is an async process for PP3M scenarios
    buildCumulativeDoseCurves(doses, metadata)
      .then(dosesWithCumulativeCurves => {
        // recalculate y-domain to account for cumulative curves
        const entireDomain = getEntireDomain(dosesWithCumulativeCurves, metadata);
        this.setState({
          cumulativeDataLoaded: true,
          doses: dosesWithCumulativeCurves,
          entireDomain,
          pixelsPerDay: this.calcPixelsPerDay(zoomDomain)
        });
      })
      .catch(this.handleCumulativeBuildError);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners = () => {
    window.addEventListener('resize', this.debouncedResizeHandler);
  };

  removeEventListeners = () => {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  };

  handleResize = () => {
    const { zoomDomain } = this.state;

    this.setState({
      pixelsPerDay: this.calcPixelsPerDay(zoomDomain)
    });
  };

  /**
   * Calculates the approximate number of pixels per day,
   * based on the current zoom domain and the width of the graph
   * @param {array} zoomDomain [x1, x2]
   */
  calcPixelsPerDay = (zoomDomain) => {
    const backgroundNode = this.containerRef.current.querySelector('.edi-chart-background');
    const { width } = backgroundNode.getBoundingClientRect();
    const daysInView = getRangeForDomain(zoomDomain);
    const approxPixelsPerDay = width / daysInView;
    return approxPixelsPerDay;
  };

  closeModal = () => {
    this.setState({
      modalContent: null
    });
  };

  /**
   * Updates the start day for the given dose by the day offset
   * This triggers the cumulative levels to be recalculated,
   * and can also affect future dose start days
   * @param {object} dose
   * @param {number} dayOffset (signed integer)
   */
  handleUpdateStart = (dose, dayOffset) => {
    const { doses, metadata } = this.state;
    const updatedDoses = updateDoseStartDay(dose, dayOffset, doses, metadata);

    buildCumulativeDoseCurves(updatedDoses, metadata)
      .then(dosesWithCumulativeCurves => {
        this.setState({ doses: dosesWithCumulativeCurves });
      })
      .catch(this.handleCumulativeBuildError);
  };

  handleDoseChanges = doses => {
    this.setState({ doses });
  };

  handleDomainChanges = ({ zoom = 0, pan = 0 }) => {
    const { entireDomain, metadata, zoomDomain } = this.state;
    const newZoomDomain = updateDomain({ zoomDomain, zoom, pan, entireDomain, metadata });
    this.setState({
      pixelsPerDay: this.calcPixelsPerDay(newZoomDomain),
      zoomDomain: newZoomDomain,
    });
  };

  /**
   * Updates the amount and/or location for the given dose
   * May also update future doses based on conditional logic
   * Triggers a re-calculation of cumulative curves
   * @param {object} dose (with new prop values)
   * @param {boolean} updateFutureDoses
   */
  handleDoseEdit = (dose, updateFutureDoses) => {
    const { doses, metadata } = this.state;
    const updatedDoses = editDoseData(dose, doses, metadata, updateFutureDoses);

    buildCumulativeDoseCurves(updatedDoses, metadata)
      .then(dosesWithCumulativeCurves => {
        const entireDomain = getEntireDomain(dosesWithCumulativeCurves, metadata);
        this.setState({ doses: dosesWithCumulativeCurves, entireDomain });
      })
      .catch(this.handleCumulativeBuildError);
  };

  handleCumulativeBuildError = error => {
    console.error(error);

    this.setState({
      modalContent: (
        <>
          <p>For illustrative purposes, this dosage combination is unavailable.</p>
          <button
            className="btn btn--gray btn--cancel"
            onClick={() => window.location.reload()}
          >
              Reload graph
          </button>
        </>
      )
    })
  };

  get zoomDomainClass() {
    const { metadata, zoomDomain } = this.state;
    const period = getPeriodByDomain(zoomDomain, metadata);
    const periodClasses = {
      1: 'one-month',
      3: 'three-months',
      6: 'six-months',
      12: 'twelve-months',
      18: 'eighteen-months',
    };
    return `scenario-graph--${periodClasses[period]}`;
  }

  render() {
    const { legendItems } = this.props;
    const {
      cumulativeDataLoaded,
      doses,
      entireDomain,
      metadata,
      modalContent,
      tooltips,
      switchLineData,
      zoomDomain
    } = this.state;

    return (
      <div
        className={classnames('scenario-graph', this.zoomDomainClass)}
        ref={this.containerRef}
      >
        <GraphContext.Provider value={this.state}>
          <Toolbar
            doses={doses}
            legendItems={legendItems}
            metadata={metadata}
            onDoseChanges={this.handleDoseChanges}
            onDomainChanges={this.handleDomainChanges}
            zoomDomain={zoomDomain}
          />
          <BaseChart
            cumulativeDataLoaded={cumulativeDataLoaded}
            doses={doses}
            entireDomain={entireDomain}
            metadata={metadata}
            tooltips={tooltips}
            onDomainChanges={this.handleDomainChanges}
            onUpdateStart={this.handleUpdateStart}
            onDoseEdit={this.handleDoseEdit}
            switchLineData={switchLineData}
            zoomDomain={zoomDomain}
          />
          {modalContent && (
            <Modal active={!!modalContent} onClose={this.closeModal}>
              {modalContent}
            </Modal>
          )}
        </GraphContext.Provider>
      </div>
    );
  }
}

export default Graph;
