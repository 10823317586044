import React from 'react';
import PropTypes from 'prop-types';
import IEFriendlyVictoryChart from './IEFriendlyVictoryChart';
import {
  Background,
  VictoryClipContainer,
  VictoryLabel,
  VictoryAxis,
  VictoryZoomContainer,
} from 'victory';
import { calcXAxisTicks } from '../../modules/axisUtils';
import theme from '../../modules/theme';
import Dose from '../Dose/Dose';
import MedianRange from '../MedianRange/MedianRange';
import InfoFlyouts from '../InfoFlyouts/InfoFlyouts';
import SwitchLines from '../SwitchLines/SwitchLines';
import ChartControls from '../ChartControls/ChartControls';
import GraphShade from '../GraphShade/GraphShade';
import { pipe, filter } from '../../modules/reducers';

class BaseChart extends React.Component {
  static propTypes = {
    cumulativeDataLoaded: PropTypes.bool,
    doses: PropTypes.array,
    entireDomain: PropTypes.object.isRequired,
    height: PropTypes.number,
    metadata: PropTypes.object,
    onDomainChanges: PropTypes.func,
    onDoseEdit: PropTypes.func,
    onUpdateStart: PropTypes.func,
    switchLineData: PropTypes.array,
    tooltips: PropTypes.object,
    width: PropTypes.number,
    zoomDomain: PropTypes.array.isRequired,
  };

  static defaultProps = {
    height: 300,
    width: 1000,
  };

  getCumulativeDoses = doses => {
    return pipe(filter(dose => dose.isCumulative))(doses);
  };

  get atMinDomain() {
    const { entireDomain, zoomDomain } = this.props;
    return entireDomain.day[0] === zoomDomain[0];
  }

  get atMaxDomain() {
    const { entireDomain, zoomDomain } = this.props;
    return entireDomain.day[1] === zoomDomain[1];
  }

  render() {
    const {
      cumulativeDataLoaded,
      doses,
      entireDomain,
      width,
      height,
      metadata,
      onDomainChanges,
      onUpdateStart,
      onDoseEdit,
      tooltips,
      switchLineData,
      zoomDomain,
    } = this.props;

    const minDay = entireDomain.day[0];
    const maxDay = entireDomain.day[1];
    const maxLevel = entireDomain.level[1];
    const minLevel = 0;
    const maxLevelWithBuffer = maxLevel * 1.3;
    const xAxisTicks = calcXAxisTicks(zoomDomain);
    const rightYAxisTitle = metadata.rightYAxis?.title?.text;

    return (
      <div className="edi-base-chart">
        <div className="tooltip-portal-container">
          <div id="tooltip-portal"></div>
        </div>

        <IEFriendlyVictoryChart
          theme={theme}
          style={{
            background: theme.background,
          }}
          backgroundComponent={
            <Background className="edi-chart-background"/>
          }
          domain={{ x: [minDay, maxDay], y: [minLevel, maxLevelWithBuffer] }}
          domainPadding={0}
          padding={{ top: 10, bottom: 120, left: 50, right: 50 }}
          height={height}
          width={width}
          containerComponent={
            <VictoryZoomContainer
              disable
              zoomDomain={{ x: zoomDomain }}
              zoomDimension="x"
              clipContainerComponent={<VictoryClipContainer clipPadding={{ bottom: 70 }} />}
            />
          }
        >
          <MedianRange maxDay={maxDay} minDay={minDay} showMedianRange={metadata.display_band} />

          <GraphShade yDomain={[minLevel, maxLevelWithBuffer]} />

          <VictoryAxis
            domain={zoomDomain}
            tickFormat={xAxisTicks.formats}
            tickValues={xAxisTicks.values}
            axisLabelComponent={<VictoryLabel dy={20} />}
          />

          <VictoryAxis
            animate={false}
            axisLabelComponent={<VictoryLabel dy={-50} />}
            axisValue={minDay}
            dependentAxis
            desc="Level (ng/mL)"
            style={{
              axis: {
                strokeWidth: 0,
              },
              ticks: {
                strokeWidth: 0,
              },
            }}
            tickCount={1}
            tickFormat={y => ''} // eslint-disable-line no-unused-vars
          />

          <SwitchLines
            switchLineData={switchLineData}
            yDomain={[minLevel, maxLevelWithBuffer]}
            {...this.props}
          />

          {
            // Render each Dose Line
            doses.map((dose, index) => (
              <Dose {...this.props} key={index} dose={dose} />
            ))
          }

          {tooltips && (
            <InfoFlyouts
              {...this.props}
              cumulativeDoseCurves={this.getCumulativeDoses(doses)}
              tooltips={tooltips}
            />
          )}

          {cumulativeDataLoaded && (
            <ChartControls
              doses={doses}
              maxDay={maxDay}
              minDay={minDay}
              minLevel={minLevel}
              onUpdateStart={onUpdateStart}
              onDoseEdit={onDoseEdit}
            />
          )}

        </IEFriendlyVictoryChart>

        <div className="edi-dose-tab-container">
          <div id="dose-tab-portal"></div>
        </div>

        {!this.atMinDomain && (
          <button
            type="button"
            className="scenario-graph-arrows scenario-graph-arrows--left"
            onClick={() => onDomainChanges({ pan: -30 })}
          >
            ‹
          </button>
        )}

        {!this.atMaxDomain && (
          <button
            type="button"
            className="scenario-graph-arrows scenario-graph-arrows--right"
            onClick={() => onDomainChanges({ pan: 30 })}
          >
            ›
          </button>
        )}

        <span
          className="edi-y-axis-label edi-y-axis-label-left"
          dangerouslySetInnerHTML={{ __html: metadata.axisYTitle }}
        />

        {rightYAxisTitle && (
          <span
            className="edi-y-axis-label edi-y-axis-label-right"
            dangerouslySetInnerHTML={{ __html: rightYAxisTitle }}
          />
        )}
      </div>
    );
  }
}

export default BaseChart;
