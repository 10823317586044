import React from 'react';
import classNames from 'classnames';

const EditDoseSelect = props => {
  const { defaultValue, visible, disabled, label, id, options, onChange } = props;
  const type = id && id.includes('amount') ? 'amount' : 'location';
  const baseClass = 'edit-dose-dropdown';
  const disabledClass = `${baseClass}--disabled`;
  const typeClass = `${baseClass}--${type}`;
  const labelClass = `${baseClass}-label`;
  const selectClass = `${baseClass}-select`;

  // Some doses are disabled by default, but we still want to show the only value in the dropdown
  let dropdownOptions = options.length ? options : [defaultValue];

  // convert string options to objects with a label and value
  dropdownOptions = dropdownOptions.map(option => {
    if (typeof option === 'string') {
      return { label: option, value: option };
    }
    return option;
  });

  if (!visible) {
    return null;
  }

  return (
    <div className={classNames(baseClass, disabledClass)}>
      <label htmlFor={id} className={classNames(labelClass, typeClass)}>
        <span className="accessibly-hidden">{label}</span>
      </label>
      <select
        className={selectClass}
        disabled={disabled}
        id={id}
        defaultValue={defaultValue}
        onChange={event => onChange(event.currentTarget.value)}
      >
        {dropdownOptions.map((option, key) => (
          <option key={key} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EditDoseSelect;
