import React, { useEffect, useRef } from 'react';

const OutsideClickHandler = ({ children, onClick }) => {

  const containerRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = event => {
    if (containerRef.current.contains(event.target)) {
      return;
    }

    onClick(event);
  };

  return (
    <div className="outside-click-handler" ref={containerRef}>
      { children }
    </div>
  );
}

export default OutsideClickHandler;
