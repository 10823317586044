import React, { createRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
class InfoBox extends React.Component {
  static propTypes = {
    tooltip: PropTypes.object,
    setFlyout: PropTypes.func,
    cycleFlyout: PropTypes.func,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.closeButtonRef = createRef();
  }

  componentDidMount() {
    this.attachEventListeners();
    this.moveFocusToElement(this.closeButtonRef.current);
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  moveFocusToElement = element => {
    if (!element) return;
    element.focus();
  };

  attachEventListeners = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleKeyDown);
  };

  removeEventListeners = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = e => {
    const ESC_KEY = 27;
    if (e.keyCode === ESC_KEY) {
      this.closeInfoBox();
    }
  };

  closeInfoBox = () => {
    const { setFlyout } = this.props;
    setFlyout(false);
  };

  handleClickOutside = e => {
    if (this.containerRef.current.contains(e.target)) {
      // click inside the info box
      return;
    }
    // close info box on outside clicks
    this.closeInfoBox();
  };

  handleCycle = direction => {
    const { cycleFlyout } = this.props;
    cycleFlyout(direction);
  };

  render() {
    const { style, tooltip } = this.props;
    const { isFirst, isLast, text } = tooltip;

    return (
      <div
        className="info-box"
        style={style}
        ref={this.containerRef}
      >
        <div className="info-box-content-wrapper">
          <button
            className="btn btn--dark btn-close"
            ref={this.closeButtonRef}
            onClick={this.closeInfoBox}
          >
            &#x2715;
          </button>
          <div className="info-box-content">
            <p dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <div className="info-box-nav-container">
            <button
              className={classnames('btn info-box-nav-btn', {
                'btn--disabled': isFirst,
              })}
              disabled={isFirst}
              onClick={() => {
                this.handleCycle('prev');
              }}
            >
              Previous
            </button>
            <button
              className={classnames('btn info-box-nav-btn', {
                'btn--disabled': isLast,
              })}
              disabled={isLast}
              onClick={() => {
                this.handleCycle('next');
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoBox;
