// accepts a map function that accepts an item and returns a modified item
// returns a function that accepts an array of those items,
// applies the function to each item in the array,
// and finally returns the array of modified items
export const map = fn => (arr =[]) => arr.reduce((acc, item, index, arr) => {
  return acc.concat(fn(item, index, arr));
}, []);

// accepts a filter function that accepts an item and returns a boolean.
// returns a function that accepts an array of those items,
// and returns a new array by calling the filter function on each item
export const filter = fn => (arr =[]) => arr.reduce((newArr, item) => {
  return fn(item) ? newArr.concat([item]) : newArr;
}, []);

// inspect values as they pass through the pipe without effecting them
// cab also use them it for side effects but not recommended
export const tap = (fn = console.log) => val =>{
  // console.warn('log will slow down your code execution due to it being a side effect and should not be used in prod')
  const v = val;
  fn(v);
  return v;
};

// accepts multiple functions. each function should accept one item and return one item.
// returns one function that will take a value, pass it to each function, and return the resulting value.
export const pipe = (...fns) => {
  return items => fns.reduce((output, fn) => fn(output), items);
};
