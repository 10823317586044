import { BLUE_CURVE, GREEN_CURVE, INJECTABLE_CURVE } from './data';
import { filter, pipe, map } from './reducers';

/** BLUE_CURVE = oral doses (legacy code z_pp1mGraphInit.js LINE 2058) */
export const BLUE_CURVE_FILTER = pipe(
  filter(dose => dose.location in BLUE_CURVE),
  map(dose => ({
    ...dose,
    inBlueCurve: true,
  }))
);

// replaces: if (!dose.ignoreTotal && dose.location in GREEN_CURVE)
export const GREEN_CURVE_FILTER = pipe(
  filter(dose => dose.location in GREEN_CURVE),
  filter(dose => !dose.ignoreTotal),
  map(dose => ({
    ...dose,
    inGreenCurve: true,
  }))
);

export const INJECTABLE_CURVE_FILTER = pipe(
  filter(dose => dose.location in INJECTABLE_CURVE),
  map(dose => ({
    ...dose,
    inBlueCurve: true,
  }))
);

export const individualDosesFilter = pipe(
  filter(dose => !dose.isCumulative)
);
