export const medianConcentrationRange = [17, 34];

export const singleDoseCurveColors = {
  'Deltoid:39mg': '#4974aa',
  'Deltoid:78mg': '#ab4544',
  'Deltoid:117mg': '#8eac50',
  'Deltoid:156mg': '#83699f',
  'Deltoid:234mg': '#429ab1',
  'Gluteal:39mg': '#4974aa',
  'Gluteal:78mg': '#97aacf',
  'Gluteal:117mg': '#a68080',
  'Gluteal:156mg': '#b8ce95',
  'Gluteal:234mg': '#da883e',
};

export const formulationColors = {
  '1M': '#E97617',
  '3M': '#BE3B2A',
  'ER': '#6E8A96',
  'RL': '#31A5F2',
};

export const risperdalToPp1mAmounts = {
  '25mg': '78mg',
  '37.5mg': '117mg',
  '50mg': '156mg',
}

export const pp1mToPp3mAmounts = {
  '39mg': '137mg',
  '78mg': '273mg',
  '117mg': '410mg',
  '156mg': '546mg',
  '234mg': '819mg',
};
