import React, { useEffect, useRef } from 'react';
import FocusTrap from './FocusTrap';
import OutsideClickHandler from './OutsideClickHandler';

function Modal({ active, children, onClose }) {

  const closeButtonRef = useRef();

  useEffect(() => {
    if (!active) return;

    addEventListeners();
    moveFocusToCloseButton();

    return () => {
      removeEventListeners();
    };
  }, [active]);

  const moveFocusToCloseButton = () => {
    closeButtonRef.current.focus();
  };

  const addEventListeners = () => {
    document.addEventListener('keydown', handleKeyDown);
  };

  const removeEventListeners = () => {
    document.removeEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = event => {
    const ESC_KEY = 27;
    if (event.keyCode === ESC_KEY) {
      onClose();
    }
  };

  if (!active) return null;

  return (
    <div className="edi-modal-overlay">
      <OutsideClickHandler onClick={onClose}>
        <div className="edi-modal">
          <FocusTrap>
            <div className="edi-modal-actions">
              <button
                ref={closeButtonRef}
                onClick={onClose}
                className="btn btn--close">×</button>
            </div>
            <div className="edi-modal-content">
              {children}
            </div>
          </FocusTrap>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default Modal;
