import React from 'react';
import PropTypes from 'prop-types';
import { singleDoseCurveColors } from '../../modules/config';
import { clearDoseTabPosition } from '../../modules/doseOverlapUtils';
class ToggleSingleDoseCurves extends React.Component {

  static propTypes = {
    doses: PropTypes.array,
    maxSelectedDoses: PropTypes.number,
    onDoseChanges: PropTypes.func,
  };

  static defaultProps = {
    doses: [],
    maxSelectedDoses: 2,
    onDoseChanges: () => {},
  };

  constructor(props) {
    super(props);

    const selectedDoses = this.getInitialSelectedDoses();
    this.state = { selectedDoses };
  }

  /**
   * Gets the initially selected doses based on the dose prop `isVisible`
   * @returns [{}]
   */
  getInitialSelectedDoses = () => {
    const { doses, maxSelectedDoses } = this.props;
    return doses.filter(dose => dose.isVisible)
      .map(dose => dose.key)
      .slice(0, maxSelectedDoses);
  };

  handleSelectionChange = dose => {
    const { maxSelectedDoses } = this.props;
    const selectedDoses = [...this.state.selectedDoses];
    const doseIndex = selectedDoses.indexOf(dose.key);
    const isSelected = doseIndex > -1;
    let changed = false;

    if (isSelected) {
      // deselect dose
      selectedDoses.splice(doseIndex, 1);
      changed = true;
      clearDoseTabPosition(dose.index);

    } else if (selectedDoses.length < maxSelectedDoses) {
      // select dose
      selectedDoses.push(dose.key);
      changed = true;
    }

    if (changed) {
      this.setState({ selectedDoses }, () => {
        this.updateDoseVisibilty();
      });
    }
  };

  updateDoseVisibilty = () => {
    const { selectedDoses } = this.state;
    const { doses, onDoseChanges } = this.props;

    onDoseChanges(
      doses.map(dose => {
        const doseIsSelected = selectedDoses.indexOf(dose.key) > -1;
        dose.isVisible = doseIsSelected;
        return dose;
      })
    );
  };

  getDoseLabelClass = dose => {
    return `${dose.location.toLowerCase()}-${parseInt(dose.amount, 10)}`;
  };

  render() {
    const { doses } = this.props;
    const { selectedDoses } = this.state;

    return (
      <ul>
        {doses
          .filter(dose => !dose.isCumulative)
          .map(dose => {
            const doseLabelClass = this.getDoseLabelClass(dose);
            return (
              <li className="single_dose_item" key={dose.key}>
                <div className="single_dose_content">
                  <div className="single_dose_checkbox">
                    <input
                      type="checkbox"
                      id={dose.key}
                      name={dose.name}
                      onChange={() => this.handleSelectionChange(dose)}
                      checked={selectedDoses.indexOf(dose.key) > -1}
                    />
                  </div>
                  <div className="single_dose_label">
                    <label htmlFor={dose.key} className={doseLabelClass}>
                      <span
                        className="single_dose_item-color"
                        style={{ backgroundColor: singleDoseCurveColors[dose.key] }}
                      />
                      {`${dose.location} ${dose.amount}`}
                    </label>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    );
  }
}

export default ToggleSingleDoseCurves;
