import last from 'lodash/last';
import drugLevelObservations from './data';
import { filter, map, pipe } from './reducers';
import { buildCumulativeDoseCurve } from './accumulatedDoseLineUtils';
import { hydrateDoses } from './doseNormalizationOperators';
import { getUpdatedDoseNameProps, isIndividualDose } from './doseUtils';
import { BLUE_CURVE_FILTER, GREEN_CURVE_FILTER, individualDosesFilter } from './doseFilters';
import { buildCumulativeDoseCurvesForPP3M } from './pp3mHelpers';
import { updatePp3mDose } from './pp3mDoseUpdateUtils';
import { getSafeDomainForDoseTabs } from './domains';

/**
 * Updates the amount and/or location for the given dose
 * and may also update following doses based on conditional logic.
 * Then, triggers a recalculation of the cumulative levels.
 *
 * @param {object} selectedDose (with new amount/location values)
 * @param {[object]} doses
 * @param {object} metadata
 * @param {bool} updateFutureDoses
 */
export const editDoseData = (selectedDose, doses, metadata, updateFutureDoses) => {

  const updatedDoses = metadata.pp3mScenario
    ? updatePp3mDose(selectedDose, doses, metadata)
    : updatePp1mDose(selectedDose, doses, updateFutureDoses);

  return normalizeDoses(updatedDoses, metadata);
};

/**
 * Updates the amount and/or location for the given dose in a PP1M scenario
 * Following doses might also be updated based on conditional logic.
 *
 * @param {object} selectedDose (with new amount/location values)
 * @param {[object]} doses
 * @param {bool} updateFutureDoses
 */
export const updatePp1mDose = (selectedDose, doses, updateFutureDoses) => {
  const { amount, location, groups, xstart } = selectedDose;
  const updatingDoseGroupCaptain = groups?.dose?.captain;
  const doseGroupName = groups?.dose?.name;

  let reachedNextInitiationDose = false;

  const updatedDoses = pipe(
    filter(isIndividualDose),
    map(dose => {
      const isSelectedDose = dose.xstart === selectedDose.xstart;
      const isFutureDose = dose.xstart > xstart;
      const inDoseGroup = Array(dose.groups?.dose?.name).flat().indexOf(doseGroupName) > -1;

      // initiation doses should not be updated
      const isInitiationDose = dose.isInitiationDose;

      // update the selected dose
      if (isSelectedDose) {
        return {
          ...dose,
          ...getUpdatedDoseNameProps(selectedDose, amount, location)
        };
      }

      // update other doses in the same dose group
      if (updatingDoseGroupCaptain && inDoseGroup && !isInitiationDose) {
        return updateFutureDose(dose, amount, location);
      }

      // once we reach the next initiation dose, stop updating future doses
      if (reachedNextInitiationDose) {
        return dose;
      }

      // update future maintenance doses
      if (updateFutureDoses && isFutureDose && !isInitiationDose) {
        return updateFutureDose(dose, amount, location);
      }

      reachedNextInitiationDose = isFutureDose && dose.isInitiationDose && !dose.secondInitiationDose;

      return dose;
    })
  )(doses);

  return updatedDoses;
};

/**
 * Tries to update a future dose with the given amount and location
 * This may not be allowed, or the values may be altered based on conditional logic
 * @param {object} dose
 * @param {amount} amount
 * @param {location} location
 */
export const updateFutureDose = (dose, amount, location) => {
  let newAmount = dose.amount;
  let newLocation = dose.location;

  if (dose.isAmountEditable) {
    newAmount = parseInt(amount) > dose.maxDose
      ? `${dose.maxDose}mg` : amount;
  }

  if (dose.isLocationEditable) {
    newLocation = location;
  }

  if (dose.limitOptions && dose.limitOptions.previousOralDose && !dose.limitOptions.oral) {
    // this dose depends on the amount of a previous oral dose
    // update its amount to an allowed value
    newAmount = dose.limitOptions.doseControls.reduce((match, item) => {
      if (item.previousOralDoseAmount === amount) {
        return last(item.range);
      }
      return match;
    }, newAmount);
    // do not change location to oral
    newLocation = dose.location;
  }

  return {
    ...dose,
    ...getUpdatedDoseNameProps(dose, newLocation, newAmount)
  };
};

/**
 * Updates start day for the selected dose by the dayOffset,
 * and may also update future doses based on conditional logic.
 * Triggers a recalculation of the cumulative drug levels.
 *
 * @param {object} selectedDose
 * @param {integer} dayOffset
 * @param {[object]} doses
 * @param {object} scenario
 */
export const updateDoseStartDay = (selectedDose, dayOffset, doses, scenario) => {

  const { isInitiationDose, id, doNotFollow, groups, xstart } = selectedDose;

  // moving a maintenance dose shifts all future maintenance doses
  const updateMaintenanceDoses = !isInitiationDose
    && !doNotFollow
    && scenario.nextLinesFollowers;

  // moving a group "captain" dose shifts all future doses in the same group
  const positionGroupCaptain = groups?.position?.captain;
  const positionGroup = groups?.position?.name;

  const isSingleDoseCurveView = scenario.singleDoseCurveView;

  const updatedDoses = pipe(
    filter(isIndividualDose),
    map(dose => {

      const isSelectedDose = isSingleDoseCurveView
        ? dose.id === id
        : dose.xstart === xstart;
      const isFutureDose = dose.xstart > xstart;
      const isMaintenanceDose = !dose.isInitiationDose;
      const inPositionGroup = dose.groups?.position?.name === positionGroup;

      if (isSelectedDose) {
        // update the xstart for the selected dose
        const newStart = dose.xstart + dayOffset;
        const currentShift = newStart - dose.rootStart;

        return {
          ...dose,
          currentShift,
          xstart: newStart,
        };
      }

      if (
        (isFutureDose && updateMaintenanceDoses && isMaintenanceDose)
        || (positionGroupCaptain && inPositionGroup)
      ) {
        // update the xstart and rootStart for future maintenance doses and doses in the same group
        // Note: while the xstart is the rendered start day for the dose on the graph,
        // the rootStart (along with relativeExtremes) is used to calculate the allowed shift range for the dose
        const newStart = dose.xstart + dayOffset;
        const rootStart = newStart - dose.currentShift;

        return {
          ...dose,
          rootStart,
          xstart: newStart,
        };
      }
      return dose;
    })
  )(doses);

  return normalizeDoses(updatedDoses, scenario);
};

/**
 * Gets metadata for scenario (exclude unused props from scenario json)
 */
export const getScenarioMetadata = scenario => {
  const metadata = { ...scenario };
  delete metadata.doseData;

  // delete cruft props
  delete metadata.axisYStyle;
  delete metadata.graphInfoDisabled;
  delete metadata.learningPointsDisabled;
  delete metadata.leftYAxisOffset;
  delete metadata.legendItems;
  delete metadata.min;
  delete metadata.switching_lines_only_after_zero;
  delete metadata.switchingLinesMode;

  return metadata;
};

/**
 * Sorts and adds props and individual dose curve data to doses
 *
 * @param {[object]} doses (raw dose data from scenario json)
 * @param {object} scenario
 * @param {boolean} initialize
 */
export const normalizeDoses = (doses, scenario, initialize = false) => {
  return hydrateDoses(drugLevelObservations, scenario, initialize)(doses);
};

/**
 * Builds the cumulative dose curves
 * @param {[object]} doses (must be sorted and hydrated by normalizeDoses first!)
 * @param {object} scenario
 */
export const buildCumulativeDoseCurves = async (doses, scenario) => {
  const individualDoses = individualDosesFilter(doses);

  if (scenario.singleDoseCurveView) {
    // cumulative dose curves are not calculated for single dose curve views
    return individualDoses;
  }

  let cumulativeDoseCurves = [];

  if (scenario.pp3mScenario) {
    cumulativeDoseCurves = await buildCumulativeDoseCurvesForPP3M(individualDoses, scenario);
  } else {
    cumulativeDoseCurves = buildCumulativeDoseCurvesForPP1M(individualDoses, scenario);
  }

  return [...individualDoses, ...cumulativeDoseCurves];
};

/**
 * Builds the cumulative dose curves for PP1M scenarios
 * @param {[object]} doses (must be sorted and hydrated by normalizeDoses first!)
 * @param {object} scenario
 */
export const buildCumulativeDoseCurvesForPP1M = (doses, scenario) => {
  /**
   * group doses by location in GREEN_CURVE and BLUE_CURVE (these can overlap)
   * order matters here - a higher index will overlap a lower index on the graph
   */
  const dosesByCurve = {
    greenCurveDoses: GREEN_CURVE_FILTER(doses),
    blueCurveDoses: BLUE_CURVE_FILTER(doses),
  };

  const cumulativeDoseCurves = pipe(
    // do not include curves that don't have any doses
    filter(doses => doses.length > 0),
    map(buildCumulativeDoseCurve(scenario))
  )(Object.values(dosesByCurve));

  return cumulativeDoseCurves;
};

/**
 * Get all tooltip data from scenario metadata
 * @param {object} scenario
 */
export const getTooltipData = scenario => {
  const { tooltipPoints, totalSwitchingLine } = scenario;

  return {
    blueCurve: totalSwitchingLine?.tooltipPoints || [], // negative day tooltips
    greenCurve: tooltipPoints || [], // positive day tooltips
  };
};

/**
 * Get the switch line data from the scenario metadata object
 * @param {object} scenario
 * @returns [{}]
 */
export const getSwitchLineData = scenario => scenario.switchLines;

/**
 * Logic to determine whether a dose tab
 * should be aligned left (instead of centered by default) to prevent clipping
 * @param {object} dose
 * @param {object} metadata
 */
export const shouldDoseTabAlignLeft = (dose, metadata) => {
  const { singleDoseCurveView } = metadata;
  const { index } = dose;
  return singleDoseCurveView ? true : index < 2;
};

/**
 * Logic to determine whether a dose edit form
 * should be aligned left (instead of centered by default) to prevent clipping
 * @param {object} dose
 * @param {object} metadata
 * @param {array} currentDomain
 * @param {array} entireDomain
 */
export const shouldDoseEditFormAlignLeft = (dose, metadata, currentDomain, entireDomain) => {
  if (!dose || !currentDomain || !entireDomain) {
    return false;
  }

  const { xstart } = dose;
  const safeDomain = getSafeDomainForDoseTabs(currentDomain);
  const atMinScroll = currentDomain[0] === entireDomain[0];
  const doseIsBeforeSafeDomainStart = xstart < safeDomain[0];

  if (atMinScroll && doseIsBeforeSafeDomainStart) {
    return true;
  }

  return false;
};
