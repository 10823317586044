import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GraphContext from '../GraphContext';

class Legend extends React.Component {

  static contextType = GraphContext;

  static propTypes = {
    buttonText: PropTypes.string,
    containerClass: PropTypes.string,
    dropdownClass: PropTypes.string,
  };

  static defaultProps = {
    containerClass: 'scenario-legend-control',
    buttonText: 'Legend',
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { metadata: { singleDoseCurveView } } = this.context;

    document.addEventListener('click', this.handleClickOutside, true);

    this.setState({ open: !!singleDoseCurveView });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleKeyDown = event => {
    const ESC_KEY = 27;
    if (event.which === ESC_KEY) {
      this.toggleDropdown();
    }
  };

  toggleDropdown = () => {
    const open = !this.state.open;

    this.setState({
      open: open,
    });
  };

  handleClickOutside = event => {
    if (!this.state.open) return;

    const domNode = this.containerRef;

    if (!domNode || !domNode.current.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  render() {
    const { containerClass, buttonText, children, dropdownClass } = this.props;

    return (
      <div className={containerClass} ref={this.containerRef} onKeyDown={this.handleKeyDown}>
        <button className="btn scenario-dropdown-show" onClick={this.toggleDropdown}>{buttonText}</button>

        <div
          onKeyDown={this.handleKeyDown}
          className={classNames(dropdownClass, 'scenario-dropdown', 'tri-up', {
            isOpen: this.state.open,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Legend;
