import React, { useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import { getLimitOptions } from '../../modules/doseOptionsUtils';
import GraphContext from '../GraphContext';
import EditDoseButton from './EditDoseButton';
import EditDoseSelect from './EditDoseSelect';
import { shouldDoseEditFormAlignLeft } from '../../modules/helpers';

const EditDose = props => {
  const { dose, onClose, onDoseEdit, isActive } = props;

  const {
    amount,
    isAmountEditable,
    isEditable,
    isLocationEditable,
    location,
    showOralTooltip,
    tooltipDisableText,
    tooltipEnableText,
    xstart,
  } = dose;

  const [currentAmount, setAmount] = useState(amount);
  const [currentLocation, setLocation] = useState(location);
  const {
    doses,
    entireDomain,
    metadata,
    zoomDomain,
  } = useContext(GraphContext);

  useEffect(() => {
    // update local state if the dose amount changes outside of this component
    setAmount(amount);
  }, [amount]);

  const handleApplyClick = (applyToFutureDoses) => {
    return onDoseEdit(
      {
        ...dose,
        amount: currentAmount,
        location: currentLocation,
      },
      applyToFutureDoses
    );
  };

  const { amountOptions, locationOptions } = getLimitOptions(dose, doses, metadata);
  const text = !isAmountEditable && !isLocationEditable ? tooltipDisableText : tooltipEnableText;
  const showApplyToThisDoseButton = !showOralTooltip;
  const showApplyToAllButton = !metadata.pp3mScenario;
  const showDayOne = !metadata.singleDoseCurveView && xstart === 0;
  const applyToAllButtonLabel = showApplyToThisDoseButton ? 'Apply to All Future Doses' : 'Apply';

  if (!isActive) {
    return null;
  }

  return (
    <div className={classnames('edit-dose', {
      'edit-dose--align-left': shouldDoseEditFormAlignLeft(dose, metadata, zoomDomain, entireDomain.day)
    })}>
      <header className="edit-dose-header">
        <h2 className="edit-dose-text">
          {text}
          {showDayOne && (
            <><br /><span className="edit-dose-day-one">(Day 1)</span></>
          )}
        </h2>
        <button className="btn btn--close btn--dark" onClick={onClose}>×</button>
      </header>

      <div className="edit-dose-options">
        <EditDoseSelect
          disabled={!isAmountEditable}
          visible={true}
          id="select-dose-amount"
          label="Dose Amount"
          defaultValue={amount}
          options={amountOptions}
          onChange={setAmount}
          modifier="primary"
        />
        <EditDoseSelect
          disabled={!isLocationEditable}
          visible={true}
          id="select-dose-location"
          label="Dose Location"
          defaultValue={location}
          options={locationOptions}
          onChange={setLocation}
          modifier="dark"
        />
      </div>

      {isEditable && (
        <div className="edit-dose-actions">
          {showApplyToThisDoseButton && (
            <EditDoseButton
              onClick={() => handleApplyClick(false)}
            >
              Apply to this Dose
            </EditDoseButton>
          )}
          {showApplyToAllButton && (
            <EditDoseButton
              className={classnames({
                'btn--dark': showApplyToThisDoseButton
              })}
              onClick={() => handleApplyClick(true)}
            >
              {applyToAllButtonLabel}
            </EditDoseButton>
          )}
        </div>
      )}
    </div>
  );
};

export default EditDose;
