import first from 'lodash/first';
import last from 'lodash/last';
import { pipe, filter, map } from './reducers';

export const domainsByMonthView = {
  // note: order does not matter here
  // [months in view]: [days in view]
  1: 30,
  3: 90,
  6: 180,
  12: 365,
};

export const pp3mDomainsByMonthView = {
  ...domainsByMonthView,
  18: 545,
};

/**
 * Get available zoom levels based on scenario type
 * @param {object} scenario
 */
export const getDomainsForScenario = scenario => {
  return scenario.pp3mScenario ? pp3mDomainsByMonthView : domainsByMonthView;
};

/**
 * Is the given domain at the maximum zoom level?
 * @param {array} domain [x1, x2]
 * @param {object} metadata
 * @returns {boolean}
 */
export const isDomainAtMaxZoom = (domain, metadata) => {
  const domains = getDomainsForScenario(metadata);
  const maxZoomPeriod = first(Object.keys(domains));
  return getRangeForDomain(domain) === domains[maxZoomPeriod];
};

/**
 * Is the given domain at the minimum zoom level?
 * @param {array} domain [x1, x2]
 * @param {object} metadata
 * @returns {boolean}
 */
export const isDomainAtMinZoom = (domain, metadata) => {
  const domains = getDomainsForScenario(metadata);
  const minZoomPeriod = last(Object.keys(domains));
  return getRangeForDomain(domain) === domains[minZoomPeriod];
};

/**
 * Returns the range for the given domain
 * @param {array} domain [x1, x2]
 * @returns {number}
 */
export const getRangeForDomain = domain => {
  return domain[1] - domain[0];
};

/**
 * Returns a subset of the given domain that is suitable for displaying active dose tabs
 * @param {array} domain
 */
export const getSafeDomainForDoseTabs = domain => {
  const range = getRangeForDomain(domain);
  const paddingByRange = {
    30: 4,
    90: 14,
    180: 28,
    365: 56,
    545: 84,
  };
  const padding = paddingByRange[range];
  const safeDomain = [domain[0] + padding, domain[1] - padding];
  return safeDomain;
};

export const isDayWithinDomain = (day, domain) => {
  return day >= domain[0] && day <= domain[1];
};

export const getOffsetToFitDoseInDomain = (day, domain) => {
  let offset = 0;
  if (day < domain[0]) {
    offset = day - domain[0];
  }
  else if (day > domain[1]) {
    offset = day - domain[1];
  }
  return offset;
};

export const areDomainsTheSame = (domainA, domainB) => {
  return domainA[0] === domainB[0] && domainA[1] === domainB[1];
};

/**
 * Get the initial visible domain for a scenario
 * @param {object} scenario
 */
export const getInitialDomainForScenario = scenario => {
  const dayRange = getDayRangeForScenario(scenario);
  const dayOffset = scenario.scrollOffset || 0;
  return getDomainByPeriod(scenario.period, dayRange, dayOffset, scenario);
};

/**
 * Get domain in days by a period in months
 * @param {string|number} period e.g. "6 months"
 * @param {[number]} dayRange
 * @param {number} offset
 * @param {object} scenario
 * @returns array [x1, x2]
 */
export const getDomainByPeriod = (period, dayRange, offset = 0, scenario) => {
  const domains = getDomainsForScenario(scenario);
  const monthsInView = parseInt(period, 10) || 1;
  const periodRange = domains[monthsInView];
  const start = dayRange[0] + offset;
  const end = dayRange[0] + periodRange + offset;
  return [start, end];
};

/**
 * Calc the entire domain for days and levels based on a collection of doses
 * @param {array[object]} doses
 * @param {object} scenario
 * @returns {object}
 */
export const getEntireDomain = (doses, scenario) => {

  const isSingleDoseCurveView = scenario.singleDoseCurveView;

  const filterSampleDoses = isSingleDoseCurveView
    ? filter(() => true) : filter(dose => dose.isCumulative);

  // cumulative doses will naturally have the highest levels
  const sampleLevels = pipe(
    filterSampleDoses,
    map(dose => dose.data),
    map(data => data.level),
  )(doses);

  const minDomainY = 37;
  let maxLevel = Math.max(...sampleLevels);
  maxLevel = maxLevel < minDomainY ? minDomainY : maxLevel;

  const domain = {
    level: [0, maxLevel],
    day: getDayRangeForScenario(scenario),
  };

  return domain;
};

/**
 * Get the entire day range for a scenario
 * @param {object} scenario
 */
export const getDayRangeForScenario = scenario => {
  return [scenario.minHorizontalScroll, scenario.maxHorizontalScroll];
};

/**
 * Get period in months by a domain in days
 * @param {array} domain [x1, x2]
 * @param {object} scenario
 * @returns number (months)
 */
export const getPeriodByDomain = (domain, scenario) => {
  const domains = getDomainsForScenario(scenario);
  const domainRange = getRangeForDomain(domain);
  return Object.keys(domains).find(months => {
    const periodRange = domains[months];
    return domainRange === periodRange;
  });
};

export const getNextZoomDomainRange = ({ zoomDomain, zoom, metadata }) => {
  const domains = getDomainsForScenario(metadata);
  const currentPeriod = getPeriodByDomain(zoomDomain, metadata);
  const periods = Object.keys(domains);
  const currentIndex = periods.indexOf(currentPeriod) || 0;
  const lastPeriodIndex = periods.length - 1;

  let nextPeriodIndex = currentIndex + zoom * -1;

  // restrict zoom to supported month views
  if (nextPeriodIndex < 0) {
    nextPeriodIndex = 0;
  } else if (nextPeriodIndex > lastPeriodIndex) {
    nextPeriodIndex = lastPeriodIndex;
  }

  const nextRange = domains[periods[nextPeriodIndex]];
  return nextRange;
};

/**
 * Update visible domain by zooming or panning
 * @param {array} zoomDomain [x1, x2]
 * @param {array} entireDomain [x1, x2]
 * @param {number} zoom (1 to zoom in, -1 to zoom out)
 * @param {number} pan (+number to pan right, -number to pan left)
 */
export const updateDomain = ({ zoomDomain, entireDomain, metadata, zoom = 0, pan = 0 }) => {
  const minX = entireDomain.day[0];
  const maxX = entireDomain.day[1];
  let x1;
  let x2;
  let range;

  if (zoom) {
    range = getNextZoomDomainRange({ zoomDomain, zoom, metadata });
    x1 = zoomDomain[0];
    x2 = zoomDomain[0] + range;
  }

  // simulataneous zooming and panning is not currently supported
  else if (pan) {
    range = zoomDomain[1] - zoomDomain[0];
    x1 = zoomDomain[0] + pan;
    x2 = zoomDomain[1] + pan;
  }

  // restrict zoom/pan to the bounds of the entire domain
  if (x2 > maxX) {
    x2 = maxX;
    x1 = maxX - range;
  }

  // check x1 after x2 in order to show any overflow after the scenario range
  if (x1 < minX) {
    x1 = minX;
    x2 = minX + range;
  }

  return [ x1, x2 ];
};
