import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VictoryArea, VictoryLabel } from 'victory';
import GraphContext from '../GraphContext';

const propTypes = {
  yDomain: PropTypes.arrayOf(PropTypes.number),
};

function GraphShade(props) {
  const { yDomain, ...passThroughProps } = props;
  const { metadata } = useContext(GraphContext);

  if (!metadata.graphShade) {
    return null;
  }

  const {
    minHorizontalScroll,
    graphShade: { startDay, endDay, label, color, textColor, borderColor },
  } = metadata;

  // the startDay needs to be offset by the minHorizontalScroll
  // the endDay is actually the length of time the shade should cover
  const startXValue = startDay + minHorizontalScroll;
  const endXValue = startXValue + endDay;

  return (
    <VictoryArea
      {...passThroughProps}
      animate={false}
      name="shade-range"
      labels={[label]}
      data={[
        { x: startXValue, y: yDomain[1] },
        { x: endXValue, y: yDomain[1] },
      ]}
      style={{
        data: {
          fill: color,
          stroke: borderColor,
        },
        labels: {
          angle: -90,
          fill: textColor,
          textAnchor: 'end',
        },
      }}
      labelComponent={<VictoryLabel dy={15} dx={-10} />}
    />
  );
}

GraphShade.propTypes = propTypes;
export default GraphShade;
