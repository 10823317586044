import React from 'react';
import { isDoseDayShiftable } from '../../modules/doseUtils';

const DoseShiftLabel = ({ dose, isActive, metadata }) => {

  const {
    maximumShift,
    rootStart,
    showDayLabel,
    xstart,
  } = dose;

  const isShiftable = isDoseDayShiftable(dose, metadata);
  const showLabel = isActive && isShiftable;

  if (!showLabel) {
    return null;
  }

  let unit = 'day';
  let currentShiftAmount = xstart - rootStart;
  let totalShiftAllowed = maximumShift;

  if (metadata.pp3mScenario) {
    unit = 'week';
    currentShiftAmount = currentShiftAmount / 7;
    totalShiftAllowed = totalShiftAllowed / 7;
  }

  const hasMoved = currentShiftAmount !== 0;
  let action = hasMoved ? 'Moved' : 'Move';
  let shiftAmount = hasMoved ? currentShiftAmount : totalShiftAllowed;
  let operator = hasMoved ? (shiftAmount > 0 ? '+' : '') : '+/- ';

  const pluralize = unit => shiftAmount > 1 || shiftAmount < -1
    ? `${unit}s` : unit;

  let label = showDayLabel ? `Day ${xstart + 1}`
    : `${action} ${operator}${shiftAmount} ${pluralize(unit)}`;

  // custom label for PP3M 4-9 month scenarios
  if (metadata.pp3mScenario && maximumShift >= 66.5) {
    shiftAmount = hasMoved ? shiftAmount + 17 : 17;
    label = `+${shiftAmount} weeks since last injection`;
  }

  return (
    <div className="dose-tab-shift">
      <span className="dose-tab-text dose-tab-text--shift">{label}</span>
    </div>
  );
}

export default DoseShiftLabel;
