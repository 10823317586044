import React from 'react';
import PropTypes from 'prop-types';
import { VictoryGroup, VictoryScatter, VictoryTooltip, VictoryClipContainer } from 'victory';
import Dot from './Dot';
import InfoBoxContainer from './InfoBoxContainer';
import GraphContext from '../GraphContext';
import { filter, pipe } from '../../modules/reducers';
import { getRangeForDomain } from '../../../../sandbox/src/graph/modules/domains';
import { getDoseByOriginalStartDay } from '../../modules/doseUtils';

const tooltipDataShape = {
  /**
   * if true, the tooltip dot should follow the corresponding dose with the same original x value
   */
   bindToDose: PropTypes.bool,
  /**
   * the x-axis value where the tooltip should appear
   * matches the `day` property of the dose datum
   */
  x: PropTypes.number,
  /**
   * the body copy of the tooltip
   * data is encoded html, so it needs to be applied using `dangerouslySetInnerHtml`
   */
  text: PropTypes.string,
};

class InfoFlyouts extends React.Component {

  static contextType = GraphContext;

  static propTypes = {
    /**
     * the cumulative dose information
     * used to plot the tooltip points
     */
    cumulativeDoseCurves: PropTypes.array,
    /**
     * an array of tooltip objects to be displayed at points along the cumulative dose line
     *
     */
    tooltips: PropTypes.shape({
      blueCurve: PropTypes.arrayOf(
        PropTypes.shape(tooltipDataShape)
      ),
      greenCurve: PropTypes.arrayOf(
        PropTypes.shape(tooltipDataShape)
      ),
    }),
  };

  constructor(props) {
    super(props);

    const { blueCurve, greenCurve } = props.tooltips;

    const blueCurveTooltipPoints = blueCurve.reduce((acc, tip) => acc.concat(tip.x), []);

    const greenCurveTooltipPoints = greenCurve.reduce((acc, tip) => acc.concat(tip.x), []);

    const tooltipPoints = [...blueCurveTooltipPoints, ...greenCurveTooltipPoints];

    const tooltipsData = [...blueCurve, ...greenCurve].map((datum, index, arr) => {
      return {
        ...datum,
        isFirst: index === 0,
        isLast: index === arr.length -1,
      };
    });

    const anchorPositions = tooltipPoints.reduce(
      (acc, dot) => ({
        ...acc,
        [dot]: { x: null, y: null },
      }),
      {}
    );

    this.state = {
      currentFlyout: null,
      showFlyout: false,
      tooltipsData,
      tooltipPoints,
      greenCurveTooltipPoints,
      blueCurveTooltipPoints,
      anchorPositions,
    };
  }

  /**
   * Set active flyout on click
   * @param {bool} isActive
   * @param {number} nextFlyout (day associated with the flyout)
   */
  setFlyout = (isActive, nextFlyout = null) => {
    this.setState(prevState => ({
      showFlyout: isActive,
      currentFlyout: nextFlyout !== null ? nextFlyout : prevState.currentFlyout,
    }), () => {
      if (isActive) {
        this.updateDomainToFitFlyout();
      }
    });
  };

  updateDomainToFitFlyout = () => {
    const { currentFlyout } = this.state;
    const { handleDomainChanges, zoomDomain } = this.context;
    const tooltipDatum = this.scatterData.find(datum => datum.day === currentFlyout);
    const currentTooltipDay = tooltipDatum.currentDay;
    const range = getRangeForDomain(zoomDomain);
    const domainHalfwayPoint = zoomDomain[0] + (range / 2);
    const flyoutIsInView = currentTooltipDay > zoomDomain[0] && currentTooltipDay < zoomDomain[1];
    const flyoutIsOnRightHalfOfDomain = currentTooltipDay > domainHalfwayPoint;

    if (!flyoutIsInView || flyoutIsOnRightHalfOfDomain) {
      // if the flyout icon falls within the right half of the visible domain,
      // scroll the graph forward so that the flyout is entirely in view
      const leftPadding = Math.ceil(range * 0.05);
      const pan = currentTooltipDay - zoomDomain[0] - leftPadding;
      handleDomainChanges({ pan });
    }
  };

  // track anchor positions
  setPosition = (dotIndex, nextPosition) => {
    this.setState(prevState => ({
      anchorPositions: {
        ...prevState.anchorPositions,
        [dotIndex]: { ...nextPosition },
      },
    }));
  };

  cycleFlyout = direction => {
    const { currentFlyout, tooltipPoints } = this.state;
    const lastIndex = tooltipPoints.length - 1;
    const currentIndex = tooltipPoints.indexOf(currentFlyout);
    const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    let nextFlyoutPoint;

    if (nextIndex < 0 || nextIndex > lastIndex) {
      return;
    }

    nextFlyoutPoint = tooltipPoints[nextIndex];
    this.setFlyout(true, nextFlyoutPoint);
  };

  getTooltipByX = x => {
    const { tooltipsData } = this.state;
    return tooltipsData.find(tooltip => tooltip.x === x)
  };

  get tooltip() {
    const { currentFlyout } = this.state;
    if (currentFlyout === null) return {};
    return this.getTooltipByX(currentFlyout);
  }

  get anchorPosition() {
    return this.state.anchorPositions[this.state.currentFlyout];
  }

  get scatterData() {

    const { greenCurveTooltipPoints, blueCurveTooltipPoints } = this.state;
    const { cumulativeDoseCurves } = this.props;
    const { doses } = this.context;

    // positive day tooltips
    const greenCurveTooltipData = pipe(
      filter(dose => dose.isGreenCurve || dose.isPp3mCurve),
      greenCurveDoses => {
        return greenCurveDoses.reduce((tooltips, dose) => {
          return tooltips.concat(
            dose.data.filter(datum => greenCurveTooltipPoints.includes(datum.day))
          );
        }, [])
      },
      tooltipsData => {
        // dedupe days for pp3m scenarios
        return tooltipsData.reduce((dedupedTooltips, datum) => {
          const isDuplicate = dedupedTooltips.some(tooltip => tooltip.day === datum.day);
          return isDuplicate ? dedupedTooltips : dedupedTooltips.concat(datum);
        }, []);
      },
    )(cumulativeDoseCurves);

    // negative day "anterior" tooltips
    const blueCurveTooltipData = pipe(
      filter(dose => dose.isBlueCurve),
      blueCurveDoses => {
        return blueCurveDoses.reduce((tooltips, dose) => {
          return tooltips.concat(
            dose.data.filter(datum => blueCurveTooltipPoints.includes(datum.day))
          );
        }, [])
      }
    )(cumulativeDoseCurves);

    const mergedData = blueCurveTooltipData.concat(greenCurveTooltipData)
      .map(datum => {
        const tooltip = this.getTooltipByX(datum.day);

        let currentDay = datum.day;
        const bindToDoseStartDay = tooltip.bindToDoseStartDay === '1';

        if (bindToDoseStartDay) {
          // bind this tooltip's x value to the corresponding dose's current x value
          const correspondingDose = getDoseByOriginalStartDay(doses, datum.day);
          currentDay = correspondingDose ? correspondingDose.xstart : datum.day;
        }

        return {
          ...datum,
          currentDay
        };
      });

    return mergedData;
  }

  render() {
    const { doses } = this.props;
    const { currentFlyout, showFlyout } = this.state;

    if (!doses) return null;

    return (
      <VictoryGroup {...this.props} data={this.scatterData} x="currentDay" y="level">
        <VictoryScatter
          animate={false}
          groupComponent={<VictoryClipContainer clipPadding={{ left: 20, bottom: 20, right: 20 }} />}
          dataComponent={
            <Dot
              showFlyout={showFlyout}
              currentFlyout={currentFlyout}
              setFlyout={this.setFlyout}
              setPosition={this.setPosition}
            />
          }
          // use a wrapper(ie. DoseTooltip) to render our tooltip
          // in a portal(#tooltip-portal) using `react-portal`
          // get the tooltip to show from the Dot's click handler
          // be able to cycle using next/prev buttons
          labels={[' ']}
          labelComponent={
            <VictoryTooltip
              active={showFlyout}
              flyoutComponent={
                <InfoBoxContainer
                  setFlyout={this.setFlyout}
                  cycleFlyout={this.cycleFlyout}
                  anchorPosition={this.anchorPosition}
                  tooltip={this.tooltip}
                />
              }
            />
          }
        />
      </VictoryGroup>
    );
  }
}

export default InfoFlyouts;
