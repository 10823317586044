import React from 'react';
import Graph from './Graph';

const GraphWrapper = () => {
  // get data and settings from global variable created in template (scenario.html)
  const { legendItems, printMode, scenario, translations } = eddi.settings;

  return (
    <Graph
      legendItems={legendItems}
      printMode={printMode}
      scenario={scenario}
      translations={translations}
    />
  );
};

export default GraphWrapper;
