import inRange from 'lodash/inRange';
import range from 'lodash/range';

const TICK_SPACING = 12;
const TICKS_TO_DAYS_THRESHOLD = 10;
const TICKS_TO_MONTHS_THRESHOLD = 70;

export const daysToWeeks = days => Math.ceil(days / 7);

/**
 * Get ticks for the given domain
 * @param {array} domain [x1, x2]
 */
export const getTicksForDomain = domain => {
  const endDay = domain[1];
  const startDay = domain[0];
  const weeksInFrame = daysToWeeks(endDay - startDay);

  const tickConditions = [
    {
      // one tick per four weeks
      rule: weeksInFrame > TICKS_TO_MONTHS_THRESHOLD,
      ticks: (startDay, endDay) => {
        return range(daysToWeeks(startDay), daysToWeeks(endDay))
          .filter(x => x % 4 === 0)
          .map(x => x * 7);
      }
    },
    {
      // one tick per week
      rule: weeksInFrame > TICKS_TO_DAYS_THRESHOLD,
      ticks: (startDay, endDay) => {
        return range(daysToWeeks(startDay), daysToWeeks(endDay)).map(x => x * 7)
      }
    },
    {
      // one tick per day
      rule: weeksInFrame <= TICKS_TO_DAYS_THRESHOLD,
      ticks: (startDay, endDay) => {
        return range(Math.floor(startDay), Math.floor(endDay));
      }
    },
  ];

  const match = tickConditions.find(condition => condition.rule);
  return match.ticks(startDay, endDay);
};

/**
 * Calculate x-axis ticks and labels for the given domain
 * @param {array} domain [x1, x2]
 * @returns {object}
 */
export const calcXAxisTicks = domain => {
  const endDay = domain[1];
  const startDay = domain[0];
  const labelTick = x => `${x} Weeks`;

  const weeksInFrame = daysToWeeks(endDay - startDay);

  // create an array of all ticks, either in days or weeks
  const ticks = getTicksForDomain(domain);

  // create an array of tick labels
  // NOTE: all ticks shown on the x-axis must be in this array
  // therefore we use use empty string for non-labeled ticks
  // => ["","","3 weeks","","","6 weeks"]
  const labels = ticks.map(days => {
    const WEEK_NUM = daysToWeeks(days);

    // if weeks in view are below the lower bound
    // they are shown as days and
    // we label every week (7th tick)
    if (weeksInFrame <= TICKS_TO_DAYS_THRESHOLD) {
      return days % 7 === 0 ? labelTick(WEEK_NUM) : '';
    }

    // if weeks in view are between 30 and the lower bound
    // label every 3rd tick
    if (inRange(weeksInFrame, TICKS_TO_DAYS_THRESHOLD, 30)) {
      return WEEK_NUM % (TICK_SPACING / 4) === 0 ? labelTick(WEEK_NUM) : '';
    }

    if (inRange(weeksInFrame, 30, 60)) {
      return WEEK_NUM % (TICK_SPACING / 2) === 0 ? labelTick(WEEK_NUM) : '';
    }

    // default ticks ticks are in weeks
    // and every 12th week is labled
    return WEEK_NUM % TICK_SPACING === 0 ? labelTick(WEEK_NUM) : '';
  });

  return { values: ticks, formats: labels };
};
