import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VictoryGroup, VictoryLine } from 'victory';
import GraphContext from '../GraphContext';
import theme from '../../modules/theme';
import { BLUE_CURVE } from '../../modules/data';
import { singleDoseCurveColors } from '../../modules/config';
import { getDoseFormulationColor } from '../../modules/doseUtils';

const propTypes = {
  dose: PropTypes.object
}

const Dose = props => {

  const { metadata } = useContext(GraphContext);
  const { dose } = props;
  const { color, data, formulation, isCumulative, isVisible, key, location } = dose;

  const isIndividualPp3mDose = metadata.pp3mScenario && !isCumulative;
  const isCurveVisible = isCumulative || dose.isCurveVisible;
  const inBlueCurve = location in BLUE_CURVE;

  if (!isCurveVisible || !isVisible || inBlueCurve || isIndividualPp3mDose) return null;

  let strokeWidth = isCumulative ? 3 : 1;
  let stroke = color || theme.line.style.data.color;

  if (formulation) {
    stroke = getDoseFormulationColor(formulation);
  }

  if (metadata.singleDoseCurveView) {
    stroke = singleDoseCurveColors[key];
  }

  return (
    <VictoryGroup
      {...props}
      data={data}
      animate={{
        duration: 500
      }}
      x="day"
      y="level"
    >
      <VictoryLine style={{
        data: { strokeWidth, stroke }
      }} />
    </VictoryGroup>
  );
};

Dose.propTypes = propTypes;
export default Dose;
