import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import GraphContext from '../GraphContext';
import infoDot from '../../../img/graphs/info.svg';
import infoDotActive from '../../../img/graphs/info-active.svg';

const propTypes = {
  /**
   * open/close state of the tooltip flyout
   */
  showFlyout: PropTypes.bool,
  /**
   * the flyout currently being displayed
   */
  currentFlyout: PropTypes.number,
  /**
   * function to set the parent state of the flyout
   */
  setFlyout: PropTypes.func,
  /**
   * updates the position of this Dot in the parent state
   */
  setPosition: PropTypes.func,
};
function Dot(props) {
  const { datum, index, x, y, setFlyout, showFlyout, currentFlyout, setPosition } = props;
  const isCurrentFlyout = showFlyout && currentFlyout === datum.day;
  const imgSize = 28;
  const imgOffset = imgSize / 2;
  const { printMode } = useContext(GraphContext);

  useEffect(() => {
    const currentPosition = { x, y };
    // update parent state when dot position changes
    setPosition(datum.day, currentPosition);
  }, [setPosition, x, y, datum.day]);

  const handleClick = () => {
    // if this flyout is currently open, close on click
    if (isCurrentFlyout) {
      setFlyout(false);
    } else {
      // else show this flyout
      setFlyout(true, datum.day);
    }
  };

  return printMode ? (
    <g>
      <circle className="edi-print-dot-circle" cx={x} cy={y} r="9" />
      <text className="edi-print-dot-text" x={x} y={y} dy="5">{index + 1}</text>
    </g>
  ) : (
    <image
      className="tooltip-img"
      href={isCurrentFlyout ? infoDotActive : infoDot}
      x={x - imgOffset}
      y={y - imgOffset}
      height={imgSize}
      width={imgSize}
      onClick={handleClick}
    />
  );
}

Dot.propTypes = propTypes;
export default Dot;
