// Additional Data for use
export const DELTOID = 'Deltoid';
export const GLUTEAL = 'Gluteal';
export const ORAL = 'Oral';
export const OTHER = 'Other';
export const INJECTIONS = 'HaldolDecoanate';
export const ORAL_INVEGA = 'InvegaER';
export const ORAL_RISPERDAL = 'OralRisperdal';

export const DOSE_39 = '39mg';
export const DOSE_78 = '78mg';
export const DOSE_117 = '117mg';
export const DOSE_156 = '156mg';
export const DOSE_234 = '234mg';
export const DOSE_ORAL = '6mg';

export const DOSE_ORAL_RISPERDAL = '3mg';
export const DOSE_UNKNOWN = '';

export const GREEN_CURVE = {};
GREEN_CURVE[DELTOID] = true;
GREEN_CURVE[GLUTEAL] = true;
GREEN_CURVE[ORAL] = true;
GREEN_CURVE[ORAL_INVEGA] = true;
GREEN_CURVE[ORAL_RISPERDAL] = true;

export const BLUE_CURVE = {};
BLUE_CURVE[ORAL] = true;
BLUE_CURVE[OTHER] = true;
BLUE_CURVE[INJECTIONS] = true;
BLUE_CURVE[ORAL_INVEGA] = true;
BLUE_CURVE[ORAL_RISPERDAL] = true;

export const INJECTABLE_CURVE = {
  [INJECTIONS]: true,
};

export const allowedAmountsByScenario = {
  pp1mOralRisperdal: ['1mg', '2mg', '3mg', '4mg', '6mg'],
  pp1mSchizophrenia: ['39mg', '78mg', '117mg', '156mg', '234mg'],
  pp1mSchizoaffective: ['78mg', '117mg', '156mg', '234mg'],
  pp3mOralInvegaEr: ['3mg', '6mg', '9mg', '12mg'],
  pp3mSchizophrenia: ['273mg', '410mg', '546mg', '819mg'],
  pp3mInjectable: [], // not editable
  pp3mRisperdal: ['25mg', '37.5mg', '50mg'],
  renalImpairment: ['39mg', '78mg', '117', '156'],
};

// drugLevel["location:amount"] = data
// data is an array of arrays, where each subarray has the
// format [time, level], where time is measured in hours and
// the level is measured in ng/mL.
const drugLevel = {};

drugLevel['Gluteal:39mg'] = [
  [0, 0],
  [24, 0.982678],
  [48, 1.62949],
  [72, 2.02666],
  [96, 2.36031],
  [120, 2.57378],
  [144, 2.74889],
  [168, 2.92399],
  [192, 3.02241],
  [216, 3.12083],
  [240, 3.18614],
  [264, 3.25145],
  [288, 3.28371],
  [312, 3.31597],
  [336, 3.20705],
  [360, 3.09813],
  [384, 3.02731],
  [408, 2.95648],
  [432, 2.86519],
  [456, 2.77389],
  [480, 2.68498],
  [504, 2.59607],
  [528, 2.55431],
  [552, 2.51254],
  [576, 2.44961],
  [600, 2.38668],
  [624, 2.32561],
  [648, 2.26454],
  [672, 2.22495],
  [696, 2.18537],
  [720, 2.12548],
  [744, 2.0656],
  [768, 2.02055],
  [792, 1.9755],
  [816, 1.93878],
  [840, 1.90206],
  [864, 1.86603],
  [888, 1.83],
  [912, 1.78845],
  [936, 1.7469],
  [960, 1.70688],
  [984, 1.66686],
  [1008, 1.64482],
  [1032, 1.62279],
  [1056, 1.58754],
  [1080, 1.5523],
  [1104, 1.51729],
  [1128, 1.48228],
  [1152, 1.45014],
  [1176, 1.41801],
  [1200, 1.3869],
  [1224, 1.35579],
  [1248, 1.33654],
  [1272, 1.31729],
  [1296, 1.29143],
  [1320, 1.26557],
  [1344, 1.23323],
  [1368, 1.20089],
  [1392, 1.17809],
  [1416, 1.1553],
  [1440, 1.13419],
  [1464, 1.11309],
  [1488, 1.09366],
  [1512, 1.07423],
  [1536, 1.05233],
  [1560, 1.03042],
  [1584, 1.00852],
  [1608, 0.993198],
  [1632, 0.97788],
  [1656, 0.962561],
  [1680, 0.947242],
  [1704, 0.931923],
  [1728, 0.912575],
  [1752, 0.893227],
  [1776, 0.873879],
  [1800, 0.854531],
  [1824, 0.835182],
  [1848, 0.81974],
  [1872, 0.804298],
  [1896, 0.788856],
  [1920, 0.773413],
  [1944, 0.757971],
  [1968, 0.74616],
  [1992, 0.734349],
  [2016, 0.722538],
  [2040, 0.710727],
  [2064, 0.698916],
  [2088, 0.686399],
  [2112, 0.673882],
  [2136, 0.661365],
  [2160, 0.648849],
  [2184, 0.636332],
  [2208, 0.624989],
  [2232, 0.613647],
  [2256, 0.602304],
  [2280, 0.590961],
  [2304, 0.579619],
  [2328, 0.570116],
  [2352, 0.560614],
  [2376, 0.551112],
  [2400, 0.54161],
  [2424, 0.532107],
  [2448, 0.524303],
  [2472, 0.516498],
  [2496, 0.508694],
  [2520, 0.500889],
  [2544, 0.493084],
  [2568, 0.484841],
  [2592, 0.476598],
  [2616, 0.468355],
  [2640, 0.460111],
  [2664, 0.451868],
  [2688, 0.443694],
  [2712, 0.43552],
  [2736, 0.427346],
  [2760, 0.419173],
  [2784, 0.410999],
  [2808, 0.404631],
  [2832, 0.398263],
  [2856, 0.391895],
  [2880, 0.385527],
  [2904, 0.379159],
  [2928, 0.37291],
  [2952, 0.366661],
  [2976, 0.360412],
  [3000, 0.354163],
  [3024, 0.347914],
  [3048, 0.342931],
  [3072, 0.337949],
  [3096, 0.332966],
  [3120, 0.327984],
  [3144, 0.323001],
  [3168, 0.317589],
  [3192, 0.312176],
  [3216, 0.306764],
  [3240, 0.301352],
  [3264, 0.29594],
  [3288, 0.291277],
  [3312, 0.286615],
  [3336, 0.281952],
  [3360, 0.27729],
  [3384, 0.272627],
  [3408, 0.268614],
  [3432, 0.264601],
  [3456, 0.260588],
  [3480, 0.256575],
  [3504, 0.252562],
  [3528, 0.248318],
  [3552, 0.244075],
  [3576, 0.239831],
  [3600, 0.235587],
  [3624, 0.231344],
  [3648, 0.227735],
  [3672, 0.224127],
  [3696, 0.220518],
  [3720, 0.21691],
  [3744, 0.213301],
  [3768, 0.209964],
  [3792, 0.206627],
  [3816, 0.20329],
  [3840, 0.199953],
  [3864, 0.196617],
  [3888, 0.194188],
  [3912, 0.191759],
  [3936, 0.189331],
  [3960, 0.186902],
  [3984, 0.184473],
  [4008, 0.181279],
  [4032, 0.178085],
  [4056, 0.174891],
  [4080, 0.171697],
  [4104, 0.168503],
  [4128, 0.165726],
  [4152, 0.162949],
  [4176, 0.160172],
  [4200, 0.157395],
  [4224, 0.154618],
  [4248, 0.152313],
  [4272, 0.150008],
  [4296, 0.147704],
  [4320, 0.145399],
  [4344, 0.143095],
  [4368, 0.140942],
  [4392, 0.138789],
  [4416, 0.136636],
  [4440, 0.134484],
  [4464, 0.132331],
  [4488, 0.130544],
  [4512, 0.128757],
  [4536, 0.12697],
  [4560, 0.125184],
  [4584, 0.123397],
  [4608, 0.121156],
  [4632, 0.118915],
  [4656, 0.116673],
  [4680, 0.114432],
  [4704, 0.112191],
  [4728, 0.110481],
  [4752, 0.10877],
  [4776, 0.107059],
  [4800, 0.105349],
  [4824, 0.103638],
  [4848, 0.101919],
  [4872, 0.100199],
  [4896, 0.0984797],
  [4920, 0.0967602],
  [4944, 0.0950407],
  [4968, 0.0941155],
  [4992, 0.0931902],
  [5016, 0.092265],
  [5040, 0.0913398],
  [5064, 0.0904146],
  [5088, 0.088793],
  [5112, 0.0871715],
  [5136, 0.08555],
  [5160, 0.0839285],
  [5184, 0.0823069],
  [5208, 0.0809565],
  [5232, 0.079606],
  [5256, 0.0782555],
  [5280, 0.076905],
  [5304, 0.0755546],
  [5328, 0.0740491],
  [5352, 0.0725436],
  [5376, 0.0710381],
  [5400, 0.0695326],
  [5424, 0.068027],
  [5448, 0.0672674],
  [5472, 0.0665078],
  [5496, 0.0657482],
  [5520, 0.0649886],
  [5544, 0.064229],
  [5568, 0.0633128],
  [5592, 0.0623965],
  [5616, 0.0614802],
  [5640, 0.060564],
  [5664, 0.0596477],
  [5688, 0.0583885],
  [5712, 0.0571293],
  [5736, 0.0558701],
  [5760, 0.0546109],
  [5784, 0.0533517],
  [5808, 0.0527731],
  [5832, 0.0521945],
  [5856, 0.0516159],
  [5880, 0.0510373],
  [5904, 0.0504587],
  [5928, 0.049486],
  [5952, 0.0485134],
  [5976, 0.0475407],
  [6000, 0.046568],
  [6024, 0.0455953],
  [6048, 0.0448326],
  [6072, 0.0440698],
  [6096, 0.0433071],
  [6120, 0.0425443],
  [6144, 0.0417816],
  [6168, 0.0411588],
  [6192, 0.040536],
  [6216, 0.0399133],
  [6240, 0.0392905],
  [6264, 0.0386677],
  [6288, 0.0380806],
  [6312, 0.0374936],
  [6336, 0.0369065],
  [6360, 0.0363194],
  [6384, 0.0357323],
  [6408, 0.0351097],
  [6432, 0.0344871],
  [6456, 0.0338645],
  [6480, 0.0332419],
  [6504, 0.0326193],
  [6528, 0.0320162],
  [6552, 0.0314132],
  [6576, 0.0308101],
  [6600, 0.030207],
  [6624, 0.0296039],
  [6648, 0.0292859],
  [6672, 0.0289679],
  [6696, 0.02865],
  [6720, 0.028332],
  [6744, 0.028014],
  [6768, 0.027476],
  [6792, 0.026938],
  [6816, 0.0264001],
  [6840, 0.0258621],
  [6864, 0.0253241],
  [6888, 0.0249985],
  [6912, 0.0246728],
  [6936, 0.0243472],
  [6960, 0.0240216],
  [6984, 0.023696],
  [7008, 0.0232202],
  [7032, 0.0227444],
  [7056, 0.0222686],
  [7080, 0.0217928],
  [7104, 0.021317],
  [7128, 0.0210064],
  [7152, 0.0206958],
  [7176, 0.0203851],
  [7200, 0.0200745],
  [7224, 0.0197639],
  [7248, 0.0195197],
  [7272, 0.0192756],
  [7296, 0.0190315],
  [7320, 0.0187874],
  [7344, 0.0185432],
  [7368, 0.0181731],
  [7392, 0.0178029],
  [7416, 0.0174328],
  [7440, 0.0170626],
  [7464, 0.0166924],
  [7488, 0.0164381],
  [7512, 0.0161837],
  [7536, 0.0159293],
  [7560, 0.015675],
  [7584, 0.0154206],
  [7608, 0.0151028],
  [7632, 0.014785],
  [7656, 0.0144672],
  [7680, 0.0141494],
  [7704, 0.0138316],
  [7728, 0.0137221],
  [7752, 0.0136125],
  [7776, 0.013503],
  [7800, 0.0133935],
  [7824, 0.0132839],
  [7848, 0.0130334],
  [7872, 0.0127829],
  [7896, 0.0125323],
  [7920, 0.0122818],
  [7944, 0.0120312],
  [7968, 0.0118539],
  [7992, 0.0116766],
  [8016, 0.0114993],
  [8040, 0.0113219],
  [8064, 0.0111446],
  [8088, 0.0109599],
  [8112, 0.0107752],
  [8136, 0.0105905],
  [8160, 0.0104058],
  [8184, 0.0102211],
  [8208, 0.0100141],
  [8232, 0.00980716],
  [8256, 0.00960018],
  [8280, 0.0093932],
  [8304, 0.00918623],
  [8328, 0.00902056],
  [8352, 0.00885489],
  [8376, 0.00868922],
  [8400, 0.00852355],
  [8424, 0.00835788],
  [8448, 0.00822991],
  [8472, 0.00810193],
  [8496, 0.00797395],
  [8520, 0.00784598],
  [8544, 0.007718],
  [8568, 0.00761602],
  [8592, 0.00751404],
  [8616, 0.00741206],
  [8640, 0.00731008],
  [8664, 0.0072081],
  [8688, 0.00711346],
  [8712, 0.00701883],
  [8736, 0.00692419],
  [8760, 0.00682955],
  [8784, 0.00673492],
  [8808, 0.00659322],
  [8832, 0.00645152],
  [8856, 0.00630983],
  [8880, 0.00616813],
  [8904, 0.00602643],
];
drugLevel['Gluteal:78mg'] = [
  [0, 0],
  [24, 1.61696],
  [48, 2.66914],
  [72, 3.33076],
  [96, 3.8756],
  [120, 4.22492],
  [144, 4.51182],
  [168, 4.79872],
  [192, 4.96255],
  [216, 5.12638],
  [240, 5.22526],
  [264, 5.32413],
  [288, 5.3752],
  [312, 5.42626],
  [336, 5.27363],
  [360, 5.121],
  [384, 5.00846],
  [408, 4.89591],
  [432, 4.76583],
  [456, 4.63576],
  [480, 4.49446],
  [504, 4.35315],
  [528, 4.30536],
  [552, 4.25758],
  [576, 4.16879],
  [600, 4.08001],
  [624, 3.98596],
  [648, 3.89191],
  [672, 3.84586],
  [696, 3.79981],
  [720, 3.70525],
  [744, 3.61069],
  [768, 3.57044],
  [792, 3.53018],
  [816, 3.46352],
  [840, 3.39685],
  [864, 3.34263],
  [888, 3.2884],
  [912, 3.23685],
  [936, 3.18531],
  [960, 3.12057],
  [984, 3.05583],
  [1008, 3.02894],
  [1032, 3.00206],
  [1056, 2.94935],
  [1080, 2.89664],
  [1104, 2.84822],
  [1128, 2.79981],
  [1152, 2.75584],
  [1176, 2.71187],
  [1200, 2.65265],
  [1224, 2.59343],
  [1248, 2.56562],
  [1272, 2.53781],
  [1296, 2.50622],
  [1320, 2.47463],
  [1344, 2.42272],
  [1368, 2.37082],
  [1392, 2.33647],
  [1416, 2.30213],
  [1440, 2.26397],
  [1464, 2.22582],
  [1488, 2.20441],
  [1512, 2.183],
  [1536, 2.14126],
  [1560, 2.09952],
  [1584, 2.05778],
  [1608, 2.03086],
  [1632, 2.00394],
  [1656, 1.97701],
  [1680, 1.95009],
  [1704, 1.92316],
  [1728, 1.89081],
  [1752, 1.85846],
  [1776, 1.82612],
  [1800, 1.79377],
  [1824, 1.76142],
  [1848, 1.73816],
  [1872, 1.71491],
  [1896, 1.69165],
  [1920, 1.6684],
  [1944, 1.64514],
  [1968, 1.61913],
  [1992, 1.59312],
  [2016, 1.56711],
  [2040, 1.54109],
  [2064, 1.51508],
  [2088, 1.49274],
  [2112, 1.47039],
  [2136, 1.44805],
  [2160, 1.4257],
  [2184, 1.40335],
  [2208, 1.38515],
  [2232, 1.36696],
  [2256, 1.34876],
  [2280, 1.33056],
  [2304, 1.31236],
  [2328, 1.29592],
  [2352, 1.27948],
  [2376, 1.26304],
  [2400, 1.2466],
  [2424, 1.23016],
  [2448, 1.21198],
  [2472, 1.1938],
  [2496, 1.17561],
  [2520, 1.15743],
  [2544, 1.13925],
  [2568, 1.12316],
  [2592, 1.10708],
  [2616, 1.09099],
  [2640, 1.0749],
  [2664, 1.05882],
  [2688, 1.04427],
  [2712, 1.02973],
  [2736, 1.01519],
  [2760, 1.00065],
  [2784, 0.986102],
  [2808, 0.97398],
  [2832, 0.961858],
  [2856, 0.949735],
  [2880, 0.937613],
  [2904, 0.925491],
  [2928, 0.911834],
  [2952, 0.898178],
  [2976, 0.884521],
  [3000, 0.870864],
  [3024, 0.857208],
  [3048, 0.847229],
  [3072, 0.837249],
  [3096, 0.82727],
  [3120, 0.817291],
  [3144, 0.807312],
  [3168, 0.796144],
  [3192, 0.784975],
  [3216, 0.773806],
  [3240, 0.762638],
  [3264, 0.751469],
  [3288, 0.741853],
  [3312, 0.732237],
  [3336, 0.722621],
  [3360, 0.713005],
  [3384, 0.703389],
  [3408, 0.695426],
  [3432, 0.687463],
  [3456, 0.6795],
  [3480, 0.671537],
  [3504, 0.663574],
  [3528, 0.653374],
  [3552, 0.643174],
  [3576, 0.632974],
  [3600, 0.622774],
  [3624, 0.612574],
  [3648, 0.6059],
  [3672, 0.599225],
  [3696, 0.592551],
  [3720, 0.585876],
  [3744, 0.579201],
  [3768, 0.570129],
  [3792, 0.561056],
  [3816, 0.551983],
  [3840, 0.54291],
  [3864, 0.533837],
  [3888, 0.52877],
  [3912, 0.523703],
  [3936, 0.518636],
  [3960, 0.513569],
  [3984, 0.508503],
  [4008, 0.502561],
  [4032, 0.49662],
  [4056, 0.490679],
  [4080, 0.484738],
  [4104, 0.478796],
  [4128, 0.47318],
  [4152, 0.467563],
  [4176, 0.461946],
  [4200, 0.456329],
  [4224, 0.450713],
  [4248, 0.444968],
  [4272, 0.439223],
  [4296, 0.433477],
  [4320, 0.427732],
  [4344, 0.421987],
  [4368, 0.417175],
  [4392, 0.412363],
  [4416, 0.40755],
  [4440, 0.402738],
  [4464, 0.397926],
  [4488, 0.393091],
  [4512, 0.388257],
  [4536, 0.383422],
  [4560, 0.378588],
  [4584, 0.373753],
  [4608, 0.368443],
  [4632, 0.363132],
  [4656, 0.357822],
  [4680, 0.352512],
  [4704, 0.347201],
  [4728, 0.344246],
  [4752, 0.34129],
  [4776, 0.338334],
  [4800, 0.335378],
  [4824, 0.332422],
  [4848, 0.327575],
  [4872, 0.322728],
  [4896, 0.317881],
  [4920, 0.313034],
  [4944, 0.308187],
  [4968, 0.305838],
  [4992, 0.303489],
  [5016, 0.30114],
  [5040, 0.298792],
  [5064, 0.296443],
  [5088, 0.291441],
  [5112, 0.286438],
  [5136, 0.281435],
  [5160, 0.276433],
  [5184, 0.27143],
  [5208, 0.268922],
  [5232, 0.266414],
  [5256, 0.263906],
  [5280, 0.261398],
  [5304, 0.258891],
  [5328, 0.255125],
  [5352, 0.25136],
  [5376, 0.247595],
  [5400, 0.243829],
  [5424, 0.240064],
  [5448, 0.237779],
  [5472, 0.235495],
  [5496, 0.23321],
  [5520, 0.230926],
  [5544, 0.228641],
  [5568, 0.225744],
  [5592, 0.222847],
  [5616, 0.21995],
  [5640, 0.217053],
  [5664, 0.214156],
  [5688, 0.211063],
  [5712, 0.20797],
  [5736, 0.204878],
  [5760, 0.201785],
  [5784, 0.198692],
  [5808, 0.196703],
  [5832, 0.194714],
  [5856, 0.192725],
  [5880, 0.190736],
  [5904, 0.188747],
  [5928, 0.18587],
  [5952, 0.182994],
  [5976, 0.180118],
  [6000, 0.177242],
  [6024, 0.174366],
  [6048, 0.172292],
  [6072, 0.170219],
  [6096, 0.168145],
  [6120, 0.166072],
  [6144, 0.163998],
  [6168, 0.16231],
  [6192, 0.160621],
  [6216, 0.158933],
  [6240, 0.157244],
  [6264, 0.155556],
  [6288, 0.153909],
  [6312, 0.152261],
  [6336, 0.150614],
  [6360, 0.148967],
  [6384, 0.14732],
  [6408, 0.144532],
  [6432, 0.141744],
  [6456, 0.138956],
  [6480, 0.136168],
  [6504, 0.13338],
  [6528, 0.131683],
  [6552, 0.129985],
  [6576, 0.128288],
  [6600, 0.12659],
  [6624, 0.124893],
  [6648, 0.123803],
  [6672, 0.122714],
  [6696, 0.121624],
  [6720, 0.120534],
  [6744, 0.119445],
  [6768, 0.117689],
  [6792, 0.115933],
  [6816, 0.114177],
  [6840, 0.112421],
  [6864, 0.110665],
  [6888, 0.109821],
  [6912, 0.108978],
  [6936, 0.108135],
  [6960, 0.107291],
  [6984, 0.106448],
  [7008, 0.104834],
  [7032, 0.103219],
  [7056, 0.101605],
  [7080, 0.099991],
  [7104, 0.0983768],
  [7128, 0.0971147],
  [7152, 0.0958525],
  [7176, 0.0945903],
  [7200, 0.0933281],
  [7224, 0.092066],
  [7248, 0.0911653],
  [7272, 0.0902647],
  [7296, 0.089364],
  [7320, 0.0884634],
  [7344, 0.0875627],
  [7368, 0.0862973],
  [7392, 0.0850319],
  [7416, 0.0837665],
  [7440, 0.0825011],
  [7464, 0.0812357],
  [7488, 0.0801282],
  [7512, 0.0790208],
  [7536, 0.0779133],
  [7560, 0.0768058],
  [7584, 0.0756983],
  [7608, 0.0747398],
  [7632, 0.0737814],
  [7656, 0.0728229],
  [7680, 0.0718645],
  [7704, 0.070906],
  [7728, 0.0704196],
  [7752, 0.0699332],
  [7776, 0.0694469],
  [7800, 0.0689605],
  [7824, 0.0684741],
  [7848, 0.0673292],
  [7872, 0.0661844],
  [7896, 0.0650395],
  [7920, 0.0638947],
  [7944, 0.0627498],
  [7968, 0.0619641],
  [7992, 0.0611784],
  [8016, 0.0603927],
  [8040, 0.0596071],
  [8064, 0.0588214],
  [8088, 0.0580943],
  [8112, 0.0573671],
  [8136, 0.05664],
  [8160, 0.0559129],
  [8184, 0.0551858],
  [8208, 0.0544535],
  [8232, 0.0537212],
  [8256, 0.0529889],
  [8280, 0.0522567],
  [8304, 0.0515244],
  [8328, 0.0508388],
  [8352, 0.0501532],
  [8376, 0.0494675],
  [8400, 0.0487819],
  [8424, 0.0480963],
  [8448, 0.047429],
  [8472, 0.0467616],
  [8496, 0.0460943],
  [8520, 0.0454269],
  [8544, 0.0447596],
  [8568, 0.0442425],
  [8592, 0.0437254],
  [8616, 0.0432083],
  [8640, 0.0426912],
  [8664, 0.0421741],
  [8688, 0.041686],
  [8712, 0.041198],
  [8736, 0.04071],
  [8760, 0.0402219],
  [8784, 0.0397339],
  [8808, 0.0390893],
  [8832, 0.0384446],
  [8856, 0.0378],
  [8880, 0.0371553],
  [8904, 0.0365107],
];
drugLevel['Gluteal:117mg'] = [
  [0, 0],
  [24, 2.1589],
  [48, 3.57298],
  [72, 4.45043],
  [96, 5.1919],
  [120, 5.64884],
  [144, 6.02747],
  [168, 6.4061],
  [192, 6.63577],
  [216, 6.86544],
  [240, 6.99038],
  [264, 7.11532],
  [288, 7.18211],
  [312, 7.2489],
  [336, 7.05648],
  [360, 6.86407],
  [384, 6.71715],
  [408, 6.57024],
  [432, 6.39179],
  [456, 6.21335],
  [480, 6.03829],
  [504, 5.86323],
  [528, 5.80302],
  [552, 5.74282],
  [576, 5.6243],
  [600, 5.50579],
  [624, 5.4119],
  [648, 5.31801],
  [672, 5.26237],
  [696, 5.20672],
  [720, 5.09952],
  [744, 4.99232],
  [768, 4.92339],
  [792, 4.85447],
  [816, 4.78627],
  [840, 4.71807],
  [864, 4.65374],
  [888, 4.5894],
  [912, 4.52537],
  [936, 4.46134],
  [960, 4.37656],
  [984, 4.29177],
  [1008, 4.26628],
  [1032, 4.24079],
  [1056, 4.16438],
  [1080, 4.08798],
  [1104, 4.03392],
  [1128, 3.97987],
  [1152, 3.93181],
  [1176, 3.88375],
  [1200, 3.80443],
  [1224, 3.72512],
  [1248, 3.68842],
  [1272, 3.65173],
  [1296, 3.61691],
  [1320, 3.5821],
  [1344, 3.51205],
  [1368, 3.44199],
  [1392, 3.41146],
  [1416, 3.38093],
  [1440, 3.32352],
  [1464, 3.26611],
  [1488, 3.23706],
  [1512, 3.20801],
  [1536, 3.15169],
  [1560, 3.09538],
  [1584, 3.03907],
  [1608, 3.00645],
  [1632, 2.97384],
  [1656, 2.94123],
  [1680, 2.90861],
  [1704, 2.876],
  [1728, 2.83477],
  [1752, 2.79355],
  [1776, 2.75232],
  [1800, 2.7111],
  [1824, 2.66987],
  [1848, 2.63887],
  [1872, 2.60786],
  [1896, 2.57686],
  [1920, 2.54586],
  [1944, 2.51485],
  [1968, 2.47724],
  [1992, 2.43963],
  [2016, 2.40202],
  [2040, 2.36441],
  [2064, 2.3268],
  [2088, 2.29231],
  [2112, 2.25782],
  [2136, 2.22333],
  [2160, 2.18884],
  [2184, 2.15435],
  [2208, 2.12946],
  [2232, 2.10457],
  [2256, 2.07968],
  [2280, 2.05479],
  [2304, 2.0299],
  [2328, 2.00961],
  [2352, 1.98932],
  [2376, 1.96904],
  [2400, 1.94875],
  [2424, 1.92847],
  [2448, 1.90524],
  [2472, 1.88201],
  [2496, 1.85878],
  [2520, 1.83555],
  [2544, 1.81231],
  [2568, 1.78718],
  [2592, 1.76204],
  [2616, 1.73691],
  [2640, 1.71177],
  [2664, 1.68663],
  [2688, 1.66583],
  [2712, 1.64503],
  [2736, 1.62423],
  [2760, 1.60343],
  [2784, 1.58263],
  [2808, 1.56528],
  [2832, 1.54794],
  [2856, 1.5306],
  [2880, 1.51326],
  [2904, 1.49592],
  [2928, 1.477],
  [2952, 1.45808],
  [2976, 1.43916],
  [3000, 1.42024],
  [3024, 1.40132],
  [3048, 1.3857],
  [3072, 1.37009],
  [3096, 1.35447],
  [3120, 1.33885],
  [3144, 1.32324],
  [3168, 1.30839],
  [3192, 1.29354],
  [3216, 1.2787],
  [3240, 1.26385],
  [3264, 1.24901],
  [3288, 1.2335],
  [3312, 1.218],
  [3336, 1.20249],
  [3360, 1.18699],
  [3384, 1.17148],
  [3408, 1.15797],
  [3432, 1.14446],
  [3456, 1.13095],
  [3480, 1.11745],
  [3504, 1.10394],
  [3528, 1.08951],
  [3552, 1.07509],
  [3576, 1.06066],
  [3600, 1.04624],
  [3624, 1.03181],
  [3648, 1.02237],
  [3672, 1.01292],
  [3696, 1.00348],
  [3720, 0.994032],
  [3744, 0.984587],
  [3768, 0.971299],
  [3792, 0.95801],
  [3816, 0.944722],
  [3840, 0.931434],
  [3864, 0.918145],
  [3888, 0.909535],
  [3912, 0.900925],
  [3936, 0.892314],
  [3960, 0.883704],
  [3984, 0.875093],
  [4008, 0.865689],
  [4032, 0.856285],
  [4056, 0.84688],
  [4080, 0.837476],
  [4104, 0.828072],
  [4128, 0.820472],
  [4152, 0.812872],
  [4176, 0.805273],
  [4200, 0.797673],
  [4224, 0.790073],
  [4248, 0.781018],
  [4272, 0.771964],
  [4296, 0.762909],
  [4320, 0.753854],
  [4344, 0.7448],
  [4368, 0.737113],
  [4392, 0.729427],
  [4416, 0.72174],
  [4440, 0.714054],
  [4464, 0.706367],
  [4488, 0.698625],
  [4512, 0.690883],
  [4536, 0.683141],
  [4560, 0.675399],
  [4584, 0.667657],
  [4608, 0.660342],
  [4632, 0.653027],
  [4656, 0.645712],
  [4680, 0.638397],
  [4704, 0.631082],
  [4728, 0.626116],
  [4752, 0.621151],
  [4776, 0.616185],
  [4800, 0.61122],
  [4824, 0.606255],
  [4848, 0.599011],
  [4872, 0.591767],
  [4896, 0.584524],
  [4920, 0.57728],
  [4944, 0.570036],
  [4968, 0.564177],
  [4992, 0.558317],
  [5016, 0.552458],
  [5040, 0.546598],
  [5064, 0.540738],
  [5088, 0.534813],
  [5112, 0.528887],
  [5136, 0.522961],
  [5160, 0.517036],
  [5184, 0.51111],
  [5208, 0.505462],
  [5232, 0.499813],
  [5256, 0.494164],
  [5280, 0.488515],
  [5304, 0.482867],
  [5328, 0.477446],
  [5352, 0.472026],
  [5376, 0.466605],
  [5400, 0.461185],
  [5424, 0.455764],
  [5448, 0.451422],
  [5472, 0.44708],
  [5496, 0.442738],
  [5520, 0.438396],
  [5544, 0.434055],
  [5568, 0.429363],
  [5592, 0.424671],
  [5616, 0.41998],
  [5640, 0.415288],
  [5664, 0.410596],
  [5688, 0.406229],
  [5712, 0.401862],
  [5736, 0.397495],
  [5760, 0.393128],
  [5784, 0.388761],
  [5808, 0.384921],
  [5832, 0.381082],
  [5856, 0.377242],
  [5880, 0.373402],
  [5904, 0.369563],
  [5928, 0.365501],
  [5952, 0.36144],
  [5976, 0.357379],
  [6000, 0.353317],
  [6024, 0.349256],
  [6048, 0.345604],
  [6072, 0.341952],
  [6096, 0.3383],
  [6120, 0.334648],
  [6144, 0.330996],
  [6168, 0.327824],
  [6192, 0.324652],
  [6216, 0.32148],
  [6240, 0.318308],
  [6264, 0.315136],
  [6288, 0.311904],
  [6312, 0.308671],
  [6336, 0.305439],
  [6360, 0.302206],
  [6384, 0.298974],
  [6408, 0.294845],
  [6432, 0.290716],
  [6456, 0.286587],
  [6480, 0.282458],
  [6504, 0.278329],
  [6528, 0.275048],
  [6552, 0.271767],
  [6576, 0.268486],
  [6600, 0.265205],
  [6624, 0.261924],
  [6648, 0.259442],
  [6672, 0.256959],
  [6696, 0.254477],
  [6720, 0.251994],
  [6744, 0.249512],
  [6768, 0.24703],
  [6792, 0.244549],
  [6816, 0.242068],
  [6840, 0.239587],
  [6864, 0.237106],
  [6888, 0.235073],
  [6912, 0.233041],
  [6936, 0.231009],
  [6960, 0.228977],
  [6984, 0.226944],
  [7008, 0.224488],
  [7032, 0.222031],
  [7056, 0.219574],
  [7080, 0.217117],
  [7104, 0.21466],
  [7128, 0.212744],
  [7152, 0.210828],
  [7176, 0.208913],
  [7200, 0.206997],
  [7224, 0.205081],
  [7248, 0.202321],
  [7272, 0.199562],
  [7296, 0.196802],
  [7320, 0.194043],
  [7344, 0.191283],
  [7368, 0.189543],
  [7392, 0.187803],
  [7416, 0.186063],
  [7440, 0.184323],
  [7464, 0.182583],
  [7488, 0.1803],
  [7512, 0.178018],
  [7536, 0.175735],
  [7560, 0.173452],
  [7584, 0.17117],
  [7608, 0.169364],
  [7632, 0.167557],
  [7656, 0.165751],
  [7680, 0.163945],
  [7704, 0.162139],
  [7728, 0.161093],
  [7752, 0.160046],
  [7776, 0.159],
  [7800, 0.157954],
  [7824, 0.156907],
  [7848, 0.154311],
  [7872, 0.151715],
  [7896, 0.149119],
  [7920, 0.146523],
  [7944, 0.143927],
  [7968, 0.142713],
  [7992, 0.141498],
  [8016, 0.140284],
  [8040, 0.13907],
  [8064, 0.137855],
  [8088, 0.136444],
  [8112, 0.135032],
  [8136, 0.133621],
  [8160, 0.13221],
  [8184, 0.130798],
  [8208, 0.129088],
  [8232, 0.127378],
  [8256, 0.125668],
  [8280, 0.123958],
  [8304, 0.122248],
  [8328, 0.120805],
  [8352, 0.119362],
  [8376, 0.117918],
  [8400, 0.116475],
  [8424, 0.115031],
  [8448, 0.113907],
  [8472, 0.112783],
  [8496, 0.111659],
  [8520, 0.110535],
  [8544, 0.10941],
  [8568, 0.108193],
  [8592, 0.106975],
  [8616, 0.105758],
  [8640, 0.10454],
  [8664, 0.103323],
  [8688, 0.102234],
  [8712, 0.101146],
  [8736, 0.100058],
  [8760, 0.0989695],
  [8784, 0.0978813],
  [8808, 0.0965068],
  [8832, 0.0951323],
  [8856, 0.0937577],
  [8880, 0.0923832],
  [8904, 0.0910087],
];
drugLevel['Gluteal:156mg'] = [
  [0, 0],
  [24, 2.64647],
  [48, 4.38899],
  [72, 5.45046],
  [96, 6.36873],
  [120, 6.94033],
  [144, 7.41124],
  [168, 7.88214],
  [192, 8.15862],
  [216, 8.43509],
  [240, 8.5934],
  [264, 8.75172],
  [288, 8.83565],
  [312, 8.91959],
  [336, 8.67165],
  [360, 8.42371],
  [384, 8.25068],
  [408, 8.07764],
  [432, 7.86552],
  [456, 7.6534],
  [480, 7.44192],
  [504, 7.23044],
  [528, 7.15213],
  [552, 7.07382],
  [576, 6.9479],
  [600, 6.82198],
  [624, 6.70961],
  [648, 6.59723],
  [672, 6.53806],
  [696, 6.4789],
  [720, 6.35655],
  [744, 6.2342],
  [768, 6.1501],
  [792, 6.066],
  [816, 5.99452],
  [840, 5.92304],
  [864, 5.83796],
  [888, 5.75288],
  [912, 5.69099],
  [936, 5.6291],
  [960, 5.527],
  [984, 5.4249],
  [1008, 5.39416],
  [1032, 5.36341],
  [1056, 5.27805],
  [1080, 5.19268],
  [1104, 5.1325],
  [1128, 5.07233],
  [1152, 5.01876],
  [1176, 4.96518],
  [1200, 4.85867],
  [1224, 4.75216],
  [1248, 4.72852],
  [1272, 4.70488],
  [1296, 4.66164],
  [1320, 4.61841],
  [1344, 4.54177],
  [1368, 4.46514],
  [1392, 4.4384],
  [1416, 4.41166],
  [1440, 4.33185],
  [1464, 4.25204],
  [1488, 4.20433],
  [1512, 4.15661],
  [1536, 4.09803],
  [1560, 4.03945],
  [1584, 3.98087],
  [1608, 3.94158],
  [1632, 3.9023],
  [1656, 3.86302],
  [1680, 3.82373],
  [1704, 3.78445],
  [1728, 3.73625],
  [1752, 3.68805],
  [1776, 3.63985],
  [1800, 3.59165],
  [1824, 3.54345],
  [1848, 3.50361],
  [1872, 3.46378],
  [1896, 3.42394],
  [1920, 3.38411],
  [1944, 3.34428],
  [1968, 3.30127],
  [1992, 3.25827],
  [2016, 3.21527],
  [2040, 3.17227],
  [2064, 3.12927],
  [2088, 3.08591],
  [2112, 3.04255],
  [2136, 2.99919],
  [2160, 2.95583],
  [2184, 2.91247],
  [2208, 2.87964],
  [2232, 2.84682],
  [2256, 2.814],
  [2280, 2.78117],
  [2304, 2.74835],
  [2328, 2.72494],
  [2352, 2.70154],
  [2376, 2.67813],
  [2400, 2.65472],
  [2424, 2.63132],
  [2448, 2.60039],
  [2472, 2.56945],
  [2496, 2.53852],
  [2520, 2.50759],
  [2544, 2.47666],
  [2568, 2.44417],
  [2592, 2.41169],
  [2616, 2.37921],
  [2640, 2.34672],
  [2664, 2.31424],
  [2688, 2.28996],
  [2712, 2.26568],
  [2736, 2.2414],
  [2760, 2.21712],
  [2784, 2.19283],
  [2808, 2.1677],
  [2832, 2.14257],
  [2856, 2.11744],
  [2880, 2.0923],
  [2904, 2.06717],
  [2928, 2.04279],
  [2952, 2.01842],
  [2976, 1.99404],
  [3000, 1.96966],
  [3024, 1.94529],
  [3048, 1.92728],
  [3072, 1.90928],
  [3096, 1.89127],
  [3120, 1.87327],
  [3144, 1.85526],
  [3168, 1.83449],
  [3192, 1.81372],
  [3216, 1.79295],
  [3240, 1.77218],
  [3264, 1.75141],
  [3288, 1.73073],
  [3312, 1.71005],
  [3336, 1.68937],
  [3360, 1.66869],
  [3384, 1.64801],
  [3408, 1.63223],
  [3432, 1.61645],
  [3456, 1.60067],
  [3480, 1.58489],
  [3504, 1.5691],
  [3528, 1.54818],
  [3552, 1.52725],
  [3576, 1.50633],
  [3600, 1.4854],
  [3624, 1.46448],
  [3648, 1.452],
  [3672, 1.43953],
  [3696, 1.42706],
  [3720, 1.41458],
  [3744, 1.40211],
  [3768, 1.38789],
  [3792, 1.37368],
  [3816, 1.35947],
  [3840, 1.34525],
  [3864, 1.33104],
  [3888, 1.31611],
  [3912, 1.30119],
  [3936, 1.28626],
  [3960, 1.27133],
  [3984, 1.25641],
  [4008, 1.24635],
  [4032, 1.2363],
  [4056, 1.22625],
  [4080, 1.2162],
  [4104, 1.20615],
  [4128, 1.19447],
  [4152, 1.18279],
  [4176, 1.17111],
  [4200, 1.15943],
  [4224, 1.14775],
  [4248, 1.13653],
  [4272, 1.12531],
  [4296, 1.1141],
  [4320, 1.10288],
  [4344, 1.09167],
  [4368, 1.0812],
  [4392, 1.07074],
  [4416, 1.06028],
  [4440, 1.04982],
  [4464, 1.03936],
  [4488, 1.02887],
  [4512, 1.01839],
  [4536, 1.00791],
  [4560, 0.997424],
  [4584, 0.986941],
  [4608, 0.977211],
  [4632, 0.967481],
  [4656, 0.957751],
  [4680, 0.948021],
  [4704, 0.938292],
  [4728, 0.929854],
  [4752, 0.921416],
  [4776, 0.912978],
  [4800, 0.904541],
  [4824, 0.896103],
  [4848, 0.886897],
  [4872, 0.87769],
  [4896, 0.868484],
  [4920, 0.859278],
  [4944, 0.850071],
  [4968, 0.844471],
  [4992, 0.838871],
  [5016, 0.83327],
  [5040, 0.82767],
  [5064, 0.82207],
  [5088, 0.812013],
  [5112, 0.801957],
  [5136, 0.791901],
  [5160, 0.781845],
  [5184, 0.771788],
  [5208, 0.764337],
  [5232, 0.756885],
  [5256, 0.749433],
  [5280, 0.741981],
  [5304, 0.73453],
  [5328, 0.726831],
  [5352, 0.719132],
  [5376, 0.711433],
  [5400, 0.703734],
  [5424, 0.696035],
  [5448, 0.689737],
  [5472, 0.68344],
  [5496, 0.677142],
  [5520, 0.670844],
  [5544, 0.664547],
  [5568, 0.658296],
  [5592, 0.652045],
  [5616, 0.645793],
  [5640, 0.639542],
  [5664, 0.633291],
  [5688, 0.62772],
  [5712, 0.622148],
  [5736, 0.616577],
  [5760, 0.611005],
  [5784, 0.605434],
  [5808, 0.599678],
  [5832, 0.593923],
  [5856, 0.588167],
  [5880, 0.582411],
  [5904, 0.576656],
  [5928, 0.57117],
  [5952, 0.565685],
  [5976, 0.5602],
  [6000, 0.554714],
  [6024, 0.549229],
  [6048, 0.545192],
  [6072, 0.541155],
  [6096, 0.537118],
  [6120, 0.53308],
  [6144, 0.529043],
  [6168, 0.522886],
  [6192, 0.51673],
  [6216, 0.510573],
  [6240, 0.504416],
  [6264, 0.498259],
  [6288, 0.493131],
  [6312, 0.488002],
  [6336, 0.482874],
  [6360, 0.477745],
  [6384, 0.472617],
  [6408, 0.468337],
  [6432, 0.464056],
  [6456, 0.459776],
  [6480, 0.455496],
  [6504, 0.451216],
  [6528, 0.445151],
  [6552, 0.439087],
  [6576, 0.433022],
  [6600, 0.426958],
  [6624, 0.420894],
  [6648, 0.417873],
  [6672, 0.414853],
  [6696, 0.411833],
  [6720, 0.408812],
  [6744, 0.405792],
  [6768, 0.40246],
  [6792, 0.399129],
  [6816, 0.395797],
  [6840, 0.392466],
  [6864, 0.389135],
  [6888, 0.386162],
  [6912, 0.38319],
  [6936, 0.380218],
  [6960, 0.377245],
  [6984, 0.374273],
  [7008, 0.369578],
  [7032, 0.364882],
  [7056, 0.360187],
  [7080, 0.355491],
  [7104, 0.350796],
  [7128, 0.348183],
  [7152, 0.345571],
  [7176, 0.342958],
  [7200, 0.340346],
  [7224, 0.337733],
  [7248, 0.334093],
  [7272, 0.330452],
  [7296, 0.326812],
  [7320, 0.323172],
  [7344, 0.319531],
  [7368, 0.316762],
  [7392, 0.313993],
  [7416, 0.311224],
  [7440, 0.308454],
  [7464, 0.305685],
  [7488, 0.302791],
  [7512, 0.299896],
  [7536, 0.297002],
  [7560, 0.294107],
  [7584, 0.291213],
  [7608, 0.28832],
  [7632, 0.285427],
  [7656, 0.282534],
  [7680, 0.279641],
  [7704, 0.276747],
  [7728, 0.27409],
  [7752, 0.271433],
  [7776, 0.268776],
  [7800, 0.266118],
  [7824, 0.263461],
  [7848, 0.26039],
  [7872, 0.257319],
  [7896, 0.254248],
  [7920, 0.251177],
  [7944, 0.248106],
  [7968, 0.246354],
  [7992, 0.244601],
  [8016, 0.242849],
  [8040, 0.241097],
  [8064, 0.239345],
  [8088, 0.236953],
  [8112, 0.234562],
  [8136, 0.23217],
  [8160, 0.229779],
  [8184, 0.227387],
  [8208, 0.224958],
  [8232, 0.222529],
  [8256, 0.220099],
  [8280, 0.21767],
  [8304, 0.21524],
  [8328, 0.212742],
  [8352, 0.210243],
  [8376, 0.207744],
  [8400, 0.205245],
  [8424, 0.202746],
  [8448, 0.201244],
  [8472, 0.199742],
  [8496, 0.19824],
  [8520, 0.196737],
  [8544, 0.195235],
  [8568, 0.193105],
  [8592, 0.190975],
  [8616, 0.188845],
  [8640, 0.186714],
  [8664, 0.184584],
  [8688, 0.183151],
  [8712, 0.181717],
  [8736, 0.180284],
  [8760, 0.178851],
  [8784, 0.177417],
  [8808, 0.174867],
  [8832, 0.172316],
  [8856, 0.169765],
  [8880, 0.167214],
  [8904, 0.164664],
];
drugLevel['Gluteal:234mg'] = [
  [0, 0],
  [24, 3.53372],
  [48, 5.86909],
  [72, 7.28852],
  [96, 8.50879],
  [120, 9.27572],
  [144, 9.91299],
  [168, 10.5503],
  [192, 10.902],
  [216, 11.2537],
  [240, 11.4816],
  [264, 11.7095],
  [288, 11.8051],
  [312, 11.9007],
  [336, 11.5576],
  [360, 11.2144],
  [384, 10.9994],
  [408, 10.7844],
  [432, 10.5124],
  [456, 10.2405],
  [480, 9.96164],
  [504, 9.68279],
  [528, 9.59595],
  [552, 9.50911],
  [576, 9.3504],
  [600, 9.19169],
  [624, 9.0405],
  [648, 8.88931],
  [672, 8.81308],
  [696, 8.73685],
  [720, 8.60499],
  [744, 8.47313],
  [768, 8.35821],
  [792, 8.24329],
  [816, 8.18315],
  [840, 8.123],
  [864, 8.01461],
  [888, 7.90622],
  [912, 7.81441],
  [936, 7.72259],
  [960, 7.60997],
  [984, 7.49735],
  [1008, 7.47213],
  [1032, 7.44692],
  [1056, 7.332],
  [1080, 7.21708],
  [1104, 7.152],
  [1128, 7.08691],
  [1152, 7.01206],
  [1176, 6.93721],
  [1200, 6.81256],
  [1224, 6.6879],
  [1248, 6.65794],
  [1272, 6.62798],
  [1296, 6.57701],
  [1320, 6.52604],
  [1344, 6.4477],
  [1368, 6.36937],
  [1392, 6.30631],
  [1416, 6.24324],
  [1440, 6.1607],
  [1464, 6.07815],
  [1488, 6.02737],
  [1512, 5.97659],
  [1536, 5.90643],
  [1560, 5.83628],
  [1584, 5.76612],
  [1608, 5.71158],
  [1632, 5.65704],
  [1656, 5.6025],
  [1680, 5.54796],
  [1704, 5.49341],
  [1728, 5.43348],
  [1752, 5.37354],
  [1776, 5.3136],
  [1800, 5.25366],
  [1824, 5.19372],
  [1848, 5.13833],
  [1872, 5.08294],
  [1896, 5.02754],
  [1920, 4.97215],
  [1944, 4.91676],
  [1968, 4.86293],
  [1992, 4.80909],
  [2016, 4.75526],
  [2040, 4.70143],
  [2064, 4.6476],
  [2088, 4.59574],
  [2112, 4.54389],
  [2136, 4.49204],
  [2160, 4.44019],
  [2184, 4.38834],
  [2208, 4.34079],
  [2232, 4.29324],
  [2256, 4.24569],
  [2280, 4.19814],
  [2304, 4.15059],
  [2328, 4.1141],
  [2352, 4.07761],
  [2376, 4.04113],
  [2400, 4.00464],
  [2424, 3.96815],
  [2448, 3.92534],
  [2472, 3.88253],
  [2496, 3.83972],
  [2520, 3.79691],
  [2544, 3.75411],
  [2568, 3.71517],
  [2592, 3.67623],
  [2616, 3.6373],
  [2640, 3.59836],
  [2664, 3.55943],
  [2688, 3.52346],
  [2712, 3.48749],
  [2736, 3.45152],
  [2760, 3.41555],
  [2784, 3.37958],
  [2808, 3.34443],
  [2832, 3.30928],
  [2856, 3.27413],
  [2880, 3.23899],
  [2904, 3.20384],
  [2928, 3.17213],
  [2952, 3.14042],
  [2976, 3.10872],
  [3000, 3.07701],
  [3024, 3.0453],
  [3048, 3.01833],
  [3072, 2.99135],
  [3096, 2.96438],
  [3120, 2.9374],
  [3144, 2.91043],
  [3168, 2.88132],
  [3192, 2.85221],
  [3216, 2.8231],
  [3240, 2.794],
  [3264, 2.76489],
  [3288, 2.73667],
  [3312, 2.70844],
  [3336, 2.68022],
  [3360, 2.652],
  [3384, 2.62378],
  [3408, 2.60167],
  [3432, 2.57955],
  [3456, 2.55744],
  [3480, 2.53533],
  [3504, 2.51322],
  [3528, 2.4861],
  [3552, 2.45898],
  [3576, 2.43185],
  [3600, 2.40473],
  [3624, 2.37761],
  [3648, 2.35772],
  [3672, 2.33784],
  [3696, 2.31795],
  [3720, 2.29806],
  [3744, 2.27818],
  [3768, 2.25722],
  [3792, 2.23625],
  [3816, 2.21529],
  [3840, 2.19433],
  [3864, 2.17337],
  [3888, 2.15379],
  [3912, 2.13421],
  [3936, 2.11463],
  [3960, 2.09505],
  [3984, 2.07547],
  [4008, 2.05967],
  [4032, 2.04386],
  [4056, 2.02805],
  [4080, 2.01225],
  [4104, 1.99644],
  [4128, 1.97841],
  [4152, 1.96039],
  [4176, 1.94236],
  [4200, 1.92434],
  [4224, 1.90631],
  [4248, 1.88948],
  [4272, 1.87265],
  [4296, 1.85582],
  [4320, 1.83899],
  [4344, 1.82216],
  [4368, 1.80788],
  [4392, 1.79361],
  [4416, 1.77933],
  [4440, 1.76506],
  [4464, 1.75079],
  [4488, 1.73539],
  [4512, 1.71999],
  [4536, 1.7046],
  [4560, 1.6892],
  [4584, 1.67381],
  [4608, 1.65855],
  [4632, 1.6433],
  [4656, 1.62804],
  [4680, 1.61279],
  [4704, 1.59753],
  [4728, 1.58441],
  [4752, 1.57128],
  [4776, 1.55815],
  [4800, 1.54503],
  [4824, 1.5319],
  [4848, 1.51694],
  [4872, 1.50197],
  [4896, 1.487],
  [4920, 1.47204],
  [4944, 1.45707],
  [4968, 1.44706],
  [4992, 1.43704],
  [5016, 1.42702],
  [5040, 1.417],
  [5064, 1.40699],
  [5088, 1.39257],
  [5112, 1.37816],
  [5136, 1.36374],
  [5160, 1.34933],
  [5184, 1.33491],
  [5208, 1.32509],
  [5232, 1.31526],
  [5256, 1.30544],
  [5280, 1.29562],
  [5304, 1.28579],
  [5328, 1.27195],
  [5352, 1.25811],
  [5376, 1.24427],
  [5400, 1.23043],
  [5424, 1.21659],
  [5448, 1.20974],
  [5472, 1.20288],
  [5496, 1.19602],
  [5520, 1.18916],
  [5544, 1.1823],
  [5568, 1.17074],
  [5592, 1.15917],
  [5616, 1.14761],
  [5640, 1.13604],
  [5664, 1.12447],
  [5688, 1.11519],
  [5712, 1.10591],
  [5736, 1.09663],
  [5760, 1.08735],
  [5784, 1.07807],
  [5808, 1.07072],
  [5832, 1.06338],
  [5856, 1.05604],
  [5880, 1.04869],
  [5904, 1.04135],
  [5928, 1.02957],
  [5952, 1.0178],
  [5976, 1.00602],
  [6000, 0.994249],
  [6024, 0.982474],
  [6048, 0.976802],
  [6072, 0.97113],
  [6096, 0.965459],
  [6120, 0.959787],
  [6144, 0.954115],
  [6168, 0.945791],
  [6192, 0.937468],
  [6216, 0.929145],
  [6240, 0.920822],
  [6264, 0.912499],
  [6288, 0.904906],
  [6312, 0.897313],
  [6336, 0.889719],
  [6360, 0.882126],
  [6384, 0.874533],
  [6408, 0.865258],
  [6432, 0.855982],
  [6456, 0.846706],
  [6480, 0.837431],
  [6504, 0.828155],
  [6528, 0.82028],
  [6552, 0.812406],
  [6576, 0.804531],
  [6600, 0.796657],
  [6624, 0.788782],
  [6648, 0.784939],
  [6672, 0.781095],
  [6696, 0.777252],
  [6720, 0.773408],
  [6744, 0.769565],
  [6768, 0.763829],
  [6792, 0.758093],
  [6816, 0.752357],
  [6840, 0.746621],
  [6864, 0.740885],
  [6888, 0.735444],
  [6912, 0.730003],
  [6936, 0.724562],
  [6960, 0.719121],
  [6984, 0.71368],
  [7008, 0.705211],
  [7032, 0.696741],
  [7056, 0.688272],
  [7080, 0.679802],
  [7104, 0.671333],
  [7128, 0.666258],
  [7152, 0.661183],
  [7176, 0.656108],
  [7200, 0.651033],
  [7224, 0.645958],
  [7248, 0.64081],
  [7272, 0.635661],
  [7296, 0.630512],
  [7320, 0.625363],
  [7344, 0.620214],
  [7368, 0.61616],
  [7392, 0.612106],
  [7416, 0.608052],
  [7440, 0.603998],
  [7464, 0.599943],
  [7488, 0.594428],
  [7512, 0.588912],
  [7536, 0.583396],
  [7560, 0.57788],
  [7584, 0.572364],
  [7608, 0.567683],
  [7632, 0.563002],
  [7656, 0.558322],
  [7680, 0.553641],
  [7704, 0.54896],
  [7728, 0.545181],
  [7752, 0.541403],
  [7776, 0.537625],
  [7800, 0.533846],
  [7824, 0.530068],
  [7848, 0.524583],
  [7872, 0.519097],
  [7896, 0.513611],
  [7920, 0.508126],
  [7944, 0.502641],
  [7968, 0.49969],
  [7992, 0.496739],
  [8016, 0.493789],
  [8040, 0.490838],
  [8064, 0.487888],
  [8088, 0.482973],
  [8112, 0.478058],
  [8136, 0.473144],
  [8160, 0.468229],
  [8184, 0.463314],
  [8208, 0.460225],
  [8232, 0.457137],
  [8256, 0.454048],
  [8280, 0.45096],
  [8304, 0.447871],
  [8328, 0.442768],
  [8352, 0.437664],
  [8376, 0.432561],
  [8400, 0.427458],
  [8424, 0.422355],
  [8448, 0.419365],
  [8472, 0.416375],
  [8496, 0.413385],
  [8520, 0.410396],
  [8544, 0.407406],
  [8568, 0.403721],
  [8592, 0.400036],
  [8616, 0.396351],
  [8640, 0.392666],
  [8664, 0.388981],
  [8688, 0.386567],
  [8712, 0.384154],
  [8736, 0.38174],
  [8760, 0.379327],
  [8784, 0.376913],
  [8808, 0.372455],
  [8832, 0.367997],
  [8856, 0.363539],
  [8880, 0.359081],
  [8904, 0.354623],
];

drugLevel['Deltoid:39mg'] = [
  [0, 0],
  [24, 1.49655],
  [48, 2.48876],
  [72, 3.13444],
  [96, 3.61557],
  [120, 3.95804],
  [144, 4.22222],
  [168, 4.4864],
  [192, 4.63035],
  [216, 4.77431],
  [240, 4.86479],
  [264, 4.95526],
  [288, 5.04711],
  [312, 5.13896],
  [336, 4.60358],
  [360, 4.0682],
  [384, 3.82291],
  [408, 3.57763],
  [432, 3.38689],
  [456, 3.19616],
  [480, 3.03227],
  [504, 2.86839],
  [528, 2.77048],
  [552, 2.67258],
  [576, 2.57891],
  [600, 2.48524],
  [624, 2.39368],
  [648, 2.30212],
  [672, 2.23515],
  [696, 2.16817],
  [720, 2.09705],
  [744, 2.02593],
  [768, 1.97065],
  [792, 1.91537],
  [816, 1.87085],
  [840, 1.82633],
  [864, 1.77963],
  [888, 1.73293],
  [912, 1.67778],
  [936, 1.62262],
  [960, 1.58353],
  [984, 1.54443],
  [1008, 1.51402],
  [1032, 1.48362],
  [1056, 1.44206],
  [1080, 1.4005],
  [1104, 1.36079],
  [1128, 1.32108],
  [1152, 1.29375],
  [1176, 1.26641],
  [1200, 1.22957],
  [1224, 1.19272],
  [1248, 1.1615],
  [1272, 1.13027],
  [1296, 1.10812],
  [1320, 1.08597],
  [1344, 1.05699],
  [1368, 1.028],
  [1392, 1.0088],
  [1416, 0.989596],
  [1440, 0.962807],
  [1464, 0.936017],
  [1488, 0.920798],
  [1512, 0.90558],
  [1536, 0.883108],
  [1560, 0.860637],
  [1584, 0.838165],
  [1608, 0.821261],
  [1632, 0.804357],
  [1656, 0.787453],
  [1680, 0.770548],
  [1704, 0.753644],
  [1728, 0.734729],
  [1752, 0.715813],
  [1776, 0.696898],
  [1800, 0.677982],
  [1824, 0.659067],
  [1848, 0.64665],
  [1872, 0.634233],
  [1896, 0.621815],
  [1920, 0.609398],
  [1944, 0.596981],
  [1968, 0.585916],
  [1992, 0.574851],
  [2016, 0.563786],
  [2040, 0.552721],
  [2064, 0.541656],
  [2088, 0.530419],
  [2112, 0.519182],
  [2136, 0.507945],
  [2160, 0.496708],
  [2184, 0.485471],
  [2208, 0.47597],
  [2232, 0.466469],
  [2256, 0.456968],
  [2280, 0.447467],
  [2304, 0.437966],
  [2328, 0.428564],
  [2352, 0.419163],
  [2376, 0.409761],
  [2400, 0.40036],
  [2424, 0.390958],
  [2448, 0.383595],
  [2472, 0.376233],
  [2496, 0.36887],
  [2520, 0.361507],
  [2544, 0.354145],
  [2568, 0.346586],
  [2592, 0.339028],
  [2616, 0.331469],
  [2640, 0.32391],
  [2664, 0.316352],
  [2688, 0.310348],
  [2712, 0.304344],
  [2736, 0.298341],
  [2760, 0.292337],
  [2784, 0.286333],
  [2808, 0.280332],
  [2832, 0.274331],
  [2856, 0.26833],
  [2880, 0.262328],
  [2904, 0.256327],
  [2928, 0.251702],
  [2952, 0.247077],
  [2976, 0.242452],
  [3000, 0.237827],
  [3024, 0.233202],
  [3048, 0.229035],
  [3072, 0.224867],
  [3096, 0.2207],
  [3120, 0.216532],
  [3144, 0.212365],
  [3168, 0.208281],
  [3192, 0.204197],
  [3216, 0.200112],
  [3240, 0.196028],
  [3264, 0.191944],
  [3288, 0.188269],
  [3312, 0.184593],
  [3336, 0.180917],
  [3360, 0.177241],
  [3384, 0.173566],
  [3408, 0.170231],
  [3432, 0.166896],
  [3456, 0.163561],
  [3480, 0.160226],
  [3504, 0.156892],
  [3528, 0.154007],
  [3552, 0.151121],
  [3576, 0.148236],
  [3600, 0.145351],
  [3624, 0.142466],
  [3648, 0.139756],
  [3672, 0.137046],
  [3696, 0.134336],
  [3720, 0.131625],
  [3744, 0.128915],
  [3768, 0.126126],
  [3792, 0.123336],
  [3816, 0.120547],
  [3840, 0.117758],
  [3864, 0.114968],
  [3888, 0.113229],
  [3912, 0.11149],
  [3936, 0.109751],
  [3960, 0.108012],
  [3984, 0.106272],
  [4008, 0.104142],
  [4032, 0.102012],
  [4056, 0.0998823],
  [4080, 0.0977523],
  [4104, 0.0956222],
  [4128, 0.0936953],
  [4152, 0.0917685],
  [4176, 0.0898416],
  [4200, 0.0879147],
  [4224, 0.0859878],
  [4248, 0.0845848],
  [4272, 0.0831819],
  [4296, 0.0817789],
  [4320, 0.0803759],
  [4344, 0.0789729],
  [4368, 0.0773369],
  [4392, 0.0757008],
  [4416, 0.0740648],
  [4440, 0.0724287],
  [4464, 0.0707927],
  [4488, 0.0695618],
  [4512, 0.0683309],
  [4536, 0.0671],
  [4560, 0.0658691],
  [4584, 0.0646382],
  [4608, 0.0630662],
  [4632, 0.0614943],
  [4656, 0.0599223],
  [4680, 0.0583503],
  [4704, 0.0567784],
  [4728, 0.0558088],
  [4752, 0.0548392],
  [4776, 0.0538696],
  [4800, 0.0529],
  [4824, 0.0519304],
  [4848, 0.0507712],
  [4872, 0.049612],
  [4896, 0.0484527],
  [4920, 0.0472935],
  [4944, 0.0461342],
  [4968, 0.0455191],
  [4992, 0.0449039],
  [5016, 0.0442887],
  [5040, 0.0436735],
  [5064, 0.0430583],
  [5088, 0.0421883],
  [5112, 0.0413183],
  [5136, 0.0404483],
  [5160, 0.0395783],
  [5184, 0.0387083],
  [5208, 0.037876],
  [5232, 0.0370436],
  [5256, 0.0362112],
  [5280, 0.0353788],
  [5304, 0.0345465],
  [5328, 0.033787],
  [5352, 0.0330275],
  [5376, 0.0322679],
  [5400, 0.0315084],
  [5424, 0.0307489],
  [5448, 0.0302258],
  [5472, 0.0297027],
  [5496, 0.0291796],
  [5520, 0.0286565],
  [5544, 0.0281333],
  [5568, 0.0276681],
  [5592, 0.0272029],
  [5616, 0.0267377],
  [5640, 0.0262725],
  [5664, 0.0258072],
  [5688, 0.0251712],
  [5712, 0.0245352],
  [5736, 0.0238993],
  [5760, 0.0232633],
  [5784, 0.0226273],
  [5808, 0.0222025],
  [5832, 0.0217778],
  [5856, 0.0213531],
  [5880, 0.0209284],
  [5904, 0.0205037],
  [5928, 0.0200557],
  [5952, 0.0196076],
  [5976, 0.0191596],
  [6000, 0.0187116],
  [6024, 0.0182635],
  [6048, 0.0179881],
  [6072, 0.0177126],
  [6096, 0.0174372],
  [6120, 0.0171618],
  [6144, 0.0168863],
  [6168, 0.0164668],
  [6192, 0.0160472],
  [6216, 0.0156277],
  [6240, 0.0152081],
  [6264, 0.0147886],
  [6288, 0.0145615],
  [6312, 0.0143343],
  [6336, 0.0141072],
  [6360, 0.0138801],
  [6384, 0.0136529],
  [6408, 0.0133511],
  [6432, 0.0130492],
  [6456, 0.0127474],
  [6480, 0.0124455],
  [6504, 0.0121436],
  [6528, 0.0118635],
  [6552, 0.0115834],
  [6576, 0.0113033],
  [6600, 0.0110231],
  [6624, 0.010743],
  [6648, 0.0105624],
  [6672, 0.0103818],
  [6696, 0.0102012],
  [6720, 0.0100206],
  [6744, 0.00984],
  [6768, 0.00967016],
  [6792, 0.00950033],
  [6816, 0.0093305],
  [6840, 0.00916066],
  [6864, 0.00899083],
  [6888, 0.00881508],
  [6912, 0.00863933],
  [6936, 0.00846358],
  [6960, 0.00828784],
  [6984, 0.00811209],
  [7008, 0.00793591],
  [7032, 0.00775973],
  [7056, 0.00758356],
  [7080, 0.00740738],
  [7104, 0.0072312],
  [7128, 0.00712523],
  [7152, 0.00701925],
  [7176, 0.00691328],
  [7200, 0.0068073],
  [7224, 0.00670133],
  [7248, 0.00656743],
  [7272, 0.00643354],
  [7296, 0.00629965],
  [7320, 0.00616575],
  [7344, 0.00603186],
  [7368, 0.00587693],
  [7392, 0.00572201],
  [7416, 0.00556708],
  [7440, 0.00541216],
  [7464, 0.00525724],
  [7488, 0.00517844],
  [7512, 0.00509964],
  [7536, 0.00502084],
  [7560, 0.00494204],
  [7584, 0.00486324],
  [7608, 0.00473478],
  [7632, 0.00460632],
  [7656, 0.00447785],
  [7680, 0.00434939],
  [7704, 0.00422093],
  [7728, 0.00417851],
  [7752, 0.0041361],
  [7776, 0.00409368],
  [7800, 0.00405127],
  [7824, 0.00400885],
  [7848, 0.00390981],
  [7872, 0.00381077],
  [7896, 0.00371173],
  [7920, 0.00361269],
  [7944, 0.00351365],
  [7968, 0.00344786],
  [7992, 0.00338207],
  [8016, 0.00331627],
  [8040, 0.00325048],
  [8064, 0.00318469],
  [8088, 0.00312616],
  [8112, 0.00306762],
  [8136, 0.00300909],
  [8160, 0.00295055],
  [8184, 0.00289202],
  [8208, 0.00282926],
  [8232, 0.00276651],
  [8256, 0.00270375],
  [8280, 0.00264099],
  [8304, 0.00257824],
  [8328, 0.00252229],
  [8352, 0.00246634],
  [8376, 0.00241039],
  [8400, 0.00235444],
  [8424, 0.0022985],
  [8448, 0.00225492],
  [8472, 0.00221134],
  [8496, 0.00216776],
  [8520, 0.00212418],
  [8544, 0.0020806],
  [8568, 0.00204171],
  [8592, 0.00200283],
  [8616, 0.00196395],
  [8640, 0.00192507],
  [8664, 0.00188618],
  [8688, 0.00185129],
  [8712, 0.0018164],
  [8736, 0.00178151],
  [8760, 0.00174662],
  [8784, 0.00171173],
  [8808, 0.00167273],
  [8832, 0.00163373],
  [8856, 0.00159473],
  [8880, 0.00155573],
  [8904, 0.00151673],
];
drugLevel['Deltoid:78mg'] = [
  [0, 0],
  [24, 2.46576],
  [48, 4.09145],
  [72, 5.15208],
  [96, 5.92156],
  [120, 6.49836],
  [144, 6.92528],
  [168, 7.3522],
  [192, 7.59791],
  [216, 7.84362],
  [240, 8.00469],
  [264, 8.16576],
  [288, 8.30169],
  [312, 8.43762],
  [336, 7.64159],
  [360, 6.84556],
  [384, 6.45245],
  [408, 6.05933],
  [432, 5.76186],
  [456, 5.46438],
  [480, 5.23672],
  [504, 5.00907],
  [528, 4.87172],
  [552, 4.73438],
  [576, 4.58596],
  [600, 4.43754],
  [624, 4.31461],
  [648, 4.19167],
  [672, 4.09375],
  [696, 3.99582],
  [720, 3.87421],
  [744, 3.7526],
  [768, 3.67094],
  [792, 3.58927],
  [816, 3.52046],
  [840, 3.45164],
  [864, 3.38157],
  [888, 3.3115],
  [912, 3.23714],
  [936, 3.16278],
  [960, 3.09108],
  [984, 3.01938],
  [1008, 2.9741],
  [1032, 2.92882],
  [1056, 2.86515],
  [1080, 2.80149],
  [1104, 2.74069],
  [1128, 2.6799],
  [1152, 2.62971],
  [1176, 2.57951],
  [1200, 2.52587],
  [1224, 2.47223],
  [1248, 2.42921],
  [1272, 2.38619],
  [1296, 2.33887],
  [1320, 2.29154],
  [1344, 2.2352],
  [1368, 2.17887],
  [1392, 2.14483],
  [1416, 2.1108],
  [1440, 2.06835],
  [1464, 2.0259],
  [1488, 1.99841],
  [1512, 1.97091],
  [1536, 1.92738],
  [1560, 1.88386],
  [1584, 1.84033],
  [1608, 1.8116],
  [1632, 1.78288],
  [1656, 1.75415],
  [1680, 1.72543],
  [1704, 1.6967],
  [1728, 1.66305],
  [1752, 1.62939],
  [1776, 1.59574],
  [1800, 1.56208],
  [1824, 1.52843],
  [1848, 1.50182],
  [1872, 1.47521],
  [1896, 1.4486],
  [1920, 1.42199],
  [1944, 1.39538],
  [1968, 1.37322],
  [1992, 1.35106],
  [2016, 1.32889],
  [2040, 1.30673],
  [2064, 1.28456],
  [2088, 1.26351],
  [2112, 1.24245],
  [2136, 1.22139],
  [2160, 1.20034],
  [2184, 1.17928],
  [2208, 1.15836],
  [2232, 1.13744],
  [2256, 1.11652],
  [2280, 1.0956],
  [2304, 1.07468],
  [2328, 1.05776],
  [2352, 1.04085],
  [2376, 1.02394],
  [2400, 1.00702],
  [2424, 0.990109],
  [2448, 0.974902],
  [2472, 0.959695],
  [2496, 0.944488],
  [2520, 0.929281],
  [2544, 0.914074],
  [2568, 0.899413],
  [2592, 0.884751],
  [2616, 0.87009],
  [2640, 0.855429],
  [2664, 0.840768],
  [2688, 0.827783],
  [2712, 0.814798],
  [2736, 0.801813],
  [2760, 0.788828],
  [2784, 0.775843],
  [2808, 0.763353],
  [2832, 0.750862],
  [2856, 0.738372],
  [2880, 0.725882],
  [2904, 0.713391],
  [2928, 0.702112],
  [2952, 0.690833],
  [2976, 0.679553],
  [3000, 0.668274],
  [3024, 0.656994],
  [3048, 0.646348],
  [3072, 0.635701],
  [3096, 0.625055],
  [3120, 0.614409],
  [3144, 0.603762],
  [3168, 0.594363],
  [3192, 0.584964],
  [3216, 0.575565],
  [3240, 0.566165],
  [3264, 0.556766],
  [3288, 0.548453],
  [3312, 0.540141],
  [3336, 0.531828],
  [3360, 0.523515],
  [3384, 0.515203],
  [3408, 0.507251],
  [3432, 0.499298],
  [3456, 0.491346],
  [3480, 0.483394],
  [3504, 0.475442],
  [3528, 0.468503],
  [3552, 0.461564],
  [3576, 0.454625],
  [3600, 0.447686],
  [3624, 0.440747],
  [3648, 0.434007],
  [3672, 0.427267],
  [3696, 0.420527],
  [3720, 0.413787],
  [3744, 0.407048],
  [3768, 0.400349],
  [3792, 0.39365],
  [3816, 0.386951],
  [3840, 0.380252],
  [3864, 0.373553],
  [3888, 0.369104],
  [3912, 0.364654],
  [3936, 0.360204],
  [3960, 0.355755],
  [3984, 0.351305],
  [4008, 0.345916],
  [4032, 0.340527],
  [4056, 0.335138],
  [4080, 0.329749],
  [4104, 0.32436],
  [4128, 0.318973],
  [4152, 0.313585],
  [4176, 0.308198],
  [4200, 0.30281],
  [4224, 0.297423],
  [4248, 0.29323],
  [4272, 0.289037],
  [4296, 0.284843],
  [4320, 0.28065],
  [4344, 0.276457],
  [4368, 0.272944],
  [4392, 0.26943],
  [4416, 0.265917],
  [4440, 0.262404],
  [4464, 0.258891],
  [4488, 0.255041],
  [4512, 0.251192],
  [4536, 0.247343],
  [4560, 0.243493],
  [4584, 0.239644],
  [4608, 0.235456],
  [4632, 0.231267],
  [4656, 0.227078],
  [4680, 0.22289],
  [4704, 0.218701],
  [4728, 0.215597],
  [4752, 0.212494],
  [4776, 0.20939],
  [4800, 0.206286],
  [4824, 0.203183],
  [4848, 0.199737],
  [4872, 0.196292],
  [4896, 0.192846],
  [4920, 0.189401],
  [4944, 0.185955],
  [4968, 0.18409],
  [4992, 0.182225],
  [5016, 0.18036],
  [5040, 0.178495],
  [5064, 0.17663],
  [5088, 0.173738],
  [5112, 0.170846],
  [5136, 0.167955],
  [5160, 0.165063],
  [5184, 0.162172],
  [5208, 0.159771],
  [5232, 0.15737],
  [5256, 0.15497],
  [5280, 0.152569],
  [5304, 0.150168],
  [5328, 0.147367],
  [5352, 0.144566],
  [5376, 0.141765],
  [5400, 0.138964],
  [5424, 0.136163],
  [5448, 0.134294],
  [5472, 0.132425],
  [5496, 0.130556],
  [5520, 0.128687],
  [5544, 0.126818],
  [5568, 0.125426],
  [5592, 0.124034],
  [5616, 0.122642],
  [5640, 0.12125],
  [5664, 0.119858],
  [5688, 0.117488],
  [5712, 0.115118],
  [5736, 0.112748],
  [5760, 0.110378],
  [5784, 0.108008],
  [5808, 0.107049],
  [5832, 0.10609],
  [5856, 0.105132],
  [5880, 0.104173],
  [5904, 0.103214],
  [5928, 0.100927],
  [5952, 0.0986408],
  [5976, 0.0963542],
  [6000, 0.0940676],
  [6024, 0.091781],
  [6048, 0.0906766],
  [6072, 0.0895722],
  [6096, 0.0884678],
  [6120, 0.0873635],
  [6144, 0.0862591],
  [6168, 0.0846736],
  [6192, 0.0830882],
  [6216, 0.0815028],
  [6240, 0.0799174],
  [6264, 0.0783319],
  [6288, 0.0773872],
  [6312, 0.0764424],
  [6336, 0.0754976],
  [6360, 0.0745529],
  [6384, 0.0736081],
  [6408, 0.0722132],
  [6432, 0.0708182],
  [6456, 0.0694233],
  [6480, 0.0680283],
  [6504, 0.0666334],
  [6528, 0.0655223],
  [6552, 0.0644113],
  [6576, 0.0633003],
  [6600, 0.0621892],
  [6624, 0.0610782],
  [6648, 0.0601701],
  [6672, 0.059262],
  [6696, 0.0583538],
  [6720, 0.0574457],
  [6744, 0.0565376],
  [6768, 0.0557568],
  [6792, 0.054976],
  [6816, 0.0541952],
  [6840, 0.0534144],
  [6864, 0.0526336],
  [6888, 0.051852],
  [6912, 0.0510704],
  [6936, 0.0502888],
  [6960, 0.0495072],
  [6984, 0.0487256],
  [7008, 0.0479409],
  [7032, 0.0471562],
  [7056, 0.0463715],
  [7080, 0.0455868],
  [7104, 0.0448021],
  [7128, 0.0441405],
  [7152, 0.043479],
  [7176, 0.0428174],
  [7200, 0.0421558],
  [7224, 0.0414943],
  [7248, 0.0409043],
  [7272, 0.0403142],
  [7296, 0.0397242],
  [7320, 0.0391342],
  [7344, 0.0385442],
  [7368, 0.0379075],
  [7392, 0.0372709],
  [7416, 0.0366342],
  [7440, 0.0359975],
  [7464, 0.0353609],
  [7488, 0.03486],
  [7512, 0.0343592],
  [7536, 0.0338584],
  [7560, 0.0333575],
  [7584, 0.0328567],
  [7608, 0.0322305],
  [7632, 0.0316042],
  [7656, 0.030978],
  [7680, 0.0303517],
  [7704, 0.0297255],
  [7728, 0.0294844],
  [7752, 0.0292433],
  [7776, 0.0290022],
  [7800, 0.0287611],
  [7824, 0.02852],
  [7848, 0.0279289],
  [7872, 0.0273378],
  [7896, 0.0267467],
  [7920, 0.0261556],
  [7944, 0.0255645],
  [7968, 0.0251754],
  [7992, 0.0247863],
  [8016, 0.0243973],
  [8040, 0.0240082],
  [8064, 0.0236191],
  [8088, 0.0233024],
  [8112, 0.0229857],
  [8136, 0.0226689],
  [8160, 0.0223522],
  [8184, 0.0220355],
  [8208, 0.0216433],
  [8232, 0.0212511],
  [8256, 0.020859],
  [8280, 0.0204668],
  [8304, 0.0200746],
  [8328, 0.0197303],
  [8352, 0.019386],
  [8376, 0.0190418],
  [8400, 0.0186975],
  [8424, 0.0183532],
  [8448, 0.0180373],
  [8472, 0.0177214],
  [8496, 0.0174054],
  [8520, 0.0170895],
  [8544, 0.0167736],
  [8568, 0.0165902],
  [8592, 0.0164069],
  [8616, 0.0162236],
  [8640, 0.0160402],
  [8664, 0.0158569],
  [8688, 0.0155646],
  [8712, 0.0152723],
  [8736, 0.01498],
  [8760, 0.0146877],
  [8784, 0.0143954],
  [8808, 0.0141781],
  [8832, 0.0139609],
  [8856, 0.0137437],
  [8880, 0.0135264],
  [8904, 0.0133092],
];
drugLevel['Deltoid:117mg'] = [
  [0, 0],
  [24, 3.29449],
  [48, 5.44665],
  [72, 6.88744],
  [96, 7.93077],
  [120, 8.70372],
  [144, 9.27183],
  [168, 9.83994],
  [192, 10.1512],
  [216, 10.4625],
  [240, 10.6801],
  [264, 10.8977],
  [288, 11.0918],
  [312, 11.2859],
  [336, 10.2376],
  [360, 9.18939],
  [384, 8.67574],
  [408, 8.16209],
  [432, 7.78778],
  [456, 7.41348],
  [480, 7.12456],
  [504, 6.83564],
  [528, 6.66944],
  [552, 6.50324],
  [576, 6.32584],
  [600, 6.14845],
  [624, 5.98335],
  [648, 5.81825],
  [672, 5.70656],
  [696, 5.59487],
  [720, 5.44848],
  [744, 5.30208],
  [768, 5.19686],
  [792, 5.09164],
  [816, 5.00174],
  [840, 4.91185],
  [864, 4.81508],
  [888, 4.71831],
  [912, 4.63125],
  [936, 4.54419],
  [960, 4.45194],
  [984, 4.35969],
  [1008, 4.31161],
  [1032, 4.26354],
  [1056, 4.18139],
  [1080, 4.09923],
  [1104, 4.02103],
  [1128, 3.94283],
  [1152, 3.87951],
  [1176, 3.81618],
  [1200, 3.73605],
  [1224, 3.65593],
  [1248, 3.61526],
  [1272, 3.57459],
  [1296, 3.50348],
  [1320, 3.43237],
  [1344, 3.36978],
  [1368, 3.30719],
  [1392, 3.26379],
  [1416, 3.22038],
  [1440, 3.14745],
  [1464, 3.07452],
  [1488, 3.04808],
  [1512, 3.02164],
  [1536, 2.95529],
  [1560, 2.88893],
  [1584, 2.82258],
  [1608, 2.78507],
  [1632, 2.74756],
  [1656, 2.71006],
  [1680, 2.67255],
  [1704, 2.63504],
  [1728, 2.59191],
  [1752, 2.54878],
  [1776, 2.50564],
  [1800, 2.46251],
  [1824, 2.41938],
  [1848, 2.3779],
  [1872, 2.33643],
  [1896, 2.29496],
  [1920, 2.25349],
  [1944, 2.21202],
  [1968, 2.1817],
  [1992, 2.15138],
  [2016, 2.12107],
  [2040, 2.09075],
  [2064, 2.06043],
  [2088, 2.02906],
  [2112, 1.99769],
  [2136, 1.96631],
  [2160, 1.93494],
  [2184, 1.90357],
  [2208, 1.87669],
  [2232, 1.84981],
  [2256, 1.82293],
  [2280, 1.79606],
  [2304, 1.76918],
  [2328, 1.74383],
  [2352, 1.71849],
  [2376, 1.69314],
  [2400, 1.6678],
  [2424, 1.64245],
  [2448, 1.61977],
  [2472, 1.59708],
  [2496, 1.5744],
  [2520, 1.55172],
  [2544, 1.52903],
  [2568, 1.50667],
  [2592, 1.48431],
  [2616, 1.46195],
  [2640, 1.43959],
  [2664, 1.41722],
  [2688, 1.39626],
  [2712, 1.3753],
  [2736, 1.35434],
  [2760, 1.33338],
  [2784, 1.31242],
  [2808, 1.29482],
  [2832, 1.27723],
  [2856, 1.25964],
  [2880, 1.24205],
  [2904, 1.22445],
  [2928, 1.20501],
  [2952, 1.18557],
  [2976, 1.16613],
  [3000, 1.14669],
  [3024, 1.12725],
  [3048, 1.11145],
  [3072, 1.09566],
  [3096, 1.07986],
  [3120, 1.06407],
  [3144, 1.04828],
  [3168, 1.03665],
  [3192, 1.02503],
  [3216, 1.01341],
  [3240, 1.00178],
  [3264, 0.99016],
  [3288, 0.976038],
  [3312, 0.961915],
  [3336, 0.947793],
  [3360, 0.933671],
  [3384, 0.919549],
  [3408, 0.906404],
  [3432, 0.893259],
  [3456, 0.880114],
  [3480, 0.866969],
  [3504, 0.853824],
  [3528, 0.84236],
  [3552, 0.830895],
  [3576, 0.819431],
  [3600, 0.807967],
  [3624, 0.796503],
  [3648, 0.785923],
  [3672, 0.775344],
  [3696, 0.764764],
  [3720, 0.754185],
  [3744, 0.743605],
  [3768, 0.731427],
  [3792, 0.719249],
  [3816, 0.70707],
  [3840, 0.694892],
  [3864, 0.682714],
  [3888, 0.676784],
  [3912, 0.670855],
  [3936, 0.664926],
  [3960, 0.658997],
  [3984, 0.653067],
  [4008, 0.645082],
  [4032, 0.637097],
  [4056, 0.629112],
  [4080, 0.621126],
  [4104, 0.613141],
  [4128, 0.60383],
  [4152, 0.594518],
  [4176, 0.585207],
  [4200, 0.575895],
  [4224, 0.566584],
  [4248, 0.560058],
  [4272, 0.553532],
  [4296, 0.547006],
  [4320, 0.54048],
  [4344, 0.533954],
  [4368, 0.527125],
  [4392, 0.520296],
  [4416, 0.513467],
  [4440, 0.506638],
  [4464, 0.499809],
  [4488, 0.49401],
  [4512, 0.488211],
  [4536, 0.482412],
  [4560, 0.476614],
  [4584, 0.470815],
  [4608, 0.463385],
  [4632, 0.455956],
  [4656, 0.448526],
  [4680, 0.441096],
  [4704, 0.433666],
  [4728, 0.428874],
  [4752, 0.424082],
  [4776, 0.41929],
  [4800, 0.414498],
  [4824, 0.409706],
  [4848, 0.403883],
  [4872, 0.39806],
  [4896, 0.392237],
  [4920, 0.386414],
  [4944, 0.380591],
  [4968, 0.376884],
  [4992, 0.373178],
  [5016, 0.369471],
  [5040, 0.365765],
  [5064, 0.362058],
  [5088, 0.356291],
  [5112, 0.350523],
  [5136, 0.344756],
  [5160, 0.338988],
  [5184, 0.333221],
  [5208, 0.328893],
  [5232, 0.324564],
  [5256, 0.320236],
  [5280, 0.315908],
  [5304, 0.31158],
  [5328, 0.306605],
  [5352, 0.30163],
  [5376, 0.296655],
  [5400, 0.29168],
  [5424, 0.286705],
  [5448, 0.28427],
  [5472, 0.281835],
  [5496, 0.279399],
  [5520, 0.276964],
  [5544, 0.274529],
  [5568, 0.271086],
  [5592, 0.267644],
  [5616, 0.264201],
  [5640, 0.260759],
  [5664, 0.257316],
  [5688, 0.253012],
  [5712, 0.248707],
  [5736, 0.244402],
  [5760, 0.240098],
  [5784, 0.235793],
  [5808, 0.23396],
  [5832, 0.232127],
  [5856, 0.230293],
  [5880, 0.22846],
  [5904, 0.226627],
  [5928, 0.222542],
  [5952, 0.218457],
  [5976, 0.214372],
  [6000, 0.210287],
  [6024, 0.206202],
  [6048, 0.20369],
  [6072, 0.201177],
  [6096, 0.198665],
  [6120, 0.196153],
  [6144, 0.193641],
  [6168, 0.191127],
  [6192, 0.188614],
  [6216, 0.186101],
  [6240, 0.183587],
  [6264, 0.181074],
  [6288, 0.179396],
  [6312, 0.177717],
  [6336, 0.176039],
  [6360, 0.174361],
  [6384, 0.172683],
  [6408, 0.16939],
  [6432, 0.166097],
  [6456, 0.162804],
  [6480, 0.159511],
  [6504, 0.156218],
  [6528, 0.154303],
  [6552, 0.152389],
  [6576, 0.150474],
  [6600, 0.148559],
  [6624, 0.146644],
  [6648, 0.144823],
  [6672, 0.143002],
  [6696, 0.141181],
  [6720, 0.13936],
  [6744, 0.137539],
  [6768, 0.135634],
  [6792, 0.13373],
  [6816, 0.131825],
  [6840, 0.129921],
  [6864, 0.128016],
  [6888, 0.126781],
  [6912, 0.125545],
  [6936, 0.12431],
  [6960, 0.123075],
  [6984, 0.12184],
  [7008, 0.119999],
  [7032, 0.118158],
  [7056, 0.116317],
  [7080, 0.114476],
  [7104, 0.112635],
  [7128, 0.111058],
  [7152, 0.10948],
  [7176, 0.107903],
  [7200, 0.106325],
  [7224, 0.104748],
  [7248, 0.103272],
  [7272, 0.101796],
  [7296, 0.10032],
  [7320, 0.0988446],
  [7344, 0.0973687],
  [7368, 0.0961077],
  [7392, 0.0948466],
  [7416, 0.0935855],
  [7440, 0.0923244],
  [7464, 0.0910633],
  [7488, 0.089918],
  [7512, 0.0887727],
  [7536, 0.0876274],
  [7560, 0.0864821],
  [7584, 0.0853368],
  [7608, 0.0841122],
  [7632, 0.0828877],
  [7656, 0.0816632],
  [7680, 0.0804387],
  [7704, 0.0792142],
  [7728, 0.0784875],
  [7752, 0.0777609],
  [7776, 0.0770343],
  [7800, 0.0763077],
  [7824, 0.075581],
  [7848, 0.0743631],
  [7872, 0.0731452],
  [7896, 0.0719273],
  [7920, 0.0707095],
  [7944, 0.0694916],
  [7968, 0.0684429],
  [7992, 0.0673943],
  [8016, 0.0663456],
  [8040, 0.065297],
  [8064, 0.0642483],
  [8088, 0.0635212],
  [8112, 0.0627941],
  [8136, 0.062067],
  [8160, 0.0613399],
  [8184, 0.0606127],
  [8208, 0.0597318],
  [8232, 0.0588508],
  [8256, 0.0579698],
  [8280, 0.0570888],
  [8304, 0.0562078],
  [8328, 0.0552577],
  [8352, 0.0543077],
  [8376, 0.0533576],
  [8400, 0.0524075],
  [8424, 0.0514574],
  [8448, 0.0508615],
  [8472, 0.0502656],
  [8496, 0.0496696],
  [8520, 0.0490737],
  [8544, 0.0484778],
  [8568, 0.0478958],
  [8592, 0.0473139],
  [8616, 0.0467319],
  [8640, 0.0461499],
  [8664, 0.045568],
  [8688, 0.0448425],
  [8712, 0.044117],
  [8736, 0.0433916],
  [8760, 0.0426661],
  [8784, 0.0419406],
  [8808, 0.0413478],
  [8832, 0.0407549],
  [8856, 0.0401621],
  [8880, 0.0395692],
  [8904, 0.0389764],
];

// working with this jawn
drugLevel['Deltoid:156mg'] = [
  [0, 0],
  [24, 4.06251],
  [48, 6.68957],
  [72, 8.44437],
  [96, 9.74739],
  [120, 10.6942],
  [144, 11.3795],
  [168, 12.0649],
  [192, 12.4607],
  [216, 12.8565],
  [240, 13.1346],
  [264, 13.4127],
  [288, 13.6536],
  [312, 13.8946],
  [336, 12.6177],
  [360, 11.3407],
  [384, 10.7257],
  [408, 10.1108],
  [432, 9.65904],
  [456, 9.20733],
  [480, 8.85126],
  [504, 8.49519],
  [528, 8.30362],
  [552, 8.11205],
  [576, 7.91365],
  [600, 7.71526],
  [624, 7.51119],
  [648, 7.30713],
  [672, 7.18287],
  [696, 7.05862],
  [720, 6.87309],
  [744, 6.68757],
  [768, 6.57644],
  [792, 6.4653],
  [816, 6.36553],
  [840, 6.26576],
  [864, 6.14337],
  [888, 6.02098],
  [912, 5.93274],
  [936, 5.84449],
  [960, 5.7243],
  [984, 5.60411],
  [1008, 5.55743],
  [1032, 5.51075],
  [1056, 5.4035],
  [1080, 5.29626],
  [1104, 5.21299],
  [1128, 5.12971],
  [1152, 5.04931],
  [1176, 4.96891],
  [1200, 4.874],
  [1224, 4.77909],
  [1248, 4.72557],
  [1272, 4.67206],
  [1296, 4.61324],
  [1320, 4.55443],
  [1344, 4.47116],
  [1368, 4.3879],
  [1392, 4.32412],
  [1416, 4.26034],
  [1440, 4.18584],
  [1464, 4.11134],
  [1488, 4.07467],
  [1512, 4.038],
  [1536, 3.95103],
  [1560, 3.86405],
  [1584, 3.77708],
  [1608, 3.73701],
  [1632, 3.69694],
  [1656, 3.65687],
  [1680, 3.6168],
  [1704, 3.57673],
  [1728, 3.5171],
  [1752, 3.45746],
  [1776, 3.39782],
  [1800, 3.33818],
  [1824, 3.27855],
  [1848, 3.23205],
  [1872, 3.18555],
  [1896, 3.13906],
  [1920, 3.09256],
  [1944, 3.04606],
  [1968, 3.00278],
  [1992, 2.95949],
  [2016, 2.91621],
  [2040, 2.87293],
  [2064, 2.82964],
  [2088, 2.78893],
  [2112, 2.74822],
  [2136, 2.70751],
  [2160, 2.6668],
  [2184, 2.62609],
  [2208, 2.59227],
  [2232, 2.55846],
  [2256, 2.52465],
  [2280, 2.49083],
  [2304, 2.45702],
  [2328, 2.4243],
  [2352, 2.39159],
  [2376, 2.35887],
  [2400, 2.32615],
  [2424, 2.29343],
  [2448, 2.26537],
  [2472, 2.23731],
  [2496, 2.20924],
  [2520, 2.18118],
  [2544, 2.15311],
  [2568, 2.12189],
  [2592, 2.09066],
  [2616, 2.05943],
  [2640, 2.02821],
  [2664, 1.99698],
  [2688, 1.97153],
  [2712, 1.94608],
  [2736, 1.92063],
  [2760, 1.89518],
  [2784, 1.86973],
  [2808, 1.84881],
  [2832, 1.8279],
  [2856, 1.80698],
  [2880, 1.78606],
  [2904, 1.76514],
  [2928, 1.7384],
  [2952, 1.71166],
  [2976, 1.68493],
  [3000, 1.65819],
  [3024, 1.63145],
  [3048, 1.60872],
  [3072, 1.58599],
  [3096, 1.56326],
  [3120, 1.54053],
  [3144, 1.5178],
  [3168, 1.5007],
  [3192, 1.4836],
  [3216, 1.4665],
  [3240, 1.4494],
  [3264, 1.4323],
  [3288, 1.41571],
  [3312, 1.39911],
  [3336, 1.38251],
  [3360, 1.36592],
  [3384, 1.34932],
  [3408, 1.33248],
  [3432, 1.31563],
  [3456, 1.29879],
  [3480, 1.28195],
  [3504, 1.2651],
  [3528, 1.24809],
  [3552, 1.23108],
  [3576, 1.21407],
  [3600, 1.19705],
  [3624, 1.18004],
  [3648, 1.16658],
  [3672, 1.15311],
  [3696, 1.13964],
  [3720, 1.12618],
  [3744, 1.11271],
  [3768, 1.09577],
  [3792, 1.07883],
  [3816, 1.06189],
  [3840, 1.04495],
  [3864, 1.02801],
  [3888, 1.01796],
  [3912, 1.00791],
  [3936, 0.997861],
  [3960, 0.987813],
  [3984, 0.977764],
  [4008, 0.96733],
  [4032, 0.956896],
  [4056, 0.946461],
  [4080, 0.936027],
  [4104, 0.925592],
  [4128, 0.915331],
  [4152, 0.905071],
  [4176, 0.89481],
  [4200, 0.884549],
  [4224, 0.874288],
  [4248, 0.863661],
  [4272, 0.853033],
  [4296, 0.842405],
  [4320, 0.831777],
  [4344, 0.82115],
  [4368, 0.812087],
  [4392, 0.803024],
  [4416, 0.793961],
  [4440, 0.784898],
  [4464, 0.775835],
  [4488, 0.766186],
  [4512, 0.756536],
  [4536, 0.746887],
  [4560, 0.737238],
  [4584, 0.727588],
  [4608, 0.718349],
  [4632, 0.709109],
  [4656, 0.699869],
  [4680, 0.69063],
  [4704, 0.68139],
  [4728, 0.674037],
  [4752, 0.666685],
  [4776, 0.659332],
  [4800, 0.651979],
  [4824, 0.644626],
  [4848, 0.635355],
  [4872, 0.626084],
  [4896, 0.616813],
  [4920, 0.607543],
  [4944, 0.598272],
  [4968, 0.594015],
  [4992, 0.589758],
  [5016, 0.585501],
  [5040, 0.581244],
  [5064, 0.576987],
  [5088, 0.568285],
  [5112, 0.559583],
  [5136, 0.550881],
  [5160, 0.542179],
  [5184, 0.533477],
  [5208, 0.528235],
  [5232, 0.522993],
  [5256, 0.517752],
  [5280, 0.51251],
  [5304, 0.507268],
  [5328, 0.499985],
  [5352, 0.492702],
  [5376, 0.485419],
  [5400, 0.478136],
  [5424, 0.470853],
  [5448, 0.466965],
  [5472, 0.463078],
  [5496, 0.459191],
  [5520, 0.455304],
  [5544, 0.451416],
  [5568, 0.445858],
  [5592, 0.440299],
  [5616, 0.434741],
  [5640, 0.429182],
  [5664, 0.423624],
  [5688, 0.418023],
  [5712, 0.412423],
  [5736, 0.406823],
  [5760, 0.401223],
  [5784, 0.395622],
  [5808, 0.391216],
  [5832, 0.386809],
  [5856, 0.382402],
  [5880, 0.377995],
  [5904, 0.373589],
  [5928, 0.369171],
  [5952, 0.364753],
  [5976, 0.360335],
  [6000, 0.355917],
  [6024, 0.351498],
  [6048, 0.347391],
  [6072, 0.343284],
  [6096, 0.339177],
  [6120, 0.33507],
  [6144, 0.330963],
  [6168, 0.327236],
  [6192, 0.323509],
  [6216, 0.319782],
  [6240, 0.316055],
  [6264, 0.312328],
  [6288, 0.309484],
  [6312, 0.30664],
  [6336, 0.303796],
  [6360, 0.300952],
  [6384, 0.298108],
  [6408, 0.29268],
  [6432, 0.287252],
  [6456, 0.281825],
  [6480, 0.276397],
  [6504, 0.270969],
  [6528, 0.267734],
  [6552, 0.264499],
  [6576, 0.261264],
  [6600, 0.258029],
  [6624, 0.254794],
  [6648, 0.252664],
  [6672, 0.250534],
  [6696, 0.248404],
  [6720, 0.246273],
  [6744, 0.244143],
  [6768, 0.240694],
  [6792, 0.237246],
  [6816, 0.233797],
  [6840, 0.230348],
  [6864, 0.226899],
  [6888, 0.225144],
  [6912, 0.223388],
  [6936, 0.221633],
  [6960, 0.219878],
  [6984, 0.218122],
  [7008, 0.214601],
  [7032, 0.211081],
  [7056, 0.20756],
  [7080, 0.204039],
  [7104, 0.200518],
  [7128, 0.198285],
  [7152, 0.196052],
  [7176, 0.193818],
  [7200, 0.191585],
  [7224, 0.189352],
  [7248, 0.187341],
  [7272, 0.185331],
  [7296, 0.18332],
  [7320, 0.18131],
  [7344, 0.179299],
  [7368, 0.177283],
  [7392, 0.175268],
  [7416, 0.173252],
  [7440, 0.171237],
  [7464, 0.169221],
  [7488, 0.166824],
  [7512, 0.164427],
  [7536, 0.162031],
  [7560, 0.159634],
  [7584, 0.157237],
  [7608, 0.155612],
  [7632, 0.153988],
  [7656, 0.152363],
  [7680, 0.150738],
  [7704, 0.149113],
  [7728, 0.147382],
  [7752, 0.145651],
  [7776, 0.14392],
  [7800, 0.142189],
  [7824, 0.140458],
  [7848, 0.138602],
  [7872, 0.136746],
  [7896, 0.13489],
  [7920, 0.133034],
  [7944, 0.131178],
  [7968, 0.129511],
  [7992, 0.127844],
  [8016, 0.126177],
  [8040, 0.12451],
  [8064, 0.122843],
  [8088, 0.12155],
  [8112, 0.120257],
  [8136, 0.118965],
  [8160, 0.117672],
  [8184, 0.116379],
  [8208, 0.114893],
  [8232, 0.113406],
  [8256, 0.11192],
  [8280, 0.110433],
  [8304, 0.108946],
  [8328, 0.107454],
  [8352, 0.105962],
  [8376, 0.104469],
  [8400, 0.102977],
  [8424, 0.101484],
  [8448, 0.100225],
  [8472, 0.0989659],
  [8496, 0.0977066],
  [8520, 0.0964474],
  [8544, 0.0951881],
  [8568, 0.0940786],
  [8592, 0.092969],
  [8616, 0.0918595],
  [8640, 0.0907499],
  [8664, 0.0896403],
  [8688, 0.0885196],
  [8712, 0.0873989],
  [8736, 0.0862782],
  [8760, 0.0851575],
  [8784, 0.0840368],
  [8808, 0.0828099],
  [8832, 0.081583],
  [8856, 0.0803561],
  [8880, 0.0791292],
  [8904, 0.0779023],
];

drugLevel['Deltoid:234mg'] = [
  [0, 0],
  [24, 5.43522],
  [48, 8.94281],
  [72, 11.2763],
  [96, 13.0026],
  [120, 14.3092],
  [144, 15.2395],
  [168, 16.1699],
  [192, 16.6475],
  [216, 17.1252],
  [240, 17.5001],
  [264, 17.8749],
  [288, 18.2346],
  [312, 18.5942],
  [336, 16.9154],
  [360, 15.2366],
  [384, 14.4029],
  [408, 13.5692],
  [432, 12.9886],
  [456, 12.4081],
  [480, 11.9544],
  [504, 11.5006],
  [528, 11.258],
  [552, 11.0155],
  [576, 10.7847],
  [600, 10.5539],
  [624, 10.2711],
  [648, 9.98816],
  [672, 9.84298],
  [696, 9.69781],
  [720, 9.48769],
  [744, 9.27757],
  [768, 9.13463],
  [792, 8.99168],
  [816, 8.86034],
  [840, 8.72899],
  [864, 8.58499],
  [888, 8.44099],
  [912, 8.34153],
  [936, 8.24206],
  [960, 8.09237],
  [984, 7.94267],
  [1008, 7.87651],
  [1032, 7.81035],
  [1056, 7.65085],
  [1080, 7.49136],
  [1104, 7.42583],
  [1128, 7.36029],
  [1152, 7.27069],
  [1176, 7.18108],
  [1200, 7.01041],
  [1224, 6.83974],
  [1248, 6.78395],
  [1272, 6.72815],
  [1296, 6.67657],
  [1320, 6.625],
  [1344, 6.50196],
  [1368, 6.37893],
  [1392, 6.3139],
  [1416, 6.24887],
  [1440, 6.15926],
  [1464, 6.06964],
  [1488, 6.01059],
  [1512, 5.95154],
  [1536, 5.84876],
  [1560, 5.74598],
  [1584, 5.64319],
  [1608, 5.58547],
  [1632, 5.52774],
  [1656, 5.47002],
  [1680, 5.41229],
  [1704, 5.35457],
  [1728, 5.28297],
  [1752, 5.21137],
  [1776, 5.13976],
  [1800, 5.06816],
  [1824, 4.99656],
  [1848, 4.93433],
  [1872, 4.87209],
  [1896, 4.80986],
  [1920, 4.74763],
  [1944, 4.68539],
  [1968, 4.61934],
  [1992, 4.55328],
  [2016, 4.48722],
  [2040, 4.42117],
  [2064, 4.35511],
  [2088, 4.2957],
  [2112, 4.23628],
  [2136, 4.17687],
  [2160, 4.11745],
  [2184, 4.05804],
  [2208, 4.01438],
  [2232, 3.97071],
  [2256, 3.92705],
  [2280, 3.88339],
  [2304, 3.83972],
  [2328, 3.79696],
  [2352, 3.75419],
  [2376, 3.71142],
  [2400, 3.66866],
  [2424, 3.62589],
  [2448, 3.58294],
  [2472, 3.54],
  [2496, 3.49705],
  [2520, 3.4541],
  [2544, 3.41115],
  [2568, 3.36748],
  [2592, 3.32381],
  [2616, 3.28014],
  [2640, 3.23647],
  [2664, 3.19281],
  [2688, 3.15574],
  [2712, 3.11867],
  [2736, 3.08161],
  [2760, 3.04454],
  [2784, 3.00747],
  [2808, 2.97611],
  [2832, 2.94475],
  [2856, 2.91338],
  [2880, 2.88202],
  [2904, 2.85066],
  [2928, 2.8125],
  [2952, 2.77434],
  [2976, 2.73617],
  [3000, 2.69801],
  [3024, 2.65985],
  [3048, 2.63054],
  [3072, 2.60123],
  [3096, 2.57193],
  [3120, 2.54262],
  [3144, 2.51331],
  [3168, 2.4883],
  [3192, 2.46328],
  [3216, 2.43827],
  [3240, 2.41326],
  [3264, 2.38825],
  [3288, 2.35743],
  [3312, 2.32662],
  [3336, 2.2958],
  [3360, 2.26499],
  [3384, 2.23417],
  [3408, 2.21175],
  [3432, 2.18934],
  [3456, 2.16692],
  [3480, 2.1445],
  [3504, 2.12209],
  [3528, 2.09412],
  [3552, 2.06615],
  [3576, 2.03819],
  [3600, 2.01022],
  [3624, 1.98225],
  [3648, 1.96071],
  [3672, 1.93917],
  [3696, 1.91763],
  [3720, 1.89609],
  [3744, 1.87454],
  [3768, 1.85559],
  [3792, 1.83664],
  [3816, 1.81769],
  [3840, 1.79873],
  [3864, 1.77978],
  [3888, 1.75884],
  [3912, 1.7379],
  [3936, 1.71695],
  [3960, 1.69601],
  [3984, 1.67507],
  [4008, 1.66225],
  [4032, 1.64944],
  [4056, 1.63662],
  [4080, 1.6238],
  [4104, 1.61098],
  [4128, 1.59373],
  [4152, 1.57647],
  [4176, 1.55921],
  [4200, 1.54195],
  [4224, 1.5247],
  [4248, 1.50956],
  [4272, 1.49443],
  [4296, 1.4793],
  [4320, 1.46416],
  [4344, 1.44903],
  [4368, 1.43433],
  [4392, 1.41962],
  [4416, 1.40491],
  [4440, 1.39021],
  [4464, 1.3755],
  [4488, 1.36103],
  [4512, 1.34655],
  [4536, 1.33207],
  [4560, 1.3176],
  [4584, 1.30312],
  [4608, 1.28817],
  [4632, 1.27321],
  [4656, 1.25826],
  [4680, 1.24331],
  [4704, 1.22836],
  [4728, 1.21675],
  [4752, 1.20514],
  [4776, 1.19352],
  [4800, 1.18191],
  [4824, 1.1703],
  [4848, 1.15875],
  [4872, 1.1472],
  [4896, 1.13565],
  [4920, 1.1241],
  [4944, 1.11254],
  [4968, 1.10286],
  [4992, 1.09318],
  [5016, 1.0835],
  [5040, 1.07382],
  [5064, 1.06414],
  [5088, 1.05187],
  [5112, 1.0396],
  [5136, 1.02733],
  [5160, 1.01505],
  [5184, 1.00278],
  [5208, 0.991948],
  [5232, 0.981115],
  [5256, 0.970283],
  [5280, 0.959451],
  [5304, 0.948618],
  [5328, 0.937146],
  [5352, 0.925675],
  [5376, 0.914203],
  [5400, 0.902731],
  [5424, 0.891259],
  [5448, 0.883764],
  [5472, 0.876268],
  [5496, 0.868773],
  [5520, 0.861277],
  [5544, 0.853781],
  [5568, 0.84582],
  [5592, 0.837859],
  [5616, 0.829898],
  [5640, 0.821937],
  [5664, 0.813976],
  [5688, 0.805402],
  [5712, 0.796829],
  [5736, 0.788256],
  [5760, 0.779683],
  [5784, 0.771109],
  [5808, 0.761968],
  [5832, 0.752826],
  [5856, 0.743685],
  [5880, 0.734543],
  [5904, 0.725401],
  [5928, 0.719515],
  [5952, 0.713629],
  [5976, 0.707742],
  [6000, 0.701856],
  [6024, 0.695969],
  [6048, 0.6902],
  [6072, 0.684431],
  [6096, 0.678662],
  [6120, 0.672893],
  [6144, 0.667124],
  [6168, 0.658251],
  [6192, 0.649378],
  [6216, 0.640505],
  [6240, 0.631632],
  [6264, 0.622759],
  [6288, 0.617373],
  [6312, 0.611986],
  [6336, 0.6066],
  [6360, 0.601213],
  [6384, 0.595827],
  [6408, 0.588773],
  [6432, 0.581719],
  [6456, 0.574665],
  [6480, 0.567611],
  [6504, 0.560557],
  [6528, 0.554526],
  [6552, 0.548496],
  [6576, 0.542465],
  [6600, 0.536435],
  [6624, 0.530405],
  [6648, 0.526356],
  [6672, 0.522307],
  [6696, 0.518258],
  [6720, 0.514209],
  [6744, 0.51016],
  [6768, 0.503734],
  [6792, 0.497307],
  [6816, 0.49088],
  [6840, 0.484453],
  [6864, 0.478026],
  [6888, 0.474662],
  [6912, 0.471297],
  [6936, 0.467933],
  [6960, 0.464568],
  [6984, 0.461204],
  [7008, 0.455612],
  [7032, 0.450021],
  [7056, 0.444429],
  [7080, 0.438837],
  [7104, 0.433246],
  [7128, 0.429807],
  [7152, 0.426367],
  [7176, 0.422928],
  [7200, 0.419489],
  [7224, 0.41605],
  [7248, 0.410396],
  [7272, 0.404743],
  [7296, 0.39909],
  [7320, 0.393436],
  [7344, 0.387783],
  [7368, 0.38449],
  [7392, 0.381198],
  [7416, 0.377905],
  [7440, 0.374613],
  [7464, 0.37132],
  [7488, 0.368294],
  [7512, 0.365267],
  [7536, 0.36224],
  [7560, 0.359214],
  [7584, 0.356187],
  [7608, 0.352341],
  [7632, 0.348495],
  [7656, 0.344649],
  [7680, 0.340803],
  [7704, 0.336957],
  [7728, 0.333373],
  [7752, 0.329789],
  [7776, 0.326205],
  [7800, 0.322621],
  [7824, 0.319036],
  [7848, 0.314928],
  [7872, 0.31082],
  [7896, 0.306712],
  [7920, 0.302604],
  [7944, 0.298496],
  [7968, 0.296054],
  [7992, 0.293613],
  [8016, 0.291172],
  [8040, 0.288731],
  [8064, 0.28629],
  [8088, 0.283131],
  [8112, 0.279972],
  [8136, 0.276813],
  [8160, 0.273654],
  [8184, 0.270495],
  [8208, 0.267202],
  [8232, 0.26391],
  [8256, 0.260617],
  [8280, 0.257324],
  [8304, 0.254031],
  [8328, 0.251478],
  [8352, 0.248925],
  [8376, 0.246373],
  [8400, 0.24382],
  [8424, 0.241267],
  [8448, 0.23871],
  [8472, 0.236153],
  [8496, 0.233596],
  [8520, 0.231039],
  [8544, 0.228482],
  [8568, 0.225537],
  [8592, 0.222593],
  [8616, 0.219649],
  [8640, 0.216705],
  [8664, 0.21376],
  [8688, 0.211912],
  [8712, 0.210063],
  [8736, 0.208215],
  [8760, 0.206366],
  [8784, 0.204518],
  [8808, 0.201768],
  [8832, 0.199018],
  [8856, 0.196268],
  [8880, 0.193518],
  [8904, 0.190767],
];
drugLevel['InvegaER:6mg'] = [
  [0, 0],
  [24, 8.28],
  [48, 3.88],
  [72, 2.03],
  [96, 1.13],
  [120, 0.66],
  [144, 0.38],
  [168, 0.23],
  [192, 0.12],
  [216, 0.07],
  [240, 0.04],
  [264, 0.02],
  [288, 0.01],
  [312, 0.01],
  [336, 0],
];
drugLevel['InvegaER:3mg'] = drugLevel['InvegaER:6mg'].map(function (dose) {
  return [dose[0], dose[1] / 2];
});
drugLevel['InvegaER:9mg'] = drugLevel['InvegaER:6mg'].map(function (dose) {
  return [dose[0], dose[1] * 1.5];
});
drugLevel['InvegaER:12mg'] = drugLevel['InvegaER:6mg'].map(function (dose) {
  return [dose[0], dose[1] * 2];
});

drugLevel['Other:6mg'] = drugLevel['InvegaER:6mg'];

//Every other hour concentration
drugLevel['OralRisperdal:1mg'] = [
  [0, 0.0],
  [1, 5.9877],
  [2, 6.7483],
  [3, 6.5376],
  [4, 6.2516],
  [5, 5.9716],
  [6, 5.7044],
  [7, 5.4501],
  [8, 5.2081],
  [9, 4.9778],
  [10, 4.7587],
  [11, 4.5501],
  [12, 4.3515],
  [13, 4.1625],
  [14, 3.9825],
  [15, 3.8112],
  [16, 3.6482],
  [17, 3.4929],
  [18, 3.345],
  [19, 3.2042],
  [20, 3.0701],
  [21, 2.9424],
  [22, 2.8207],
  [23, 2.7048],
  [24, 2.5944],
  [25, 2.4892],
  [26, 2.389],
  [27, 2.2935],
  [28, 2.2025],
  [29, 2.1157],
  [30, 2.033],
  [31, 1.9541],
  [32, 1.8789],
  [33, 1.8072],
  [34, 1.7388],
  [35, 1.6736],
  [36, 1.6114],
  [37, 1.552],
  [38, 1.4954],
  [39, 1.4413],
  [40, 1.3897],
  [41, 1.3405],
  [42, 1.2934],
  [43, 1.2485],
  [44, 1.2056],
  [45, 1.1646],
  [46, 1.1254],
  [47, 1.088],
  [48, 1.0522],
  [49, 1.018],
  [50, 0.9853],
  [51, 0.954],
  [52, 0.9241],
  [53, 0.8955],
  [54, 0.8681],
  [55, 0.8418],
  [56, 0.8167],
  [57, 0.7926],
  [58, 0.7696],
  [59, 0.7475],
  [60, 0.7263],
  [61, 0.706],
  [62, 0.6866],
  [63, 0.6679],
  [64, 0.65],
  [65, 0.6328],
  [66, 0.6163],
  [67, 0.6004],
  [68, 0.5852],
  [69, 0.5705],
  [70, 0.5564],
  [71, 0.5429],
  [72, 0.5299],
  [73, 0.5174],
  [74, 0.5053],
  [75, 0.4937],
  [76, 0.4825],
  [77, 0.4717],
  [78, 0.4613],
  [79, 0.4513],
  [80, 0.4416],
  [81, 0.4323],
  [82, 0.4233],
  [83, 0.4146],
  [84, 0.4062],
  [85, 0.398],
  [86, 0.3902],
  [87, 0.3826],
  [88, 0.3752],
  [89, 0.3681],
  [90, 0.3612],
  [91, 0.3546],
  [92, 0.3481],
  [93, 0.3418],
  [94, 0.3357],
  [95, 0.3298],
  [96, 0.3241],
  [97, 0.3185],
  [98, 0.3131],
  [99, 0.3079],
  [100, 0.3028],
  [101, 0.2978],
  [102, 0.293],
  [103, 0.2882],
  [104, 0.2837],
  [105, 0.2792],
  [106, 0.2749],
  [107, 0.2706],
  [108, 0.2665],
  [109, 0.2625],
  [110, 0.2585],
  [111, 0.2547],
  [112, 0.2509],
  [113, 0.2473],
  [114, 0.2437],
  [115, 0.2402],
  [116, 0.2368],
  [117, 0.2334],
  [118, 0.2302],
  [119, 0.2269],
  [120, 0.2238],
  [121, 0.2207],
  [122, 0.2177],
  [123, 0.2148],
  [124, 0.2119],
  [125, 0.2091],
  [126, 0.2063],
  [127, 0.2036],
  [128, 0.2009],
  [129, 0.1983],
  [130, 0.1957],
  [131, 0.1932],
  [132, 0.1907],
  [133, 0.1882],
  [134, 0.1858],
  [135, 0.1835],
  [136, 0.1812],
  [137, 0.1789],
  [138, 0.1767],
  [139, 0.1745],
  [140, 0.1723],
  [141, 0.1702],
  [142, 0.1681],
  [143, 0.166],
  [144, 0.164],
  [145, 0.162],
  [146, 0.16],
  [147, 0.1581],
  [148, 0.1562],
  [149, 0.1543],
  [150, 0.1524],
  [151, 0.1506],
  [152, 0.1488],
  [153, 0.147],
  [154, 0.1453],
  [155, 0.1436],
  [156, 0.1419],
  [157, 0.1402],
  [158, 0.1385],
  [159, 0.1369],
  [160, 0.1353],
  [161, 0.1337],
  [162, 0.1322],
  [163, 0.1306],
  [164, 0.1291],
  [165, 0.1276],
  [166, 0.1261],
  [167, 0.1247],
  [168, 0.1232],
  [169, 0.1218],
  [170, 0.1204],
  [171, 0.119],
  [172, 0.1176],
  [173, 0.1163],
  [174, 0.1149],
  [175, 0.1136],
  [176, 0.1123],
  [177, 0.111],
  [178, 0.1098],
  [179, 0.1085],
  [180, 0.1073],
  [181, 0.106],
  [182, 0.1048],
  [183, 0.1036],
  [184, 0.1025],
  [185, 0.1013],
  [186, 0.1001],
  [187, 0.099],
  [188, 0.0979],
  [189, 0.0968],
  [190, 0.0957],
  [191, 0.0946],
  [192, 0.0935],
  [193, 0.0925],
  [194, 0.0914],
  [195, 0.0904],
  [196, 0.0894],
  [197, 0.0884],
  [198, 0.0874],
  [199, 0.0864],
  [200, 0.0854],
  [201, 0.0844],
  [202, 0.0835],
  [203, 0.0825],
  [204, 0.0816],
  [205, 0.0807],
  [206, 0.0798],
  [207, 0.0789],
  [208, 0.078],
  [209, 0.0771],
  [210, 0.0763],
  [211, 0.0754],
  [212, 0.0746],
  [213, 0.0737],
  [214, 0.0729],
  [215, 0.0721],
  [216, 0.0713],
  [217, 0.0705],
  [218, 0.0697],
  [219, 0.0689],
  [220, 0.0681],
  [221, 0.0674],
  [222, 0.0666],
  [223, 0.0659],
  [224, 0.0651],
  [225, 0.0644],
  [226, 0.0637],
  [227, 0.063],
  [228, 0.0623],
  [229, 0.0616],
  [230, 0.0609],
  [231, 0.0602],
  [232, 0.0595],
  [233, 0.0588],
  [234, 0.0582],
  [235, 0.0575],
  [236, 0.0569],
  [237, 0.0563],
  [238, 0.0556],
  [239, 0.055],
  [240, 0.0544],
  [241, 0.0538],
  [242, 0.0532],
  [243, 0.0526],
  [244, 0.052],
  [245, 0.0514],
  [246, 0.0508],
  [247, 0.0503],
  [248, 0.0497],
  [249, 0.0492],
  [250, 0.0486],
  [251, 0.0481],
  [252, 0.0475],
  [253, 0.047],
  [254, 0.0465],
  [255, 0.046],
  [256, 0.0454],
  [257, 0.0449],
  [258, 0.0444],
  [259, 0.0439],
  [260, 0.0434],
  [261, 0.043],
  [262, 0.0425],
  [263, 0.042],
  [264, 0.0415],
  [265, 0.0411],
  [266, 0.0406],
  [267, 0.0402],
  [268, 0.0397],
  [269, 0.0393],
  [270, 0.0388],
  [271, 0.0384],
  [272, 0.038],
  [273, 0.0375],
  [274, 0.0371],
  [275, 0.0367],
  [276, 0.0363],
  [277, 0.0359],
  [278, 0.0355],
  [279, 0.0351],
  [280, 0.0347],
  [281, 0.0343],
  [282, 0.0339],
  [283, 0.0336],
  [284, 0.0332],
  [285, 0.0328],
  [286, 0.0324],
  [287, 0.0321],
  [288, 0.0317],
  [289, 0.0314],
  [290, 0.031],
  [291, 0.0307],
  [292, 0.0303],
  [293, 0.03],
  [294, 0.0297],
  [295, 0.0293],
  [296, 0.029],
  [297, 0.0287],
  [298, 0.0284],
  [299, 0.028],
  [300, 0.0277],
  [301, 0.0274],
  [302, 0.0271],
  [303, 0.0268],
  [304, 0.0265],
  [305, 0.0262],
  [306, 0.0259],
  [307, 0.0256],
  [308, 0.0253],
  [309, 0.0251],
  [310, 0.0248],
  [311, 0.0245],
  [312, 0.0242],
  [313, 0.024],
  [314, 0.0237],
  [315, 0.0234],
  [316, 0.0232],
  [317, 0.0229],
  [318, 0.0227],
  [319, 0.0224],
  [320, 0.0222],
  [321, 0.0219],
  [322, 0.0217],
  [323, 0.0214],
  [324, 0.0212],
  [325, 0.0209],
  [326, 0.0207],
  [327, 0.0205],
  [328, 0.0203],
  [329, 0.02],
  [330, 0.0198],
  [331, 0.0196],
  [332, 0.0194],
  [333, 0.0191],
  [334, 0.0189],
  [335, 0.0187],
  [336, 0.0185],
  [337, 0.0183],
  [338, 0.0181],
  [339, 0.0179],
  [340, 0.0177],
  [341, 0.0175],
  [342, 0.0173],
  [343, 0.0171],
  [344, 0.0169],
  [345, 0.0167],
  [346, 0.0165],
  [347, 0.0164],
  [348, 0.0162],
  [349, 0.016],
  [350, 0.0158],
  [351, 0.0156],
  [352, 0.0155],
  [353, 0.0153],
  [354, 0.0151],
  [355, 0.015],
  [356, 0.0148],
  [357, 0.0146],
  [358, 0.0145],
  [359, 0.0143],
  [360, 0.0141],
  [361, 0.014],
  [362, 0.0138],
  [363, 0.0137],
  [364, 0.0135],
  [365, 0.0134],
  [366, 0.0132],
  [367, 0.0131],
  [368, 0.0129],
  [369, 0.0128],
  [370, 0.0126],
  [371, 0.0125],
  [372, 0.0124],
  [373, 0.0122],
  [374, 0.0121],
  [375, 0.012],
  [376, 0.0118],
  [377, 0.0117],
  [378, 0.0116],
  [379, 0.0114],
  [380, 0.0113],
  [381, 0.0112],
  [382, 0.0111],
  [383, 0.0109],
  [384, 0.0108],
  [385, 0.0107],
  [386, 0.0106],
  [387, 0.0104],
  [388, 0.0103],
  [389, 0.0102],
  [390, 0.0101],
  [391, 0.01],
  [392, 0.0099],
  [393, 0.0098],
  [394, 0.0097],
  [395, 0.0096],
  [396, 0.0094],
  [397, 0.0093],
  [398, 0.0092],
  [399, 0.0091],
  [400, 0.009],
  [401, 0.0089],
  [402, 0.0088],
  [403, 0.0087],
  [404, 0.0086],
  [405, 0.0085],
  [406, 0.0084],
  [407, 0.0083],
  [408, 0.0083],
  [409, 0.0082],
  [410, 0.0081],
  [411, 0.008],
  [412, 0.0079],
  [413, 0.0078],
  [414, 0.0077],
  [415, 0.0076],
  [416, 0.0075],
  [417, 0.0075],
  [418, 0.0074],
  [419, 0.0073],
  [420, 0.0072],
  [421, 0.0071],
  [422, 0.0071],
  [423, 0.007],
  [424, 0.0069],
  [425, 0.0068],
  [426, 0.0067],
  [427, 0.0067],
  [428, 0.0066],
  [429, 0.0065],
  [430, 0.0064],
  [431, 0.0064],
  [432, 0.0063],
  [433, 0.0062],
  [434, 0.0062],
  [435, 0.0061],
  [436, 0.006],
  [437, 0.006],
  [438, 0.0059],
  [439, 0.0058],
  [440, 0.0058],
  [441, 0.0057],
  [442, 0.0056],
  [443, 0.0056],
  [444, 0.0055],
  [445, 0.0055],
  [446, 0.0054],
  [447, 0.0053],
  [448, 0.0053],
  [449, 0.0052],
  [450, 0.0052],
  [451, 0.0051],
  [452, 0.005],
  [453, 0.005],
  [454, 0.0049],
  [455, 0.0049],
  [456, 0.0048],
  [457, 0.0048],
  [458, 0.0047],
  [459, 0.0047],
  [460, 0.0046],
  [461, 0.0046],
  [462, 0.0045],
  [463, 0.0045],
  [464, 0.0044],
  [465, 0.0044],
  [466, 0.0043],
  [467, 0.0043],
  [468, 0.0042],
  [469, 0.0042],
  [470, 0.0041],
  [471, 0.0041],
  [472, 0.004],
  [473, 0.004],
  [474, 0.0039],
  [475, 0.0039],
  [476, 0.0038],
  [477, 0.0038],
  [478, 0.0038],
  [479, 0.0037],
  [480, 0.0037],
  [481, 0.0036],
  [482, 0.0036],
  [483, 0.0036],
  [484, 0.0035],
  [485, 0.0035],
  [486, 0.0034],
  [487, 0.0034],
  [488, 0.0034],
  [489, 0.0033],
  [490, 0.0033],
  [491, 0.0033],
  [492, 0.0032],
  [493, 0.0032],
  [494, 0.0031],
  [495, 0.0031],
  [496, 0.0031],
  [497, 0.003],
  [498, 0.003],
  [499, 0.003],
  [500, 0.0029],
  [501, 0.0029],
  [502, 0.0029],
  [503, 0.0028],
  [504, 0.0028],
  [505, 0.0028],
  [506, 0.0027],
  [507, 0.0027],
  [508, 0.0027],
  [509, 0.0027],
  [510, 0.0026],
  [511, 0.0026],
  [512, 0.0026],
  [513, 0.0025],
  [514, 0.0025],
  [515, 0.0025],
  [516, 0.0025],
  [517, 0.0024],
  [518, 0.0024],
  [519, 0.0024],
  [520, 0.0023],
  [521, 0.0023],
  [522, 0.0023],
  [523, 0.0023],
  [524, 0.0022],
  [525, 0.0022],
  [526, 0.0022],
  [527, 0.0022],
  [528, 0.0021],
  [529, 0.0021],
  [530, 0.0021],
  [531, 0.0021],
  [532, 0.0021],
  [533, 0.002],
  [534, 0.002],
  [535, 0.002],
  [536, 0.002],
  [537, 0.0019],
  [538, 0.0019],
  [539, 0.0019],
  [540, 0.0019],
  [541, 0.0019],
  [542, 0.0018],
  [543, 0.0018],
  [544, 0.0018],
  [545, 0.0018],
  [546, 0.0018],
  [547, 0.0017],
  [548, 0.0017],
  [549, 0.0017],
  [550, 0.0017],
  [551, 0.0017],
  [552, 0.0016],
  [553, 0.0016],
  [554, 0.0016],
  [555, 0.0016],
  [556, 0.0016],
  [557, 0.0016],
  [558, 0.0015],
  [559, 0.0015],
  [560, 0.0015],
  [561, 0.0015],
  [562, 0.0015],
  [563, 0.0015],
  [564, 0.0014],
  [565, 0.0014],
  [566, 0.0014],
  [567, 0.0014],
  [568, 0.0014],
  [569, 0.0014],
  [570, 0.0013],
  [571, 0.0013],
  [572, 0.0013],
  [573, 0.0013],
  [574, 0.0013],
  [575, 0.0013],
  [576, 0.0013],
  [577, 0.0012],
  [578, 0.0012],
  [579, 0.0012],
  [580, 0.0012],
  [581, 0.0012],
  [582, 0.0012],
  [583, 0.0012],
  [584, 0.0011],
  [585, 0.0011],
  [586, 0.0011],
  [587, 0.0011],
  [588, 0.0011],
  [589, 0.0011],
  [590, 0.0011],
  [591, 0.0011],
  [592, 0.001],
  [593, 0.001],
  [594, 0.001],
  [595, 0.001],
  [596, 0.001],
  [597, 0.001],
  [598, 0.001],
  [599, 0.001],
  [600, 0.001],
  [601, 0.0009],
  [602, 0.0009],
  [603, 0.0009],
  [604, 0.0009],
  [605, 0.0009],
  [606, 0.0009],
  [607, 0.0009],
  [608, 0.0009],
  [609, 0.0009],
  [610, 0.0009],
  [611, 0.0008],
  [612, 0.0008],
  [613, 0.0008],
  [614, 0.0008],
  [615, 0.0008],
  [616, 0.0008],
  [617, 0.0008],
  [618, 0.0008],
  [619, 0.0008],
  [620, 0.0008],
  [621, 0.0008],
  [622, 0.0008],
  [623, 0.0007],
  [624, 0.0007],
  [625, 0.0007],
  [626, 0.0007],
  [627, 0.0007],
  [628, 0.0007],
  [629, 0.0007],
  [630, 0.0007],
  [631, 0.0007],
  [632, 0.0007],
  [633, 0.0007],
  [634, 0.0007],
  [635, 0.0006],
  [636, 0.0006],
  [637, 0.0006],
  [638, 0.0006],
  [639, 0.0006],
  [640, 0.0006],
  [641, 0.0006],
  [642, 0.0006],
  [643, 0.0006],
  [644, 0.0006],
  [645, 0.0006],
  [646, 0.0006],
  [647, 0.0006],
  [648, 0.0006],
  [649, 0.0006],
  [650, 0.0005],
  [651, 0.0005],
  [652, 0.0005],
  [653, 0.0005],
  [654, 0.0005],
  [655, 0.0005],
  [656, 0.0005],
  [657, 0.0005],
  [658, 0.0005],
  [659, 0.0005],
  [660, 0.0005],
  [661, 0.0005],
  [662, 0.0005],
  [663, 0.0005],
  [664, 0.0005],
  [665, 0.0005],
  [666, 0.0005],
  [667, 0.0005],
  [668, 0.0004],
  [669, 0.0004],
  [670, 0.0004],
  [671, 0.0004],
  [672, 0.0004],
];
drugLevel['OralRisperdal:2mg'] = [
  [0, 0.0],
  [1, 11.9755],
  [2, 13.4965],
  [3, 13.0752],
  [4, 12.5031],
  [5, 11.9431],
  [6, 11.4088],
  [7, 10.9003],
  [8, 10.4163],
  [9, 9.9557],
  [10, 9.5173],
  [11, 9.1001],
  [12, 8.703],
  [13, 8.325],
  [14, 7.9651],
  [15, 7.6225],
  [16, 7.2964],
  [17, 6.9858],
  [18, 6.6901],
  [19, 6.4085],
  [20, 6.1403],
  [21, 5.8848],
  [22, 5.6415],
  [23, 5.4097],
  [24, 5.1889],
  [25, 4.9785],
  [26, 4.7781],
  [27, 4.587],
  [28, 4.405],
  [29, 4.2314],
  [30, 4.066],
  [31, 3.9083],
  [32, 3.7579],
  [33, 3.6145],
  [34, 3.4778],
  [35, 3.3473],
  [36, 3.2229],
  [37, 3.1041],
  [38, 2.9908],
  [39, 2.8827],
  [40, 2.7795],
  [41, 2.681],
  [42, 2.5869],
  [43, 2.497],
  [44, 2.4112],
  [45, 2.3292],
  [46, 2.2509],
  [47, 2.1761],
  [48, 2.1045],
  [49, 2.0361],
  [50, 1.9707],
  [51, 1.9081],
  [52, 1.8482],
  [53, 1.791],
  [54, 1.7362],
  [55, 1.6837],
  [56, 1.6334],
  [57, 1.5853],
  [58, 1.5392],
  [59, 1.495],
  [60, 1.4527],
  [61, 1.4121],
  [62, 1.3732],
  [63, 1.3358],
  [64, 1.3],
  [65, 1.2656],
  [66, 1.2326],
  [67, 1.2008],
  [68, 1.1704],
  [69, 1.1411],
  [70, 1.1129],
  [71, 1.0859],
  [72, 1.0598],
  [73, 1.0347],
  [74, 1.0106],
  [75, 0.9874],
  [76, 0.965],
  [77, 0.9434],
  [78, 0.9227],
  [79, 0.9026],
  [80, 0.8833],
  [81, 0.8646],
  [82, 0.8466],
  [83, 0.8292],
  [84, 0.8124],
  [85, 0.7961],
  [86, 0.7804],
  [87, 0.7652],
  [88, 0.7505],
  [89, 0.7363],
  [90, 0.7225],
  [91, 0.7091],
  [92, 0.6962],
  [93, 0.6837],
  [94, 0.6715],
  [95, 0.6597],
  [96, 0.6482],
  [97, 0.6371],
  [98, 0.6263],
  [99, 0.6158],
  [100, 0.6055],
  [101, 0.5956],
  [102, 0.5859],
  [103, 0.5765],
  [104, 0.5674],
  [105, 0.5584],
  [106, 0.5497],
  [107, 0.5413],
  [108, 0.533],
  [109, 0.5249],
  [110, 0.5171],
  [111, 0.5094],
  [112, 0.5019],
  [113, 0.4946],
  [114, 0.4874],
  [115, 0.4804],
  [116, 0.4736],
  [117, 0.4669],
  [118, 0.4603],
  [119, 0.4539],
  [120, 0.4476],
  [121, 0.4415],
  [122, 0.4355],
  [123, 0.4296],
  [124, 0.4238],
  [125, 0.4181],
  [126, 0.4126],
  [127, 0.4071],
  [128, 0.4018],
  [129, 0.3965],
  [130, 0.3914],
  [131, 0.3863],
  [132, 0.3814],
  [133, 0.3765],
  [134, 0.3717],
  [135, 0.367],
  [136, 0.3624],
  [137, 0.3578],
  [138, 0.3533],
  [139, 0.3489],
  [140, 0.3446],
  [141, 0.3403],
  [142, 0.3361],
  [143, 0.332],
  [144, 0.328],
  [145, 0.324],
  [146, 0.32],
  [147, 0.3162],
  [148, 0.3123],
  [149, 0.3086],
  [150, 0.3049],
  [151, 0.3012],
  [152, 0.2976],
  [153, 0.2941],
  [154, 0.2906],
  [155, 0.2871],
  [156, 0.2837],
  [157, 0.2804],
  [158, 0.2771],
  [159, 0.2738],
  [160, 0.2706],
  [161, 0.2674],
  [162, 0.2643],
  [163, 0.2612],
  [164, 0.2582],
  [165, 0.2552],
  [166, 0.2522],
  [167, 0.2493],
  [168, 0.2464],
  [169, 0.2436],
  [170, 0.2408],
  [171, 0.238],
  [172, 0.2352],
  [173, 0.2325],
  [174, 0.2299],
  [175, 0.2272],
  [176, 0.2246],
  [177, 0.222],
  [178, 0.2195],
  [179, 0.217],
  [180, 0.2145],
  [181, 0.2121],
  [182, 0.2097],
  [183, 0.2073],
  [184, 0.2049],
  [185, 0.2026],
  [186, 0.2003],
  [187, 0.198],
  [188, 0.1958],
  [189, 0.1935],
  [190, 0.1913],
  [191, 0.1892],
  [192, 0.187],
  [193, 0.1849],
  [194, 0.1828],
  [195, 0.1808],
  [196, 0.1787],
  [197, 0.1767],
  [198, 0.1747],
  [199, 0.1727],
  [200, 0.1708],
  [201, 0.1689],
  [202, 0.167],
  [203, 0.1651],
  [204, 0.1632],
  [205, 0.1614],
  [206, 0.1596],
  [207, 0.1578],
  [208, 0.156],
  [209, 0.1543],
  [210, 0.1525],
  [211, 0.1508],
  [212, 0.1491],
  [213, 0.1474],
  [214, 0.1458],
  [215, 0.1441],
  [216, 0.1425],
  [217, 0.1409],
  [218, 0.1393],
  [219, 0.1378],
  [220, 0.1362],
  [221, 0.1347],
  [222, 0.1332],
  [223, 0.1317],
  [224, 0.1302],
  [225, 0.1288],
  [226, 0.1273],
  [227, 0.1259],
  [228, 0.1245],
  [229, 0.1231],
  [230, 0.1217],
  [231, 0.1204],
  [232, 0.119],
  [233, 0.1177],
  [234, 0.1164],
  [235, 0.1151],
  [236, 0.1138],
  [237, 0.1125],
  [238, 0.1113],
  [239, 0.11],
  [240, 0.1088],
  [241, 0.1076],
  [242, 0.1064],
  [243, 0.1052],
  [244, 0.104],
  [245, 0.1028],
  [246, 0.1017],
  [247, 0.1005],
  [248, 0.0994],
  [249, 0.0983],
  [250, 0.0972],
  [251, 0.0961],
  [252, 0.0951],
  [253, 0.094],
  [254, 0.0929],
  [255, 0.0919],
  [256, 0.0909],
  [257, 0.0899],
  [258, 0.0889],
  [259, 0.0879],
  [260, 0.0869],
  [261, 0.0859],
  [262, 0.085],
  [263, 0.084],
  [264, 0.0831],
  [265, 0.0821],
  [266, 0.0812],
  [267, 0.0803],
  [268, 0.0794],
  [269, 0.0785],
  [270, 0.0777],
  [271, 0.0768],
  [272, 0.0759],
  [273, 0.0751],
  [274, 0.0742],
  [275, 0.0734],
  [276, 0.0726],
  [277, 0.0718],
  [278, 0.071],
  [279, 0.0702],
  [280, 0.0694],
  [281, 0.0686],
  [282, 0.0679],
  [283, 0.0671],
  [284, 0.0664],
  [285, 0.0656],
  [286, 0.0649],
  [287, 0.0642],
  [288, 0.0635],
  [289, 0.0627],
  [290, 0.062],
  [291, 0.0614],
  [292, 0.0607],
  [293, 0.06],
  [294, 0.0593],
  [295, 0.0587],
  [296, 0.058],
  [297, 0.0574],
  [298, 0.0567],
  [299, 0.0561],
  [300, 0.0555],
  [301, 0.0548],
  [302, 0.0542],
  [303, 0.0536],
  [304, 0.053],
  [305, 0.0524],
  [306, 0.0518],
  [307, 0.0513],
  [308, 0.0507],
  [309, 0.0501],
  [310, 0.0496],
  [311, 0.049],
  [312, 0.0485],
  [313, 0.0479],
  [314, 0.0474],
  [315, 0.0469],
  [316, 0.0463],
  [317, 0.0458],
  [318, 0.0453],
  [319, 0.0448],
  [320, 0.0443],
  [321, 0.0438],
  [322, 0.0433],
  [323, 0.0428],
  [324, 0.0424],
  [325, 0.0419],
  [326, 0.0414],
  [327, 0.041],
  [328, 0.0405],
  [329, 0.0401],
  [330, 0.0396],
  [331, 0.0392],
  [332, 0.0387],
  [333, 0.0383],
  [334, 0.0379],
  [335, 0.0374],
  [336, 0.037],
  [337, 0.0366],
  [338, 0.0362],
  [339, 0.0358],
  [340, 0.0354],
  [341, 0.035],
  [342, 0.0346],
  [343, 0.0342],
  [344, 0.0338],
  [345, 0.0335],
  [346, 0.0331],
  [347, 0.0327],
  [348, 0.0324],
  [349, 0.032],
  [350, 0.0316],
  [351, 0.0313],
  [352, 0.0309],
  [353, 0.0306],
  [354, 0.0303],
  [355, 0.0299],
  [356, 0.0296],
  [357, 0.0293],
  [358, 0.0289],
  [359, 0.0286],
  [360, 0.0283],
  [361, 0.028],
  [362, 0.0277],
  [363, 0.0274],
  [364, 0.027],
  [365, 0.0267],
  [366, 0.0264],
  [367, 0.0262],
  [368, 0.0259],
  [369, 0.0256],
  [370, 0.0253],
  [371, 0.025],
  [372, 0.0247],
  [373, 0.0244],
  [374, 0.0242],
  [375, 0.0239],
  [376, 0.0236],
  [377, 0.0234],
  [378, 0.0231],
  [379, 0.0229],
  [380, 0.0226],
  [381, 0.0223],
  [382, 0.0221],
  [383, 0.0219],
  [384, 0.0216],
  [385, 0.0214],
  [386, 0.0211],
  [387, 0.0209],
  [388, 0.0207],
  [389, 0.0204],
  [390, 0.0202],
  [391, 0.02],
  [392, 0.0198],
  [393, 0.0195],
  [394, 0.0193],
  [395, 0.0191],
  [396, 0.0189],
  [397, 0.0187],
  [398, 0.0185],
  [399, 0.0183],
  [400, 0.0181],
  [401, 0.0179],
  [402, 0.0177],
  [403, 0.0175],
  [404, 0.0173],
  [405, 0.0171],
  [406, 0.0169],
  [407, 0.0167],
  [408, 0.0165],
  [409, 0.0163],
  [410, 0.0161],
  [411, 0.016],
  [412, 0.0158],
  [413, 0.0156],
  [414, 0.0154],
  [415, 0.0153],
  [416, 0.0151],
  [417, 0.0149],
  [418, 0.0148],
  [419, 0.0146],
  [420, 0.0144],
  [421, 0.0143],
  [422, 0.0141],
  [423, 0.014],
  [424, 0.0138],
  [425, 0.0136],
  [426, 0.0135],
  [427, 0.0133],
  [428, 0.0132],
  [429, 0.013],
  [430, 0.0129],
  [431, 0.0128],
  [432, 0.0126],
  [433, 0.0125],
  [434, 0.0123],
  [435, 0.0122],
  [436, 0.0121],
  [437, 0.0119],
  [438, 0.0118],
  [439, 0.0117],
  [440, 0.0115],
  [441, 0.0114],
  [442, 0.0113],
  [443, 0.0111],
  [444, 0.011],
  [445, 0.0109],
  [446, 0.0108],
  [447, 0.0107],
  [448, 0.0105],
  [449, 0.0104],
  [450, 0.0103],
  [451, 0.0102],
  [452, 0.0101],
  [453, 0.01],
  [454, 0.0099],
  [455, 0.0097],
  [456, 0.0096],
  [457, 0.0095],
  [458, 0.0094],
  [459, 0.0093],
  [460, 0.0092],
  [461, 0.0091],
  [462, 0.009],
  [463, 0.0089],
  [464, 0.0088],
  [465, 0.0087],
  [466, 0.0086],
  [467, 0.0085],
  [468, 0.0084],
  [469, 0.0083],
  [470, 0.0082],
  [471, 0.0081],
  [472, 0.0081],
  [473, 0.008],
  [474, 0.0079],
  [475, 0.0078],
  [476, 0.0077],
  [477, 0.0076],
  [478, 0.0075],
  [479, 0.0074],
  [480, 0.0074],
  [481, 0.0073],
  [482, 0.0072],
  [483, 0.0071],
  [484, 0.007],
  [485, 0.007],
  [486, 0.0069],
  [487, 0.0068],
  [488, 0.0067],
  [489, 0.0067],
  [490, 0.0066],
  [491, 0.0065],
  [492, 0.0064],
  [493, 0.0064],
  [494, 0.0063],
  [495, 0.0062],
  [496, 0.0062],
  [497, 0.0061],
  [498, 0.006],
  [499, 0.0059],
  [500, 0.0059],
  [501, 0.0058],
  [502, 0.0058],
  [503, 0.0057],
  [504, 0.0056],
  [505, 0.0056],
  [506, 0.0055],
  [507, 0.0054],
  [508, 0.0054],
  [509, 0.0053],
  [510, 0.0053],
  [511, 0.0052],
  [512, 0.0051],
  [513, 0.0051],
  [514, 0.005],
  [515, 0.005],
  [516, 0.0049],
  [517, 0.0049],
  [518, 0.0048],
  [519, 0.0048],
  [520, 0.0047],
  [521, 0.0046],
  [522, 0.0046],
  [523, 0.0045],
  [524, 0.0045],
  [525, 0.0044],
  [526, 0.0044],
  [527, 0.0043],
  [528, 0.0043],
  [529, 0.0042],
  [530, 0.0042],
  [531, 0.0042],
  [532, 0.0041],
  [533, 0.0041],
  [534, 0.004],
  [535, 0.004],
  [536, 0.0039],
  [537, 0.0039],
  [538, 0.0038],
  [539, 0.0038],
  [540, 0.0038],
  [541, 0.0037],
  [542, 0.0037],
  [543, 0.0036],
  [544, 0.0036],
  [545, 0.0035],
  [546, 0.0035],
  [547, 0.0035],
  [548, 0.0034],
  [549, 0.0034],
  [550, 0.0034],
  [551, 0.0033],
  [552, 0.0033],
  [553, 0.0032],
  [554, 0.0032],
  [555, 0.0032],
  [556, 0.0031],
  [557, 0.0031],
  [558, 0.0031],
  [559, 0.003],
  [560, 0.003],
  [561, 0.003],
  [562, 0.0029],
  [563, 0.0029],
  [564, 0.0029],
  [565, 0.0028],
  [566, 0.0028],
  [567, 0.0028],
  [568, 0.0027],
  [569, 0.0027],
  [570, 0.0027],
  [571, 0.0027],
  [572, 0.0026],
  [573, 0.0026],
  [574, 0.0026],
  [575, 0.0025],
  [576, 0.0025],
  [577, 0.0025],
  [578, 0.0025],
  [579, 0.0024],
  [580, 0.0024],
  [581, 0.0024],
  [582, 0.0023],
  [583, 0.0023],
  [584, 0.0023],
  [585, 0.0023],
  [586, 0.0022],
  [587, 0.0022],
  [588, 0.0022],
  [589, 0.0022],
  [590, 0.0021],
  [591, 0.0021],
  [592, 0.0021],
  [593, 0.0021],
  [594, 0.002],
  [595, 0.002],
  [596, 0.002],
  [597, 0.002],
  [598, 0.002],
  [599, 0.0019],
  [600, 0.0019],
  [601, 0.0019],
  [602, 0.0019],
  [603, 0.0019],
  [604, 0.0018],
  [605, 0.0018],
  [606, 0.0018],
  [607, 0.0018],
  [608, 0.0018],
  [609, 0.0017],
  [610, 0.0017],
  [611, 0.0017],
  [612, 0.0017],
  [613, 0.0017],
  [614, 0.0016],
  [615, 0.0016],
  [616, 0.0016],
  [617, 0.0016],
  [618, 0.0016],
  [619, 0.0015],
  [620, 0.0015],
  [621, 0.0015],
  [622, 0.0015],
  [623, 0.0015],
  [624, 0.0015],
  [625, 0.0014],
  [626, 0.0014],
  [627, 0.0014],
  [628, 0.0014],
  [629, 0.0014],
  [630, 0.0014],
  [631, 0.0014],
  [632, 0.0013],
  [633, 0.0013],
  [634, 0.0013],
  [635, 0.0013],
  [636, 0.0013],
  [637, 0.0013],
  [638, 0.0013],
  [639, 0.0012],
  [640, 0.0012],
  [641, 0.0012],
  [642, 0.0012],
  [643, 0.0012],
  [644, 0.0012],
  [645, 0.0012],
  [646, 0.0011],
  [647, 0.0011],
  [648, 0.0011],
  [649, 0.0011],
  [650, 0.0011],
  [651, 0.0011],
  [652, 0.0011],
  [653, 0.0011],
  [654, 0.001],
  [655, 0.001],
  [656, 0.001],
  [657, 0.001],
  [658, 0.001],
  [659, 0.001],
  [660, 0.001],
  [661, 0.001],
  [662, 0.001],
  [663, 0.0009],
  [664, 0.0009],
  [665, 0.0009],
  [666, 0.0009],
  [667, 0.0009],
  [668, 0.0009],
  [669, 0.0009],
  [670, 0.0009],
  [671, 0.0009],
  [672, 0.0009],
];
drugLevel['OralRisperdal:3mg'] = [
  [0, 0.0],
  [1, 17.9632],
  [2, 20.2448],
  [3, 19.6128],
  [4, 18.7547],
  [5, 17.9147],
  [6, 17.1133],
  [7, 16.3504],
  [8, 15.6245],
  [9, 14.9336],
  [10, 14.276],
  [11, 13.6502],
  [12, 13.0545],
  [13, 12.4875],
  [14, 11.9477],
  [15, 11.4338],
  [16, 10.9446],
  [17, 10.4787],
  [18, 10.0351],
  [19, 9.6127],
  [20, 9.2104],
  [21, 8.8273],
  [22, 8.4623],
  [23, 8.1146],
  [24, 7.7834],
  [25, 7.4678],
  [26, 7.1671],
  [27, 6.8806],
  [28, 6.6075],
  [29, 6.3472],
  [30, 6.099],
  [31, 5.8625],
  [32, 5.6369],
  [33, 5.4218],
  [34, 5.2167],
  [35, 5.021],
  [36, 4.8343],
  [37, 4.6562],
  [38, 4.4863],
  [39, 4.3241],
  [40, 4.1693],
  [41, 4.0215],
  [42, 3.8804],
  [43, 3.7456],
  [44, 3.6169],
  [45, 3.4939],
  [46, 3.3764],
  [47, 3.2641],
  [48, 3.1568],
  [49, 3.0542],
  [50, 2.956],
  [51, 2.8622],
  [52, 2.7724],
  [53, 2.6865],
  [54, 2.6043],
  [55, 2.5256],
  [56, 2.4502],
  [57, 2.378],
  [58, 2.3089],
  [59, 2.2426],
  [60, 2.1791],
  [61, 2.1182],
  [62, 2.0598],
  [63, 2.0038],
  [64, 1.95],
  [65, 1.8984],
  [66, 1.8489],
  [67, 1.8013],
  [68, 1.7556],
  [69, 1.7117],
  [70, 1.6694],
  [71, 1.6288],
  [72, 1.5897],
  [73, 1.5522],
  [74, 1.516],
  [75, 1.4811],
  [76, 1.4475],
  [77, 1.4152],
  [78, 1.384],
  [79, 1.3539],
  [80, 1.3249],
  [81, 1.2969],
  [82, 1.2699],
  [83, 1.2438],
  [84, 1.2186],
  [85, 1.1942],
  [86, 1.1706],
  [87, 1.1479],
  [88, 1.1258],
  [89, 1.1044],
  [90, 1.0838],
  [91, 1.0637],
  [92, 1.0443],
  [93, 1.0255],
  [94, 1.0072],
  [95, 0.9895],
  [96, 0.9723],
  [97, 0.9556],
  [98, 0.9394],
  [99, 0.9236],
  [100, 0.9083],
  [101, 0.8934],
  [102, 0.8789],
  [103, 0.8648],
  [104, 0.851],
  [105, 0.8377],
  [106, 0.8246],
  [107, 0.8119],
  [108, 0.7995],
  [109, 0.7874],
  [110, 0.7756],
  [111, 0.7641],
  [112, 0.7528],
  [113, 0.7418],
  [114, 0.7311],
  [115, 0.7206],
  [116, 0.7103],
  [117, 0.7003],
  [118, 0.6905],
  [119, 0.6809],
  [120, 0.6715],
  [121, 0.6622],
  [122, 0.6532],
  [123, 0.6444],
  [124, 0.6357],
  [125, 0.6272],
  [126, 0.6189],
  [127, 0.6107],
  [128, 0.6027],
  [129, 0.5948],
  [130, 0.5871],
  [131, 0.5795],
  [132, 0.5721],
  [133, 0.5647],
  [134, 0.5575],
  [135, 0.5505],
  [136, 0.5435],
  [137, 0.5367],
  [138, 0.53],
  [139, 0.5234],
  [140, 0.5169],
  [141, 0.5105],
  [142, 0.5042],
  [143, 0.498],
  [144, 0.4919],
  [145, 0.4859],
  [146, 0.48],
  [147, 0.4742],
  [148, 0.4685],
  [149, 0.4629],
  [150, 0.4573],
  [151, 0.4518],
  [152, 0.4464],
  [153, 0.4411],
  [154, 0.4359],
  [155, 0.4307],
  [156, 0.4256],
  [157, 0.4206],
  [158, 0.4156],
  [159, 0.4107],
  [160, 0.4059],
  [161, 0.4012],
  [162, 0.3965],
  [163, 0.3919],
  [164, 0.3873],
  [165, 0.3828],
  [166, 0.3783],
  [167, 0.374],
  [168, 0.3696],
  [169, 0.3654],
  [170, 0.3611],
  [171, 0.357],
  [172, 0.3529],
  [173, 0.3488],
  [174, 0.3448],
  [175, 0.3408],
  [176, 0.3369],
  [177, 0.3331],
  [178, 0.3293],
  [179, 0.3255],
  [180, 0.3218],
  [181, 0.3181],
  [182, 0.3145],
  [183, 0.3109],
  [184, 0.3074],
  [185, 0.3039],
  [186, 0.3004],
  [187, 0.297],
  [188, 0.2936],
  [189, 0.2903],
  [190, 0.287],
  [191, 0.2838],
  [192, 0.2806],
  [193, 0.2774],
  [194, 0.2742],
  [195, 0.2711],
  [196, 0.2681],
  [197, 0.2651],
  [198, 0.2621],
  [199, 0.2591],
  [200, 0.2562],
  [201, 0.2533],
  [202, 0.2504],
  [203, 0.2476],
  [204, 0.2448],
  [205, 0.2421],
  [206, 0.2394],
  [207, 0.2367],
  [208, 0.234],
  [209, 0.2314],
  [210, 0.2288],
  [211, 0.2262],
  [212, 0.2237],
  [213, 0.2212],
  [214, 0.2187],
  [215, 0.2162],
  [216, 0.2138],
  [217, 0.2114],
  [218, 0.209],
  [219, 0.2067],
  [220, 0.2044],
  [221, 0.2021],
  [222, 0.1998],
  [223, 0.1976],
  [224, 0.1954],
  [225, 0.1932],
  [226, 0.191],
  [227, 0.1889],
  [228, 0.1868],
  [229, 0.1847],
  [230, 0.1826],
  [231, 0.1806],
  [232, 0.1785],
  [233, 0.1765],
  [234, 0.1746],
  [235, 0.1726],
  [236, 0.1707],
  [237, 0.1688],
  [238, 0.1669],
  [239, 0.165],
  [240, 0.1632],
  [241, 0.1613],
  [242, 0.1595],
  [243, 0.1578],
  [244, 0.156],
  [245, 0.1542],
  [246, 0.1525],
  [247, 0.1508],
  [248, 0.1491],
  [249, 0.1475],
  [250, 0.1458],
  [251, 0.1442],
  [252, 0.1426],
  [253, 0.141],
  [254, 0.1394],
  [255, 0.1379],
  [256, 0.1363],
  [257, 0.1348],
  [258, 0.1333],
  [259, 0.1318],
  [260, 0.1303],
  [261, 0.1289],
  [262, 0.1274],
  [263, 0.126],
  [264, 0.1246],
  [265, 0.1232],
  [266, 0.1218],
  [267, 0.1205],
  [268, 0.1191],
  [269, 0.1178],
  [270, 0.1165],
  [271, 0.1152],
  [272, 0.1139],
  [273, 0.1126],
  [274, 0.1114],
  [275, 0.1101],
  [276, 0.1089],
  [277, 0.1077],
  [278, 0.1065],
  [279, 0.1053],
  [280, 0.1041],
  [281, 0.103],
  [282, 0.1018],
  [283, 0.1007],
  [284, 0.0995],
  [285, 0.0984],
  [286, 0.0973],
  [287, 0.0963],
  [288, 0.0952],
  [289, 0.0941],
  [290, 0.0931],
  [291, 0.092],
  [292, 0.091],
  [293, 0.09],
  [294, 0.089],
  [295, 0.088],
  [296, 0.087],
  [297, 0.086],
  [298, 0.0851],
  [299, 0.0841],
  [300, 0.0832],
  [301, 0.0823],
  [302, 0.0813],
  [303, 0.0804],
  [304, 0.0795],
  [305, 0.0786],
  [306, 0.0778],
  [307, 0.0769],
  [308, 0.076],
  [309, 0.0752],
  [310, 0.0744],
  [311, 0.0735],
  [312, 0.0727],
  [313, 0.0719],
  [314, 0.0711],
  [315, 0.0703],
  [316, 0.0695],
  [317, 0.0687],
  [318, 0.068],
  [319, 0.0672],
  [320, 0.0665],
  [321, 0.0657],
  [322, 0.065],
  [323, 0.0643],
  [324, 0.0635],
  [325, 0.0628],
  [326, 0.0621],
  [327, 0.0614],
  [328, 0.0608],
  [329, 0.0601],
  [330, 0.0594],
  [331, 0.0587],
  [332, 0.0581],
  [333, 0.0574],
  [334, 0.0568],
  [335, 0.0562],
  [336, 0.0555],
  [337, 0.0549],
  [338, 0.0543],
  [339, 0.0537],
  [340, 0.0531],
  [341, 0.0525],
  [342, 0.0519],
  [343, 0.0513],
  [344, 0.0508],
  [345, 0.0502],
  [346, 0.0496],
  [347, 0.0491],
  [348, 0.0485],
  [349, 0.048],
  [350, 0.0475],
  [351, 0.0469],
  [352, 0.0464],
  [353, 0.0459],
  [354, 0.0454],
  [355, 0.0449],
  [356, 0.0444],
  [357, 0.0439],
  [358, 0.0434],
  [359, 0.0429],
  [360, 0.0424],
  [361, 0.042],
  [362, 0.0415],
  [363, 0.041],
  [364, 0.0406],
  [365, 0.0401],
  [366, 0.0397],
  [367, 0.0392],
  [368, 0.0388],
  [369, 0.0384],
  [370, 0.0379],
  [371, 0.0375],
  [372, 0.0371],
  [373, 0.0367],
  [374, 0.0363],
  [375, 0.0359],
  [376, 0.0355],
  [377, 0.0351],
  [378, 0.0347],
  [379, 0.0343],
  [380, 0.0339],
  [381, 0.0335],
  [382, 0.0332],
  [383, 0.0328],
  [384, 0.0324],
  [385, 0.0321],
  [386, 0.0317],
  [387, 0.0313],
  [388, 0.031],
  [389, 0.0306],
  [390, 0.0303],
  [391, 0.03],
  [392, 0.0296],
  [393, 0.0293],
  [394, 0.029],
  [395, 0.0287],
  [396, 0.0283],
  [397, 0.028],
  [398, 0.0277],
  [399, 0.0274],
  [400, 0.0271],
  [401, 0.0268],
  [402, 0.0265],
  [403, 0.0262],
  [404, 0.0259],
  [405, 0.0256],
  [406, 0.0253],
  [407, 0.025],
  [408, 0.0248],
  [409, 0.0245],
  [410, 0.0242],
  [411, 0.0239],
  [412, 0.0237],
  [413, 0.0234],
  [414, 0.0232],
  [415, 0.0229],
  [416, 0.0226],
  [417, 0.0224],
  [418, 0.0221],
  [419, 0.0219],
  [420, 0.0216],
  [421, 0.0214],
  [422, 0.0212],
  [423, 0.0209],
  [424, 0.0207],
  [425, 0.0205],
  [426, 0.0202],
  [427, 0.02],
  [428, 0.0198],
  [429, 0.0196],
  [430, 0.0193],
  [431, 0.0191],
  [432, 0.0189],
  [433, 0.0187],
  [434, 0.0185],
  [435, 0.0183],
  [436, 0.0181],
  [437, 0.0179],
  [438, 0.0177],
  [439, 0.0175],
  [440, 0.0173],
  [441, 0.0171],
  [442, 0.0169],
  [443, 0.0167],
  [444, 0.0165],
  [445, 0.0164],
  [446, 0.0162],
  [447, 0.016],
  [448, 0.0158],
  [449, 0.0156],
  [450, 0.0155],
  [451, 0.0153],
  [452, 0.0151],
  [453, 0.0149],
  [454, 0.0148],
  [455, 0.0146],
  [456, 0.0145],
  [457, 0.0143],
  [458, 0.0141],
  [459, 0.014],
  [460, 0.0138],
  [461, 0.0137],
  [462, 0.0135],
  [463, 0.0134],
  [464, 0.0132],
  [465, 0.0131],
  [466, 0.0129],
  [467, 0.0128],
  [468, 0.0126],
  [469, 0.0125],
  [470, 0.0124],
  [471, 0.0122],
  [472, 0.0121],
  [473, 0.0119],
  [474, 0.0118],
  [475, 0.0117],
  [476, 0.0115],
  [477, 0.0114],
  [478, 0.0113],
  [479, 0.0112],
  [480, 0.011],
  [481, 0.0109],
  [482, 0.0108],
  [483, 0.0107],
  [484, 0.0106],
  [485, 0.0104],
  [486, 0.0103],
  [487, 0.0102],
  [488, 0.0101],
  [489, 0.01],
  [490, 0.0099],
  [491, 0.0098],
  [492, 0.0097],
  [493, 0.0095],
  [494, 0.0094],
  [495, 0.0093],
  [496, 0.0092],
  [497, 0.0091],
  [498, 0.009],
  [499, 0.0089],
  [500, 0.0088],
  [501, 0.0087],
  [502, 0.0086],
  [503, 0.0085],
  [504, 0.0084],
  [505, 0.0083],
  [506, 0.0082],
  [507, 0.0082],
  [508, 0.0081],
  [509, 0.008],
  [510, 0.0079],
  [511, 0.0078],
  [512, 0.0077],
  [513, 0.0076],
  [514, 0.0075],
  [515, 0.0075],
  [516, 0.0074],
  [517, 0.0073],
  [518, 0.0072],
  [519, 0.0071],
  [520, 0.007],
  [521, 0.007],
  [522, 0.0069],
  [523, 0.0068],
  [524, 0.0067],
  [525, 0.0067],
  [526, 0.0066],
  [527, 0.0065],
  [528, 0.0064],
  [529, 0.0064],
  [530, 0.0063],
  [531, 0.0062],
  [532, 0.0062],
  [533, 0.0061],
  [534, 0.006],
  [535, 0.006],
  [536, 0.0059],
  [537, 0.0058],
  [538, 0.0058],
  [539, 0.0057],
  [540, 0.0056],
  [541, 0.0056],
  [542, 0.0055],
  [543, 0.0054],
  [544, 0.0054],
  [545, 0.0053],
  [546, 0.0053],
  [547, 0.0052],
  [548, 0.0051],
  [549, 0.0051],
  [550, 0.005],
  [551, 0.005],
  [552, 0.0049],
  [553, 0.0049],
  [554, 0.0048],
  [555, 0.0048],
  [556, 0.0047],
  [557, 0.0047],
  [558, 0.0046],
  [559, 0.0046],
  [560, 0.0045],
  [561, 0.0044],
  [562, 0.0044],
  [563, 0.0044],
  [564, 0.0043],
  [565, 0.0043],
  [566, 0.0042],
  [567, 0.0042],
  [568, 0.0041],
  [569, 0.0041],
  [570, 0.004],
  [571, 0.004],
  [572, 0.0039],
  [573, 0.0039],
  [574, 0.0038],
  [575, 0.0038],
  [576, 0.0038],
  [577, 0.0037],
  [578, 0.0037],
  [579, 0.0036],
  [580, 0.0036],
  [581, 0.0036],
  [582, 0.0035],
  [583, 0.0035],
  [584, 0.0034],
  [585, 0.0034],
  [586, 0.0034],
  [587, 0.0033],
  [588, 0.0033],
  [589, 0.0033],
  [590, 0.0032],
  [591, 0.0032],
  [592, 0.0031],
  [593, 0.0031],
  [594, 0.0031],
  [595, 0.003],
  [596, 0.003],
  [597, 0.003],
  [598, 0.0029],
  [599, 0.0029],
  [600, 0.0029],
  [601, 0.0028],
  [602, 0.0028],
  [603, 0.0028],
  [604, 0.0027],
  [605, 0.0027],
  [606, 0.0027],
  [607, 0.0027],
  [608, 0.0026],
  [609, 0.0026],
  [610, 0.0026],
  [611, 0.0025],
  [612, 0.0025],
  [613, 0.0025],
  [614, 0.0025],
  [615, 0.0024],
  [616, 0.0024],
  [617, 0.0024],
  [618, 0.0023],
  [619, 0.0023],
  [620, 0.0023],
  [621, 0.0023],
  [622, 0.0022],
  [623, 0.0022],
  [624, 0.0022],
  [625, 0.0022],
  [626, 0.0021],
  [627, 0.0021],
  [628, 0.0021],
  [629, 0.0021],
  [630, 0.0021],
  [631, 0.002],
  [632, 0.002],
  [633, 0.002],
  [634, 0.002],
  [635, 0.0019],
  [636, 0.0019],
  [637, 0.0019],
  [638, 0.0019],
  [639, 0.0019],
  [640, 0.0018],
  [641, 0.0018],
  [642, 0.0018],
  [643, 0.0018],
  [644, 0.0018],
  [645, 0.0017],
  [646, 0.0017],
  [647, 0.0017],
  [648, 0.0017],
  [649, 0.0017],
  [650, 0.0016],
  [651, 0.0016],
  [652, 0.0016],
  [653, 0.0016],
  [654, 0.0016],
  [655, 0.0015],
  [656, 0.0015],
  [657, 0.0015],
  [658, 0.0015],
  [659, 0.0015],
  [660, 0.0015],
  [661, 0.0014],
  [662, 0.0014],
  [663, 0.0014],
  [664, 0.0014],
  [665, 0.0014],
  [666, 0.0014],
  [667, 0.0014],
  [668, 0.0013],
  [669, 0.0013],
  [670, 0.0013],
  [671, 0.0013],
  [672, 0.0013],
];
drugLevel['OralRisperdal:4mg'] = [
  [0, 0.0],
  [1, 23.9509],
  [2, 26.993],
  [3, 26.1504],
  [4, 25.0063],
  [5, 23.8863],
  [6, 22.8177],
  [7, 21.8006],
  [8, 20.8326],
  [9, 19.9114],
  [10, 19.0347],
  [11, 18.2003],
  [12, 17.406],
  [13, 16.65],
  [14, 15.9303],
  [15, 15.2451],
  [16, 14.5927],
  [17, 13.9716],
  [18, 13.3802],
  [19, 12.817],
  [20, 12.2806],
  [21, 11.7697],
  [22, 11.2831],
  [23, 10.8195],
  [24, 10.3779],
  [25, 9.9571],
  [26, 9.5562],
  [27, 9.1741],
  [28, 8.81],
  [29, 8.4629],
  [30, 8.1321],
  [31, 7.8167],
  [32, 7.5159],
  [33, 7.2291],
  [34, 6.9556],
  [35, 6.6947],
  [36, 6.4458],
  [37, 6.2084],
  [38, 5.9818],
  [39, 5.7655],
  [40, 5.5591],
  [41, 5.362],
  [42, 5.1739],
  [43, 4.9942],
  [44, 4.8225],
  [45, 4.6586],
  [46, 4.5019],
  [47, 4.3522],
  [48, 4.2091],
  [49, 4.0723],
  [50, 3.9414],
  [51, 3.8163],
  [52, 3.6966],
  [53, 3.582],
  [54, 3.4724],
  [55, 3.3675],
  [56, 3.267],
  [57, 3.1707],
  [58, 3.0785],
  [59, 2.9902],
  [60, 2.9055],
  [61, 2.8243],
  [62, 2.7464],
  [63, 2.6717],
  [64, 2.6],
  [65, 2.5312],
  [66, 2.4652],
  [67, 2.4018],
  [68, 2.3408],
  [69, 2.2822],
  [70, 2.2259],
  [71, 2.1718],
  [72, 2.1197],
  [73, 2.0696],
  [74, 2.0213],
  [75, 1.9748],
  [76, 1.9301],
  [77, 1.8869],
  [78, 1.8454],
  [79, 1.8053],
  [80, 1.7666],
  [81, 1.7293],
  [82, 1.6932],
  [83, 1.6584],
  [84, 1.6248],
  [85, 1.5923],
  [86, 1.5609],
  [87, 1.5305],
  [88, 1.5011],
  [89, 1.4726],
  [90, 1.445],
  [91, 1.4183],
  [92, 1.3924],
  [93, 1.3673],
  [94, 1.343],
  [95, 1.3194],
  [96, 1.2964],
  [97, 1.2742],
  [98, 1.2526],
  [99, 1.2315],
  [100, 1.2111],
  [101, 1.1912],
  [102, 1.1719],
  [103, 1.1531],
  [104, 1.1347],
  [105, 1.1169],
  [106, 1.0995],
  [107, 1.0825],
  [108, 1.066],
  [109, 1.0499],
  [110, 1.0341],
  [111, 1.0188],
  [112, 1.0038],
  [113, 0.9891],
  [114, 0.9748],
  [115, 0.9608],
  [116, 0.9471],
  [117, 0.9337],
  [118, 0.9207],
  [119, 0.9078],
  [120, 0.8953],
  [121, 0.883],
  [122, 0.871],
  [123, 0.8592],
  [124, 0.8476],
  [125, 0.8363],
  [126, 0.8252],
  [127, 0.8143],
  [128, 0.8036],
  [129, 0.7931],
  [130, 0.7828],
  [131, 0.7727],
  [132, 0.7627],
  [133, 0.753],
  [134, 0.7434],
  [135, 0.734],
  [136, 0.7247],
  [137, 0.7156],
  [138, 0.7067],
  [139, 0.6979],
  [140, 0.6892],
  [141, 0.6807],
  [142, 0.6723],
  [143, 0.6641],
  [144, 0.6559],
  [145, 0.6479],
  [146, 0.6401],
  [147, 0.6323],
  [148, 0.6247],
  [149, 0.6172],
  [150, 0.6097],
  [151, 0.6024],
  [152, 0.5952],
  [153, 0.5882],
  [154, 0.5812],
  [155, 0.5743],
  [156, 0.5675],
  [157, 0.5608],
  [158, 0.5542],
  [159, 0.5477],
  [160, 0.5412],
  [161, 0.5349],
  [162, 0.5286],
  [163, 0.5225],
  [164, 0.5164],
  [165, 0.5104],
  [166, 0.5045],
  [167, 0.4986],
  [168, 0.4928],
  [169, 0.4871],
  [170, 0.4815],
  [171, 0.476],
  [172, 0.4705],
  [173, 0.4651],
  [174, 0.4597],
  [175, 0.4545],
  [176, 0.4492],
  [177, 0.4441],
  [178, 0.439],
  [179, 0.434],
  [180, 0.429],
  [181, 0.4242],
  [182, 0.4193],
  [183, 0.4145],
  [184, 0.4098],
  [185, 0.4052],
  [186, 0.4006],
  [187, 0.396],
  [188, 0.3915],
  [189, 0.3871],
  [190, 0.3827],
  [191, 0.3784],
  [192, 0.3741],
  [193, 0.3698],
  [194, 0.3657],
  [195, 0.3615],
  [196, 0.3574],
  [197, 0.3534],
  [198, 0.3494],
  [199, 0.3455],
  [200, 0.3416],
  [201, 0.3377],
  [202, 0.3339],
  [203, 0.3302],
  [204, 0.3264],
  [205, 0.3228],
  [206, 0.3191],
  [207, 0.3156],
  [208, 0.312],
  [209, 0.3085],
  [210, 0.305],
  [211, 0.3016],
  [212, 0.2982],
  [213, 0.2949],
  [214, 0.2916],
  [215, 0.2883],
  [216, 0.2851],
  [217, 0.2819],
  [218, 0.2787],
  [219, 0.2756],
  [220, 0.2725],
  [221, 0.2694],
  [222, 0.2664],
  [223, 0.2634],
  [224, 0.2605],
  [225, 0.2576],
  [226, 0.2547],
  [227, 0.2518],
  [228, 0.249],
  [229, 0.2462],
  [230, 0.2435],
  [231, 0.2407],
  [232, 0.238],
  [233, 0.2354],
  [234, 0.2327],
  [235, 0.2301],
  [236, 0.2276],
  [237, 0.225],
  [238, 0.2225],
  [239, 0.22],
  [240, 0.2176],
  [241, 0.2151],
  [242, 0.2127],
  [243, 0.2103],
  [244, 0.208],
  [245, 0.2057],
  [246, 0.2034],
  [247, 0.2011],
  [248, 0.1988],
  [249, 0.1966],
  [250, 0.1944],
  [251, 0.1923],
  [252, 0.1901],
  [253, 0.188],
  [254, 0.1859],
  [255, 0.1838],
  [256, 0.1818],
  [257, 0.1797],
  [258, 0.1777],
  [259, 0.1757],
  [260, 0.1738],
  [261, 0.1718],
  [262, 0.1699],
  [263, 0.168],
  [264, 0.1661],
  [265, 0.1643],
  [266, 0.1625],
  [267, 0.1606],
  [268, 0.1588],
  [269, 0.1571],
  [270, 0.1553],
  [271, 0.1536],
  [272, 0.1519],
  [273, 0.1502],
  [274, 0.1485],
  [275, 0.1468],
  [276, 0.1452],
  [277, 0.1436],
  [278, 0.142],
  [279, 0.1404],
  [280, 0.1388],
  [281, 0.1373],
  [282, 0.1357],
  [283, 0.1342],
  [284, 0.1327],
  [285, 0.1312],
  [286, 0.1298],
  [287, 0.1283],
  [288, 0.1269],
  [289, 0.1255],
  [290, 0.1241],
  [291, 0.1227],
  [292, 0.1213],
  [293, 0.12],
  [294, 0.1186],
  [295, 0.1173],
  [296, 0.116],
  [297, 0.1147],
  [298, 0.1134],
  [299, 0.1122],
  [300, 0.1109],
  [301, 0.1097],
  [302, 0.1085],
  [303, 0.1072],
  [304, 0.106],
  [305, 0.1049],
  [306, 0.1037],
  [307, 0.1025],
  [308, 0.1014],
  [309, 0.1003],
  [310, 0.0991],
  [311, 0.098],
  [312, 0.0969],
  [313, 0.0959],
  [314, 0.0948],
  [315, 0.0937],
  [316, 0.0927],
  [317, 0.0917],
  [318, 0.0906],
  [319, 0.0896],
  [320, 0.0886],
  [321, 0.0876],
  [322, 0.0867],
  [323, 0.0857],
  [324, 0.0847],
  [325, 0.0838],
  [326, 0.0828],
  [327, 0.0819],
  [328, 0.081],
  [329, 0.0801],
  [330, 0.0792],
  [331, 0.0783],
  [332, 0.0775],
  [333, 0.0766],
  [334, 0.0757],
  [335, 0.0749],
  [336, 0.0741],
  [337, 0.0732],
  [338, 0.0724],
  [339, 0.0716],
  [340, 0.0708],
  [341, 0.07],
  [342, 0.0692],
  [343, 0.0685],
  [344, 0.0677],
  [345, 0.0669],
  [346, 0.0662],
  [347, 0.0655],
  [348, 0.0647],
  [349, 0.064],
  [350, 0.0633],
  [351, 0.0626],
  [352, 0.0619],
  [353, 0.0612],
  [354, 0.0605],
  [355, 0.0598],
  [356, 0.0592],
  [357, 0.0585],
  [358, 0.0579],
  [359, 0.0572],
  [360, 0.0566],
  [361, 0.0559],
  [362, 0.0553],
  [363, 0.0547],
  [364, 0.0541],
  [365, 0.0535],
  [366, 0.0529],
  [367, 0.0523],
  [368, 0.0517],
  [369, 0.0511],
  [370, 0.0506],
  [371, 0.05],
  [372, 0.0494],
  [373, 0.0489],
  [374, 0.0484],
  [375, 0.0478],
  [376, 0.0473],
  [377, 0.0468],
  [378, 0.0462],
  [379, 0.0457],
  [380, 0.0452],
  [381, 0.0447],
  [382, 0.0442],
  [383, 0.0437],
  [384, 0.0432],
  [385, 0.0427],
  [386, 0.0423],
  [387, 0.0418],
  [388, 0.0413],
  [389, 0.0409],
  [390, 0.0404],
  [391, 0.04],
  [392, 0.0395],
  [393, 0.0391],
  [394, 0.0386],
  [395, 0.0382],
  [396, 0.0378],
  [397, 0.0374],
  [398, 0.0369],
  [399, 0.0365],
  [400, 0.0361],
  [401, 0.0357],
  [402, 0.0353],
  [403, 0.0349],
  [404, 0.0345],
  [405, 0.0341],
  [406, 0.0338],
  [407, 0.0334],
  [408, 0.033],
  [409, 0.0326],
  [410, 0.0323],
  [411, 0.0319],
  [412, 0.0316],
  [413, 0.0312],
  [414, 0.0309],
  [415, 0.0305],
  [416, 0.0302],
  [417, 0.0298],
  [418, 0.0295],
  [419, 0.0292],
  [420, 0.0289],
  [421, 0.0285],
  [422, 0.0282],
  [423, 0.0279],
  [424, 0.0276],
  [425, 0.0273],
  [426, 0.027],
  [427, 0.0267],
  [428, 0.0264],
  [429, 0.0261],
  [430, 0.0258],
  [431, 0.0255],
  [432, 0.0252],
  [433, 0.0249],
  [434, 0.0247],
  [435, 0.0244],
  [436, 0.0241],
  [437, 0.0238],
  [438, 0.0236],
  [439, 0.0233],
  [440, 0.0231],
  [441, 0.0228],
  [442, 0.0225],
  [443, 0.0223],
  [444, 0.022],
  [445, 0.0218],
  [446, 0.0216],
  [447, 0.0213],
  [448, 0.0211],
  [449, 0.0208],
  [450, 0.0206],
  [451, 0.0204],
  [452, 0.0202],
  [453, 0.0199],
  [454, 0.0197],
  [455, 0.0195],
  [456, 0.0193],
  [457, 0.0191],
  [458, 0.0188],
  [459, 0.0186],
  [460, 0.0184],
  [461, 0.0182],
  [462, 0.018],
  [463, 0.0178],
  [464, 0.0176],
  [465, 0.0174],
  [466, 0.0172],
  [467, 0.017],
  [468, 0.0168],
  [469, 0.0167],
  [470, 0.0165],
  [471, 0.0163],
  [472, 0.0161],
  [473, 0.0159],
  [474, 0.0157],
  [475, 0.0156],
  [476, 0.0154],
  [477, 0.0152],
  [478, 0.0151],
  [479, 0.0149],
  [480, 0.0147],
  [481, 0.0146],
  [482, 0.0144],
  [483, 0.0142],
  [484, 0.0141],
  [485, 0.0139],
  [486, 0.0138],
  [487, 0.0136],
  [488, 0.0135],
  [489, 0.0133],
  [490, 0.0132],
  [491, 0.013],
  [492, 0.0129],
  [493, 0.0127],
  [494, 0.0126],
  [495, 0.0124],
  [496, 0.0123],
  [497, 0.0122],
  [498, 0.012],
  [499, 0.0119],
  [500, 0.0118],
  [501, 0.0116],
  [502, 0.0115],
  [503, 0.0114],
  [504, 0.0112],
  [505, 0.0111],
  [506, 0.011],
  [507, 0.0109],
  [508, 0.0108],
  [509, 0.0106],
  [510, 0.0105],
  [511, 0.0104],
  [512, 0.0103],
  [513, 0.0102],
  [514, 0.0101],
  [515, 0.0099],
  [516, 0.0098],
  [517, 0.0097],
  [518, 0.0096],
  [519, 0.0095],
  [520, 0.0094],
  [521, 0.0093],
  [522, 0.0092],
  [523, 0.0091],
  [524, 0.009],
  [525, 0.0089],
  [526, 0.0088],
  [527, 0.0087],
  [528, 0.0086],
  [529, 0.0085],
  [530, 0.0084],
  [531, 0.0083],
  [532, 0.0082],
  [533, 0.0081],
  [534, 0.008],
  [535, 0.0079],
  [536, 0.0079],
  [537, 0.0078],
  [538, 0.0077],
  [539, 0.0076],
  [540, 0.0075],
  [541, 0.0074],
  [542, 0.0073],
  [543, 0.0073],
  [544, 0.0072],
  [545, 0.0071],
  [546, 0.007],
  [547, 0.0069],
  [548, 0.0069],
  [549, 0.0068],
  [550, 0.0067],
  [551, 0.0066],
  [552, 0.0066],
  [553, 0.0065],
  [554, 0.0064],
  [555, 0.0063],
  [556, 0.0063],
  [557, 0.0062],
  [558, 0.0061],
  [559, 0.0061],
  [560, 0.006],
  [561, 0.0059],
  [562, 0.0059],
  [563, 0.0058],
  [564, 0.0057],
  [565, 0.0057],
  [566, 0.0056],
  [567, 0.0055],
  [568, 0.0055],
  [569, 0.0054],
  [570, 0.0054],
  [571, 0.0053],
  [572, 0.0052],
  [573, 0.0052],
  [574, 0.0051],
  [575, 0.0051],
  [576, 0.005],
  [577, 0.005],
  [578, 0.0049],
  [579, 0.0048],
  [580, 0.0048],
  [581, 0.0047],
  [582, 0.0047],
  [583, 0.0046],
  [584, 0.0046],
  [585, 0.0045],
  [586, 0.0045],
  [587, 0.0044],
  [588, 0.0044],
  [589, 0.0043],
  [590, 0.0043],
  [591, 0.0042],
  [592, 0.0042],
  [593, 0.0041],
  [594, 0.0041],
  [595, 0.0041],
  [596, 0.004],
  [597, 0.004],
  [598, 0.0039],
  [599, 0.0039],
  [600, 0.0038],
  [601, 0.0038],
  [602, 0.0037],
  [603, 0.0037],
  [604, 0.0037],
  [605, 0.0036],
  [606, 0.0036],
  [607, 0.0035],
  [608, 0.0035],
  [609, 0.0035],
  [610, 0.0034],
  [611, 0.0034],
  [612, 0.0033],
  [613, 0.0033],
  [614, 0.0033],
  [615, 0.0032],
  [616, 0.0032],
  [617, 0.0032],
  [618, 0.0031],
  [619, 0.0031],
  [620, 0.0031],
  [621, 0.003],
  [622, 0.003],
  [623, 0.003],
  [624, 0.0029],
  [625, 0.0029],
  [626, 0.0029],
  [627, 0.0028],
  [628, 0.0028],
  [629, 0.0028],
  [630, 0.0027],
  [631, 0.0027],
  [632, 0.0027],
  [633, 0.0026],
  [634, 0.0026],
  [635, 0.0026],
  [636, 0.0026],
  [637, 0.0025],
  [638, 0.0025],
  [639, 0.0025],
  [640, 0.0024],
  [641, 0.0024],
  [642, 0.0024],
  [643, 0.0024],
  [644, 0.0023],
  [645, 0.0023],
  [646, 0.0023],
  [647, 0.0023],
  [648, 0.0022],
  [649, 0.0022],
  [650, 0.0022],
  [651, 0.0022],
  [652, 0.0021],
  [653, 0.0021],
  [654, 0.0021],
  [655, 0.0021],
  [656, 0.002],
  [657, 0.002],
  [658, 0.002],
  [659, 0.002],
  [660, 0.002],
  [661, 0.0019],
  [662, 0.0019],
  [663, 0.0019],
  [664, 0.0019],
  [665, 0.0018],
  [666, 0.0018],
  [667, 0.0018],
  [668, 0.0018],
  [669, 0.0018],
  [670, 0.0017],
  [671, 0.0017],
  [672, 0.0017],
];
drugLevel['OralRisperdal:6mg'] = [
  [0, 0.0],
  [1, 35.9264],
  [2, 40.4895],
  [3, 39.2256],
  [4, 37.5094],
  [5, 35.8295],
  [6, 34.2266],
  [7, 32.7009],
  [8, 31.2489],
  [9, 29.8672],
  [10, 28.5521],
  [11, 27.3004],
  [12, 26.1091],
  [13, 24.975],
  [14, 23.8954],
  [15, 22.8677],
  [16, 21.8891],
  [17, 20.9575],
  [18, 20.0703],
  [19, 19.2255],
  [20, 18.4209],
  [21, 17.6546],
  [22, 16.9246],
  [23, 16.2293],
  [24, 15.5669],
  [25, 14.9357],
  [26, 14.3343],
  [27, 13.7612],
  [28, 13.215],
  [29, 12.6944],
  [30, 12.1982],
  [31, 11.725],
  [32, 11.2739],
  [33, 10.8437],
  [34, 10.4334],
  [35, 10.0421],
  [36, 9.6688],
  [37, 9.3126],
  [38, 8.9727],
  [39, 8.6483],
  [40, 8.3387],
  [41, 8.0431],
  [42, 7.7608],
  [43, 7.4913],
  [44, 7.2338],
  [45, 6.9879],
  [46, 6.7529],
  [47, 6.5283],
  [48, 6.3136],
  [49, 6.1084],
  [50, 5.9122],
  [51, 5.7245],
  [52, 5.5449],
  [53, 5.3731],
  [54, 5.2086],
  [55, 5.0512],
  [56, 4.9005],
  [57, 4.7561],
  [58, 4.6178],
  [59, 4.4853],
  [60, 4.3583],
  [61, 4.2365],
  [62, 4.1197],
  [63, 4.0076],
  [64, 3.9001],
  [65, 3.7969],
  [66, 3.6978],
  [67, 3.6027],
  [68, 3.5112],
  [69, 3.4234],
  [70, 3.3389],
  [71, 3.2577],
  [72, 3.1796],
  [73, 3.1044],
  [74, 3.032],
  [75, 2.9623],
  [76, 2.8952],
  [77, 2.8305],
  [78, 2.7681],
  [79, 2.7079],
  [80, 2.6499],
  [81, 2.5939],
  [82, 2.5399],
  [83, 2.4877],
  [84, 2.4372],
  [85, 2.3885],
  [86, 2.3413],
  [87, 2.2958],
  [88, 2.2516],
  [89, 2.2089],
  [90, 2.1676],
  [91, 2.1275],
  [92, 2.0887],
  [93, 2.051],
  [94, 2.0145],
  [95, 1.9791],
  [96, 1.9447],
  [97, 1.9113],
  [98, 1.8789],
  [99, 1.8473],
  [100, 1.8167],
  [101, 1.7869],
  [102, 1.7578],
  [103, 1.7296],
  [104, 1.7021],
  [105, 1.6754],
  [106, 1.6493],
  [107, 1.6238],
  [108, 1.599],
  [109, 1.5748],
  [110, 1.5512],
  [111, 1.5282],
  [112, 1.5057],
  [113, 1.4837],
  [114, 1.4622],
  [115, 1.4412],
  [116, 1.4207],
  [117, 1.4006],
  [118, 1.381],
  [119, 1.3618],
  [120, 1.343],
  [121, 1.3245],
  [122, 1.3065],
  [123, 1.2888],
  [124, 1.2714],
  [125, 1.2544],
  [126, 1.2378],
  [127, 1.2214],
  [128, 1.2054],
  [129, 1.1896],
  [130, 1.1742],
  [131, 1.159],
  [132, 1.1441],
  [133, 1.1295],
  [134, 1.1151],
  [135, 1.101],
  [136, 1.0871],
  [137, 1.0734],
  [138, 1.06],
  [139, 1.0468],
  [140, 1.0338],
  [141, 1.021],
  [142, 1.0085],
  [143, 0.9961],
  [144, 0.9839],
  [145, 0.9719],
  [146, 0.9601],
  [147, 0.9485],
  [148, 0.937],
  [149, 0.9257],
  [150, 0.9146],
  [151, 0.9037],
  [152, 0.8929],
  [153, 0.8822],
  [154, 0.8718],
  [155, 0.8614],
  [156, 0.8512],
  [157, 0.8412],
  [158, 0.8313],
  [159, 0.8215],
  [160, 0.8119],
  [161, 0.8024],
  [162, 0.793],
  [163, 0.7837],
  [164, 0.7746],
  [165, 0.7656],
  [166, 0.7567],
  [167, 0.7479],
  [168, 0.7393],
  [169, 0.7307],
  [170, 0.7223],
  [171, 0.714],
  [172, 0.7057],
  [173, 0.6976],
  [174, 0.6896],
  [175, 0.6817],
  [176, 0.6739],
  [177, 0.6662],
  [178, 0.6585],
  [179, 0.651],
  [180, 0.6436],
  [181, 0.6362],
  [182, 0.629],
  [183, 0.6218],
  [184, 0.6147],
  [185, 0.6077],
  [186, 0.6008],
  [187, 0.594],
  [188, 0.5873],
  [189, 0.5806],
  [190, 0.574],
  [191, 0.5675],
  [192, 0.5611],
  [193, 0.5548],
  [194, 0.5485],
  [195, 0.5423],
  [196, 0.5362],
  [197, 0.5301],
  [198, 0.5241],
  [199, 0.5182],
  [200, 0.5124],
  [201, 0.5066],
  [202, 0.5009],
  [203, 0.4953],
  [204, 0.4897],
  [205, 0.4842],
  [206, 0.4787],
  [207, 0.4733],
  [208, 0.468],
  [209, 0.4628],
  [210, 0.4576],
  [211, 0.4524],
  [212, 0.4473],
  [213, 0.4423],
  [214, 0.4373],
  [215, 0.4324],
  [216, 0.4276],
  [217, 0.4228],
  [218, 0.418],
  [219, 0.4134],
  [220, 0.4087],
  [221, 0.4041],
  [222, 0.3996],
  [223, 0.3951],
  [224, 0.3907],
  [225, 0.3863],
  [226, 0.382],
  [227, 0.3777],
  [228, 0.3735],
  [229, 0.3693],
  [230, 0.3652],
  [231, 0.3611],
  [232, 0.3571],
  [233, 0.3531],
  [234, 0.3491],
  [235, 0.3452],
  [236, 0.3413],
  [237, 0.3375],
  [238, 0.3338],
  [239, 0.33],
  [240, 0.3263],
  [241, 0.3227],
  [242, 0.3191],
  [243, 0.3155],
  [244, 0.312],
  [245, 0.3085],
  [246, 0.3051],
  [247, 0.3016],
  [248, 0.2983],
  [249, 0.2949],
  [250, 0.2916],
  [251, 0.2884],
  [252, 0.2852],
  [253, 0.282],
  [254, 0.2788],
  [255, 0.2757],
  [256, 0.2726],
  [257, 0.2696],
  [258, 0.2666],
  [259, 0.2636],
  [260, 0.2607],
  [261, 0.2577],
  [262, 0.2549],
  [263, 0.252],
  [264, 0.2492],
  [265, 0.2464],
  [266, 0.2437],
  [267, 0.241],
  [268, 0.2383],
  [269, 0.2356],
  [270, 0.233],
  [271, 0.2304],
  [272, 0.2278],
  [273, 0.2253],
  [274, 0.2227],
  [275, 0.2203],
  [276, 0.2178],
  [277, 0.2154],
  [278, 0.213],
  [279, 0.2106],
  [280, 0.2082],
  [281, 0.2059],
  [282, 0.2036],
  [283, 0.2013],
  [284, 0.1991],
  [285, 0.1969],
  [286, 0.1947],
  [287, 0.1925],
  [288, 0.1904],
  [289, 0.1882],
  [290, 0.1861],
  [291, 0.1841],
  [292, 0.182],
  [293, 0.18],
  [294, 0.178],
  [295, 0.176],
  [296, 0.174],
  [297, 0.1721],
  [298, 0.1701],
  [299, 0.1682],
  [300, 0.1664],
  [301, 0.1645],
  [302, 0.1627],
  [303, 0.1609],
  [304, 0.1591],
  [305, 0.1573],
  [306, 0.1555],
  [307, 0.1538],
  [308, 0.1521],
  [309, 0.1504],
  [310, 0.1487],
  [311, 0.1471],
  [312, 0.1454],
  [313, 0.1438],
  [314, 0.1422],
  [315, 0.1406],
  [316, 0.139],
  [317, 0.1375],
  [318, 0.1359],
  [319, 0.1344],
  [320, 0.1329],
  [321, 0.1314],
  [322, 0.13],
  [323, 0.1285],
  [324, 0.1271],
  [325, 0.1257],
  [326, 0.1243],
  [327, 0.1229],
  [328, 0.1215],
  [329, 0.1202],
  [330, 0.1188],
  [331, 0.1175],
  [332, 0.1162],
  [333, 0.1149],
  [334, 0.1136],
  [335, 0.1123],
  [336, 0.1111],
  [337, 0.1098],
  [338, 0.1086],
  [339, 0.1074],
  [340, 0.1062],
  [341, 0.105],
  [342, 0.1039],
  [343, 0.1027],
  [344, 0.1015],
  [345, 0.1004],
  [346, 0.0993],
  [347, 0.0982],
  [348, 0.0971],
  [349, 0.096],
  [350, 0.0949],
  [351, 0.0939],
  [352, 0.0928],
  [353, 0.0918],
  [354, 0.0908],
  [355, 0.0898],
  [356, 0.0888],
  [357, 0.0878],
  [358, 0.0868],
  [359, 0.0858],
  [360, 0.0849],
  [361, 0.0839],
  [362, 0.083],
  [363, 0.0821],
  [364, 0.0811],
  [365, 0.0802],
  [366, 0.0793],
  [367, 0.0785],
  [368, 0.0776],
  [369, 0.0767],
  [370, 0.0759],
  [371, 0.075],
  [372, 0.0742],
  [373, 0.0733],
  [374, 0.0725],
  [375, 0.0717],
  [376, 0.0709],
  [377, 0.0701],
  [378, 0.0693],
  [379, 0.0686],
  [380, 0.0678],
  [381, 0.067],
  [382, 0.0663],
  [383, 0.0656],
  [384, 0.0648],
  [385, 0.0641],
  [386, 0.0634],
  [387, 0.0627],
  [388, 0.062],
  [389, 0.0613],
  [390, 0.0606],
  [391, 0.0599],
  [392, 0.0593],
  [393, 0.0586],
  [394, 0.0579],
  [395, 0.0573],
  [396, 0.0567],
  [397, 0.056],
  [398, 0.0554],
  [399, 0.0548],
  [400, 0.0542],
  [401, 0.0536],
  [402, 0.053],
  [403, 0.0524],
  [404, 0.0518],
  [405, 0.0512],
  [406, 0.0507],
  [407, 0.0501],
  [408, 0.0495],
  [409, 0.049],
  [410, 0.0484],
  [411, 0.0479],
  [412, 0.0474],
  [413, 0.0468],
  [414, 0.0463],
  [415, 0.0458],
  [416, 0.0453],
  [417, 0.0448],
  [418, 0.0443],
  [419, 0.0438],
  [420, 0.0433],
  [421, 0.0428],
  [422, 0.0423],
  [423, 0.0419],
  [424, 0.0414],
  [425, 0.0409],
  [426, 0.0405],
  [427, 0.04],
  [428, 0.0396],
  [429, 0.0391],
  [430, 0.0387],
  [431, 0.0383],
  [432, 0.0378],
  [433, 0.0374],
  [434, 0.037],
  [435, 0.0366],
  [436, 0.0362],
  [437, 0.0358],
  [438, 0.0354],
  [439, 0.035],
  [440, 0.0346],
  [441, 0.0342],
  [442, 0.0338],
  [443, 0.0334],
  [444, 0.0331],
  [445, 0.0327],
  [446, 0.0323],
  [447, 0.032],
  [448, 0.0316],
  [449, 0.0313],
  [450, 0.0309],
  [451, 0.0306],
  [452, 0.0302],
  [453, 0.0299],
  [454, 0.0296],
  [455, 0.0292],
  [456, 0.0289],
  [457, 0.0286],
  [458, 0.0283],
  [459, 0.0279],
  [460, 0.0276],
  [461, 0.0273],
  [462, 0.027],
  [463, 0.0267],
  [464, 0.0264],
  [465, 0.0261],
  [466, 0.0258],
  [467, 0.0255],
  [468, 0.0253],
  [469, 0.025],
  [470, 0.0247],
  [471, 0.0244],
  [472, 0.0242],
  [473, 0.0239],
  [474, 0.0236],
  [475, 0.0234],
  [476, 0.0231],
  [477, 0.0228],
  [478, 0.0226],
  [479, 0.0223],
  [480, 0.0221],
  [481, 0.0218],
  [482, 0.0216],
  [483, 0.0214],
  [484, 0.0211],
  [485, 0.0209],
  [486, 0.0206],
  [487, 0.0204],
  [488, 0.0202],
  [489, 0.02],
  [490, 0.0197],
  [491, 0.0195],
  [492, 0.0193],
  [493, 0.0191],
  [494, 0.0189],
  [495, 0.0187],
  [496, 0.0185],
  [497, 0.0182],
  [498, 0.018],
  [499, 0.0178],
  [500, 0.0176],
  [501, 0.0174],
  [502, 0.0173],
  [503, 0.0171],
  [504, 0.0169],
  [505, 0.0167],
  [506, 0.0165],
  [507, 0.0163],
  [508, 0.0161],
  [509, 0.0159],
  [510, 0.0158],
  [511, 0.0156],
  [512, 0.0154],
  [513, 0.0152],
  [514, 0.0151],
  [515, 0.0149],
  [516, 0.0147],
  [517, 0.0146],
  [518, 0.0144],
  [519, 0.0143],
  [520, 0.0141],
  [521, 0.0139],
  [522, 0.0138],
  [523, 0.0136],
  [524, 0.0135],
  [525, 0.0133],
  [526, 0.0132],
  [527, 0.013],
  [528, 0.0129],
  [529, 0.0127],
  [530, 0.0126],
  [531, 0.0125],
  [532, 0.0123],
  [533, 0.0122],
  [534, 0.012],
  [535, 0.0119],
  [536, 0.0118],
  [537, 0.0116],
  [538, 0.0115],
  [539, 0.0114],
  [540, 0.0113],
  [541, 0.0111],
  [542, 0.011],
  [543, 0.0109],
  [544, 0.0108],
  [545, 0.0106],
  [546, 0.0105],
  [547, 0.0104],
  [548, 0.0103],
  [549, 0.0102],
  [550, 0.0101],
  [551, 0.01],
  [552, 0.0098],
  [553, 0.0097],
  [554, 0.0096],
  [555, 0.0095],
  [556, 0.0094],
  [557, 0.0093],
  [558, 0.0092],
  [559, 0.0091],
  [560, 0.009],
  [561, 0.0089],
  [562, 0.0088],
  [563, 0.0087],
  [564, 0.0086],
  [565, 0.0085],
  [566, 0.0084],
  [567, 0.0083],
  [568, 0.0082],
  [569, 0.0081],
  [570, 0.008],
  [571, 0.008],
  [572, 0.0079],
  [573, 0.0078],
  [574, 0.0077],
  [575, 0.0076],
  [576, 0.0075],
  [577, 0.0074],
  [578, 0.0074],
  [579, 0.0073],
  [580, 0.0072],
  [581, 0.0071],
  [582, 0.007],
  [583, 0.007],
  [584, 0.0069],
  [585, 0.0068],
  [586, 0.0067],
  [587, 0.0066],
  [588, 0.0066],
  [589, 0.0065],
  [590, 0.0064],
  [591, 0.0064],
  [592, 0.0063],
  [593, 0.0062],
  [594, 0.0061],
  [595, 0.0061],
  [596, 0.006],
  [597, 0.0059],
  [598, 0.0059],
  [599, 0.0058],
  [600, 0.0057],
  [601, 0.0057],
  [602, 0.0056],
  [603, 0.0056],
  [604, 0.0055],
  [605, 0.0054],
  [606, 0.0054],
  [607, 0.0053],
  [608, 0.0053],
  [609, 0.0052],
  [610, 0.0051],
  [611, 0.0051],
  [612, 0.005],
  [613, 0.005],
  [614, 0.0049],
  [615, 0.0049],
  [616, 0.0048],
  [617, 0.0047],
  [618, 0.0047],
  [619, 0.0046],
  [620, 0.0046],
  [621, 0.0045],
  [622, 0.0045],
  [623, 0.0044],
  [624, 0.0044],
  [625, 0.0043],
  [626, 0.0043],
  [627, 0.0042],
  [628, 0.0042],
  [629, 0.0041],
  [630, 0.0041],
  [631, 0.0041],
  [632, 0.004],
  [633, 0.004],
  [634, 0.0039],
  [635, 0.0039],
  [636, 0.0038],
  [637, 0.0038],
  [638, 0.0038],
  [639, 0.0037],
  [640, 0.0037],
  [641, 0.0036],
  [642, 0.0036],
  [643, 0.0035],
  [644, 0.0035],
  [645, 0.0035],
  [646, 0.0034],
  [647, 0.0034],
  [648, 0.0034],
  [649, 0.0033],
  [650, 0.0033],
  [651, 0.0032],
  [652, 0.0032],
  [653, 0.0032],
  [654, 0.0031],
  [655, 0.0031],
  [656, 0.0031],
  [657, 0.003],
  [658, 0.003],
  [659, 0.003],
  [660, 0.0029],
  [661, 0.0029],
  [662, 0.0029],
  [663, 0.0028],
  [664, 0.0028],
  [665, 0.0028],
  [666, 0.0027],
  [667, 0.0027],
  [668, 0.0027],
  [669, 0.0026],
  [670, 0.0026],
  [671, 0.0026],
  [672, 0.0026],
];

drugLevel['HaldolDecoanate:'] = [
  [0, 0],
  [24, 1.333548306],
  [48, 1.778351083],
  [72, 2.371517074],
  [96, 3.162532576],
  [120, 4.217389958],
  [144, 5.624093232],
  [168, 7.5],
  [192, 7.277345646],
  [216, 7.061301286],
  [240, 6.851670689],
  [264, 6.648263447],
  [288, 6.450894806],
  [312, 6.259385497],
  [336, 6.073561572],
  [360, 5.893254248],
  [384, 5.718299752],
  [408, 5.548539174],
  [432, 5.383818319],
  [456, 5.223987574],
  [480, 5.068901763],
  [504, 4.918420023],
  [528, 4.772405672],
  [552, 4.630726085],
  [576, 4.493252574],
  [600, 4.359860274],
  [624, 4.230428024],
  [648, 4.104838262],
  [672, 3.982976913],
  [696, 3.864733293],
  [720, 3.75],
  [744, 3.638672823],
  [768, 3.530650643],
  [792, 3.425835344],
  [816, 3.324131724],
  [840, 3.225447403],
  [864, 3.129692749],
  [888, 3.036780786],
  [912, 2.946627124],
  [936, 2.859149876],
  [960, 2.774269587],
  [984, 2.69190916],
  [1008, 2.611993787],
  [1032, 2.534450882],
  [1056, 2.459210012],
  [1080, 2.386202836],
  [1104, 2.315363042],
  [1128, 2.246626287],
  [1152, 2.179930137],
  [1176, 2.115214012],
  [1200, 2.052419131],
  [1224, 1.991488457],
  [1248, 1.932366646],
  [1272, 1.875],
  [1296, 1.819336411],
  [1320, 1.765325322],
  [1344, 1.712917672],
  [1368, 1.662065862],
  [1392, 1.612723702],
  [1416, 1.564846374],
  [1440, 1.518390393],
  [1464, 1.473313562],
  [1488, 1.429574938],
  [1512, 1.387134793],
  [1536, 1.34595458],
  [1560, 1.305996893],
  [1584, 1.267225441],
  [1608, 1.229605006],
  [1632, 1.193101418],
  [1656, 1.157681521],
  [1680, 1.123313144],
  [1704, 1.089965069],
  [1728, 1.057607006],
  [1752, 1.026209565],
  [1776, 0.995744228],
  [1800, 0.966183323],
  [1824, 0.9375],
  [1848, 0.909668206],
  [1872, 0.882662661],
  [1896, 0.856458836],
  [1920, 0.831032931],
  [1944, 0.806361851],
  [1968, 0.782423187],
  [1992, 0.759195197],
  [2016, 0.736656781],
  [2040, 0.714787469],
  [2064, 0.693567397],
  [2088, 0.67297729],
  [2112, 0.652998447],
  [2136, 0.63361272],
  [2160, 0.614802503],
  [2184, 0.596550709],
  [2208, 0.578840761],
  [2232, 0.561656572],
  [2256, 0.544982534],
  [2280, 0.528803503],
  [2304, 0.513104783],
  [2328, 0.497872114],
  [2352, 0.483091662],
  [2376, 0.46875],
  [2400, 0.454834103],
  [2424, 0.44133133],
  [2448, 0.428229418],
  [2472, 0.415516465],
  [2496, 0.403180925],
  [2520, 0.391211594],
  [2544, 0.379597598],
  [2568, 0.368328391],
  [2592, 0.357393735],
  [2616, 0.346783698],
  [2640, 0.336488645],
  [2664, 0.326499223],
  [2688, 0.31680636],
  [2712, 0.307401251],
  [2736, 0.298275354],
  [2760, 0.28942038],
  [2784, 0.280828286],
  [2808, 0.272491267],
  [2832, 0.264401752],
  [2856, 0.256552391],
  [2880, 0.248936057],
  [2904, 0.241545831],
  [2928, 0.234375],
  [2952, 0.227417051],
  [2976, 0.220665665],
  [3000, 0.214114709],
  [3024, 0.207758233],
  [3048, 0.201590463],
  [3072, 0.195605797],
  [3096, 0.189798799],
  [3120, 0.184164195],
  [3144, 0.178696867],
  [3168, 0.173391849],
  [3192, 0.168244322],
  [3216, 0.163249612],
  [3240, 0.15840318],
  [3264, 0.153700626],
  [3288, 0.149137677],
  [3312, 0.14471019],
  [3336, 0.140414143],
  [3360, 0.136245634],
  [3384, 0.132200876],
  [3408, 0.128276196],
  [3432, 0.124468029],
  [3456, 0.120772915],
  [3480, 0.1171875],
  [3504, 0.113708526],
  [3528, 0.110332833],
  [3552, 0.107057355],
  [3576, 0.103879116],
  [3600, 0.100795231],
  [3624, 0.097802898],
  [3648, 0.0948994],
  [3672, 0.092082098],
  [3696, 0.089348434],
  [3720, 0.086695925],
  [3744, 0.084122161],
  [3768, 0.081624806],
  [3792, 0.07920159],
  [3816, 0.076850313],
  [3840, 0.074568839],
  [3864, 0.072355095],
  [3888, 0.070207071],
  [3912, 0.068122817],
  [3936, 0.066100438],
  [3960, 0.064138098],
  [3984, 0.062234014],
  [4008, 0.060386458],
  [4032, 0.05859375],
  [4056, 0.056854263],
  [4080, 0.055166416],
  [4104, 0.053528677],
  [4128, 0.051939558],
  [4152, 0.050397616],
  [4176, 0.048901449],
  [4200, 0.0474497],
  [4224, 0.046041049],
  [4248, 0.044674217],
  [4272, 0.043347962],
  [4296, 0.042061081],
  [4320, 0.040812403],
  [4344, 0.039600795],
  [4368, 0.038425156],
  [4392, 0.037284419],
];

drugLevel['RI:Deltoid:39mg'] = [
  [0, 0],
  [24, 1.71539],
  [48, 2.80864],
  [72, 3.5637],
  [96, 4.07797],
  [120, 4.47587],
  [144, 4.75817],
  [168, 5.04047],
  [192, 5.2197],
  [216, 5.39893],
  [240, 5.48587],
  [264, 5.57281],
  [288, 5.65222],
  [312, 5.73163],
  [336, 5.24309],
  [360, 4.75454],
  [384, 4.48319],
  [408, 4.21184],
  [432, 4.03194],
  [456, 3.85203],
  [480, 3.66231],
  [504, 3.47259],
  [528, 3.35844],
  [552, 3.2443],
  [576, 3.14648],
  [600, 3.04865],
  [624, 2.92486],
  [648, 2.80107],
  [672, 2.73103],
  [696, 2.66099],
  [720, 2.58006],
  [744, 2.49912],
  [768, 2.43772],
  [792, 2.37631],
  [816, 2.31899],
  [840, 2.26166],
  [864, 2.19497],
  [888, 2.12827],
  [912, 2.05911],
  [936, 1.98995],
  [960, 1.94472],
  [984, 1.89948],
  [1008, 1.85251],
  [1032, 1.80554],
  [1056, 1.76828],
  [1080, 1.73102],
  [1104, 1.67934],
  [1128, 1.62765],
  [1152, 1.57881],
  [1176, 1.52997],
  [1200, 1.49278],
  [1224, 1.45559],
  [1248, 1.41984],
  [1272, 1.3841],
  [1296, 1.3622],
  [1320, 1.34031],
  [1344, 1.30094],
  [1368, 1.26157],
  [1392, 1.23035],
  [1416, 1.19912],
  [1440, 1.17098],
  [1464, 1.14283],
  [1488, 1.11955],
  [1512, 1.09628],
  [1536, 1.07002],
  [1560, 1.04377],
  [1584, 1.01752],
  [1608, 0.998244],
  [1632, 0.978968],
  [1656, 0.959692],
  [1680, 0.940416],
  [1704, 0.921139],
  [1728, 0.89847],
  [1752, 0.875801],
  [1776, 0.853131],
  [1800, 0.830462],
  [1824, 0.807793],
  [1848, 0.790575],
  [1872, 0.773357],
  [1896, 0.75614],
  [1920, 0.738922],
  [1944, 0.721704],
  [1968, 0.707327],
  [1992, 0.69295],
  [2016, 0.678574],
  [2040, 0.664197],
  [2064, 0.64982],
  [2088, 0.637599],
  [2112, 0.625379],
  [2136, 0.613158],
  [2160, 0.600937],
  [2184, 0.588717],
  [2208, 0.577073],
  [2232, 0.565429],
  [2256, 0.553785],
  [2280, 0.542141],
  [2304, 0.530497],
  [2328, 0.519254],
  [2352, 0.50801],
  [2376, 0.496766],
  [2400, 0.485523],
  [2424, 0.474279],
  [2448, 0.466083],
  [2472, 0.457888],
  [2496, 0.449693],
  [2520, 0.441497],
  [2544, 0.433302],
  [2568, 0.42383],
  [2592, 0.414358],
  [2616, 0.404886],
  [2640, 0.395413],
  [2664, 0.385941],
  [2688, 0.377692],
  [2712, 0.369442],
  [2736, 0.361193],
  [2760, 0.352943],
  [2784, 0.344693],
  [2808, 0.339457],
  [2832, 0.33422],
  [2856, 0.328984],
  [2880, 0.323747],
  [2904, 0.31851],
  [2928, 0.311943],
  [2952, 0.305376],
  [2976, 0.298809],
  [3000, 0.292242],
  [3024, 0.285675],
  [3048, 0.28043],
  [3072, 0.275184],
  [3096, 0.269939],
  [3120, 0.264693],
  [3144, 0.259448],
  [3168, 0.254084],
  [3192, 0.248721],
  [3216, 0.243358],
  [3240, 0.237995],
  [3264, 0.232631],
  [3288, 0.227944],
  [3312, 0.223256],
  [3336, 0.218568],
  [3360, 0.21388],
  [3384, 0.209193],
  [3408, 0.205592],
  [3432, 0.201991],
  [3456, 0.19839],
  [3480, 0.194789],
  [3504, 0.191188],
  [3528, 0.186587],
  [3552, 0.181986],
  [3576, 0.177385],
  [3600, 0.172784],
  [3624, 0.168183],
  [3648, 0.165347],
  [3672, 0.16251],
  [3696, 0.159674],
  [3720, 0.156837],
  [3744, 0.154],
  [3768, 0.150969],
  [3792, 0.147938],
  [3816, 0.144907],
  [3840, 0.141876],
  [3864, 0.138845],
  [3888, 0.136582],
  [3912, 0.134319],
  [3936, 0.132056],
  [3960, 0.129793],
  [3984, 0.12753],
  [4008, 0.125046],
  [4032, 0.122561],
  [4056, 0.120076],
  [4080, 0.117591],
  [4104, 0.115106],
  [4128, 0.112801],
  [4152, 0.110496],
  [4176, 0.108191],
  [4200, 0.105886],
  [4224, 0.103581],
  [4248, 0.101464],
  [4272, 0.099347],
  [4296, 0.09723],
  [4320, 0.0951129],
  [4344, 0.0929959],
  [4368, 0.0912445],
  [4392, 0.089493],
  [4416, 0.0877416],
  [4440, 0.0859902],
  [4464, 0.0842388],
  [4488, 0.0824326],
  [4512, 0.0806264],
  [4536, 0.0788202],
  [4560, 0.077014],
  [4584, 0.0752078],
  [4608, 0.0738119],
  [4632, 0.0724159],
  [4656, 0.0710199],
  [4680, 0.069624],
  [4704, 0.068228],
  [4728, 0.0670473],
  [4752, 0.0658666],
  [4776, 0.0646859],
  [4800, 0.0635052],
  [4824, 0.0623245],
  [4848, 0.0611372],
  [4872, 0.0599499],
  [4896, 0.0587626],
  [4920, 0.0575753],
  [4944, 0.0563879],
  [4968, 0.0552314],
  [4992, 0.0540749],
  [5016, 0.0529183],
  [5040, 0.0517618],
  [5064, 0.0506052],
  [5088, 0.0495067],
  [5112, 0.0484081],
  [5136, 0.0473095],
  [5160, 0.0462109],
  [5184, 0.0451123],
  [5208, 0.0443374],
  [5232, 0.0435625],
  [5256, 0.0427876],
  [5280, 0.0420127],
  [5304, 0.0412378],
  [5328, 0.0403235],
  [5352, 0.0394092],
  [5376, 0.0384948],
  [5400, 0.0375805],
  [5424, 0.0366662],
  [5448, 0.0359491],
  [5472, 0.0352321],
  [5496, 0.034515],
  [5520, 0.0337979],
  [5544, 0.0330809],
  [5568, 0.0323704],
  [5592, 0.0316599],
  [5616, 0.0309494],
  [5640, 0.030239],
  [5664, 0.0295285],
  [5688, 0.0289809],
  [5712, 0.0284334],
  [5736, 0.0278858],
  [5760, 0.0273383],
  [5784, 0.0267907],
  [5808, 0.0262292],
  [5832, 0.0256677],
  [5856, 0.0251062],
  [5880, 0.0245447],
  [5904, 0.0239832],
  [5928, 0.0235259],
  [5952, 0.0230686],
  [5976, 0.0226113],
  [6000, 0.0221539],
  [6024, 0.0216966],
  [6048, 0.0212692],
  [6072, 0.0208417],
  [6096, 0.0204143],
  [6120, 0.0199868],
  [6144, 0.0195594],
  [6168, 0.0192148],
  [6192, 0.0188703],
  [6216, 0.0185257],
  [6240, 0.0181812],
  [6264, 0.0178366],
  [6288, 0.0174475],
  [6312, 0.0170584],
  [6336, 0.0166693],
  [6360, 0.0162802],
  [6384, 0.015891],
  [6408, 0.0155826],
  [6432, 0.0152741],
  [6456, 0.0149656],
  [6480, 0.0146571],
  [6504, 0.0143486],
  [6528, 0.0140112],
  [6552, 0.0136737],
  [6576, 0.0133363],
  [6600, 0.0129988],
  [6624, 0.0126614],
  [6648, 0.0124234],
  [6672, 0.0121854],
  [6696, 0.0119474],
  [6720, 0.0117094],
  [6744, 0.0114714],
  [6768, 0.0112681],
  [6792, 0.0110647],
  [6816, 0.0108614],
  [6840, 0.0106581],
  [6864, 0.0104547],
  [6888, 0.0102177],
  [6912, 0.00998074],
  [6936, 0.00974374],
  [6960, 0.00950674],
  [6984, 0.00926974],
  [7008, 0.00907629],
  [7032, 0.00888284],
  [7056, 0.00868939],
  [7080, 0.00849594],
  [7104, 0.00830249],
  [7128, 0.00810928],
  [7152, 0.00791607],
  [7176, 0.00772287],
  [7200, 0.00752966],
  [7224, 0.00733645],
  [7248, 0.00722798],
  [7272, 0.00711951],
  [7296, 0.00701104],
  [7320, 0.00690257],
  [7344, 0.0067941],
  [7368, 0.00663636],
  [7392, 0.00647861],
  [7416, 0.00632087],
  [7440, 0.00616312],
  [7464, 0.00600538],
  [7488, 0.00586308],
  [7512, 0.00572079],
  [7536, 0.00557849],
  [7560, 0.0054362],
  [7584, 0.0052939],
  [7608, 0.00518989],
  [7632, 0.00508588],
  [7656, 0.00498188],
  [7680, 0.00487787],
  [7704, 0.00477386],
  [7728, 0.00468698],
  [7752, 0.00460011],
  [7776, 0.00451323],
  [7800, 0.00442636],
  [7824, 0.00433948],
  [7848, 0.00428097],
  [7872, 0.00422246],
  [7896, 0.00416394],
  [7920, 0.00410543],
  [7944, 0.00404692],
  [7968, 0.00394357],
  [7992, 0.00384023],
  [8016, 0.00373689],
  [8040, 0.00363355],
  [8064, 0.0035302],
  [8088, 0.0034564],
  [8112, 0.00338261],
  [8136, 0.00330881],
  [8160, 0.00323501],
  [8184, 0.00316121],
  [8208, 0.0030923],
  [8232, 0.00302339],
  [8256, 0.00295449],
  [8280, 0.00288558],
  [8304, 0.00281667],
  [8328, 0.00277513],
  [8352, 0.00273359],
  [8376, 0.00269205],
  [8400, 0.00265051],
  [8424, 0.00260897],
  [8448, 0.00254303],
  [8472, 0.00247709],
  [8496, 0.00241115],
  [8520, 0.00234521],
  [8544, 0.00227927],
  [8568, 0.00223461],
  [8592, 0.00218996],
  [8616, 0.0021453],
  [8640, 0.00210064],
  [8664, 0.00205599],
  [8688, 0.00202999],
  [8712, 0.002004],
  [8736, 0.00197801],
  [8760, 0.00195201],
  [8784, 0.00192602],
  [8808, 0.00187825],
  [8832, 0.00183048],
  [8856, 0.00178271],
  [8880, 0.00173494],
  [8904, 0.00168717],
];
drugLevel['RI:Deltoid:78mg'] = [
  [0, 0],
  [24, 2.8182],
  [48, 4.63066],
  [72, 5.84742],
  [96, 6.75714],
  [120, 7.38241],
  [144, 7.83558],
  [168, 8.28876],
  [192, 8.57594],
  [216, 8.86313],
  [240, 9.03938],
  [264, 9.21562],
  [288, 9.32141],
  [312, 9.42721],
  [336, 8.69747],
  [360, 7.96773],
  [384, 7.55168],
  [408, 7.13563],
  [432, 6.85934],
  [456, 6.58306],
  [480, 6.3076],
  [504, 6.03213],
  [528, 5.87329],
  [552, 5.71446],
  [576, 5.57403],
  [600, 5.43359],
  [624, 5.23577],
  [648, 5.03795],
  [672, 4.96277],
  [696, 4.88759],
  [720, 4.74919],
  [744, 4.61079],
  [768, 4.53752],
  [792, 4.46424],
  [816, 4.36944],
  [840, 4.27464],
  [864, 4.17061],
  [888, 4.06657],
  [912, 3.96528],
  [936, 3.86399],
  [960, 3.77565],
  [984, 3.68732],
  [1008, 3.62908],
  [1032, 3.57084],
  [1056, 3.52606],
  [1080, 3.48128],
  [1104, 3.38533],
  [1128, 3.28938],
  [1152, 3.20667],
  [1176, 3.12396],
  [1200, 3.07023],
  [1224, 3.01651],
  [1248, 2.95751],
  [1272, 2.89852],
  [1296, 2.85428],
  [1320, 2.81004],
  [1344, 2.73645],
  [1368, 2.66286],
  [1392, 2.61373],
  [1416, 2.56459],
  [1440, 2.51115],
  [1464, 2.45771],
  [1488, 2.42276],
  [1512, 2.38782],
  [1536, 2.34217],
  [1560, 2.29652],
  [1584, 2.25088],
  [1608, 2.21662],
  [1632, 2.18236],
  [1656, 2.1481],
  [1680, 2.11385],
  [1704, 2.07959],
  [1728, 2.03403],
  [1752, 1.98847],
  [1776, 1.94292],
  [1800, 1.89736],
  [1824, 1.8518],
  [1848, 1.82241],
  [1872, 1.79301],
  [1896, 1.76361],
  [1920, 1.73422],
  [1944, 1.70482],
  [1968, 1.67592],
  [1992, 1.64701],
  [2016, 1.61811],
  [2040, 1.58921],
  [2064, 1.5603],
  [2088, 1.53423],
  [2112, 1.50816],
  [2136, 1.48208],
  [2160, 1.45601],
  [2184, 1.42994],
  [2208, 1.40835],
  [2232, 1.38677],
  [2256, 1.36519],
  [2280, 1.3436],
  [2304, 1.32202],
  [2328, 1.29511],
  [2352, 1.2682],
  [2376, 1.24129],
  [2400, 1.21438],
  [2424, 1.18746],
  [2448, 1.17196],
  [2472, 1.15645],
  [2496, 1.14095],
  [2520, 1.12544],
  [2544, 1.10993],
  [2568, 1.09098],
  [2592, 1.07203],
  [2616, 1.05308],
  [2640, 1.03413],
  [2664, 1.01519],
  [2688, 0.998786],
  [2712, 0.982387],
  [2736, 0.965988],
  [2760, 0.949589],
  [2784, 0.93319],
  [2808, 0.918884],
  [2832, 0.904579],
  [2856, 0.890274],
  [2880, 0.875969],
  [2904, 0.861664],
  [2928, 0.848527],
  [2952, 0.835391],
  [2976, 0.822254],
  [3000, 0.809118],
  [3024, 0.795981],
  [3048, 0.784267],
  [3072, 0.772553],
  [3096, 0.760839],
  [3120, 0.749125],
  [3144, 0.737411],
  [3168, 0.724713],
  [3192, 0.712016],
  [3216, 0.699318],
  [3240, 0.68662],
  [3264, 0.673923],
  [3288, 0.662872],
  [3312, 0.651822],
  [3336, 0.640772],
  [3360, 0.629722],
  [3384, 0.618672],
  [3408, 0.610519],
  [3432, 0.602367],
  [3456, 0.594214],
  [3480, 0.586061],
  [3504, 0.577908],
  [3528, 0.568054],
  [3552, 0.5582],
  [3576, 0.548347],
  [3600, 0.538493],
  [3624, 0.528639],
  [3648, 0.520775],
  [3672, 0.512911],
  [3696, 0.505047],
  [3720, 0.497183],
  [3744, 0.489319],
  [3768, 0.480708],
  [3792, 0.472096],
  [3816, 0.463485],
  [3840, 0.454873],
  [3864, 0.446262],
  [3888, 0.440617],
  [3912, 0.434973],
  [3936, 0.429328],
  [3960, 0.423684],
  [3984, 0.418039],
  [4008, 0.41281],
  [4032, 0.40758],
  [4056, 0.402351],
  [4080, 0.397121],
  [4104, 0.391892],
  [4128, 0.38485],
  [4152, 0.377809],
  [4176, 0.370768],
  [4200, 0.363727],
  [4224, 0.356686],
  [4248, 0.351903],
  [4272, 0.34712],
  [4296, 0.342337],
  [4320, 0.337554],
  [4344, 0.332771],
  [4368, 0.328197],
  [4392, 0.323623],
  [4416, 0.31905],
  [4440, 0.314476],
  [4464, 0.309902],
  [4488, 0.304043],
  [4512, 0.298184],
  [4536, 0.292325],
  [4560, 0.286466],
  [4584, 0.280607],
  [4608, 0.276474],
  [4632, 0.272341],
  [4656, 0.268208],
  [4680, 0.264074],
  [4704, 0.259941],
  [4728, 0.256635],
  [4752, 0.253329],
  [4776, 0.250024],
  [4800, 0.246718],
  [4824, 0.243412],
  [4848, 0.239854],
  [4872, 0.236295],
  [4896, 0.232737],
  [4920, 0.229179],
  [4944, 0.225621],
  [4968, 0.221882],
  [4992, 0.218142],
  [5016, 0.214403],
  [5040, 0.210664],
  [5064, 0.206925],
  [5088, 0.203125],
  [5112, 0.199326],
  [5136, 0.195526],
  [5160, 0.191727],
  [5184, 0.187927],
  [5208, 0.186062],
  [5232, 0.184197],
  [5256, 0.182332],
  [5280, 0.180466],
  [5304, 0.178601],
  [5328, 0.175002],
  [5352, 0.171403],
  [5376, 0.167804],
  [5400, 0.164205],
  [5424, 0.160606],
  [5448, 0.158784],
  [5472, 0.156962],
  [5496, 0.15514],
  [5520, 0.153318],
  [5544, 0.151495],
  [5568, 0.148645],
  [5592, 0.145795],
  [5616, 0.142944],
  [5640, 0.140094],
  [5664, 0.137243],
  [5688, 0.135447],
  [5712, 0.13365],
  [5736, 0.131853],
  [5760, 0.130056],
  [5784, 0.128259],
  [5808, 0.126394],
  [5832, 0.124529],
  [5856, 0.122663],
  [5880, 0.120798],
  [5904, 0.118932],
  [5928, 0.11663],
  [5952, 0.114328],
  [5976, 0.112026],
  [6000, 0.109723],
  [6024, 0.107421],
  [6048, 0.106007],
  [6072, 0.104592],
  [6096, 0.103178],
  [6120, 0.101763],
  [6144, 0.100349],
  [6168, 0.0988153],
  [6192, 0.0972816],
  [6216, 0.0957478],
  [6240, 0.094214],
  [6264, 0.0926802],
  [6288, 0.0911341],
  [6312, 0.0895879],
  [6336, 0.0880417],
  [6360, 0.0864955],
  [6384, 0.0849494],
  [6408, 0.0837849],
  [6432, 0.0826204],
  [6456, 0.081456],
  [6480, 0.0802915],
  [6504, 0.0791271],
  [6528, 0.0775811],
  [6552, 0.076035],
  [6576, 0.074489],
  [6600, 0.072943],
  [6624, 0.071397],
  [6648, 0.0706274],
  [6672, 0.0698578],
  [6696, 0.0690883],
  [6720, 0.0683187],
  [6744, 0.0675491],
  [6768, 0.0665667],
  [6792, 0.0655843],
  [6816, 0.0646018],
  [6840, 0.0636194],
  [6864, 0.0626369],
  [6888, 0.0615256],
  [6912, 0.0604142],
  [6936, 0.0593028],
  [6960, 0.0581915],
  [6984, 0.0570801],
  [7008, 0.0561509],
  [7032, 0.0552217],
  [7056, 0.0542924],
  [7080, 0.0533632],
  [7104, 0.052434],
  [7128, 0.051566],
  [7152, 0.050698],
  [7176, 0.0498299],
  [7200, 0.0489619],
  [7224, 0.0480939],
  [7248, 0.0473464],
  [7272, 0.0465989],
  [7296, 0.0458514],
  [7320, 0.0451038],
  [7344, 0.0443563],
  [7368, 0.0435968],
  [7392, 0.0428374],
  [7416, 0.0420779],
  [7440, 0.0413184],
  [7464, 0.0405589],
  [7488, 0.0399367],
  [7512, 0.0393146],
  [7536, 0.0386924],
  [7560, 0.0380702],
  [7584, 0.0374481],
  [7608, 0.0367442],
  [7632, 0.0360402],
  [7656, 0.0353363],
  [7680, 0.0346324],
  [7704, 0.0339285],
  [7728, 0.0335174],
  [7752, 0.0331062],
  [7776, 0.0326951],
  [7800, 0.032284],
  [7824, 0.0318729],
  [7848, 0.0313482],
  [7872, 0.0308234],
  [7896, 0.0302987],
  [7920, 0.029774],
  [7944, 0.0292493],
  [7968, 0.028699],
  [7992, 0.0281486],
  [8016, 0.0275983],
  [8040, 0.027048],
  [8064, 0.0264976],
  [8088, 0.0261726],
  [8112, 0.0258475],
  [8136, 0.0255224],
  [8160, 0.0251974],
  [8184, 0.0248723],
  [8208, 0.0244574],
  [8232, 0.0240424],
  [8256, 0.0236275],
  [8280, 0.0232125],
  [8304, 0.0227976],
  [8328, 0.0225235],
  [8352, 0.0222494],
  [8376, 0.0219753],
  [8400, 0.0217012],
  [8424, 0.0214271],
  [8448, 0.0209807],
  [8472, 0.0205343],
  [8496, 0.0200879],
  [8520, 0.0196415],
  [8544, 0.0191951],
  [8568, 0.0188668],
  [8592, 0.0185385],
  [8616, 0.0182102],
  [8640, 0.0178818],
  [8664, 0.0175535],
  [8688, 0.0173224],
  [8712, 0.0170913],
  [8736, 0.0168602],
  [8760, 0.0166291],
  [8784, 0.0163979],
  [8808, 0.0161283],
  [8832, 0.0158586],
  [8856, 0.015589],
  [8880, 0.0153193],
  [8904, 0.0150497],
];
drugLevel['RI:Deltoid:117mg'] = [
  [0, 0],
  [24, 3.77085],
  [48, 6.16261],
  [72, 7.82481],
  [96, 9.02682],
  [120, 9.85225],
  [144, 10.4626],
  [168, 11.0729],
  [192, 11.4755],
  [216, 11.8781],
  [240, 12.1088],
  [264, 12.3394],
  [288, 12.466],
  [312, 12.5925],
  [336, 11.6599],
  [360, 10.7274],
  [384, 10.1932],
  [408, 9.6591],
  [432, 9.29984],
  [456, 8.94058],
  [480, 8.6006],
  [504, 8.26062],
  [528, 8.0702],
  [552, 7.87978],
  [576, 7.68426],
  [600, 7.48874],
  [624, 7.23699],
  [648, 6.98524],
  [672, 6.91095],
  [696, 6.83666],
  [720, 6.64953],
  [744, 6.4624],
  [768, 6.38735],
  [792, 6.3123],
  [816, 6.18976],
  [840, 6.06722],
  [864, 5.94215],
  [888, 5.81709],
  [912, 5.68355],
  [936, 5.55001],
  [960, 5.4428],
  [984, 5.33559],
  [1008, 5.25701],
  [1032, 5.17842],
  [1056, 5.12005],
  [1080, 5.06169],
  [1104, 4.95784],
  [1128, 4.85398],
  [1152, 4.7516],
  [1176, 4.64922],
  [1200, 4.56366],
  [1224, 4.47811],
  [1248, 4.39591],
  [1272, 4.31372],
  [1296, 4.26805],
  [1320, 4.22238],
  [1344, 4.12425],
  [1368, 4.02611],
  [1392, 3.95143],
  [1416, 3.87676],
  [1440, 3.81515],
  [1464, 3.75354],
  [1488, 3.70794],
  [1512, 3.66233],
  [1536, 3.59608],
  [1560, 3.52982],
  [1584, 3.46357],
  [1608, 3.41223],
  [1632, 3.3609],
  [1656, 3.30956],
  [1680, 3.25822],
  [1704, 3.20688],
  [1728, 3.14969],
  [1752, 3.0925],
  [1776, 3.03531],
  [1800, 2.97812],
  [1824, 2.92092],
  [1848, 2.87549],
  [1872, 2.83006],
  [1896, 2.78463],
  [1920, 2.7392],
  [1944, 2.69377],
  [1968, 2.6545],
  [1992, 2.61523],
  [2016, 2.57596],
  [2040, 2.53669],
  [2064, 2.49742],
  [2088, 2.4592],
  [2112, 2.42098],
  [2136, 2.38276],
  [2160, 2.34453],
  [2184, 2.30631],
  [2208, 2.27458],
  [2232, 2.24284],
  [2256, 2.21111],
  [2280, 2.17938],
  [2304, 2.14764],
  [2328, 2.11293],
  [2352, 2.07821],
  [2376, 2.0435],
  [2400, 2.00879],
  [2424, 1.97408],
  [2448, 1.94714],
  [2472, 1.92021],
  [2496, 1.89328],
  [2520, 1.86635],
  [2544, 1.83942],
  [2568, 1.80881],
  [2592, 1.7782],
  [2616, 1.74759],
  [2640, 1.71698],
  [2664, 1.68637],
  [2688, 1.66696],
  [2712, 1.64755],
  [2736, 1.62814],
  [2760, 1.60874],
  [2784, 1.58933],
  [2808, 1.56396],
  [2832, 1.5386],
  [2856, 1.51323],
  [2880, 1.48787],
  [2904, 1.4625],
  [2928, 1.44306],
  [2952, 1.42362],
  [2976, 1.40418],
  [3000, 1.38474],
  [3024, 1.36529],
  [3048, 1.34734],
  [3072, 1.32939],
  [3096, 1.31144],
  [3120, 1.29349],
  [3144, 1.27554],
  [3168, 1.25667],
  [3192, 1.23779],
  [3216, 1.21891],
  [3240, 1.20004],
  [3264, 1.18116],
  [3288, 1.16628],
  [3312, 1.1514],
  [3336, 1.13652],
  [3360, 1.12164],
  [3384, 1.10676],
  [3408, 1.09174],
  [3432, 1.07672],
  [3456, 1.0617],
  [3480, 1.04668],
  [3504, 1.03166],
  [3528, 1.01651],
  [3552, 1.00136],
  [3576, 0.986214],
  [3600, 0.971065],
  [3624, 0.955916],
  [3648, 0.942773],
  [3672, 0.929629],
  [3696, 0.916486],
  [3720, 0.903343],
  [3744, 0.890199],
  [3768, 0.877619],
  [3792, 0.865039],
  [3816, 0.852459],
  [3840, 0.839878],
  [3864, 0.827298],
  [3888, 0.817199],
  [3912, 0.8071],
  [3936, 0.797],
  [3960, 0.786901],
  [3984, 0.776802],
  [4008, 0.768897],
  [4032, 0.760992],
  [4056, 0.753088],
  [4080, 0.745183],
  [4104, 0.737278],
  [4128, 0.725954],
  [4152, 0.714629],
  [4176, 0.703304],
  [4200, 0.691979],
  [4224, 0.680655],
  [4248, 0.671659],
  [4272, 0.662662],
  [4296, 0.653666],
  [4320, 0.64467],
  [4344, 0.635674],
  [4368, 0.627411],
  [4392, 0.619148],
  [4416, 0.610886],
  [4440, 0.602623],
  [4464, 0.59436],
  [4488, 0.58704],
  [4512, 0.579719],
  [4536, 0.572399],
  [4560, 0.565078],
  [4584, 0.557758],
  [4608, 0.54944],
  [4632, 0.541123],
  [4656, 0.532805],
  [4680, 0.524487],
  [4704, 0.51617],
  [4728, 0.5105],
  [4752, 0.504831],
  [4776, 0.499162],
  [4800, 0.493493],
  [4824, 0.487824],
  [4848, 0.481715],
  [4872, 0.475606],
  [4896, 0.469498],
  [4920, 0.463389],
  [4944, 0.45728],
  [4968, 0.450923],
  [4992, 0.444566],
  [5016, 0.438209],
  [5040, 0.431853],
  [5064, 0.425496],
  [5088, 0.419185],
  [5112, 0.412875],
  [5136, 0.406564],
  [5160, 0.400253],
  [5184, 0.393943],
  [5208, 0.390175],
  [5232, 0.386407],
  [5256, 0.38264],
  [5280, 0.378872],
  [5304, 0.375105],
  [5328, 0.368391],
  [5352, 0.361678],
  [5376, 0.354964],
  [5400, 0.34825],
  [5424, 0.341537],
  [5448, 0.337495],
  [5472, 0.333452],
  [5496, 0.32941],
  [5520, 0.325368],
  [5544, 0.321326],
  [5568, 0.317601],
  [5592, 0.313877],
  [5616, 0.310153],
  [5640, 0.306428],
  [5664, 0.302704],
  [5688, 0.298696],
  [5712, 0.294688],
  [5736, 0.290679],
  [5760, 0.286671],
  [5784, 0.282663],
  [5808, 0.278809],
  [5832, 0.274956],
  [5856, 0.271102],
  [5880, 0.267249],
  [5904, 0.263395],
  [5928, 0.258655],
  [5952, 0.253914],
  [5976, 0.249173],
  [6000, 0.244433],
  [6024, 0.239692],
  [6048, 0.237258],
  [6072, 0.234825],
  [6096, 0.232391],
  [6120, 0.229958],
  [6144, 0.227524],
  [6168, 0.224874],
  [6192, 0.222224],
  [6216, 0.219574],
  [6240, 0.216924],
  [6264, 0.214274],
  [6288, 0.210963],
  [6312, 0.207653],
  [6336, 0.204342],
  [6360, 0.201031],
  [6384, 0.197721],
  [6408, 0.195377],
  [6432, 0.193033],
  [6456, 0.190689],
  [6480, 0.188345],
  [6504, 0.186002],
  [6528, 0.183286],
  [6552, 0.18057],
  [6576, 0.177855],
  [6600, 0.175139],
  [6624, 0.172424],
  [6648, 0.170634],
  [6672, 0.168844],
  [6696, 0.167054],
  [6720, 0.165264],
  [6744, 0.163474],
  [6768, 0.160954],
  [6792, 0.158435],
  [6816, 0.155915],
  [6840, 0.153395],
  [6864, 0.150876],
  [6888, 0.149278],
  [6912, 0.14768],
  [6936, 0.146083],
  [6960, 0.144485],
  [6984, 0.142887],
  [7008, 0.140767],
  [7032, 0.138646],
  [7056, 0.136526],
  [7080, 0.134405],
  [7104, 0.132285],
  [7128, 0.130121],
  [7152, 0.127958],
  [7176, 0.125795],
  [7200, 0.123632],
  [7224, 0.121469],
  [7248, 0.119949],
  [7272, 0.118429],
  [7296, 0.116909],
  [7320, 0.11539],
  [7344, 0.11387],
  [7368, 0.112319],
  [7392, 0.110769],
  [7416, 0.109219],
  [7440, 0.107669],
  [7464, 0.106118],
  [7488, 0.104599],
  [7512, 0.103079],
  [7536, 0.101559],
  [7560, 0.100039],
  [7584, 0.0985196],
  [7608, 0.0969556],
  [7632, 0.0953917],
  [7656, 0.0938277],
  [7680, 0.0922638],
  [7704, 0.0906998],
  [7728, 0.0898099],
  [7752, 0.0889201],
  [7776, 0.0880302],
  [7800, 0.0871403],
  [7824, 0.0862505],
  [7848, 0.0848797],
  [7872, 0.0835089],
  [7896, 0.0821381],
  [7920, 0.0807673],
  [7944, 0.0793966],
  [7968, 0.0781464],
  [7992, 0.0768963],
  [8016, 0.0756461],
  [8040, 0.074396],
  [8064, 0.0731458],
  [8088, 0.0724407],
  [8112, 0.0717356],
  [8136, 0.0710305],
  [8160, 0.0703254],
  [8184, 0.0696202],
  [8208, 0.0684524],
  [8232, 0.0672845],
  [8256, 0.0661167],
  [8280, 0.0649488],
  [8304, 0.063781],
  [8328, 0.0631686],
  [8352, 0.0625562],
  [8376, 0.0619437],
  [8400, 0.0613313],
  [8424, 0.0607189],
  [8448, 0.0597149],
  [8472, 0.0587108],
  [8496, 0.0577067],
  [8520, 0.0567027],
  [8544, 0.0556986],
  [8568, 0.0550804],
  [8592, 0.0544621],
  [8616, 0.0538438],
  [8640, 0.0532256],
  [8664, 0.0526073],
  [8688, 0.0517984],
  [8712, 0.0509895],
  [8736, 0.0501805],
  [8760, 0.0493716],
  [8784, 0.0485627],
  [8808, 0.0478916],
  [8832, 0.0472205],
  [8856, 0.0465494],
  [8880, 0.0458783],
  [8904, 0.0452072],
];
drugLevel['RI:Deltoid:156mg'] = [
  [0, 0],
  [24, 4.63947],
  [48, 7.57157],
  [72, 9.62631],
  [96, 11.0574],
  [120, 12.0975],
  [144, 12.8776],
  [168, 13.6577],
  [192, 14.1126],
  [216, 14.5676],
  [240, 14.8596],
  [264, 15.1515],
  [288, 15.3146],
  [312, 15.4777],
  [336, 14.3553],
  [360, 13.2328],
  [384, 12.5858],
  [408, 11.9389],
  [432, 11.507],
  [456, 11.0751],
  [480, 10.6817],
  [504, 10.2882],
  [528, 10.0486],
  [552, 9.809],
  [576, 9.57821],
  [600, 9.34742],
  [624, 9.05811],
  [648, 8.7688],
  [672, 8.68628],
  [696, 8.60377],
  [720, 8.38864],
  [744, 8.17352],
  [768, 8.09821],
  [792, 8.0229],
  [816, 7.87371],
  [840, 7.72452],
  [864, 7.59131],
  [888, 7.4581],
  [912, 7.29401],
  [936, 7.12992],
  [960, 7.01385],
  [984, 6.89778],
  [1008, 6.78483],
  [1032, 6.67187],
  [1056, 6.61072],
  [1080, 6.54957],
  [1104, 6.42306],
  [1128, 6.29654],
  [1152, 6.18674],
  [1176, 6.07693],
  [1200, 5.97301],
  [1224, 5.86909],
  [1248, 5.76759],
  [1272, 5.66609],
  [1296, 5.61361],
  [1320, 5.56112],
  [1344, 5.44569],
  [1368, 5.33026],
  [1392, 5.24788],
  [1416, 5.16549],
  [1440, 5.08866],
  [1464, 5.01182],
  [1488, 4.93874],
  [1512, 4.86565],
  [1536, 4.78331],
  [1560, 4.70097],
  [1584, 4.61864],
  [1608, 4.55878],
  [1632, 4.49891],
  [1656, 4.43905],
  [1680, 4.37919],
  [1704, 4.31933],
  [1728, 4.24854],
  [1752, 4.17775],
  [1776, 4.10696],
  [1800, 4.03616],
  [1824, 3.96537],
  [1848, 3.91324],
  [1872, 3.8611],
  [1896, 3.80896],
  [1920, 3.75683],
  [1944, 3.70469],
  [1968, 3.65005],
  [1992, 3.59542],
  [2016, 3.54078],
  [2040, 3.48615],
  [2064, 3.43151],
  [2088, 3.38313],
  [2112, 3.33475],
  [2136, 3.28637],
  [2160, 3.23799],
  [2184, 3.18961],
  [2208, 3.14935],
  [2232, 3.10908],
  [2256, 3.06882],
  [2280, 3.02855],
  [2304, 2.98829],
  [2328, 2.9456],
  [2352, 2.90292],
  [2376, 2.86023],
  [2400, 2.81754],
  [2424, 2.77486],
  [2448, 2.73867],
  [2472, 2.70247],
  [2496, 2.66628],
  [2520, 2.63009],
  [2544, 2.59389],
  [2568, 2.55463],
  [2592, 2.51536],
  [2616, 2.47609],
  [2640, 2.43682],
  [2664, 2.39756],
  [2688, 2.36749],
  [2712, 2.33742],
  [2736, 2.30735],
  [2760, 2.27728],
  [2784, 2.24721],
  [2808, 2.21658],
  [2832, 2.18596],
  [2856, 2.15533],
  [2880, 2.1247],
  [2904, 2.09407],
  [2928, 2.067],
  [2952, 2.03993],
  [2976, 2.01286],
  [3000, 1.98579],
  [3024, 1.95872],
  [3048, 1.93541],
  [3072, 1.9121],
  [3096, 1.8888],
  [3120, 1.86549],
  [3144, 1.84218],
  [3168, 1.81903],
  [3192, 1.79588],
  [3216, 1.77273],
  [3240, 1.74959],
  [3264, 1.72644],
  [3288, 1.7044],
  [3312, 1.68236],
  [3336, 1.66033],
  [3360, 1.63829],
  [3384, 1.61625],
  [3408, 1.59816],
  [3432, 1.58007],
  [3456, 1.56198],
  [3480, 1.54389],
  [3504, 1.5258],
  [3528, 1.5034],
  [3552, 1.481],
  [3576, 1.4586],
  [3600, 1.4362],
  [3624, 1.4138],
  [3648, 1.39734],
  [3672, 1.38089],
  [3696, 1.36443],
  [3720, 1.34797],
  [3744, 1.33152],
  [3768, 1.31211],
  [3792, 1.2927],
  [3816, 1.2733],
  [3840, 1.25389],
  [3864, 1.23448],
  [3888, 1.22193],
  [3912, 1.20937],
  [3936, 1.19682],
  [3960, 1.18426],
  [3984, 1.17171],
  [4008, 1.15836],
  [4032, 1.14501],
  [4056, 1.13166],
  [4080, 1.11831],
  [4104, 1.10497],
  [4128, 1.09113],
  [4152, 1.0773],
  [4176, 1.06347],
  [4200, 1.04963],
  [4224, 1.0358],
  [4248, 1.02523],
  [4272, 1.01466],
  [4296, 1.00408],
  [4320, 0.993514],
  [4344, 0.982942],
  [4368, 0.970269],
  [4392, 0.957596],
  [4416, 0.944923],
  [4440, 0.93225],
  [4464, 0.919577],
  [4488, 0.908115],
  [4512, 0.896653],
  [4536, 0.885191],
  [4560, 0.873729],
  [4584, 0.862267],
  [4608, 0.852208],
  [4632, 0.842149],
  [4656, 0.83209],
  [4680, 0.822031],
  [4704, 0.811972],
  [4728, 0.80418],
  [4752, 0.796388],
  [4776, 0.788596],
  [4800, 0.780804],
  [4824, 0.773013],
  [4848, 0.763948],
  [4872, 0.754884],
  [4896, 0.745819],
  [4920, 0.736755],
  [4944, 0.72769],
  [4968, 0.718467],
  [4992, 0.709244],
  [5016, 0.700021],
  [5040, 0.690797],
  [5064, 0.681574],
  [5088, 0.672574],
  [5112, 0.663573],
  [5136, 0.654572],
  [5160, 0.645571],
  [5184, 0.636571],
  [5208, 0.630859],
  [5232, 0.625147],
  [5256, 0.619436],
  [5280, 0.613724],
  [5304, 0.608012],
  [5328, 0.598663],
  [5352, 0.589314],
  [5376, 0.579965],
  [5400, 0.570615],
  [5424, 0.561266],
  [5448, 0.554544],
  [5472, 0.547822],
  [5496, 0.5411],
  [5520, 0.534378],
  [5544, 0.527656],
  [5568, 0.520987],
  [5592, 0.514318],
  [5616, 0.507649],
  [5640, 0.50098],
  [5664, 0.494311],
  [5688, 0.489588],
  [5712, 0.484866],
  [5736, 0.480143],
  [5760, 0.47542],
  [5784, 0.470697],
  [5808, 0.464709],
  [5832, 0.458721],
  [5856, 0.452734],
  [5880, 0.446746],
  [5904, 0.440758],
  [5928, 0.434624],
  [5952, 0.42849],
  [5976, 0.422357],
  [6000, 0.416223],
  [6024, 0.410089],
  [6048, 0.406459],
  [6072, 0.402829],
  [6096, 0.399199],
  [6120, 0.395569],
  [6144, 0.391939],
  [6168, 0.387953],
  [6192, 0.383968],
  [6216, 0.379982],
  [6240, 0.375997],
  [6264, 0.372012],
  [6288, 0.366019],
  [6312, 0.360027],
  [6336, 0.354034],
  [6360, 0.348042],
  [6384, 0.342049],
  [6408, 0.33813],
  [6432, 0.334211],
  [6456, 0.330291],
  [6480, 0.326372],
  [6504, 0.322452],
  [6528, 0.31846],
  [6552, 0.314468],
  [6576, 0.310476],
  [6600, 0.306484],
  [6624, 0.302492],
  [6648, 0.299421],
  [6672, 0.29635],
  [6696, 0.293278],
  [6720, 0.290207],
  [6744, 0.287136],
  [6768, 0.283227],
  [6792, 0.279317],
  [6816, 0.275408],
  [6840, 0.271499],
  [6864, 0.26759],
  [6888, 0.265353],
  [6912, 0.263116],
  [6936, 0.260878],
  [6960, 0.258641],
  [6984, 0.256404],
  [7008, 0.252742],
  [7032, 0.249079],
  [7056, 0.245417],
  [7080, 0.241754],
  [7104, 0.238092],
  [7128, 0.234668],
  [7152, 0.231245],
  [7176, 0.227822],
  [7200, 0.224399],
  [7224, 0.220976],
  [7248, 0.218407],
  [7272, 0.215837],
  [7296, 0.213268],
  [7320, 0.210698],
  [7344, 0.208128],
  [7368, 0.205466],
  [7392, 0.202803],
  [7416, 0.200141],
  [7440, 0.197478],
  [7464, 0.194816],
  [7488, 0.192408],
  [7512, 0.19],
  [7536, 0.187591],
  [7560, 0.185183],
  [7584, 0.182775],
  [7608, 0.180358],
  [7632, 0.177941],
  [7656, 0.175525],
  [7680, 0.173108],
  [7704, 0.170691],
  [7728, 0.169359],
  [7752, 0.168027],
  [7776, 0.166695],
  [7800, 0.165363],
  [7824, 0.164031],
  [7848, 0.161591],
  [7872, 0.15915],
  [7896, 0.15671],
  [7920, 0.154269],
  [7944, 0.151829],
  [7968, 0.14977],
  [7992, 0.147712],
  [8016, 0.145653],
  [8040, 0.143595],
  [8064, 0.141536],
  [8088, 0.140394],
  [8112, 0.139251],
  [8136, 0.138108],
  [8160, 0.136966],
  [8184, 0.135823],
  [8208, 0.133523],
  [8232, 0.131222],
  [8256, 0.128921],
  [8280, 0.12662],
  [8304, 0.12432],
  [8328, 0.123414],
  [8352, 0.122508],
  [8376, 0.121603],
  [8400, 0.120697],
  [8424, 0.119792],
  [8448, 0.117976],
  [8472, 0.116161],
  [8496, 0.114345],
  [8520, 0.11253],
  [8544, 0.110715],
  [8568, 0.109551],
  [8592, 0.108387],
  [8616, 0.107223],
  [8640, 0.106059],
  [8664, 0.104895],
  [8688, 0.103466],
  [8712, 0.102038],
  [8736, 0.10061],
  [8760, 0.0991822],
  [8784, 0.0977541],
  [8808, 0.0963288],
  [8832, 0.0949034],
  [8856, 0.093478],
  [8880, 0.0920526],
  [8904, 0.0906273],
];
drugLevel['RI:Deltoid:234mg'] = [
  [0, 0],
  [24, 6.22766],
  [48, 10.1553],
  [72, 12.9087],
  [96, 14.7996],
  [120, 16.1448],
  [144, 17.2041],
  [168, 18.2634],
  [192, 18.8475],
  [216, 19.4316],
  [240, 19.8839],
  [264, 20.3361],
  [288, 20.4981],
  [312, 20.66],
  [336, 19.1998],
  [360, 17.7396],
  [384, 16.8939],
  [408, 16.0483],
  [432, 15.4767],
  [456, 14.905],
  [480, 14.4144],
  [504, 13.9238],
  [528, 13.6321],
  [552, 13.3404],
  [576, 13.038],
  [600, 12.7356],
  [624, 12.381],
  [648, 12.0263],
  [672, 11.9043],
  [696, 11.7823],
  [720, 11.5491],
  [744, 11.3158],
  [768, 11.1921],
  [792, 11.0685],
  [816, 10.8776],
  [840, 10.6867],
  [864, 10.5678],
  [888, 10.4489],
  [912, 10.2351],
  [936, 10.0212],
  [960, 9.87211],
  [984, 9.72306],
  [1008, 9.58788],
  [1032, 9.4527],
  [1056, 9.40801],
  [1080, 9.36332],
  [1104, 9.19146],
  [1128, 9.0196],
  [1152, 8.86057],
  [1176, 8.70154],
  [1200, 8.57507],
  [1224, 8.44859],
  [1248, 8.34018],
  [1272, 8.23176],
  [1296, 8.15874],
  [1320, 8.08572],
  [1344, 7.93653],
  [1368, 7.78734],
  [1392, 7.70948],
  [1416, 7.63162],
  [1440, 7.53852],
  [1464, 7.44543],
  [1488, 7.32062],
  [1512, 7.19582],
  [1536, 7.0905],
  [1560, 6.98519],
  [1584, 6.87987],
  [1608, 6.80507],
  [1632, 6.73026],
  [1656, 6.65545],
  [1680, 6.58065],
  [1704, 6.50584],
  [1728, 6.4076],
  [1752, 6.30937],
  [1776, 6.21113],
  [1800, 6.1129],
  [1824, 6.01466],
  [1848, 5.94008],
  [1872, 5.86551],
  [1896, 5.79093],
  [1920, 5.71636],
  [1944, 5.64178],
  [1968, 5.57374],
  [1992, 5.50569],
  [2016, 5.43765],
  [2040, 5.3696],
  [2064, 5.30155],
  [2088, 5.22671],
  [2112, 5.15187],
  [2136, 5.07703],
  [2160, 5.00219],
  [2184, 4.92734],
  [2208, 4.87023],
  [2232, 4.81312],
  [2256, 4.75601],
  [2280, 4.6989],
  [2304, 4.64179],
  [2328, 4.5845],
  [2352, 4.52721],
  [2376, 4.46991],
  [2400, 4.41262],
  [2424, 4.35533],
  [2448, 4.3037],
  [2472, 4.25207],
  [2496, 4.20044],
  [2520, 4.14881],
  [2544, 4.09718],
  [2568, 4.04041],
  [2592, 3.98364],
  [2616, 3.92687],
  [2640, 3.87009],
  [2664, 3.81332],
  [2688, 3.77533],
  [2712, 3.73733],
  [2736, 3.69934],
  [2760, 3.66135],
  [2784, 3.62335],
  [2808, 3.57423],
  [2832, 3.5251],
  [2856, 3.47597],
  [2880, 3.42684],
  [2904, 3.37772],
  [2928, 3.34003],
  [2952, 3.30235],
  [2976, 3.26466],
  [3000, 3.22698],
  [3024, 3.1893],
  [3048, 3.15558],
  [3072, 3.12187],
  [3096, 3.08816],
  [3120, 3.05445],
  [3144, 3.02073],
  [3168, 2.98532],
  [3192, 2.94991],
  [3216, 2.9145],
  [3240, 2.87909],
  [3264, 2.84368],
  [3288, 2.81017],
  [3312, 2.77666],
  [3336, 2.74315],
  [3360, 2.70964],
  [3384, 2.67613],
  [3408, 2.64905],
  [3432, 2.62198],
  [3456, 2.5949],
  [3480, 2.56783],
  [3504, 2.54075],
  [3528, 2.50977],
  [3552, 2.4788],
  [3576, 2.44782],
  [3600, 2.41684],
  [3624, 2.38586],
  [3648, 2.36288],
  [3672, 2.3399],
  [3696, 2.31692],
  [3720, 2.29394],
  [3744, 2.27095],
  [3768, 2.24466],
  [3792, 2.21836],
  [3816, 2.19207],
  [3840, 2.16577],
  [3864, 2.13947],
  [3888, 2.11588],
  [3912, 2.09229],
  [3936, 2.0687],
  [3960, 2.04511],
  [3984, 2.02152],
  [4008, 2.00191],
  [4032, 1.98231],
  [4056, 1.9627],
  [4080, 1.94309],
  [4104, 1.92349],
  [4128, 1.90279],
  [4152, 1.88209],
  [4176, 1.86139],
  [4200, 1.84069],
  [4224, 1.81999],
  [4248, 1.80201],
  [4272, 1.78403],
  [4296, 1.76605],
  [4320, 1.74806],
  [4344, 1.73008],
  [4368, 1.71048],
  [4392, 1.69087],
  [4416, 1.67127],
  [4440, 1.65166],
  [4464, 1.63206],
  [4488, 1.61746],
  [4512, 1.60286],
  [4536, 1.58826],
  [4560, 1.57367],
  [4584, 1.55907],
  [4608, 1.54019],
  [4632, 1.52131],
  [4656, 1.50243],
  [4680, 1.48355],
  [4704, 1.46467],
  [4728, 1.45184],
  [4752, 1.43901],
  [4776, 1.42619],
  [4800, 1.41336],
  [4824, 1.40053],
  [4848, 1.38778],
  [4872, 1.37503],
  [4896, 1.36228],
  [4920, 1.34953],
  [4944, 1.33677],
  [4968, 1.32329],
  [4992, 1.30981],
  [5016, 1.29633],
  [5040, 1.28285],
  [5064, 1.26937],
  [5088, 1.25463],
  [5112, 1.2399],
  [5136, 1.22516],
  [5160, 1.21042],
  [5184, 1.19569],
  [5208, 1.18343],
  [5232, 1.17118],
  [5256, 1.15893],
  [5280, 1.14667],
  [5304, 1.13442],
  [5328, 1.12031],
  [5352, 1.10621],
  [5376, 1.09211],
  [5400, 1.078],
  [5424, 1.0639],
  [5448, 1.05321],
  [5472, 1.04253],
  [5496, 1.03185],
  [5520, 1.02116],
  [5544, 1.01048],
  [5568, 1.0007],
  [5592, 0.990912],
  [5616, 0.981128],
  [5640, 0.971344],
  [5664, 0.96156],
  [5688, 0.953105],
  [5712, 0.94465],
  [5736, 0.936195],
  [5760, 0.927739],
  [5784, 0.919284],
  [5808, 0.908292],
  [5832, 0.897299],
  [5856, 0.886307],
  [5880, 0.875314],
  [5904, 0.864322],
  [5928, 0.854658],
  [5952, 0.844993],
  [5976, 0.835329],
  [6000, 0.825665],
  [6024, 0.816001],
  [6048, 0.807436],
  [6072, 0.79887],
  [6096, 0.790305],
  [6120, 0.78174],
  [6144, 0.773175],
  [6168, 0.766729],
  [6192, 0.760283],
  [6216, 0.753836],
  [6240, 0.74739],
  [6264, 0.740944],
  [6288, 0.732994],
  [6312, 0.725044],
  [6336, 0.717094],
  [6360, 0.709144],
  [6384, 0.701194],
  [6408, 0.694522],
  [6432, 0.687849],
  [6456, 0.681176],
  [6480, 0.674504],
  [6504, 0.667831],
  [6528, 0.659228],
  [6552, 0.650625],
  [6576, 0.642021],
  [6600, 0.633418],
  [6624, 0.624815],
  [6648, 0.619432],
  [6672, 0.614049],
  [6696, 0.608667],
  [6720, 0.603284],
  [6744, 0.597901],
  [6768, 0.590743],
  [6792, 0.583584],
  [6816, 0.576426],
  [6840, 0.569267],
  [6864, 0.562109],
  [6888, 0.558344],
  [6912, 0.554579],
  [6936, 0.550815],
  [6960, 0.54705],
  [6984, 0.543286],
  [7008, 0.536001],
  [7032, 0.528715],
  [7056, 0.52143],
  [7080, 0.514145],
  [7104, 0.50686],
  [7128, 0.502389],
  [7152, 0.497918],
  [7176, 0.493446],
  [7200, 0.488975],
  [7224, 0.484504],
  [7248, 0.478294],
  [7272, 0.472084],
  [7296, 0.465874],
  [7320, 0.459664],
  [7344, 0.453455],
  [7368, 0.448134],
  [7392, 0.442813],
  [7416, 0.437493],
  [7440, 0.432172],
  [7464, 0.426851],
  [7488, 0.423805],
  [7512, 0.420759],
  [7536, 0.417714],
  [7560, 0.414668],
  [7584, 0.411622],
  [7608, 0.406544],
  [7632, 0.401466],
  [7656, 0.396387],
  [7680, 0.391309],
  [7704, 0.386231],
  [7728, 0.383554],
  [7752, 0.380877],
  [7776, 0.3782],
  [7800, 0.375523],
  [7824, 0.372846],
  [7848, 0.368187],
  [7872, 0.363529],
  [7896, 0.35887],
  [7920, 0.354212],
  [7944, 0.349553],
  [7968, 0.345534],
  [7992, 0.341516],
  [8016, 0.337497],
  [8040, 0.333479],
  [8064, 0.32946],
  [8088, 0.326184],
  [8112, 0.322908],
  [8136, 0.319631],
  [8160, 0.316355],
  [8184, 0.313079],
  [8208, 0.30935],
  [8232, 0.305621],
  [8256, 0.301892],
  [8280, 0.298163],
  [8304, 0.294434],
  [8328, 0.291652],
  [8352, 0.28887],
  [8376, 0.286087],
  [8400, 0.283305],
  [8424, 0.280523],
  [8448, 0.277361],
  [8472, 0.2742],
  [8496, 0.271038],
  [8520, 0.267877],
  [8544, 0.264715],
  [8568, 0.262264],
  [8592, 0.259813],
  [8616, 0.257363],
  [8640, 0.254912],
  [8664, 0.252461],
  [8688, 0.249649],
  [8712, 0.246837],
  [8736, 0.244025],
  [8760, 0.241213],
  [8784, 0.238401],
  [8808, 0.235062],
  [8832, 0.231723],
  [8856, 0.228385],
  [8880, 0.225046],
  [8904, 0.221707],
];

drugLevel['RI:Gluteal:39mg'] = [
  [0, 0],
  [24, 1.25487],
  [48, 2.04855],
  [72, 2.59776],
  [96, 2.98709],
  [120, 3.2792],
  [144, 3.4748],
  [168, 3.6704],
  [192, 3.80642],
  [216, 3.94244],
  [240, 4.01021],
  [264, 4.07797],
  [288, 4.13378],
  [312, 4.18958],
  [336, 4.0024],
  [360, 3.81523],
  [384, 3.68662],
  [408, 3.55801],
  [432, 3.45961],
  [456, 3.36121],
  [480, 3.25355],
  [504, 3.1459],
  [528, 3.08649],
  [552, 3.02708],
  [576, 2.94008],
  [600, 2.85308],
  [624, 2.76185],
  [648, 2.67062],
  [672, 2.63224],
  [696, 2.59387],
  [720, 2.52646],
  [744, 2.45906],
  [768, 2.41859],
  [792, 2.37812],
  [816, 2.3245],
  [840, 2.27089],
  [864, 2.2214],
  [888, 2.17192],
  [912, 2.10881],
  [936, 2.0457],
  [960, 2.00614],
  [984, 1.96659],
  [1008, 1.9268],
  [1032, 1.887],
  [1056, 1.87051],
  [1080, 1.85401],
  [1104, 1.79695],
  [1128, 1.73989],
  [1152, 1.70048],
  [1176, 1.66107],
  [1200, 1.63012],
  [1224, 1.59918],
  [1248, 1.56582],
  [1272, 1.53245],
  [1296, 1.50746],
  [1320, 1.48247],
  [1344, 1.4419],
  [1368, 1.40133],
  [1392, 1.37352],
  [1416, 1.34571],
  [1440, 1.31788],
  [1464, 1.29006],
  [1488, 1.27142],
  [1512, 1.25279],
  [1536, 1.22746],
  [1560, 1.20213],
  [1584, 1.1768],
  [1608, 1.1584],
  [1632, 1.14],
  [1656, 1.12161],
  [1680, 1.10321],
  [1704, 1.08481],
  [1728, 1.06116],
  [1752, 1.03752],
  [1776, 1.01387],
  [1800, 0.990227],
  [1824, 0.966581],
  [1848, 0.950284],
  [1872, 0.933987],
  [1896, 0.91769],
  [1920, 0.901392],
  [1944, 0.885095],
  [1968, 0.86968],
  [1992, 0.854265],
  [2016, 0.838849],
  [2040, 0.823434],
  [2064, 0.808019],
  [2088, 0.793921],
  [2112, 0.779823],
  [2136, 0.765725],
  [2160, 0.751627],
  [2184, 0.737529],
  [2208, 0.725547],
  [2232, 0.713565],
  [2256, 0.701583],
  [2280, 0.689601],
  [2304, 0.677619],
  [2328, 0.664456],
  [2352, 0.651294],
  [2376, 0.638131],
  [2400, 0.624969],
  [2424, 0.611806],
  [2448, 0.603882],
  [2472, 0.595958],
  [2496, 0.588034],
  [2520, 0.580111],
  [2544, 0.572187],
  [2568, 0.561624],
  [2592, 0.551062],
  [2616, 0.540499],
  [2640, 0.529937],
  [2664, 0.519375],
  [2688, 0.510535],
  [2712, 0.501695],
  [2736, 0.492855],
  [2760, 0.484016],
  [2784, 0.475176],
  [2808, 0.468115],
  [2832, 0.461055],
  [2856, 0.453994],
  [2880, 0.446933],
  [2904, 0.439873],
  [2928, 0.43281],
  [2952, 0.425747],
  [2976, 0.418684],
  [3000, 0.411621],
  [3024, 0.404558],
  [3048, 0.397684],
  [3072, 0.390809],
  [3096, 0.383935],
  [3120, 0.37706],
  [3144, 0.370186],
  [3168, 0.364217],
  [3192, 0.358249],
  [3216, 0.35228],
  [3240, 0.346312],
  [3264, 0.340343],
  [3288, 0.334666],
  [3312, 0.328988],
  [3336, 0.32331],
  [3360, 0.317632],
  [3384, 0.311954],
  [3408, 0.307759],
  [3432, 0.303564],
  [3456, 0.29937],
  [3480, 0.295175],
  [3504, 0.29098],
  [3528, 0.285311],
  [3552, 0.279641],
  [3576, 0.273972],
  [3600, 0.268302],
  [3624, 0.262632],
  [3648, 0.25871],
  [3672, 0.254789],
  [3696, 0.250867],
  [3720, 0.246945],
  [3744, 0.243023],
  [3768, 0.238675],
  [3792, 0.234328],
  [3816, 0.229981],
  [3840, 0.225633],
  [3864, 0.221286],
  [3888, 0.218855],
  [3912, 0.216423],
  [3936, 0.213992],
  [3960, 0.211561],
  [3984, 0.20913],
  [4008, 0.205825],
  [4032, 0.202521],
  [4056, 0.199217],
  [4080, 0.195912],
  [4104, 0.192608],
  [4128, 0.188928],
  [4152, 0.185248],
  [4176, 0.181568],
  [4200, 0.177889],
  [4224, 0.174209],
  [4248, 0.171889],
  [4272, 0.169569],
  [4296, 0.16725],
  [4320, 0.16493],
  [4344, 0.16261],
  [4368, 0.160411],
  [4392, 0.158213],
  [4416, 0.156014],
  [4440, 0.153815],
  [4464, 0.151617],
  [4488, 0.148786],
  [4512, 0.145955],
  [4536, 0.143124],
  [4560, 0.140294],
  [4584, 0.137463],
  [4608, 0.13517],
  [4632, 0.132877],
  [4656, 0.130583],
  [4680, 0.12829],
  [4704, 0.125997],
  [4728, 0.124406],
  [4752, 0.122815],
  [4776, 0.121225],
  [4800, 0.119634],
  [4824, 0.118044],
  [4848, 0.116055],
  [4872, 0.114066],
  [4896, 0.112077],
  [4920, 0.110089],
  [4944, 0.1081],
  [4968, 0.10641],
  [4992, 0.10472],
  [5016, 0.103029],
  [5040, 0.101339],
  [5064, 0.0996491],
  [5088, 0.0978284],
  [5112, 0.0960078],
  [5136, 0.0941871],
  [5160, 0.0923664],
  [5184, 0.0905458],
  [5208, 0.0893164],
  [5232, 0.088087],
  [5256, 0.0868577],
  [5280, 0.0856283],
  [5304, 0.084399],
  [5328, 0.0828969],
  [5352, 0.0813948],
  [5376, 0.0798926],
  [5400, 0.0783905],
  [5424, 0.0768884],
  [5448, 0.0759423],
  [5472, 0.0749962],
  [5496, 0.07405],
  [5520, 0.0731039],
  [5544, 0.0721578],
  [5568, 0.0705541],
  [5592, 0.0689503],
  [5616, 0.0673466],
  [5640, 0.0657429],
  [5664, 0.0641392],
  [5688, 0.0633091],
  [5712, 0.062479],
  [5736, 0.0616489],
  [5760, 0.0608188],
  [5784, 0.0599886],
  [5808, 0.0591106],
  [5832, 0.0582326],
  [5856, 0.0573545],
  [5880, 0.0564765],
  [5904, 0.0555984],
  [5928, 0.0545423],
  [5952, 0.0534861],
  [5976, 0.05243],
  [6000, 0.0513738],
  [6024, 0.0503176],
  [6048, 0.049556],
  [6072, 0.0487943],
  [6096, 0.0480327],
  [6120, 0.0472711],
  [6144, 0.0465094],
  [6168, 0.0458192],
  [6192, 0.045129],
  [6216, 0.0444388],
  [6240, 0.0437485],
  [6264, 0.0430583],
  [6288, 0.0423022],
  [6312, 0.041546],
  [6336, 0.0407899],
  [6360, 0.0400338],
  [6384, 0.0392776],
  [6408, 0.0387108],
  [6432, 0.0381439],
  [6456, 0.037577],
  [6480, 0.0370101],
  [6504, 0.0364432],
  [6528, 0.0356892],
  [6552, 0.0349352],
  [6576, 0.0341812],
  [6600, 0.0334272],
  [6624, 0.0326732],
  [6648, 0.032289],
  [6672, 0.0319047],
  [6696, 0.0315205],
  [6720, 0.0311362],
  [6744, 0.030752],
  [6768, 0.0302928],
  [6792, 0.0298336],
  [6816, 0.0293743],
  [6840, 0.0289151],
  [6864, 0.0284559],
  [6888, 0.0279349],
  [6912, 0.027414],
  [6936, 0.026893],
  [6960, 0.0263721],
  [6984, 0.0258512],
  [7008, 0.0253849],
  [7032, 0.0249187],
  [7056, 0.0244525],
  [7080, 0.0239863],
  [7104, 0.0235201],
  [7128, 0.0231472],
  [7152, 0.0227742],
  [7176, 0.0224013],
  [7200, 0.0220283],
  [7224, 0.0216554],
  [7248, 0.0213168],
  [7272, 0.0209782],
  [7296, 0.0206397],
  [7320, 0.0203011],
  [7344, 0.0199625],
  [7368, 0.0195627],
  [7392, 0.019163],
  [7416, 0.0187632],
  [7440, 0.0183635],
  [7464, 0.0179637],
  [7488, 0.0176743],
  [7512, 0.0173848],
  [7536, 0.0170953],
  [7560, 0.0168059],
  [7584, 0.0165164],
  [7608, 0.0162111],
  [7632, 0.0159057],
  [7656, 0.0156003],
  [7680, 0.0152949],
  [7704, 0.0149896],
  [7728, 0.0147961],
  [7752, 0.0146027],
  [7776, 0.0144093],
  [7800, 0.0142158],
  [7824, 0.0140224],
  [7848, 0.0137644],
  [7872, 0.0135064],
  [7896, 0.0132484],
  [7920, 0.0129905],
  [7944, 0.0127325],
  [7968, 0.0125131],
  [7992, 0.0122937],
  [8016, 0.0120742],
  [8040, 0.0118548],
  [8064, 0.0116354],
  [8088, 0.0114503],
  [8112, 0.0112652],
  [8136, 0.01108],
  [8160, 0.0108949],
  [8184, 0.0107098],
  [8208, 0.0105297],
  [8232, 0.0103497],
  [8256, 0.0101696],
  [8280, 0.00998958],
  [8304, 0.00980954],
  [8328, 0.00968093],
  [8352, 0.00955232],
  [8376, 0.00942371],
  [8400, 0.00929511],
  [8424, 0.0091665],
  [8448, 0.00897602],
  [8472, 0.00878555],
  [8496, 0.00859507],
  [8520, 0.0084046],
  [8544, 0.00821413],
  [8568, 0.00807664],
  [8592, 0.00793915],
  [8616, 0.00780166],
  [8640, 0.00766418],
  [8664, 0.00752669],
  [8688, 0.00742956],
  [8712, 0.00733243],
  [8736, 0.0072353],
  [8760, 0.00713817],
  [8784, 0.00704104],
  [8808, 0.00689978],
  [8832, 0.00675853],
  [8856, 0.00661728],
  [8880, 0.00647603],
  [8904, 0.00633478],
];
drugLevel['RI:Gluteal:78mg'] = [
  [0, 0],
  [24, 2.07075],
  [48, 3.37198],
  [72, 4.27208],
  [96, 4.90669],
  [120, 5.35859],
  [144, 5.70835],
  [168, 6.05812],
  [192, 6.24897],
  [216, 6.43982],
  [240, 6.57827],
  [264, 6.71672],
  [288, 6.79039],
  [312, 6.86407],
  [336, 6.58724],
  [360, 6.31041],
  [384, 6.12161],
  [408, 5.93282],
  [432, 5.78336],
  [456, 5.63389],
  [480, 5.47329],
  [504, 5.3127],
  [528, 5.22904],
  [552, 5.14538],
  [576, 5.02138],
  [600, 4.89738],
  [624, 4.77377],
  [648, 4.65015],
  [672, 4.59501],
  [696, 4.53988],
  [720, 4.43285],
  [744, 4.32581],
  [768, 4.2779],
  [792, 4.22999],
  [816, 4.15256],
  [840, 4.07512],
  [864, 4.01036],
  [888, 3.9456],
  [912, 3.85521],
  [936, 3.76482],
  [960, 3.70919],
  [984, 3.65355],
  [1008, 3.59461],
  [1032, 3.53566],
  [1056, 3.49737],
  [1080, 3.45907],
  [1104, 3.39458],
  [1128, 3.33009],
  [1152, 3.26913],
  [1176, 3.20817],
  [1200, 3.15269],
  [1224, 3.0972],
  [1248, 3.04066],
  [1272, 2.98411],
  [1296, 2.95485],
  [1320, 2.9256],
  [1344, 2.86151],
  [1368, 2.79743],
  [1392, 2.75388],
  [1416, 2.71033],
  [1440, 2.6697],
  [1464, 2.62907],
  [1488, 2.5909],
  [1512, 2.55272],
  [1536, 2.50704],
  [1560, 2.46136],
  [1584, 2.41568],
  [1608, 2.38221],
  [1632, 2.34875],
  [1656, 2.31529],
  [1680, 2.28182],
  [1704, 2.24836],
  [1728, 2.21197],
  [1752, 2.17558],
  [1776, 2.1392],
  [1800, 2.10281],
  [1824, 2.06642],
  [1848, 2.03683],
  [1872, 2.00723],
  [1896, 1.97763],
  [1920, 1.94803],
  [1944, 1.91844],
  [1968, 1.89074],
  [1992, 1.86304],
  [2016, 1.83534],
  [2040, 1.80764],
  [2064, 1.77994],
  [2088, 1.75301],
  [2112, 1.72607],
  [2136, 1.69913],
  [2160, 1.6722],
  [2184, 1.64526],
  [2208, 1.62442],
  [2232, 1.60358],
  [2256, 1.58274],
  [2280, 1.5619],
  [2304, 1.54106],
  [2328, 1.51695],
  [2352, 1.49284],
  [2376, 1.46872],
  [2400, 1.44461],
  [2424, 1.4205],
  [2448, 1.40234],
  [2472, 1.38418],
  [2496, 1.36603],
  [2520, 1.34787],
  [2544, 1.32971],
  [2568, 1.30991],
  [2592, 1.29011],
  [2616, 1.27032],
  [2640, 1.25052],
  [2664, 1.23072],
  [2688, 1.21417],
  [2712, 1.19761],
  [2736, 1.18106],
  [2760, 1.16451],
  [2784, 1.14795],
  [2808, 1.13271],
  [2832, 1.11746],
  [2856, 1.10221],
  [2880, 1.08697],
  [2904, 1.07172],
  [2928, 1.05675],
  [2952, 1.04178],
  [2976, 1.02681],
  [3000, 1.01184],
  [3024, 0.996866],
  [3048, 0.984174],
  [3072, 0.971481],
  [3096, 0.958789],
  [3120, 0.946097],
  [3144, 0.933405],
  [3168, 0.922047],
  [3192, 0.910688],
  [3216, 0.89933],
  [3240, 0.887971],
  [3264, 0.876613],
  [3288, 0.865333],
  [3312, 0.854053],
  [3336, 0.842773],
  [3360, 0.831494],
  [3384, 0.820214],
  [3408, 0.809973],
  [3432, 0.799733],
  [3456, 0.789492],
  [3480, 0.779252],
  [3504, 0.769011],
  [3528, 0.757176],
  [3552, 0.745341],
  [3576, 0.733507],
  [3600, 0.721672],
  [3624, 0.709837],
  [3648, 0.701262],
  [3672, 0.692688],
  [3696, 0.684113],
  [3720, 0.675538],
  [3744, 0.666963],
  [3768, 0.657454],
  [3792, 0.647944],
  [3816, 0.638434],
  [3840, 0.628925],
  [3864, 0.619415],
  [3888, 0.613375],
  [3912, 0.607336],
  [3936, 0.601296],
  [3960, 0.595257],
  [3984, 0.589217],
  [4008, 0.582449],
  [4032, 0.575681],
  [4056, 0.568913],
  [4080, 0.562144],
  [4104, 0.555376],
  [4128, 0.547065],
  [4152, 0.538754],
  [4176, 0.530443],
  [4200, 0.522132],
  [4224, 0.513821],
  [4248, 0.508168],
  [4272, 0.502515],
  [4296, 0.496862],
  [4320, 0.491209],
  [4344, 0.485556],
  [4368, 0.479559],
  [4392, 0.473561],
  [4416, 0.467563],
  [4440, 0.461566],
  [4464, 0.455568],
  [4488, 0.450068],
  [4512, 0.444567],
  [4536, 0.439066],
  [4560, 0.433566],
  [4584, 0.428065],
  [4608, 0.421936],
  [4632, 0.415807],
  [4656, 0.409678],
  [4680, 0.40355],
  [4704, 0.397421],
  [4728, 0.393831],
  [4752, 0.39024],
  [4776, 0.38665],
  [4800, 0.38306],
  [4824, 0.37947],
  [4848, 0.375106],
  [4872, 0.370742],
  [4896, 0.366378],
  [4920, 0.362014],
  [4944, 0.35765],
  [4968, 0.352641],
  [4992, 0.347632],
  [5016, 0.342623],
  [5040, 0.337614],
  [5064, 0.332605],
  [5088, 0.328015],
  [5112, 0.323424],
  [5136, 0.318834],
  [5160, 0.314244],
  [5184, 0.309654],
  [5208, 0.30684],
  [5232, 0.304026],
  [5256, 0.301212],
  [5280, 0.298398],
  [5304, 0.295585],
  [5328, 0.290729],
  [5352, 0.285874],
  [5376, 0.281019],
  [5400, 0.276163],
  [5424, 0.271308],
  [5448, 0.268082],
  [5472, 0.264855],
  [5496, 0.261629],
  [5520, 0.258403],
  [5544, 0.255176],
  [5568, 0.252301],
  [5592, 0.249425],
  [5616, 0.246549],
  [5640, 0.243673],
  [5664, 0.240797],
  [5688, 0.237925],
  [5712, 0.235052],
  [5736, 0.23218],
  [5760, 0.229307],
  [5784, 0.226434],
  [5808, 0.223487],
  [5832, 0.220539],
  [5856, 0.217592],
  [5880, 0.214644],
  [5904, 0.211697],
  [5928, 0.208508],
  [5952, 0.205319],
  [5976, 0.202131],
  [6000, 0.198942],
  [6024, 0.195753],
  [6048, 0.1939],
  [6072, 0.192046],
  [6096, 0.190193],
  [6120, 0.188339],
  [6144, 0.186486],
  [6168, 0.184493],
  [6192, 0.182501],
  [6216, 0.180509],
  [6240, 0.178516],
  [6264, 0.176524],
  [6288, 0.173679],
  [6312, 0.170835],
  [6336, 0.16799],
  [6360, 0.165146],
  [6384, 0.162301],
  [6408, 0.160368],
  [6432, 0.158435],
  [6456, 0.156502],
  [6480, 0.154569],
  [6504, 0.152636],
  [6528, 0.150663],
  [6552, 0.148691],
  [6576, 0.146718],
  [6600, 0.144745],
  [6624, 0.142773],
  [6648, 0.141177],
  [6672, 0.139582],
  [6696, 0.137986],
  [6720, 0.136391],
  [6744, 0.134795],
  [6768, 0.132842],
  [6792, 0.130889],
  [6816, 0.128936],
  [6840, 0.126983],
  [6864, 0.12503],
  [6888, 0.124209],
  [6912, 0.123387],
  [6936, 0.122566],
  [6960, 0.121745],
  [6984, 0.120923],
  [7008, 0.118939],
  [7032, 0.116955],
  [7056, 0.114971],
  [7080, 0.112987],
  [7104, 0.111003],
  [7128, 0.1091],
  [7152, 0.107197],
  [7176, 0.105295],
  [7200, 0.103392],
  [7224, 0.101489],
  [7248, 0.100446],
  [7272, 0.0994016],
  [7296, 0.0983577],
  [7320, 0.0973138],
  [7344, 0.0962699],
  [7368, 0.0950401],
  [7392, 0.0938104],
  [7416, 0.0925807],
  [7440, 0.0913509],
  [7464, 0.0901212],
  [7488, 0.0889801],
  [7512, 0.0878391],
  [7536, 0.086698],
  [7560, 0.0855569],
  [7584, 0.0844159],
  [7608, 0.083243],
  [7632, 0.0820701],
  [7656, 0.0808973],
  [7680, 0.0797244],
  [7704, 0.0785516],
  [7728, 0.0778723],
  [7752, 0.077193],
  [7776, 0.0765137],
  [7800, 0.0758345],
  [7824, 0.0751552],
  [7848, 0.0740232],
  [7872, 0.0728911],
  [7896, 0.0717591],
  [7920, 0.0706271],
  [7944, 0.069495],
  [7968, 0.0684843],
  [7992, 0.0674736],
  [8016, 0.0664628],
  [8040, 0.0654521],
  [8064, 0.0644413],
  [8088, 0.0637968],
  [8112, 0.0631523],
  [8136, 0.0625079],
  [8160, 0.0618634],
  [8184, 0.0612189],
  [8208, 0.0602256],
  [8232, 0.0592324],
  [8256, 0.0582392],
  [8280, 0.057246],
  [8304, 0.0562528],
  [8328, 0.0558854],
  [8352, 0.055518],
  [8376, 0.0551506],
  [8400, 0.0547832],
  [8424, 0.0544158],
  [8448, 0.0534672],
  [8472, 0.0525186],
  [8496, 0.0515699],
  [8520, 0.0506213],
  [8544, 0.0496727],
  [8568, 0.0491157],
  [8592, 0.0485587],
  [8616, 0.0480017],
  [8640, 0.0474447],
  [8664, 0.0468877],
  [8688, 0.0461477],
  [8712, 0.0454077],
  [8736, 0.0446677],
  [8760, 0.0439277],
  [8784, 0.0431877],
  [8808, 0.042679],
  [8832, 0.0421703],
  [8856, 0.0416616],
  [8880, 0.0411529],
  [8904, 0.0406442],
];
drugLevel['RI:Gluteal:117mg'] = [
  [0, 0],
  [24, 2.77986],
  [48, 4.51904],
  [72, 5.71103],
  [96, 6.57122],
  [120, 7.14277],
  [144, 7.62072],
  [168, 8.09867],
  [192, 8.34864],
  [216, 8.59861],
  [240, 8.80685],
  [264, 9.01509],
  [288, 9.10339],
  [312, 9.19169],
  [336, 8.81634],
  [360, 8.44099],
  [384, 8.19481],
  [408, 7.94863],
  [432, 7.75707],
  [456, 7.56551],
  [480, 7.36165],
  [504, 7.15778],
  [528, 7.05896],
  [552, 6.96014],
  [576, 6.80768],
  [600, 6.65521],
  [624, 6.5037],
  [648, 6.35219],
  [672, 6.29616],
  [696, 6.24012],
  [720, 6.08974],
  [744, 5.93935],
  [768, 5.88754],
  [792, 5.83573],
  [816, 5.74271],
  [840, 5.64969],
  [864, 5.57939],
  [888, 5.50909],
  [912, 5.40665],
  [936, 5.30421],
  [960, 5.22351],
  [984, 5.14281],
  [1008, 5.07106],
  [1032, 4.99931],
  [1056, 4.95988],
  [1080, 4.92045],
  [1104, 4.83868],
  [1128, 4.75692],
  [1152, 4.67557],
  [1176, 4.59422],
  [1200, 4.52099],
  [1224, 4.44776],
  [1248, 4.39155],
  [1272, 4.33534],
  [1296, 4.29348],
  [1320, 4.25162],
  [1344, 4.16847],
  [1368, 4.08532],
  [1392, 4.04107],
  [1416, 3.99682],
  [1440, 3.95099],
  [1464, 3.90516],
  [1488, 3.84084],
  [1512, 3.77651],
  [1536, 3.71631],
  [1560, 3.65612],
  [1584, 3.59592],
  [1608, 3.5558],
  [1632, 3.51568],
  [1656, 3.47556],
  [1680, 3.43545],
  [1704, 3.39533],
  [1728, 3.34137],
  [1752, 3.2874],
  [1776, 3.23344],
  [1800, 3.17948],
  [1824, 3.12552],
  [1848, 3.08536],
  [1872, 3.0452],
  [1896, 3.00504],
  [1920, 2.96488],
  [1944, 2.92472],
  [1968, 2.88797],
  [1992, 2.85121],
  [2016, 2.81445],
  [2040, 2.77769],
  [2064, 2.74094],
  [2088, 2.70265],
  [2112, 2.66437],
  [2136, 2.62608],
  [2160, 2.58779],
  [2184, 2.54951],
  [2208, 2.51738],
  [2232, 2.48525],
  [2256, 2.45312],
  [2280, 2.421],
  [2304, 2.38887],
  [2328, 2.36042],
  [2352, 2.33197],
  [2376, 2.30352],
  [2400, 2.27508],
  [2424, 2.24663],
  [2448, 2.21729],
  [2472, 2.18794],
  [2496, 2.1586],
  [2520, 2.12926],
  [2544, 2.09992],
  [2568, 2.07308],
  [2592, 2.04624],
  [2616, 2.0194],
  [2640, 1.99256],
  [2664, 1.96572],
  [2688, 1.94158],
  [2712, 1.91743],
  [2736, 1.89329],
  [2760, 1.86915],
  [2784, 1.845],
  [2808, 1.82287],
  [2832, 1.80074],
  [2856, 1.77861],
  [2880, 1.75648],
  [2904, 1.73435],
  [2928, 1.71199],
  [2952, 1.68963],
  [2976, 1.66727],
  [3000, 1.64491],
  [3024, 1.62255],
  [3048, 1.60525],
  [3072, 1.58795],
  [3096, 1.57066],
  [3120, 1.55336],
  [3144, 1.53606],
  [3168, 1.5184],
  [3192, 1.50074],
  [3216, 1.48307],
  [3240, 1.46541],
  [3264, 1.44775],
  [3288, 1.43],
  [3312, 1.41226],
  [3336, 1.39452],
  [3360, 1.37677],
  [3384, 1.35903],
  [3408, 1.34506],
  [3432, 1.3311],
  [3456, 1.31714],
  [3480, 1.30317],
  [3504, 1.28921],
  [3528, 1.27228],
  [3552, 1.25535],
  [3576, 1.23842],
  [3600, 1.22149],
  [3624, 1.20455],
  [3648, 1.19279],
  [3672, 1.18103],
  [3696, 1.16927],
  [3720, 1.1575],
  [3744, 1.14574],
  [3768, 1.1306],
  [3792, 1.11547],
  [3816, 1.10033],
  [3840, 1.08519],
  [3864, 1.07006],
  [3888, 1.05929],
  [3912, 1.04852],
  [3936, 1.03775],
  [3960, 1.02697],
  [3984, 1.0162],
  [4008, 1.00504],
  [4032, 0.99387],
  [4056, 0.982703],
  [4080, 0.971536],
  [4104, 0.960369],
  [4128, 0.949907],
  [4152, 0.939446],
  [4176, 0.928985],
  [4200, 0.918524],
  [4224, 0.908063],
  [4248, 0.898392],
  [4272, 0.888722],
  [4296, 0.879051],
  [4320, 0.869381],
  [4344, 0.85971],
  [4368, 0.85091],
  [4392, 0.842109],
  [4416, 0.833308],
  [4440, 0.824508],
  [4464, 0.815707],
  [4488, 0.806223],
  [4512, 0.79674],
  [4536, 0.787256],
  [4560, 0.777772],
  [4584, 0.768288],
  [4608, 0.759232],
  [4632, 0.750175],
  [4656, 0.741118],
  [4680, 0.732061],
  [4704, 0.723004],
  [4728, 0.716546],
  [4752, 0.710088],
  [4776, 0.70363],
  [4800, 0.697172],
  [4824, 0.690714],
  [4848, 0.685209],
  [4872, 0.679705],
  [4896, 0.674201],
  [4920, 0.668697],
  [4944, 0.663193],
  [4968, 0.654825],
  [4992, 0.646458],
  [5016, 0.638091],
  [5040, 0.629724],
  [5064, 0.621357],
  [5088, 0.614339],
  [5112, 0.607321],
  [5136, 0.600303],
  [5160, 0.593285],
  [5184, 0.586267],
  [5208, 0.580511],
  [5232, 0.574754],
  [5256, 0.568998],
  [5280, 0.563241],
  [5304, 0.557485],
  [5328, 0.549731],
  [5352, 0.541977],
  [5376, 0.534223],
  [5400, 0.526469],
  [5424, 0.518715],
  [5448, 0.513141],
  [5472, 0.507567],
  [5496, 0.501992],
  [5520, 0.496418],
  [5544, 0.490843],
  [5568, 0.486275],
  [5592, 0.481707],
  [5616, 0.477139],
  [5640, 0.472571],
  [5664, 0.468003],
  [5688, 0.463792],
  [5712, 0.45958],
  [5736, 0.455368],
  [5760, 0.451157],
  [5784, 0.446945],
  [5808, 0.442315],
  [5832, 0.437686],
  [5856, 0.433056],
  [5880, 0.428427],
  [5904, 0.423797],
  [5928, 0.417644],
  [5952, 0.41149],
  [5976, 0.405337],
  [6000, 0.399183],
  [6024, 0.39303],
  [6048, 0.389605],
  [6072, 0.38618],
  [6096, 0.382755],
  [6120, 0.37933],
  [6144, 0.375905],
  [6168, 0.372684],
  [6192, 0.369464],
  [6216, 0.366243],
  [6240, 0.363023],
  [6264, 0.359803],
  [6288, 0.355416],
  [6312, 0.351029],
  [6336, 0.346642],
  [6360, 0.342255],
  [6384, 0.337868],
  [6408, 0.334035],
  [6432, 0.330201],
  [6456, 0.326368],
  [6480, 0.322535],
  [6504, 0.318702],
  [6528, 0.314936],
  [6552, 0.31117],
  [6576, 0.307404],
  [6600, 0.303638],
  [6624, 0.299872],
  [6648, 0.296918],
  [6672, 0.293965],
  [6696, 0.291011],
  [6720, 0.288058],
  [6744, 0.285104],
  [6768, 0.281548],
  [6792, 0.277992],
  [6816, 0.274435],
  [6840, 0.270879],
  [6864, 0.267322],
  [6888, 0.265463],
  [6912, 0.263603],
  [6936, 0.261744],
  [6960, 0.259884],
  [6984, 0.258025],
  [7008, 0.254651],
  [7032, 0.251278],
  [7056, 0.247905],
  [7080, 0.244532],
  [7104, 0.241159],
  [7128, 0.238436],
  [7152, 0.235714],
  [7176, 0.232991],
  [7200, 0.230269],
  [7224, 0.227547],
  [7248, 0.224759],
  [7272, 0.221972],
  [7296, 0.219185],
  [7320, 0.216398],
  [7344, 0.213611],
  [7368, 0.211389],
  [7392, 0.209168],
  [7416, 0.206946],
  [7440, 0.204725],
  [7464, 0.202503],
  [7488, 0.200605],
  [7512, 0.198707],
  [7536, 0.196809],
  [7560, 0.194911],
  [7584, 0.193013],
  [7608, 0.190746],
  [7632, 0.18848],
  [7656, 0.186214],
  [7680, 0.183948],
  [7704, 0.181682],
  [7728, 0.179902],
  [7752, 0.178123],
  [7776, 0.176344],
  [7800, 0.174565],
  [7824, 0.172786],
  [7848, 0.170787],
  [7872, 0.168787],
  [7896, 0.166788],
  [7920, 0.164788],
  [7944, 0.162789],
  [7968, 0.160561],
  [7992, 0.158332],
  [8016, 0.156104],
  [8040, 0.153875],
  [8064, 0.151647],
  [8088, 0.150461],
  [8112, 0.149274],
  [8136, 0.148088],
  [8160, 0.146902],
  [8184, 0.145715],
  [8208, 0.143713],
  [8232, 0.14171],
  [8256, 0.139707],
  [8280, 0.137704],
  [8304, 0.135701],
  [8328, 0.13453],
  [8352, 0.133359],
  [8376, 0.132189],
  [8400, 0.131018],
  [8424, 0.129847],
  [8448, 0.128205],
  [8472, 0.126564],
  [8496, 0.124922],
  [8520, 0.12328],
  [8544, 0.121639],
  [8568, 0.120601],
  [8592, 0.119563],
  [8616, 0.118525],
  [8640, 0.117487],
  [8664, 0.116449],
  [8688, 0.115075],
  [8712, 0.113702],
  [8736, 0.112328],
  [8760, 0.110954],
  [8784, 0.10958],
  [8808, 0.107921],
  [8832, 0.106263],
  [8856, 0.104604],
  [8880, 0.102945],
  [8904, 0.101287],
];
drugLevel['RI:Gluteal:156mg'] = [
  [0, 0],
  [24, 2.64647],
  [48, 4.38899],
  [72, 5.45046],
  [96, 6.36873],
  [120, 6.94033],
  [144, 7.41124],
  [168, 7.88214],
  [192, 8.15862],
  [216, 8.43509],
  [240, 8.5934],
  [264, 8.75172],
  [288, 8.83565],
  [312, 8.91959],
  [336, 8.67165],
  [360, 8.42371],
  [384, 8.25068],
  [408, 8.07764],
  [432, 7.86552],
  [456, 7.6534],
  [480, 7.44192],
  [504, 7.23044],
  [528, 7.15213],
  [552, 7.07382],
  [576, 6.9479],
  [600, 6.82198],
  [624, 6.70961],
  [648, 6.59723],
  [672, 6.53806],
  [696, 6.4789],
  [720, 6.35655],
  [744, 6.2342],
  [768, 6.1501],
  [792, 6.066],
  [816, 5.99452],
  [840, 5.92304],
  [864, 5.83796],
  [888, 5.75288],
  [912, 5.69099],
  [936, 5.6291],
  [960, 5.527],
  [984, 5.4249],
  [1008, 5.39416],
  [1032, 5.36341],
  [1056, 5.27805],
  [1080, 5.19268],
  [1104, 5.1325],
  [1128, 5.07233],
  [1152, 5.01876],
  [1176, 4.96518],
  [1200, 4.85867],
  [1224, 4.75216],
  [1248, 4.72852],
  [1272, 4.70488],
  [1296, 4.66164],
  [1320, 4.61841],
  [1344, 4.54177],
  [1368, 4.46514],
  [1392, 4.4384],
  [1416, 4.41166],
  [1440, 4.33185],
  [1464, 4.25204],
  [1488, 4.20433],
  [1512, 4.15661],
  [1536, 4.09803],
  [1560, 4.03945],
  [1584, 3.98087],
  [1608, 3.94158],
  [1632, 3.9023],
  [1656, 3.86302],
  [1680, 3.82373],
  [1704, 3.78445],
  [1728, 3.73625],
  [1752, 3.68805],
  [1776, 3.63985],
  [1800, 3.59165],
  [1824, 3.54345],
  [1848, 3.50361],
  [1872, 3.46378],
  [1896, 3.42394],
  [1920, 3.38411],
  [1944, 3.34428],
  [1968, 3.30127],
  [1992, 3.25827],
  [2016, 3.21527],
  [2040, 3.17227],
  [2064, 3.12927],
  [2088, 3.08591],
  [2112, 3.04255],
  [2136, 2.99919],
  [2160, 2.95583],
  [2184, 2.91247],
  [2208, 2.87964],
  [2232, 2.84682],
  [2256, 2.814],
  [2280, 2.78117],
  [2304, 2.74835],
  [2328, 2.72494],
  [2352, 2.70154],
  [2376, 2.67813],
  [2400, 2.65472],
  [2424, 2.63132],
  [2448, 2.60039],
  [2472, 2.56945],
  [2496, 2.53852],
  [2520, 2.50759],
  [2544, 2.47666],
  [2568, 2.44417],
  [2592, 2.41169],
  [2616, 2.37921],
  [2640, 2.34672],
  [2664, 2.31424],
  [2688, 2.28996],
  [2712, 2.26568],
  [2736, 2.2414],
  [2760, 2.21712],
  [2784, 2.19283],
  [2808, 2.1677],
  [2832, 2.14257],
  [2856, 2.11744],
  [2880, 2.0923],
  [2904, 2.06717],
  [2928, 2.04279],
  [2952, 2.01842],
  [2976, 1.99404],
  [3000, 1.96966],
  [3024, 1.94529],
  [3048, 1.92728],
  [3072, 1.90928],
  [3096, 1.89127],
  [3120, 1.87327],
  [3144, 1.85526],
  [3168, 1.83449],
  [3192, 1.81372],
  [3216, 1.79295],
  [3240, 1.77218],
  [3264, 1.75141],
  [3288, 1.73073],
  [3312, 1.71005],
  [3336, 1.68937],
  [3360, 1.66869],
  [3384, 1.64801],
  [3408, 1.63223],
  [3432, 1.61645],
  [3456, 1.60067],
  [3480, 1.58489],
  [3504, 1.5691],
  [3528, 1.54818],
  [3552, 1.52725],
  [3576, 1.50633],
  [3600, 1.4854],
  [3624, 1.46448],
  [3648, 1.452],
  [3672, 1.43953],
  [3696, 1.42706],
  [3720, 1.41458],
  [3744, 1.40211],
  [3768, 1.38789],
  [3792, 1.37368],
  [3816, 1.35947],
  [3840, 1.34525],
  [3864, 1.33104],
  [3888, 1.31611],
  [3912, 1.30119],
  [3936, 1.28626],
  [3960, 1.27133],
  [3984, 1.25641],
  [4008, 1.24635],
  [4032, 1.2363],
  [4056, 1.22625],
  [4080, 1.2162],
  [4104, 1.20615],
  [4128, 1.19447],
  [4152, 1.18279],
  [4176, 1.17111],
  [4200, 1.15943],
  [4224, 1.14775],
  [4248, 1.13653],
  [4272, 1.12531],
  [4296, 1.1141],
  [4320, 1.10288],
  [4344, 1.09167],
  [4368, 1.0812],
  [4392, 1.07074],
  [4416, 1.06028],
  [4440, 1.04982],
  [4464, 1.03936],
  [4488, 1.02887],
  [4512, 1.01839],
  [4536, 1.00791],
  [4560, 0.997424],
  [4584, 0.986941],
  [4608, 0.977211],
  [4632, 0.967481],
  [4656, 0.957751],
  [4680, 0.948021],
  [4704, 0.938292],
  [4728, 0.929854],
  [4752, 0.921416],
  [4776, 0.912978],
  [4800, 0.904541],
  [4824, 0.896103],
  [4848, 0.886897],
  [4872, 0.87769],
  [4896, 0.868484],
  [4920, 0.859278],
  [4944, 0.850071],
  [4968, 0.844471],
  [4992, 0.838871],
  [5016, 0.83327],
  [5040, 0.82767],
  [5064, 0.82207],
  [5088, 0.812013],
  [5112, 0.801957],
  [5136, 0.791901],
  [5160, 0.781845],
  [5184, 0.771788],
  [5208, 0.764337],
  [5232, 0.756885],
  [5256, 0.749433],
  [5280, 0.741981],
  [5304, 0.73453],
  [5328, 0.726831],
  [5352, 0.719132],
  [5376, 0.711433],
  [5400, 0.703734],
  [5424, 0.696035],
  [5448, 0.689737],
  [5472, 0.68344],
  [5496, 0.677142],
  [5520, 0.670844],
  [5544, 0.664547],
  [5568, 0.658296],
  [5592, 0.652045],
  [5616, 0.645793],
  [5640, 0.639542],
  [5664, 0.633291],
  [5688, 0.62772],
  [5712, 0.622148],
  [5736, 0.616577],
  [5760, 0.611005],
  [5784, 0.605434],
  [5808, 0.599678],
  [5832, 0.593923],
  [5856, 0.588167],
  [5880, 0.582411],
  [5904, 0.576656],
  [5928, 0.57117],
  [5952, 0.565685],
  [5976, 0.5602],
  [6000, 0.554714],
  [6024, 0.549229],
  [6048, 0.545192],
  [6072, 0.541155],
  [6096, 0.537118],
  [6120, 0.53308],
  [6144, 0.529043],
  [6168, 0.522886],
  [6192, 0.51673],
  [6216, 0.510573],
  [6240, 0.504416],
  [6264, 0.498259],
  [6288, 0.493131],
  [6312, 0.488002],
  [6336, 0.482874],
  [6360, 0.477745],
  [6384, 0.472617],
  [6408, 0.468337],
  [6432, 0.464056],
  [6456, 0.459776],
  [6480, 0.455496],
  [6504, 0.451216],
  [6528, 0.445151],
  [6552, 0.439087],
  [6576, 0.433022],
  [6600, 0.426958],
  [6624, 0.420894],
  [6648, 0.417873],
  [6672, 0.414853],
  [6696, 0.411833],
  [6720, 0.408812],
  [6744, 0.405792],
  [6768, 0.40246],
  [6792, 0.399129],
  [6816, 0.395797],
  [6840, 0.392466],
  [6864, 0.389135],
  [6888, 0.386162],
  [6912, 0.38319],
  [6936, 0.380218],
  [6960, 0.377245],
  [6984, 0.374273],
  [7008, 0.369578],
  [7032, 0.364882],
  [7056, 0.360187],
  [7080, 0.355491],
  [7104, 0.350796],
  [7128, 0.348183],
  [7152, 0.345571],
  [7176, 0.342958],
  [7200, 0.340346],
  [7224, 0.337733],
  [7248, 0.334093],
  [7272, 0.330452],
  [7296, 0.326812],
  [7320, 0.323172],
  [7344, 0.319531],
  [7368, 0.316762],
  [7392, 0.313993],
  [7416, 0.311224],
  [7440, 0.308454],
  [7464, 0.305685],
  [7488, 0.302791],
  [7512, 0.299896],
  [7536, 0.297002],
  [7560, 0.294107],
  [7584, 0.291213],
  [7608, 0.28832],
  [7632, 0.285427],
  [7656, 0.282534],
  [7680, 0.279641],
  [7704, 0.276747],
  [7728, 0.27409],
  [7752, 0.271433],
  [7776, 0.268776],
  [7800, 0.266118],
  [7824, 0.263461],
  [7848, 0.26039],
  [7872, 0.257319],
  [7896, 0.254248],
  [7920, 0.251177],
  [7944, 0.248106],
  [7968, 0.246354],
  [7992, 0.244601],
  [8016, 0.242849],
  [8040, 0.241097],
  [8064, 0.239345],
  [8088, 0.236953],
  [8112, 0.234562],
  [8136, 0.23217],
  [8160, 0.229779],
  [8184, 0.227387],
  [8208, 0.224958],
  [8232, 0.222529],
  [8256, 0.220099],
  [8280, 0.21767],
  [8304, 0.21524],
  [8328, 0.212742],
  [8352, 0.210243],
  [8376, 0.207744],
  [8400, 0.205245],
  [8424, 0.202746],
  [8448, 0.201244],
  [8472, 0.199742],
  [8496, 0.19824],
  [8520, 0.196737],
  [8544, 0.195235],
  [8568, 0.193105],
  [8592, 0.190975],
  [8616, 0.188845],
  [8640, 0.186714],
  [8664, 0.184584],
  [8688, 0.183151],
  [8712, 0.181717],
  [8736, 0.180284],
  [8760, 0.178851],
  [8784, 0.177417],
  [8808, 0.174867],
  [8832, 0.172316],
  [8856, 0.169765],
  [8880, 0.167214],
  [8904, 0.164664],
];
drugLevel['RI:Gluteal:234mg'] = [
  [0, 0],
  [24, 4.56948],
  [48, 7.39571],
  [72, 9.36613],
  [96, 10.8168],
  [120, 11.7271],
  [144, 12.4992],
  [168, 13.2712],
  [192, 13.7035],
  [216, 14.1357],
  [240, 14.458],
  [264, 14.7804],
  [288, 14.941],
  [312, 15.1016],
  [336, 14.4655],
  [360, 13.8294],
  [384, 13.4496],
  [408, 13.0697],
  [432, 12.751],
  [456, 12.4323],
  [480, 12.1232],
  [504, 11.8142],
  [528, 11.6695],
  [552, 11.5248],
  [576, 11.3155],
  [600, 11.1062],
  [624, 10.8692],
  [648, 10.6323],
  [672, 10.5931],
  [696, 10.5539],
  [720, 10.3192],
  [744, 10.0845],
  [768, 10.0413],
  [792, 9.99815],
  [816, 9.86012],
  [840, 9.72208],
  [864, 9.6325],
  [888, 9.54292],
  [912, 9.40561],
  [936, 9.2683],
  [960, 9.1584],
  [984, 9.04851],
  [1008, 8.93548],
  [1032, 8.82245],
  [1056, 8.78424],
  [1080, 8.74603],
  [1104, 8.62061],
  [1128, 8.49519],
  [1152, 8.37625],
  [1176, 8.25732],
  [1200, 8.1341],
  [1224, 8.01087],
  [1248, 7.95835],
  [1272, 7.90583],
  [1296, 7.84853],
  [1320, 7.79124],
  [1344, 7.68368],
  [1368, 7.57611],
  [1392, 7.49146],
  [1416, 7.40681],
  [1440, 7.3641],
  [1464, 7.32139],
  [1488, 7.22352],
  [1512, 7.12564],
  [1536, 7.02749],
  [1560, 6.92935],
  [1584, 6.8312],
  [1608, 6.76795],
  [1632, 6.7047],
  [1656, 6.64145],
  [1680, 6.5782],
  [1704, 6.51495],
  [1728, 6.42577],
  [1752, 6.33659],
  [1776, 6.2474],
  [1800, 6.15822],
  [1824, 6.06904],
  [1848, 6.01644],
  [1872, 5.96384],
  [1896, 5.91124],
  [1920, 5.85865],
  [1944, 5.80605],
  [1968, 5.73897],
  [1992, 5.67189],
  [2016, 5.60482],
  [2040, 5.53774],
  [2064, 5.47066],
  [2088, 5.40612],
  [2112, 5.34158],
  [2136, 5.27704],
  [2160, 5.2125],
  [2184, 5.14796],
  [2208, 5.09452],
  [2232, 5.04108],
  [2256, 4.98764],
  [2280, 4.9342],
  [2304, 4.88076],
  [2328, 4.83594],
  [2352, 4.79112],
  [2376, 4.7463],
  [2400, 4.70148],
  [2424, 4.65667],
  [2448, 4.60656],
  [2472, 4.55646],
  [2496, 4.50635],
  [2520, 4.45625],
  [2544, 4.40614],
  [2568, 4.35807],
  [2592, 4.30999],
  [2616, 4.26192],
  [2640, 4.21384],
  [2664, 4.16577],
  [2688, 4.12379],
  [2712, 4.08181],
  [2736, 4.03983],
  [2760, 3.99785],
  [2784, 3.95587],
  [2808, 3.91984],
  [2832, 3.88382],
  [2856, 3.8478],
  [2880, 3.81178],
  [2904, 3.77575],
  [2928, 3.73799],
  [2952, 3.70023],
  [2976, 3.66247],
  [3000, 3.6247],
  [3024, 3.58694],
  [3048, 3.54909],
  [3072, 3.51125],
  [3096, 3.4734],
  [3120, 3.43555],
  [3144, 3.3977],
  [3168, 3.36586],
  [3192, 3.33401],
  [3216, 3.30216],
  [3240, 3.27031],
  [3264, 3.23847],
  [3288, 3.2016],
  [3312, 3.16474],
  [3336, 3.12787],
  [3360, 3.09101],
  [3384, 3.05415],
  [3408, 3.02961],
  [3432, 3.00507],
  [3456, 2.98053],
  [3480, 2.956],
  [3504, 2.93146],
  [3528, 2.89709],
  [3552, 2.86273],
  [3576, 2.82837],
  [3600, 2.794],
  [3624, 2.75964],
  [3648, 2.74179],
  [3672, 2.72395],
  [3696, 2.7061],
  [3720, 2.68825],
  [3744, 2.6704],
  [3768, 2.64223],
  [3792, 2.61405],
  [3816, 2.58587],
  [3840, 2.5577],
  [3864, 2.52952],
  [3888, 2.50506],
  [3912, 2.4806],
  [3936, 2.45614],
  [3960, 2.43168],
  [3984, 2.40721],
  [4008, 2.39406],
  [4032, 2.38091],
  [4056, 2.36775],
  [4080, 2.3546],
  [4104, 2.34145],
  [4128, 2.3164],
  [4152, 2.29135],
  [4176, 2.26631],
  [4200, 2.24126],
  [4224, 2.21621],
  [4248, 2.19416],
  [4272, 2.17211],
  [4296, 2.15006],
  [4320, 2.12801],
  [4344, 2.10596],
  [4368, 2.08875],
  [4392, 2.07154],
  [4416, 2.05434],
  [4440, 2.03713],
  [4464, 2.01992],
  [4488, 2.00187],
  [4512, 1.98382],
  [4536, 1.96577],
  [4560, 1.94772],
  [4584, 1.92967],
  [4608, 1.91217],
  [4632, 1.89466],
  [4656, 1.87716],
  [4680, 1.85965],
  [4704, 1.84214],
  [4728, 1.82812],
  [4752, 1.81409],
  [4776, 1.80007],
  [4800, 1.78604],
  [4824, 1.77202],
  [4848, 1.75826],
  [4872, 1.74449],
  [4896, 1.73073],
  [4920, 1.71697],
  [4944, 1.7032],
  [4968, 1.6881],
  [4992, 1.67299],
  [5016, 1.65788],
  [5040, 1.64278],
  [5064, 1.62767],
  [5088, 1.61175],
  [5112, 1.59583],
  [5136, 1.57991],
  [5160, 1.56399],
  [5184, 1.54807],
  [5208, 1.53596],
  [5232, 1.52384],
  [5256, 1.51173],
  [5280, 1.49962],
  [5304, 1.4875],
  [5328, 1.47161],
  [5352, 1.45571],
  [5376, 1.43981],
  [5400, 1.42391],
  [5424, 1.40801],
  [5448, 1.39672],
  [5472, 1.38543],
  [5496, 1.37414],
  [5520, 1.36285],
  [5544, 1.35156],
  [5568, 1.33739],
  [5592, 1.32322],
  [5616, 1.30905],
  [5640, 1.29488],
  [5664, 1.2807],
  [5688, 1.27615],
  [5712, 1.2716],
  [5736, 1.26705],
  [5760, 1.2625],
  [5784, 1.25795],
  [5808, 1.24425],
  [5832, 1.23056],
  [5856, 1.21686],
  [5880, 1.20317],
  [5904, 1.18947],
  [5928, 1.18167],
  [5952, 1.17387],
  [5976, 1.16607],
  [6000, 1.15827],
  [6024, 1.15047],
  [6048, 1.13987],
  [6072, 1.12927],
  [6096, 1.11867],
  [6120, 1.10807],
  [6144, 1.09747],
  [6168, 1.08712],
  [6192, 1.07676],
  [6216, 1.06641],
  [6240, 1.05605],
  [6264, 1.04569],
  [6288, 1.03727],
  [6312, 1.02885],
  [6336, 1.02043],
  [6360, 1.01201],
  [6384, 1.00358],
  [6408, 0.996352],
  [6432, 0.98912],
  [6456, 0.981887],
  [6480, 0.974654],
  [6504, 0.967421],
  [6528, 0.956381],
  [6552, 0.945341],
  [6576, 0.934301],
  [6600, 0.92326],
  [6624, 0.91222],
  [6648, 0.906551],
  [6672, 0.900882],
  [6696, 0.895213],
  [6720, 0.889544],
  [6744, 0.883875],
  [6768, 0.875015],
  [6792, 0.866155],
  [6816, 0.857295],
  [6840, 0.848435],
  [6864, 0.839575],
  [6888, 0.836886],
  [6912, 0.834198],
  [6936, 0.831509],
  [6960, 0.828821],
  [6984, 0.826133],
  [7008, 0.816385],
  [7032, 0.806637],
  [7056, 0.796889],
  [7080, 0.78714],
  [7104, 0.777392],
  [7128, 0.770144],
  [7152, 0.762896],
  [7176, 0.755648],
  [7200, 0.7484],
  [7224, 0.741152],
  [7248, 0.733577],
  [7272, 0.726003],
  [7296, 0.718429],
  [7320, 0.710854],
  [7344, 0.70328],
  [7368, 0.696915],
  [7392, 0.69055],
  [7416, 0.684184],
  [7440, 0.677819],
  [7464, 0.671454],
  [7488, 0.667632],
  [7512, 0.663811],
  [7536, 0.659989],
  [7560, 0.656168],
  [7584, 0.652346],
  [7608, 0.646921],
  [7632, 0.641496],
  [7656, 0.636071],
  [7680, 0.630646],
  [7704, 0.625221],
  [7728, 0.621381],
  [7752, 0.617541],
  [7776, 0.613701],
  [7800, 0.609861],
  [7824, 0.606021],
  [7848, 0.59917],
  [7872, 0.592318],
  [7896, 0.585467],
  [7920, 0.578615],
  [7944, 0.571763],
  [7968, 0.566358],
  [7992, 0.560953],
  [8016, 0.555548],
  [8040, 0.550143],
  [8064, 0.544738],
  [8088, 0.541709],
  [8112, 0.538679],
  [8136, 0.53565],
  [8160, 0.532621],
  [8184, 0.529591],
  [8208, 0.523344],
  [8232, 0.517097],
  [8256, 0.51085],
  [8280, 0.504603],
  [8304, 0.498356],
  [8328, 0.494976],
  [8352, 0.491596],
  [8376, 0.488216],
  [8400, 0.484836],
  [8424, 0.481456],
  [8448, 0.477261],
  [8472, 0.473065],
  [8496, 0.468869],
  [8520, 0.464674],
  [8544, 0.460478],
  [8568, 0.456489],
  [8592, 0.4525],
  [8616, 0.448511],
  [8640, 0.444522],
  [8664, 0.440533],
  [8688, 0.438286],
  [8712, 0.43604],
  [8736, 0.433793],
  [8760, 0.431546],
  [8784, 0.4293],
  [8808, 0.424093],
  [8832, 0.418886],
  [8856, 0.413679],
  [8880, 0.408472],
  [8904, 0.403266],
];

export default drugLevel;
