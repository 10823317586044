import React from 'react';
import { getRangeForDomain } from '../../modules/domains';

const DailyDosageDots = ({ numberOfDoses, zoomDomain }) => {
  const range = getRangeForDomain(zoomDomain);
  const dotSize = 10;
  const dotRadius = dotSize / 2;

  const paddingByRange = {
    30: 4,
    90: 2,
    180: 2,
    365: 0,
    545: 0,
  };

  const padding = paddingByRange[range] || 2;
  const totalWidth = dotSize * numberOfDoses;
  const dots = (new Array(numberOfDoses)).fill(null);

  return (
    <div className="daily-dose-dots">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${totalWidth} ${dotSize}`}>
        {dots.map((dot, index) => (
          <circle
            key={index}
            fill="#6E8A96"
            cx={dotSize * index + dotRadius}
            cy={dotRadius}
            r={dotRadius - padding}
          />
        ))}
      </svg>
    </div>
  );
}

export default DailyDosageDots;
