import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VictoryArea, VictoryClipContainer } from 'victory';
import GraphContext from '../GraphContext';
import { isDoseDayShiftable } from '../../modules/doseUtils';

const propTypes = {
  activeDose: PropTypes.object,
};

function DoseAdjustableRange(props) {

  const { metadata, entireDomain } = useContext(GraphContext);

  const { activeDose, ...passThroughProps } = props;

  const isSingleDoseCurveView = metadata.singleDoseCurveView;
  const isShiftable = isDoseDayShiftable(activeDose, metadata) && !isSingleDoseCurveView;

  if (!isShiftable) return null;

  const levelRange = entireDomain.level[1] - entireDomain.level[0];

  const {
    relativeExtremes,
    rootStart,
  } = activeDose;

  // dividing the total range in level by this magic number of 15
  // gets pretty close to matching the height between the dose track lines
  const yValue = levelRange / 15;
  const maxDay = rootStart + relativeExtremes.max;
  const minDay = rootStart + relativeExtremes.min;

  return (
    <VictoryArea
      {...passThroughProps}
      animate={false}
      name="dose-adjustable-range"
      data={[
        { x: minDay, y: yValue },
        { x: maxDay, y: yValue },
      ]}
      style={{
        data: {
          fill: '#bfc0c1',
          stroke: '#bfc0c1',
        },
      }}
      groupComponent={
        <VictoryClipContainer clipPadding={{ bottom: 100 }} transform="translate(0 41.5)" />
      }
    />
  );
}

DoseAdjustableRange.propTypes = propTypes;
export default DoseAdjustableRange;
