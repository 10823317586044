import axios from 'axios';
import { buildCumulativeDoseCurve } from './accumulatedDoseLineUtils';
import { INJECTABLE_CURVE_FILTER } from './doseFilters';
import { filter, map, pipe } from './reducers';

/**
 * Builds the cumulative dose curves for PP3M scenarios based on provided doses
 * @param {[object]} doses (must be sorted and hydrated by normalizeDoses first!)
 * @param {object} scenario
 * @returns {[object]}
 */
export const buildCumulativeDoseCurvesForPP3M = async (doses, scenario) => {
  const identifier = getIdentifierForDoses(doses);
  const cumulativeCurveData = await fetchCumulativeCurveData(identifier);
  const cumulativeCurveDoses = buildDoseCurvesByFormulation(cumulativeCurveData, doses);
  const injectableCurveDose = buildInjectableCumulativeCurve(doses, scenario);

  return [...injectableCurveDose, ...cumulativeCurveDoses];
};

/**
 * Split data into separate dose curves
 * so that different line colors can be applied per formulation
 * @param {array} curveData
 * @param {object} switches
 */
export const buildDoseCurvesByFormulation = (curveData, doses) => {
  return pipe(
    getSwitchPointsByFormulation(),
    map(splitCurveDataByFormulation(curveData)),
    filter(datum => datum[1].length > 0),
    map(buildDoseCurveObject()),
  )(doses)
};

/**
 * Builds a cumulative dose curve object for each formulation
 */
export const buildDoseCurveObject = () => {
  return switchPoint => {
    const formulation = switchPoint[0];
    const dataSlice = switchPoint[1];

    return [{
      formulation,
      isCumulative: true,
      isCurveVisible: true,
      isPp3mCurve: true,
      isVisible: true,
      data: map(mapPP3mLevelsToGraphDatum(-1))(dataSlice),
    }];
  };
};

/**
 * Gets slice of curve data array for each formulation switch point
 * @param {array} curveData
 */
export const splitCurveDataByFormulation = curveData => {
  return switchPoint => {
    const formulation = switchPoint[0];
    const range = switchPoint[1];
    const start = range[0];
    const end = typeof range[1] === 'number' ? range[1] + 1 : undefined;
    const dataSlice = curveData.slice(start, end);
    return [[ formulation, dataSlice ]];
  };
};

/**
 * Finds the formulation switching points for the given doses
 */
export const getSwitchPointsByFormulation = () => {
  return doses => {
    return doses.reduce((switchPoints, dose, index) => {
      const { formulation, xstart } = dose;
      const formulationChanged = formulation !== doses[index - 1]?.formulation;

      if (formulationChanged) {
        // set end day for last formulation
        const lastSwitchPoint = switchPoints[switchPoints.length - 1];
        if (lastSwitchPoint) {
          lastSwitchPoint[1].push(xstart);
        }
        // set start day for this formulation
        switchPoints.push([formulation, [xstart]]);
      }
      return switchPoints;
    }, []);
  };
};

/**
 * Builds an identifier string based on given doses
 * to use for retrieving cumulative curve data from server
 * @param {[object]} doses
 * @returns {string}
 */
export const getIdentifierForDoses = doses => {
  return doses.reduce((id, dose) => {
    const { formulation, location, amount, xstart } = dose;
    if (!formulation || !amount) return id;

    const amountNumber = parseInt(amount, 10);
    const locationInitial = location.charAt(0);
    const day = xstart + 1;
    id += `${formulation}${locationInitial}${amountNumber}${day}`;
    return id;
  }, '');
};

/**
 * Fetch cumulative curve json data for the given identifier
 * @param {string} identifier
 */
export const fetchCumulativeCurveData = async (identifier) => {
  try {
    const response = await axios.get(`/helpers/md5hash/?identifier=${identifier}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Converts our raw drugLevelObservation (drugData) to
 * an object adjusted for the context of the scenario.
 * Adjusts the day to match the scenarios x-axis
 * */
export const mapPP3mLevelsToGraphDatum = (xAxisStartDayOffset = 0) => {
  return ([rawDrugLevelDay, rawDrugLevel]) => {
    const scenarioStartDay = rawDrugLevelDay + xAxisStartDayOffset;
    const graphDatum = {
      day: scenarioStartDay,
      level: rawDrugLevel
    };
    return graphDatum;
  };
};

export const buildInjectableCumulativeCurve = (doses, scenario) => {
  const injectableDoses = INJECTABLE_CURVE_FILTER(doses);

  if (injectableDoses.length) {
    return pipe(
      map(buildCumulativeDoseCurve(scenario)),
    )([injectableDoses]);
  }

  return [];
};
