import { filter, pipe } from './reducers';
import { getUpdatedDoseNameProps, isIndividualDose } from './doseUtils';
import { risperdalToPp1mAmounts, pp1mToPp3mAmounts } from './config';

/**
 * Updates the amount and/or location for the given dose in a PP3M scenario
 * Following doses may also be updated based on conditional logic.
 *
 * @param {object} selectedDose (with new amount/location values)
 * @param {[object]} doses
 * @param {object} metadata
 */
export const updatePp3mDose = (selectedDose, doses, metadata) => {

  const updatedDoses = pipe(
    filter(isIndividualDose),
    updateDoses(selectedDose, metadata)
  )(doses);

  return updatedDoses;
};

/**
 * Update other PP3M doses based on the current dose being updated
 * @param {object} selectedDose
 * @param {object} metadata
 */
export const updateDoses = (selectedDose, metadata) => {
  return doses => {

    const updateConditionMatch = futureDoseUpdateConditions.find(item => {
      return item.updateCondition(selectedDose, doses, metadata);
    });

    const doseConditions = updateConditionMatch
      ? updateConditionMatch.doseConditions.map(item => ({
        ...item,
        // reset matches counter
        matches: 0
      }))
      : [];

    const updatingPp1mLocationGroup = selectedDose.groups?.pp1mLocationLock;
    const updatingPp1mAmountGroup = selectedDose.groups?.pp1mAmountLock;
    const updatingPp3mLocationGroup = selectedDose.groups?.pp3mLocationLock;

    return doses.map((dose, index) => {

      /**
       * Update the selected dose
       */
      const isSelectedDose = dose.xstart === selectedDose.xstart;
      if (isSelectedDose) {
        let { amount, location } = selectedDose;
        return {
          ...dose,
          ...getUpdatedDoseNameProps(dose, amount, location)
        };
      }

      const doseConditionMatch = doseConditions.find(item => item.condition(dose, index));
      const inPp1mLocationGroup = updatingPp1mLocationGroup && dose.groups?.pp1mLocationLock;
      const inPp1mAmountGroup = updatingPp1mAmountGroup && dose.groups?.pp1mAmountLock;
      const inPp3mLocationGroup = updatingPp3mLocationGroup && dose.groups?.pp3mLocationLock;

      let newAmount = dose.amount;
      let newLocation = dose.location;

      /**
       * Update other doses based on conditional logic in futureDoseUpdateConditions
       */
      if (doseConditionMatch) {
        let { amount, location } = getAmountAndLocationForDose(doseConditionMatch, dose, index, doses, selectedDose);
        newAmount = amount || newAmount;
        newLocation = location || newLocation;
      }

      /**
       * Update other doses in the same PP1M location or amount "lock" group
       */
      if (inPp1mLocationGroup || inPp1mAmountGroup) {
        newAmount = inPp1mAmountGroup ? selectedDose.amount : newAmount;
        newLocation = inPp1mLocationGroup ? selectedDose.location : newLocation;
      }

      /**
       * Update other doses in the same PP3M location "lock" group
       */
      if (inPp3mLocationGroup) {
        newLocation = selectedDose.location;
      }

      return {
        ...dose,
        ...getUpdatedDoseNameProps(dose, newAmount, newLocation)
      };

    });
  };
};

export const getAmountAndLocationForDose = (doseConditionMatch, dose, index, doses, updatedDose) => {

  let amount = doseConditionMatch.newAmount;
  let location = doseConditionMatch.newLocation;

  if (typeof amount === 'function') {
    amount = amount(dose, index, doses, updatedDose);
  }
  if (typeof location === 'function') {
    location = location(dose, index, doses, updatedDose);
  }

  if (Array.isArray(amount)) {
    // if an array, pick the value by index based on the number of matches so far
    // if the number of matches exceeds the number of values, use the last value
    amount = amount[doseConditionMatch.matches] || amount[amount.length - 1];
  }
  if (Array.isArray(location)) {
    location = location[doseConditionMatch.matches] || location[location.length - 1];
  }

  // keep track of how many doses in the array match this condition
  doseConditionMatch.matches = doseConditionMatch.matches + 1;

  return { amount, location };
};

export const futureDoseUpdateConditions = [

  // Structure for defining logic around future dose updates:
  // {
  //   name: 'this name is for debugging purposes only!',
  //   updateCondition: (updatedDose, allDoses) => {
  //     return true;
  //   },
  //   doseConditions: [
  //     {
  //       condition: (dose, index) => true,
  //       newAmount: '234mg'
  //     },
  //   ],
  // },

  // if third dose amount is 1M and set to 234mg,
  // and fourth dose amount is set to 39mg, 78mg, or 117mg,
  //   |-> then set amount for doses 4, 5, and 6 to 234mg
  //   |-> and set amount for 3M doses 7+ to 819mg
  //   |-> and set amount for 1M doses 7+ to 234mg
  //   |-> and set amount for ER doses after 7 to 6mg, 9mg, and 12mg
  {
    name: '2.1M.234mg-3.1M.39mg.78mg.117mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fourthDoseAmount = allDoses[3].amount;
      return formulation === '1M' && index === 2 && amount === '234mg'
        && ['39mg', '78mg', '117mg'].includes(fourthDoseAmount);
    },
    doseConditions: [
      {
        condition: (dose, index) => [3, 4, 5].includes(index),
        newAmount: '234mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '819mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '234mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        // [ first match amount, second match amount, third match amount ]
        newAmount: ['6mg', '9mg', '12mg']
      },
    ],
  },

  // if the third amount is 1M and set to 156mg,
  // and the fourth dose amount is set to 39mg or 78mg
  //   |-> then set amount for fourth dose to 156mg
  //   |-> and set amount for doses 5 and 6 to 234mg
  //   |-> and set amount for 3M doses 7+ to 819mg
  //   |-> and set amount for 1M doses 7+ to 234mg (or 156mg if limitedReinitiationDose)
  //   |-> and set amount for ER doses 7, 8 and 9 to 6mg, 9mg, and 12mg
  {
    name: '2.1M.156mg-3.1M.39mg.78mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fourthDoseAmount = allDoses[3].amount;
      return formulation === '1M' && index === 2 && amount === '156mg'
        && ['39mg', '78mg'].includes(fourthDoseAmount);
    },
    doseConditions: [
      {
        condition: (dose, index) => index === 3,
        newAmount: '156mg'
      },
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '234mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '819mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && dose.limitedReinitiationDose,
        newAmount: '156mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '234mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['6mg', '9mg', '12mg']
      },
    ],
  },

  // if third dose amount is 1M and set to 117mg,
  // and fourth dose amount is set to 39mg or 234mg
  //  |-> then set fourth dose amount to 117mg
  //  |-> and set amount for doses 5 and 6 to 156mg
  //  |-> and set amount for 3M doses 7+ to 546mg
  //  |-> and set amount for 1M doses 7+ to 156mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 6mg, and 9mg
  {
    name: '2.1M.117mg-3.1M.39mg.234mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fourthDoseAmount = allDoses[3].amount;
      return formulation === '1M' && index === 2 && amount === '117mg'
        && ['39mg', '234mg'].includes(fourthDoseAmount);
    },
    doseConditions: [
      {
        condition: (dose, index) => index === 3,
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '156mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '546mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '156mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '6mg', '9mg']
      },
    ],
  },

  // if third dose amount is 1M and set to 78mg,
  // and fourth dose amount is set to 156mg or 234mg
  //  |-> then set amount for fourth dose to 78mg
  //  |-> and set amount for doses 5 and 6 to 117mg
  //  |-> and set amount for 3M doses 7+ to 410mg
  //  |-> and set amount for 1M doses 7+ to 117mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 6mg
  {
    name: '2.1M.78mg-3.1M.156mg.234mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fourthDoseAmount = allDoses[3].amount;
      return formulation === '1M' && index === 2 && amount === '78mg'
        && ['156mg', '234mg'].includes(fourthDoseAmount);
    },
    doseConditions: [
      {
        condition: (dose, index) => index === 3,
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '410mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '9mg']
      },
    ],
  },

  // if third dose amount is 1M and set to 39mg,
  // and fourth dose amount is set to 117mg, 156mg or 234mg
  //  |-> then set amount for dose 4 to 39mg
  //  |-> and set amount for doses 5 and 6 to 78mg
  //  |-> and set amount for 3M doses 7+ to 273mg
  //  |-> and set amount for 1M doses 7+ to 78mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 3mg
  {
    name: '2.1M.39mg-3.1M.117mg.156mg.234mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fourthDoseAmount = allDoses[3].amount;
      return formulation === '1M' && index === 2 && amount === '39mg'
        && ['117mg', '156mg', '234mg'].includes(fourthDoseAmount);
    },
    doseConditions: [
      {
        condition: (dose, index) => index === 3,
        newAmount: '39mg'
      },
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '273mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '3mg']
      },
    ],
  },

  // if fourth dose amount is 1M and set to 39mg,
  //  |-> then set amount for doses 5 and 6 to 78mg
  //  |-> and set amount for 3M doses 7+ to 273mg
  //  |-> and set amount for 1M doses 7+ to 78mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 3mg
  {
    name: '3.1M.39mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return formulation === '1M' && index === 3 && amount === '39mg';
    },
    doseConditions: [
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '273mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '3mg']
      },
    ],
  },

  // if fourth dose amount is 1M and set to 78mg,
  //  |-> then set amount for doses 5 and 6 to 78mg
  //  |-> and set amount for 3M doses 7+ to 273mg
  //  |-> and set amount for 1M doses 7+ to 78mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 3mg
  {
    name: '3.1M.78mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return formulation === '1M' && index === 3 && amount === '78mg';
    },
    doseConditions: [
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '273mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '3mg']
      },
    ],
  },

  // if fourth dose amount is 1M and set to 117mg,
  //  |-> then set amount for doses 5 and 6 to 117mg
  //  |-> and set amount for 3M doses 7+ to 410mg
  //  |-> and set amount for 1M doses 7+ to 117mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 6mg
  {
    name: '3.1M.117mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return formulation === '1M' && index === 3 && amount === '117mg';
    },
    doseConditions: [
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '410mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '6mg']
      },
    ],
  },

  // if fourth dose amount is 1M and set to 156mg,
  // and fifth dose amount is set to 78mg,
  //  |-> then set amount for doses 5 and 6 to 117mg
  //  |-> and set amount for 3M doses 7+ to 410mg
  //  |-> and set amount for 1M doses 7+ to 117mg
  //  |-> and set amount for ER doses 7, 8 and 9 to 3mg, 3mg, and 6mg
  {
    name: '3.1M.156mg-5.1M.78mg',
    updateCondition: (updatedDose, allDoses) => {
      const { amount, formulation, index } = updatedDose;
      const fifthDoseAmount = allDoses[4].amount;
      return index === 3 && formulation === '1M' && amount === '156mg'
        && fifthDoseAmount === '78mg';
    },
    doseConditions: [
      {
        condition: (dose, index) => [4, 5].includes(index),
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '410mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '6mg']
      },
    ],
  },

  // if fifth PP1M dose is set to 78mg
  //  |-> then set sixth PP1M dose to 78mg
  //  |-> and set 3M doses 7+ to 273mg
  //  |-> and set ER doses 7+ to [3mg, 3mg, 3mg]
  {
    name: '4.1M.78mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return index === 4 && amount === '78mg' && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 5 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '78mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '273mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '3mg']
      },
    ],
  },

  // if fifth PP1M dose is set to 117mg
  //  |-> then set sixth PP1M dose to 117mg
  //  |-> and set 3M doses 7+ to 410mg
  //  |-> and set ER doses 7+ to [3mg, 3mg, 6mg]
  {
    name: '4.1M.117mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return index === 4 && amount === '117mg' && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 5 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '117mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '410mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '3mg', '6mg']
      },
    ],
  },

  // if fifth PP1M dose is set to 156mg
  //  |-> then set sixth PP1M dose to 156mg
  //  |-> and set 3M doses 7+ to 546mg
  //  |-> and set ER doses 7+ to [3mg, 6mg, 9mg]
  {
    name: '4.1M.156mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return index === 4 && amount === '156mg' && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 5 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '156mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '546mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['3mg', '6mg', '9mg']
      },
    ],
  },

  // if fifth PP1M dose is set to 234mg
  //  |-> then set sixth PP1M dose to 234mg
  //  |-> and set 3M doses 7+ to 819mg
  //  |-> and set ER doses 7+ to [6mg, 9mg, 12mg]
  {
    name: '4.1M.234mg',
    updateCondition: (updatedDose) => {
      const { amount, formulation, index } = updatedDose;
      return index === 4 && amount === '234mg' && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 5 && dose.formulation === '1M' && !dose.isInitiationDose,
        newAmount: '234mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '3M',
        newAmount: '819mg'
      },
      {
        condition: (dose, index) => index >= 6 && dose.formulation === 'ER',
        newAmount: ['6mg', '9mg', '12mg']
      },
    ],
  },

  // if hasPp1mReinitiation is set to true for this scenario
  //   |-> then set the amount for the first 1M dose after dose 6 to the amount of first dose
  //   |-> and set the amount for the second 1M dose after dose 6 to the amount of second dose
  //   |-> and set the amount for the following 1M doses to the amount of dose 6
  {
    name: 'hasPp1mReinitiation',
    updateCondition: (updatedDose, allDoses, metadata) => {
      return metadata.hasPp1mReinitiation;
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M',
        newAmount: (dose, index, doses) => {
          return [
            doses[0].amount,
            doses[1].amount,
            doses[5].amount,
          ]
        }
      },
    ],
  },

  // if is4To9MonthScenario is set to true for this scenario
  // and the amount of this dose being edited is 234mg
  //   |-> then set the amount for the 1M doses 7+ to 156mg
  {
    name: 'is4To9MonthScenario.234mg',
    updateCondition: (updatedDose, allDoses, metadata) => {
      return metadata.is4To9MonthScenario
        && updatedDose.amount === '234mg';
    },
    doseConditions: [
      {
        condition: (dose, index) => index >= 6 && dose.formulation === '1M',
        newAmount: '156mg'
      },
    ],
  },

  // if dose is sixth and RL formulation
  // |-> then update all other RL doses to same location
  // |-> and update other RL doses 3-5 to same amount
  // |-> and update other 1M doses to corresponding amount
  // |-> and update other 3M doses to corresponding amount based on the amount of dose 8
  {
    name: '5.RL',
    updateCondition: (updatedDose) => {
      const { index, formulation } = updatedDose;
      return index === 5 && formulation === 'RL';
    },
    doseConditions: [
      {
        condition: (dose) => dose.formulation === 'RL',
        newAmount: (dose, index, doses, updatedDose) => {
          return index > 1 ? updatedDose.amount : dose.amount;
        },
        newLocation: (dose, index, doses, updatedDose) => updatedDose.location,
      },
      {
        condition: (dose) => dose.formulation === '1M',
        newAmount: (dose, index, doses, updatedDose) => {
          return risperdalToPp1mAmounts[updatedDose.amount];
        },
      },
      {
        condition: (dose) => dose.formulation === '3M',
        newAmount: (dose, index, doses, updatedDose) => {
          const newPp1mAmount = risperdalToPp1mAmounts[updatedDose.amount];
          return pp1mToPp3mAmounts[newPp1mAmount];
        },
      },
    ],
  },

  // if this is a Risperdal scenario and the eigth dose is 1M...
  // |-> then update following 1M doses to the same amount
  // |-> then update following 3M doses to the corresponding amount
  {
    name: 'hasRisperdalConsta.7.1M',
    updateCondition: (updatedDose, doses, metadata) => {
      const { index, formulation } = updatedDose;
      return metadata.hasRisperdalConsta && index === 7 && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose) => dose.index > 7 && dose.formulation === '1M',
        newAmount: (dose, index, doses, updatedDose) => updatedDose.amount,
      },
      {
        condition: (dose) => dose.index > 7 && dose.formulation === '3M',
        newAmount: (dose, index, doses, updatedDose) => pp1mToPp3mAmounts[updatedDose.amount],
      },
    ],
  },

  // if this is a Risperdal scenario and the ninth dose is 1M...
  // |-> then update following 3M doses to the corresponding amount
  {
    name: 'hasRisperdalConsta.8.1M',
    updateCondition: (updatedDose, doses, metadata) => {
      const { index, formulation } = updatedDose;
      return metadata.hasRisperdalConsta && index === 8 && formulation === '1M';
    },
    doseConditions: [
      {
        condition: (dose) => dose.index > 7 && dose.formulation === '3M',
        newAmount: (dose, index, doses, updatedDose) => pp1mToPp3mAmounts[updatedDose.amount],
      },
    ],
  },

  // if this is a HaldolDecoanate scenario and the seventh dose is 1M...
  // |-> then update following 1M doses to the same amount
  // |-> then update following 3M doses to the corresponding amount
  {
    name: 'hasHaldolDecoanate.6.1M',
    updateCondition: (updatedDose, doses, metadata) => {
      const { index, formulation } = updatedDose;
      return metadata.hasHaldolDecoanate && formulation === '1M' && index === 6;
    },
    doseConditions: [
      {
        condition: (dose) => dose.index > 6 && dose.formulation === '1M',
        newAmount: (dose, index, doses, updatedDose) => updatedDose.amount,
      },
      {
        condition: (dose) => dose.index > 6 && dose.formulation === '3M',
        newAmount: (dose, index, doses, updatedDose) => pp1mToPp3mAmounts[updatedDose.amount],
      },
    ],
  },

];
