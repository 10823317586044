import theme from './theme';
import last from 'lodash/last';
import range from 'lodash/range';
import { map, filter, pipe } from './reducers';
import { doseHasDailyLevels, doseHasHourlyLevels } from './doseUtils';
import { mapDrugLevelsToGraphDatum } from './doseNormalizationOperators';

/**
 * Reducers and data pipeline for calculating the accumulated dose levels
 */

/**
 * Returns a function that will get a dose level for the given scenario day
 * @param {number} scenarioDay signed int
 * @returns {func}
 */
export const getLevelForDoseAtDay = scenarioDay => {
  return dose => {

    /** @TODO find a way to memoize this
     * because each dose pulls the same drugData from data.js
     *
     * @Note: this makes the assumption that the index of dose.data
     * corresponds with the adjusted scenario day number.
     *
     * scenario day:   0    1     2
     *    dose.data:[ [],  [],   [] ]
     */
    const doseStart = dose.xstart;
    const adjustedScenarioDay = scenarioDay - doseStart;
    return dose.data[adjustedScenarioDay]?.level;
  };
};

export const getLevelForDoseAtHour = scenarioHour => {
  return dose => {
    const doseStartInHrs = dose.xstart * 24;
    const adjustedScenarioHour = scenarioHour - doseStartInHrs;
    const drugLevel = dose.data[adjustedScenarioHour]?.level;
    return drugLevel;
  };
};

/**
 *
 * @param {[float]} drugLevels array of drug levels
 *
 * @returns {int} accumulated dose build up for given day
 */
export const calcDoseBuildUp = drugLevels => {
  const totalLevel = drugLevels.reduce((total, level) => total + level, 0);
  return totalLevel;
};

/**
 * Calcuate the accumulated dose levels for the scenario day
 *
 * @param {int} day signed integer for the day in SCENARIO_DAY_RANGE
 * @param {[object]} doses
 * @returns {float} total level of all doses for the given day
 */
export const calcAccumulatedLevelForDay = (day, doses) => {
  return pipe(
    map(getLevelForDoseAtDay(day)),
    filter(Boolean), // removes zero/undefined levels from array
    calcDoseBuildUp
  )(doses);
};

export const calcAccumulatedLevelForHour = (scenarioHour, doses) => {
  return pipe(
    map(getLevelForDoseAtHour(scenarioHour)),
    filter(Boolean), // removes zero/undefined levels from array
    calcDoseBuildUp
  )(doses);
};

/**
 * Remove all leading zero data points, except for the one at which the y-value starts to increase
 * @param {[[x, y]]} levels
 * @returns
 */
export const trimLeadingZeros = levels => {
  const isZero = level => level && level[1] === 0;
  let lastZeroIndex = -1;

  while(isZero(levels[lastZeroIndex + 1])) {
    lastZeroIndex++;
  }

  return lastZeroIndex > -1 ? levels.slice(lastZeroIndex) : levels;
};

/**
 * Removes trailing zeros from the given array of dose levels
 * @param {[[time, level]]} levels
 */
export const removeTrailingZeros = levels => {
  const copyOfLevels = [...levels];
  while(copyOfLevels[copyOfLevels.length - 1][1] === 0) {
    copyOfLevels.pop();
  }
  return copyOfLevels;
};

/**
 * Get accumulated dose levels ** per day ** in scenario
 */
export const calcAccumulatedDoseLevelPerScenarioDay = (doses, scenarioDays, doTrimLeadingZeros) => {

  return pipe(
    map(day => calcAccumulatedLevelForDay(day, doses)),
    map((accDose, scenarioDayIndex) => {
      // converts the level value into an array with corresponding observation hour
      const totalHrsUntilScenarioDay = scenarioDays[scenarioDayIndex] * 24;
      return [[totalHrsUntilScenarioDay, accDose]];
    }),
    levels => doTrimLeadingZeros ? trimLeadingZeros(levels) : levels,
    removeTrailingZeros,
  )(scenarioDays);
};

/**
 * Get accumulated dose levels ** per hour ** in scenario
 */
export const calcAccumulatedDoseLevelPerScenarioHour = (doses, scenarioHours) => {
  return pipe(
    map(hour => calcAccumulatedLevelForHour(hour, doses)),
    map((totalLevelForHour, scenarioHourIndex) => {
      // converts the level value into an array with corresponding observation hour
      const scenarioHour = scenarioHours[scenarioHourIndex];
      return [[scenarioHour, totalLevelForHour]];
    }),
  )(scenarioHours);
};

export const calcAccumulatedHourlyDoseLevels = (doses, scenarioDays) => {

  const scenarioHours = range(scenarioDays[0] * 24, last(scenarioDays) * 24);

  // group doses by how their levels data is tracked (daily or hourly)
  const dosesWithHourlyLevels = doses.filter(doseHasHourlyLevels);
  const dosesWithDailyLevels = doses.filter(doseHasDailyLevels);

  const hourlyLevels = calcAccumulatedDoseLevelPerScenarioHour(dosesWithHourlyLevels, scenarioHours);

  if (dosesWithDailyLevels.length === 0) {
    return pipe(removeTrailingZeros)(hourlyLevels);
  }

  const dailyLevels = calcAccumulatedDoseLevelPerScenarioDay(dosesWithDailyLevels, scenarioDays);
  const switchingPoint = dosesWithDailyLevels[0].xstart;

  // merge hourly and daily levels
  const mergedLevels = pipe(
    map((datum, hourIndex) => {
      const hour = datum[0];
      let level = datum[1];
      const isAt24HrMark = hourIndex % 24 === 0;
      if (isAt24HrMark) {
        const dailyLevel = dailyLevels[hourIndex / 24][1];
        level += dailyLevel;
      }
      return [[ hour, level ]];
    }),
    filter(datum => {
      const hour = datum[0];
      return hour < switchingPoint || hour % 24 === 0;
    })
  )(hourlyLevels);

  return mergedLevels;
};

/**
 * Calculate the accumulated dose level per every day in the scenario
 * */
export const buildCumulativeDoseCurve = scenario => {
  return doses => {
    const hasHourlyDoseLevels = doses.some(doseHasHourlyLevels);
    const minDay = scenario.minHorizontalScroll;
    const maxDay = scenario.maxHorizontalScroll;
    const scenarioDays = range(minDay, maxDay);
    const isColoredDoseLine = doses.every(({ color }) => Boolean(color));
    const color = isColoredDoseLine ? doses[0]?.color : theme.line.style.data.color;
    const isBlueCurve = doses[0].inBlueCurve;
    const isGreenCurve = doses[0].inGreenCurve;

    let accumulatedLevels = [];

    if (hasHourlyDoseLevels) {
      // process hourly dose level data
      accumulatedLevels = calcAccumulatedHourlyDoseLevels(doses, scenarioDays);
    } else {
      // process daily dose level data
      accumulatedLevels = calcAccumulatedDoseLevelPerScenarioDay(doses, scenarioDays, true);
    }

    const accumulatedDose = {
      isCumulative: true,
      isCurveVisible: true,
      isVisible: true,
      data: map(mapDrugLevelsToGraphDatum(0))(accumulatedLevels),
      color,
      isBlueCurve,
      isGreenCurve,
    };

    return accumulatedDose;
  };
};
