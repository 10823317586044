import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VictoryGroup, VictoryScatter, VictoryTooltip } from 'victory';
import DoseTooltip from '../DoseTooltip/DoseTooltip';
import DoseTab from '../DoseTab/DoseTab';
import DoseAdjustableRange from '../DoseAdjustableRange/DoseAdjustableRange';
import DoseTabTrack from './DoseTabTrack';

const propTypes = {
  doses: PropTypes.array,
  onUpdateStart: PropTypes.func,
  onDoseEdit: PropTypes.func,
};

const ChartControls = props => {

  const { doses, maxDay, minLevel, minDay, onUpdateStart, onDoseEdit } = props;

  const anchorDose = doses.find(dose => dose.anchor) || null;

  const [activeDose, setActiveDose] = useState(anchorDose);

  return (
    <>
      <DoseTabTrack maxDay={maxDay} minDay={minDay} minLevel={minLevel} {...props} />
      <DoseAdjustableRange activeDose={activeDose} {...props} />
      <VictoryGroup {...props}>
        {doses
          .filter(dose => !dose.isCumulative)
          .map((dose, index) => {
            return (
              <VictoryScatter
                key={`${dose.id}-${index}`}
                data={[{ x: dose.xstart, y: 0 }]}
                style={{
                  data: {
                    fill: 'none',
                    pointerEvents: 'none'
                  }
                }}
                labels={[' ']}
                labelComponent={
                  <VictoryTooltip
                    active={true}
                    flyoutComponent={
                      <DoseTooltip
                        isActive={activeDose !== null && activeDose.index === dose.index}
                        anchorOffset={{ x: 0, y: 35 }}
                      >
                        <DoseTab
                          dose={dose}
                          onDoseEdit={onDoseEdit}
                          onUpdateStart={onUpdateStart}
                          setActiveDose={setActiveDose}
                        />
                      </DoseTooltip>
                    }
                  />
                }
              />
            );
          })}
      </VictoryGroup>
    </>
  );
};

ChartControls.propTypes = propTypes;
export default ChartControls;
