import isFunction from 'lodash/isFunction';
import { map } from './reducers';

/**
 * takes in an object and merges it with the dose object.
 * If the values of the object keys are functions they get called
 * with the dose as an arg.
 * {
 *  prop1: value(dose),
 *  prop2: value(dose)
 * }
 */
export const addDoseProperty = propObj => {
  return map(dose => {
    //  if any prop values are functions call them with the dose as the arg
    const addedProps = Object.fromEntries(
      Object.entries(propObj)
        .map(([key, value]) => {
          return [
            key,
            isFunction(value) ? value(dose): value
          ];
        })
    );
    return {...dose, ...addedProps};
  })
}

/** make dose.xstart an integer */
export const coerceXStartPropToInt = dose => {
  return {
    ...dose,
    xstart: parseInt(dose.xstart, 10)
  };
};

/** adjust the x-axis value of a dose to the x-axis of the scenario day range */
export const offsetXStartDayToScenarioDay = scenarioOffset => {
  const offset = scenarioOffset > 0 ? scenarioOffset : 0
  return dose => dose.xstart - offset;
};

/**
 * Remove dose props from scenario json data that are not used by this app
 * @param {object} dose
 */
export const removeCruftProps = dose => {
  delete dose.json;
  delete dose.notCaptain;
  delete dose.pp3mAmount;
  delete dose.tooltipPoints;
  return dose;
};
