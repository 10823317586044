/* eslint jsx-a11y/no-noninteractive-tabindex: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FocusService from '../services/FocusService';

class FocusTrap extends Component {
    static propTypes = {
        /**
         * Toggle the bottom focus trap on and off
         */
        isBottomActive: PropTypes.bool,

        /**
         * Toggle the top focus trap on and off
         */
        isTopActive: PropTypes.bool,
        /**
         * Content to display within the focus trap
         */
        children: PropTypes.node,
        /**
         * Callback function when the bottom focus trap is hit
         */
        onBottomFocus: PropTypes.func,
        /**
         * Callback function when the top focus trap is hit
         */
        onTopFocus: PropTypes.func
    };

    static defaultProps = {
        isBottomActive: true,
        isTopActive: true,
        onBottomFocus: () => {},
        onTopFocus: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            focusableElements: []
        };
    }

    componentDidMount() {
        const focusableElements = this.findFocusableElements();
        this.setState({ focusableElements });
    }

    findFocusableElements() {
        const elements = FocusService.getFocusableElementsIn(this.container);
        // remove the first and last items in the array, which are the focus traps
        elements.shift();
        elements.pop();
        return elements;
    }

    handleTopFocus = event => {
        const { onTopFocus } = this.props;
        FocusService.focusElement(this.lastFocusableElement);
        onTopFocus(event);
    };

    handleBottomFocus = event => {
        const { onBottomFocus } = this.props;
        FocusService.focusElement(this.firstFocusableElement);
        onBottomFocus(event);
    };

    get firstFocusableElement() {
        const { focusableElements } = this.state;
        return focusableElements[0];
    }

    get lastFocusableElement() {
        const { focusableElements } = this.state;
        return focusableElements[focusableElements.length - 1];
    }

    render() {
        const { children, isBottomActive, isTopActive } = this.props;
        return (
            <div
                className="focus-trap"
                ref={node => {
                    this.container = node;
                }}
            >
                <span
                    className="accessibly-hidden"
                    tabIndex={isTopActive ? '0' : '-1'}
                    onFocus={this.handleTopFocus}
                />
                {children}
                <span
                    className="accessibly-hidden"
                    tabIndex={isBottomActive ? '0' : '-1'}
                    onFocus={this.handleBottomFocus}
                />
            </div>
        );
    }
}

export default FocusTrap;
