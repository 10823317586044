import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { VictoryGroup, VictoryLine, VictoryLabel } from 'victory';
import GraphContext from '../GraphContext';
import { getDoseByOriginalStartDay, isDoseDayShiftable } from '../../modules/doseUtils';

const propTypes = {
  switchLineData: PropTypes.array,
  yDomain: PropTypes.arrayOf(PropTypes.number),
};

const normalizeLabelText = text => text.replace('<br />', ' ');

function SwitchLines(props) {
  const { switchLineData, yDomain } = props;
  const { doses, metadata } = useContext(GraphContext);

  if (!switchLineData) return null;

  let dayShift = 0;

  const switchLineArray = switchLineData.map((line, index) => {
    const { x, color, width, label } = line;
    const labelText = normalizeLabelText(label.text);
    let switchLineDay = parseInt(x);

    // check if the start day of the dose associated with this switch line has been updated
    const switchDose = getDoseByOriginalStartDay(doses, switchLineDay);
    const switchDoseIsShiftable = isDoseDayShiftable(switchDose, metadata);
    if (switchDoseIsShiftable) {
      const { xstart } = switchDose;
      // update this and future shift lines with the difference in start day
      dayShift = xstart - switchLineDay;
    }

    return (
      <VictoryLine
        {...props}
        animate={false}
        data={[
          { x: switchLineDay + dayShift, y: yDomain[0] },
          { x: switchLineDay + dayShift, y: yDomain[1] },
        ]}
        key={`${x}-${index}`}
        style={{
          data: {
            stroke: color,
            strokeWidth: width,
          },
          labels: {
            angle: -90,
            fill: label && label.color || color,
          },
        }}
        labels={[labelText]}
        labelComponent={
          <VictoryLabel dy={-5} y={20} textAnchor="end" />
        }
      />
    );
  });

  return <VictoryGroup {...props}>{switchLineArray}</VictoryGroup>;
}

SwitchLines.propTypes = propTypes;
export default SwitchLines;
