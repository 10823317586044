import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import throttle from 'lodash/throttle';
class DoseTooltip extends React.Component {

  static propTypes = {
    isActive: PropTypes.bool,
    anchorOffset: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  };

  static defaultProps = {
    anchorOffset: { x: 0, y: 0 },
  };

  constructor(props) {
    super(props);

    this.tooltipAnchor = React.createRef();

    this.state = {
      top: 0,
      left: 0,
      isPositioned: false
    };

    this.windowResizeHandler = throttle(this.handleResize.bind(this), 10);

    window.addEventListener('resize', this.windowResizeHandler);
  }

  calcTooltipPosition = force => {
    const { x, y } = this.props;
    const node = this.tooltipAnchor.current;

    if (node && (this.state.x !== x || force)) {
      const container = this.doseTabContainer.getBoundingClientRect();
      const position = node.getBoundingClientRect();
      const left = Math.round(position.left - container.left);
      const top = Math.round(position.top - container.top);

      this.setState((prevState) => {
        return {
          x,
          y,
          left,
          top,
          isPositioned: prevState.left !== 0 && left !== 0
        };
      });
    }
  };

  handleResize = () => {
    this.calcTooltipPosition(true);
  };

  componentDidMount() {
    this.calcTooltipPosition();
  }

  componentDidUpdate(prevProps) {
    // x and y are inherited Victory props
    const { x, y } = this.props;

    if (prevProps.x !== x || prevProps.y !== y) {
      this.calcTooltipPosition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  get doseTabContainer() {
    return document.getElementById('dose-tab-portal');
  }

  render() {
    const { isActive, x, y, anchorOffset, children } = this.props;
    const { left, top, isPositioned } = this.state;

    return (
      <g>
        <circle
          className="chart-anchor"
          cx={x + anchorOffset.x}
          cy={y + anchorOffset.y}
          r="3"
          ref={this.tooltipAnchor}
          style={{ fill: 'none' }}
        />
        <Portal isOpened={true} node={this.doseTabContainer}>
          <div
            className={classnames('edi-dose-tooltip', {
              'edi-dose-tooltip--active': isActive,
              'edi-dose-tooltip--positioned': isPositioned,
            })}
            style={{ left, top }}
          >
            {children}
          </div>
        </Portal>
      </g>
    );
  }
}
export default DoseTooltip;
