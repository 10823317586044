import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import { BLUE_CURVE } from '../../modules/data';
import { shouldDoseTabAlignLeft } from '../../modules/helpers';
import { getDoseBorderColor, getDoseDisplayLocation, getDoseDraggableBounds, isDoseDayShiftable } from '../../modules/doseUtils';
import { areDomainsTheSame, getOffsetToFitDoseInDomain, getSafeDomainForDoseTabs, isDayWithinDomain } from '../../modules/domains';
import { doesDoesTabOverlap, doesSingleDoseCurveTabOverlap } from '../../modules/doseOverlapUtils';
import EditDose from '../EditDose/EditDose';
import GraphContext from '../GraphContext';
import DoseShiftLabel from './DoseShiftLabel';
import DoseShiftButtons from './DoseShiftButtons';
import DailyDosageDots from './DailyDosageDots';

const propTypes = {
  dose: PropTypes.object,
  onUpdateStart: PropTypes.func,
  onDoseEdit: PropTypes.func,
  calcDoseTabOverlap: PropTypes.func,
};

const DoseTab = props => {

  const {
    dose,
    setActiveDose,
    onUpdateStart,
    onDoseEdit,
  } = props;

  if (!dose.isVisible) {
    return null;
  }

  const {
    amount,
    anchor,
    formulation,
    isInitiationDose,
    location,
    index,
    numberOfDoses,
    addDisabledClass,
    disableDoseChanging,
    disableTypeChanging,
    showOnlyBullet,
    showOralTooltip,
    xstart,
  } = dose;

  /**
   * Context from parent Graph state
   */
  const {
    doses,
    handleDomainChanges,
    handleUpdateStart,
    metadata,
    pixelsPerDay,
    zoomDomain
  } = useContext(GraphContext);

  /**
   * Local state
   */
  const [isActive, setActive] = useState(anchor || false);
  const [editFormActive, setEditFormActive] = useState(anchor || false);
  const [isOverlapping, setOverlapping] = useState(false);
  const [draggablePosition, setDraggablePosition] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  /**
   * Refs
   */
  const doseTabRef = useRef();
  const doseTabContainerRef = useRef();
  const previousDomainRef = useRef(zoomDomain);

  /**
   * Attach event listeners to handle clicks outside of the dose tab
   * Runs whenever isActive changes, does not trigger a render
   */
  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);

  /**
   * Sets the overlapping class, which prevents overlapping of previous dose tabs
   * Runs whenever doseTabContainerRef, draggablePosition, zoomDomain, or metadata change
   */
  useEffect(() => {
    const domainHasChanged = !areDomainsTheSame(zoomDomain, previousDomainRef.current);
    // wait for animation to complete if domain is changing
    const animationDelay = domainHasChanged ? 500 : 0;
    previousDomainRef.current = zoomDomain;

    const isSingleDoseCurveView = metadata.singleDoseCurveView;

    setTimeout(() => {
      if (doseTabContainerRef.current) {
        const { left, right } = doseTabContainerRef.current.getBoundingClientRect();
        const overlaps = isSingleDoseCurveView
          ? doesSingleDoseCurveTabOverlap(doses, index, left, right)
          : doesDoesTabOverlap(doses, index, left, right);
        setOverlapping(overlaps);
      }
    }, animationDelay);

  }, [doseTabContainerRef, draggablePosition, zoomDomain, metadata]);

  /**
   * Update the domain if this active dose tab is not within the visible domain
   * Runs whenever isActive changes
   */
  useEffect(() => {
    if (!isActive) return;

    const safeDomain = getSafeDomainForDoseTabs(zoomDomain);
    const isDoseInSafeDomain = isDayWithinDomain(xstart, safeDomain);

    if (!isDoseInSafeDomain) {
      // update visible domain so that the active dose is within the "safe" zone
      const pan = getOffsetToFitDoseInDomain(xstart, safeDomain);
      handleDomainChanges({ pan });
    }
  }, [isActive]);

  const activateTab = () => {
    // wait until next tick to activate,
    // in case another tab is being deactived at the same time
    setActive(true);

    setTimeout(() => {
      setActiveDose(dose);
    }, 0);
  };

  const deactivateTab = () => {
    setActive(false);
    setActiveDose(null);
    setEditFormActive(false);
  };

  const handleClick = () => {
    if (isDragging) {
      setIsDragging(false);
      return;
    }

    activateTab();
    setEditFormActive(true);
  };

  const handleClickOutside = e => {
    if (doseTabRef.current.contains(e.target)) {
      // click inside dose tab
      return;
    }
    // close dose tab on outside clicks
    deactivateTab();
  };

  const handleDraggableStart = () => {
    activateTab();
  };

  const handleDraggableDrag = () => {
    setIsDragging(true);
  };

  const handleDraggableStop = (event, data) => {
    const { x } = data;
    const dayShift = Math.round(x / pixelsPerDay);
    if (dayShift) {
      handleUpdateStart(dose, dayShift);
      setDraggablePosition({ x: 0, y: 0 });
    }
  };

  const isShiftable = isDoseDayShiftable(dose, metadata);

  const isDisabled =
    addDisabledClass || // manual disabled class from CMS
    (!isShiftable && disableDoseChanging && disableTypeChanging);

  const isOralInvegaER = formulation === 'ER';
  const inBlueCurve = location in BLUE_CURVE;
  const showEditTab = !inBlueCurve || (inBlueCurve && showOralTooltip && !showOnlyBullet);
  const showBullet = !inBlueCurve || (inBlueCurve && (showOralTooltip || showOnlyBullet));
  const borderBottomColor = getDoseBorderColor(dose, metadata);
  const draggableBounds = getDoseDraggableBounds(dose, pixelsPerDay);
  const disableDraggable = !isShiftable || metadata.pp3mScenario;
  const displayLocation = getDoseDisplayLocation(dose);

  let doseTagStyle = {};

  if (isOralInvegaER) {
    doseTagStyle = {
      width: Math.round(pixelsPerDay * numberOfDoses)
    };
  }

  return (
    <Draggable
      axis="x"
      bounds={draggableBounds}
      cancel=".edit-dose, .dose-tab-shift, .dose-tab-shift-btn"
      disabled={disableDraggable}
      grid={[pixelsPerDay, 0]}
      position={draggablePosition}
      onStart={handleDraggableStart}
      onDrag={handleDraggableDrag}
      onStop={handleDraggableStop}
    >
      <div
        className={classNames('dose-tab', {
          'dose-tab--active': isActive,
          'dose-tab--initiation': isInitiationDose,
          'dose-tab--overlap': isOverlapping,
          'dose-tab--align-left': shouldDoseTabAlignLeft(dose, metadata),
          'dose-tab--oral-er': isOralInvegaER,
        })}
        ref={doseTabRef}
      >
        {showBullet && (
          <div className={classNames('dose-tab-knob', {
            'dose-tab-knob--editable': showEditTab,
            'dose-tab-knob--shiftable': isShiftable,
          })}>
            {isOralInvegaER && (
              <DailyDosageDots numberOfDoses={numberOfDoses} zoomDomain={zoomDomain} />
            )}
          </div>
        )}
        {showEditTab && (
          <>
            <EditDose
              isActive={editFormActive}
              dose={dose}
              onDoseEdit={(...args) => {
                deactivateTab();
                onDoseEdit(...args);
              }}
              onClose={deactivateTab}
            />
            <div
              className={classNames('dose-tab-container', {
                'dose-tab--disabled': isDisabled,
              })}
              onClick={handleClick}
              ref={doseTabContainerRef}
            >
              <div className="dose-tab-tag" style={doseTagStyle}>
                <div className="dose-tab-content" style={{ borderBottomColor }}>
                  <span className="dose-tab-text dose-tab-text--amount">
                    {`${amount}`}
                  </span>
                  <span className="dose-tab-text dose-tab-text--location">
                    {`${displayLocation}`}
                  </span>
                  {!isDisabled && (
                    <strong className="dose-tab-text dose-tab-text--edit">
                      EDIT
                    </strong>
                  )}
                </div>
                <DoseShiftButtons
                  isActive={isActive}
                  dose={dose}
                  metadata={metadata}
                  onDoseShift={onUpdateStart}
                />
              </div>
              <DoseShiftLabel isActive={isActive} dose={dose} metadata={metadata} />
            </div>
          </>
        )}
      </div>
    </Draggable>
  );
};

DoseTab.propTypes = propTypes;
export default DoseTab;
