
const doseTabPositions = {};

/**
 * Does the dose tab at the given index overlap previous dose tabs?
 * @param {[object]} doses
 * @param {number} doseIndex
 * @param {number} left
 * @param {number} right
 */
export const doesDoesTabOverlap = (doses, doseIndex, left, right) => {

  const previousTabOverlaps = doseTabPositions[doseIndex - 1]?.overlaps;

  const overlapsPreviousTab = previousTabOverlaps ? false : doses.some(dose => {
    if (dose.index < doseIndex) {
      const prevDosePosition = doseTabPositions[dose.index];
      if (!prevDosePosition) {
        return false;
      }
      return left < prevDosePosition.right;
    }
    return false;
  });

  doseTabPositions[doseIndex] = { left, right, overlaps: overlapsPreviousTab };

  return overlapsPreviousTab;
};

export const doesSingleDoseCurveTabOverlap = (doses, doseIndex, left, right) => {

  const overlapsAnotherTab = Object.keys(doseTabPositions).some(index => {
    if (doseIndex === Number(index)) return false;

    const otherDosePosition = doseTabPositions[index];

    return !otherDosePosition.overlaps && left < otherDosePosition.right;
  });

  doseTabPositions[doseIndex] = { left, right, overlaps: overlapsAnotherTab };

  return overlapsAnotherTab;
};

export const clearDoseTabPosition = doseIndex => {
  delete doseTabPositions[doseIndex];
};
