import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import throttle from 'lodash/throttle';
import { VictoryTooltip } from 'victory';
import InfoBox from './InfoBox';
class InfoBoxContainer extends React.Component {
  static defaultEvents = VictoryTooltip.defaultEvents;

  static propTypes = {
    /**
     * positioning of the anchor Dot for the currently active flyout
     */
    anchorPosition: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    /**
     * tooltip data for the related dose data point
     */
    tooltip: PropTypes.object,
    /**
     * offset to use when positioning tooltip relative to the Dot anchor
     */
    tooltipOffset: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    /**
     * state function
     * used to close the flyout on close button click
     */
    setFlyout: PropTypes.func,
    cycleFlyout: PropTypes.func,
  };

  static defaultProps = {
    anchorPosition: {
      x: null,
      y: null,
    },
    tooltipOffset: {
      x: 40,
      y: -80,
    },
  };

  constructor(props) {
    super(props);

    this.tooltipAnchor = React.createRef();

    this.state = {
      top: 0,
      left: 0,
      x: 0,
      y: 0,
    };

    this.throttledResizeHandler = throttle(this.handleResize.bind(this), 10);

    window.addEventListener('resize', this.throttledResizeHandler);
    window.addEventListener('scroll', this.throttledResizeHandler);
  }

  componentDidMount() {
    this.calcTooltipPosition();
  }

  componentDidUpdate(prevProps) {
    const { x, y } = this.props.anchorPosition;
    const { prevX, prevY } = prevProps.anchorPosition;

    if (prevX !== x || prevY !== y) {
      this.calcTooltipPosition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeHandler);
    window.removeEventListener('scroll', this.throttledResizeHandler);
  }

  calcTooltipPosition = () => {
    const {
      anchorPosition: { x, y },
    } = this.props;
    const { left, top } = this.tooltipAnchor.current.getBoundingClientRect();
    if (this.state.x !== x) {
      this.setState({
        left,
        top,
        x,
        y,
      });
    }
  };

  handleResize = () => {
    const { left, top } = this.tooltipAnchor.current.getBoundingClientRect();
    this.setState({ left, top });
  };

  render() {
    const { anchorPosition, tooltip, setFlyout, cycleFlyout, tooltipOffset } = this.props;
    let { left, top } = this.state;

    return (
      <>
        <circle
          className="info-box-anchor"
          fill="none"
          cx={anchorPosition.x}
          cy={anchorPosition.y}
          r="5"
          ref={this.tooltipAnchor}
        />
        <Portal node={document && document.getElementById('tooltip-portal')}>
          <InfoBox
            style={{
              left: left + tooltipOffset.x,
              top: top + tooltipOffset.y,
            }}
            tooltip={tooltip}
            setFlyout={setFlyout}
            cycleFlyout={cycleFlyout}
          />
        </Portal>
      </>
    );
  }
}

export default InfoBoxContainer;
