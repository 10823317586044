import React from 'react';
import classNames from 'classnames';

const DoseShiftButton = props => {

  const {
    dose,
    children,
    isNext,
    onClick,
  } = props;

  const {
    relativeExtremes,
    rootStart,
    xstart
  } = dose;

  const maxDay = rootStart + relativeExtremes.max;
  const minDay = rootStart + relativeExtremes.min;
  const atMinStart = xstart <= minDay;
  const atMaxStart = xstart >= maxDay;
  const showButton = isNext ? !atMaxStart : !atMinStart;

  if (!showButton) {
    return null;
  }

  return (
    <button
      type="button"
      className={classNames('dose-tab-shift-btn', {
        'btn--next': isNext,
        'btn--prev': !isNext,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );

};

export default DoseShiftButton;
