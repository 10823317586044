import React from 'react';
import { VictoryGroup, VictoryArea, VictoryLine } from 'victory';
import { medianConcentrationRange } from '../../modules/config';

const MedianRange = props => {
  const { maxDay, minDay, showMedianRange } = props;
  const medianRangeLow = medianConcentrationRange[0];
  const medianRangeHigh = medianConcentrationRange[1];

  if (!showMedianRange) return null;

  return (
    <VictoryGroup {...props}>
      <VictoryArea
        animate={false}
        name="median-range"
        data={[
          { x: minDay, y: medianRangeHigh, y0: medianRangeLow },
          { x: maxDay, y: medianRangeHigh, y0: medianRangeLow },
        ]}
        style={{
          data: {
            fill: '#EBF6F1',
            stroke: '#bde4d7',
            strokeWidth: 1,
          },
        }}
      />
      <VictoryLine
        animate={false}
        data={[
          { x: minDay, y: medianRangeLow },
          { x: maxDay, y: medianRangeLow },
        ]}
        style={{
          data: {
            stroke: '#bde4d7',
            strokeWidth: 2,
          },
        }}
      />
    </VictoryGroup>
  );
};

export default MedianRange;
