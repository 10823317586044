import { allowedAmountsByScenario } from './data';
import uniq from 'lodash/uniq';
import isObject from 'lodash/isObject';

/**
 * get amount and location options for a dose
 * @param {object} dose
 */
export const getLimitOptions = (dose, doses, metadata) => {

  const { isOralDose, isInitiationDose, limitOptions } = dose;

  let amountOptions = getDefaultAmountOptionsForDose(dose, metadata);
  let locationOptions = isOralDose ? ['Oral'] : ['Deltoid', 'Gluteal'];

  if (isInitiationDose && !amountOptions.includes(dose.amount)) {
    // for renal impairment scenarios, the initiation doses do not have a default amount option
    // it's not changeable, but it needs to be added so that it can be displayed as the selected option
    amountOptions.push(dose.amount);
  }

  if (Array.isArray(limitOptions) && limitOptions.length) {

    /**
     * For limitOptions arrays,
     * ensure amounts are allowed before adding as options
     */
    amountOptions = uniq(
      limitOptions
        .filter(item => amountOptions.includes(`${item.dose}mg`))
        .map(item => `${item.dose}mg`)
    );

    locationOptions = uniq(limitOptions.map(item => item.type));

  } else if (isObject(limitOptions)) {

    if ('previousOralDose' in limitOptions) {
      amountOptions = getAmountOptionsBasedOnPrevOralDose(limitOptions, doses) || amountOptions;
    } else if (limitOptions.doseControls?.length) {
      amountOptions = limitOptions.doseControls[0].range;
    }

    if ('oral' in limitOptions) {
      locationOptions = [{ label: 'Oral', value: limitOptions.oral }];
    }
  }

  amountOptions = restrictPp1mAmountsInPp3m(amountOptions, dose, doses, metadata);
  amountOptions = restrictPp1mAmountsInPp3mRc(amountOptions, dose, doses, metadata);

  return { amountOptions, locationOptions };
};

/**
 * Ruleset to determine the scenario type based on a provided dose and scenario metadata
 */
export const scenarioTypeRules = {
  pp1mOralRisperdal: (dose) => dose.location === 'OralRisperdal',
  pp3mRisperdal: (dose, metadata) => metadata.pp3mScenario && dose.formulation === 'RL',
  pp3mInjectable: (dose, metadata) => metadata.pp3mScenario && dose.location === 'HaldolDecoanate',
  pp3mOralInvegaEr: (dose, metadata) => metadata.pp3mScenario && dose.formulation === 'ER',
  pp1mSchizoaffective: (dose, metadata) => metadata.pp1mSchizoaffective,
  pp3mSchizophrenia: (dose, metadata) => metadata.pp3mScenario && dose.formulation === '3M',
  renalImpairment: (dose, metadata) => metadata.isRenalImpairment && dose.formulation === '1M',
};

/**
 * Get scenario type based on the given dose and scenario metadata
 * @param {object} dose
 * @param {object} metadata
 */
export const getScenarioType = (dose, metadata) => {
  const defaultScenarioType = 'pp1mSchizophrenia';

  const scenarioType = Object.keys(scenarioTypeRules)
    .reduce((match, scenarioType) => {
      if (scenarioTypeRules[scenarioType](dose, metadata)) {
        return scenarioType;
      }
      return match;
    }, defaultScenarioType);

  return scenarioType;
};

/**
 * Get default amount options for the given dose and scenario metadata
 * @param {object} dose
 * @param {object} metadata
 */
export const getDefaultAmountOptionsForDose = (dose, metadata) => {
  const scenarioType = getScenarioType(dose, metadata);
  const defaultAmounts = allowedAmountsByScenario[scenarioType];
  return defaultAmounts;
};

/**
 * Restrict amount options for current dose based on previous oral dose amount
 * @param {object} limitOptions
 * @param {[object]} doses
 */
export const getAmountOptionsBasedOnPrevOralDose = (limitOptions, doses) => {
  let amountOptions = null;

  const previousOralDose = doses.find(
    // id can be either a string or number, hence the loose `==` evaluation
    /** @TODO normalize data to make id a consistent type */
    dose => dose.id == limitOptions.previousOralDose
  );

  if (previousOralDose) {

    const doseControls = limitOptions.doseControls.find(
      control => control.previousOralDoseAmount === previousOralDose.amount
    );

    if (doseControls) {
      amountOptions = doseControls.range;
    }
  }

  return amountOptions;
};

/**
 * Restrict amount options for current PP1M dose in PP3M scenario based on previous dose amount
 * @param {[string]} amountOptions
 * @param {object} dose
 * @param {[object]} doses
 * @param {object} metadata
 */
export const restrictPp1mAmountsInPp3m = (amountOptions, dose, doses, metadata) => {
  const isPp3mScenario = metadata.pp3mScenario;
  const isPp1mDose = dose.formulation === '1M';
  const isTargetDose = dose.index > 2 && dose.index < 6;
  const matchesCondition = isPp3mScenario && isPp1mDose && isTargetDose;

  if (!matchesCondition) {
    return amountOptions;
  }

  // restrict the amount options for this dose to the amount of the previous dose,
  // plus one "step" above and below that amount
  // e.g. given the array of amount options [39, 78, 117, 156, 234]...
  // if the previous dose amount is set to 117mg,
  // then restrict the amount options for this dose to 78mg(-1), 117mg, and 156mg(+1)

  const previousDose = doses[dose.index - 1];
  const previousDoseAmount = previousDose?.amount;

  const restrictedOptions = amountOptions.reduce((results, option, index, arr) => {
    if (option === previousDoseAmount) {
      results = [arr[index - 1], option, arr[index + 1]]
        .filter(opt => Boolean(opt))
        .filter(opt => {
          const remove = opt === '39mg' && dose.index > 3;
          if (remove) {
            return false;
          }
          return true;
        });
    }
    return results;
  }, []);

  return restrictedOptions;
};

/**
 * Restricts amount options for current PP1M dose in PP3M scenarios 956 & 976
 * Based on the amount of the previous dose
 *
 * @param {array} amountOptions
 * @param {object} dose
 * @param {array} doses
 * @param {object} metadata
 */
export const restrictPp1mAmountsInPp3mRc = (amountOptions, dose, doses, metadata) => {

  const isPp1mDose = dose.formulation === '1M';
  const hasPp3mRisperdalDoses = doses.some(dose => scenarioTypeRules.pp3mRisperdal(dose, metadata));
  const hasPp3mInjectableDoses = doses.some(dose => scenarioTypeRules.pp3mInjectable(dose, metadata));

  // targets editable PP1M doses in scenarios 956 and 975
  const isTargetDose = ['2', '7', '8'].includes(dose.id);
  const matchesCondition = (hasPp3mRisperdalDoses || hasPp3mInjectableDoses) && isPp1mDose && isTargetDose;

  if (!matchesCondition) {
    return amountOptions;
  }

  const previousDose = doses[dose.index - 1];
  const previousDoseAmount = previousDose?.amount;

  const restrictedOptions = amountOptions.reduce((results, option, index, arr) => {
    if (option === previousDoseAmount) {
      results = [arr[index - 1], option, arr[index + 1]].filter(opt => Boolean(opt));
    }
    return results;
  }, []);

  return restrictedOptions;
};

// dose data examples for limitOptions:

// oral dose dependent on a previous oral dose
// "limitOptions": {
//   "oral": "InvegaER",
//   "previousOralDose": "0",
//   "doseControls": [{
//       "previousOralDoseAmount": "6mg",
//       "range": ["3mg", "6mg", "9mg"]
//   }]
// },

// non-oral dose dependent on a previous oral dose
// "limitOptions": {
//   "previousOralDose": "10",
//   "doseControls": [{
//       "previousOralDoseAmount": "12mg",
//       "range": ["234mg"]
//   },{
//       "previousOralDoseAmount": "9mg",
//       "range": ["156mg"]
//   },{
//       "previousOralDoseAmount": "6mg",
//       "range": ["117mg"]
//   },{
//       "previousOralDoseAmount": "3mg",
//       "range": ["39mg", "78mg"]
//   }]
// },

// array of options
// "limitOptions": [{
//   "type": "Deltoid",
//   "dose": 117
// },{
//   "type": "Gluteal",
//   "dose": 117
// }],
