import React from 'react';
import { VictoryClipContainer, VictoryLine } from 'victory';

const DoseTabTrack = props => {

  const { maxDay, minDay, minLevel, ...passThroughProps } = props;

  return (
    <>
      <VictoryLine
        {...passThroughProps}
        animate={false}
        data={[
          { x: minDay, y: minLevel },
          { x: maxDay, y: minLevel },
        ]}
        groupComponent={
          <VictoryClipContainer clipPadding={{ bottom: 100 }} transform="translate(0 32)" />
        }
      />
      <VictoryLine
        {...passThroughProps}
        animate={false}
        data={[
          { x: minDay, y: minLevel },
          { x: maxDay, y: minLevel },
        ]}
        groupComponent={
          <VictoryClipContainer clipPadding={{ bottom: 100 }} transform="translate(0 41.5)" />
        }
      />
    </>
  );
}

export default DoseTabTrack;
