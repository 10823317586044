import sortBy from 'lodash/sortBy';
import { map, pipe } from './reducers';
import {
  addDoseProperty,
  coerceXStartPropToInt,
  offsetXStartDayToScenarioDay,
  removeCruftProps
} from './dosePropReducers';
import {
  getRelativeExtremes,
  getDoseMaximumShift,
  getDoseOriginalStartDay,
  getRootStart,
  isDoseEditable,
  isOralDose,
  processDoseName
} from './doseUtils';

/**
 * Get the specific drug levels array (from data.js) for a dose by key
 * ex: observation["Deltoid:156mg"]
 */
export const getDrugLevelObservationsForDose = drugLevelObservations => {
  return dose => {
    const { key } = dose;
    return drugLevelObservations[key];
  };
};

/**
 * Converts our raw drugLevelObservation (drugData) to
 * an object adjusted for the context of the scenario.
 * Adjusts the day to match the scenarios x-axis
 * */
export const mapDrugLevelsToGraphDatum = (xAxisStartDayOffset = 0) => {
  return ([rawDrugLevelHrs, rawDrugLevel]) => {

    const observationDay = parseInt(rawDrugLevelHrs) / 24;
    /**
    * since drug level observations (drugData from data.js)
    * all start with a day 0 we need to shift each dose's
    * start day to the context of the scenario
    */

    /** adjust our observation start day to start within the scenario */
    const scenarioStartDay = observationDay + xAxisStartDayOffset;

    const graphDatum = {
      obsvr_hours__raw: rawDrugLevelHrs,
      obsvr_day: observationDay,
      day: scenarioStartDay,
      hour: scenarioStartDay * 24,
      level: rawDrugLevel
    };

    return graphDatum;
  };
};

/**
 * Takes in drug level data and the scenario and
 * adds properties to dose objects
 *
 * @param {[ [int, float] ]} rawDrugLevelObservations
 * @param {object} scenario
 * @param {boolean} initialize
 *
 * @useage  hydrateDose(drugData, scenario)(doses)
 * @returns [{hydratedDose}]
 * */
export const hydrateDoses = (rawDrugLevelObservations, scenario, initialize = false) => {
  return pipe(

    doses => {
      return initialize ?
        pipe(
          /**
           * ONE-TIME DOSE DATA OPERATIONS
           */

          // remove dose data properties that are not in use to ease debugging
          map(removeCruftProps),

          // sort doses by their start day (xstart) and save the sorted index
          // do not sort doses after init as their xstart value may change
          map(coerceXStartPropToInt),
          doses => sortBy(doses, 'xstart'),
          map((dose, index) => ({...dose, index })),

        )(doses) : doses
    },

    // adds location, key, amount props based on dose.name (which is not static)
    map(dose => ({...dose, ...processDoseName(dose)})),

    // adds other dose metadata and alias props
    addDoseProperty({
      currentShift: dose => dose.currentShift || 0,
      // sets the amount of days to offset each drugLevelObservation by
      offsetScenarioXStartDay: offsetXStartDayToScenarioDay(scenario.minHorizontalScroll),
      isAmountEditable: dose => !dose.disableDoseChanging,
      isCurveVisible: true,
      isEditable: isDoseEditable,
      isInitiationDose: dose => dose.typeLine === 'initial',
      isLocationEditable: dose => !dose.disableTypeChanging,
      isOralDose: dose => isOralDose(dose),
      limitOptions: dose => dose.limitOptions || [],
      originalStart: getDoseOriginalStartDay,
      relativeExtremes: getRelativeExtremes,
      rootStart: getRootStart,
      maxDose: dose => parseInt(dose.maxDose, 10),
      maximumShift: dose => getDoseMaximumShift(dose),
      showDayLabel: dose => dose.showXstart,
    }),

    // add drug level observation data to this dose
    addDoseProperty({
      data: dose => {
        // get raw drugLevelObservations for dose (location:amount)
        const rawData = getDrugLevelObservationsForDose(rawDrugLevelObservations)(dose);
        // offset each drugLevelObservation day by "offsetScenarioXStartDay"
        return map(mapDrugLevelsToGraphDatum(dose.offsetScenarioXStartDay))(rawData);
      }
    }),

  );
};
