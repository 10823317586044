import React, { useContext } from 'react';
import classNames from 'classnames';
import GraphContext from '../GraphContext';

const LegendItems = ({ legendItems, className }) => {

  const { doses, metadata, translations } = useContext(GraphContext);

  const legendItemConditions = {
    // legend item id: dose condition function (returns true if the item should be included)
    'legend-or-dose-symbol': dose => dose.location === 'OralRisperdal' && dose.showOralTooltip,
    'legend-oier-dose-symbol': dose => dose.location === 'InvegaER' && dose.showOralTooltip,
    'legend-er-plasma-concentration': dose => dose.formulation === 'ER',
    'legend-er-doses': dose => dose.formulation === 'ER',
    'legend-rl-dose-symbol': dose => dose.formulation === 'RL',
    'median-plasma-concentration-symbol': (dose, metadata) => metadata.display_band,
  };

  const filteredLegendItems = legendItems.filter(legendItem => {
    const itemId = legendItem.image.title;
    const doseCondition = legendItemConditions[itemId];

    if (!doseCondition) {
      return true;
    }
    // include this legend item if any of the doses meet the given condition
    return doses.some(dose => doseCondition(dose, metadata));
  });

  return (
    <ul>
      {filteredLegendItems.map((item, i) => (
        <li
          key={i}
          className={classNames('scenario-legend-item', className)}
        >
          <img src={item.image.src} alt={item.image.title} />
          <p>{item.label}</p>
        </li>
      ))}
      <li className="scenario-legend-item week">
        <p>{translations.week1Day8}</p>
      </li>
    </ul>
  );

};

export default LegendItems;
