import 'core-js';

import ReactOnRails from 'react-on-rails';
import LandingTitle from './components/LandingTitle';
import GraphWrapper from '../graph/components/GraphWrapper';

ReactOnRails.register({
  GraphWrapper,
  LandingTitle,
});
