import { formulationColors, singleDoseCurveColors } from './config';
import { ORAL_INVEGA, ORAL_RISPERDAL } from './data';

/**
 * Takes a dose and adds the location, key, and amount props
 *
 * @param {object} dose
 * @returns {object} dose with additional props based on name: amount, location, key, etc.
 */
export const processDoseName = dose => {
  const amountRegex = /mg/;
  const locationRegex = /Deltoid|Gluteal|Invega|Oral|HaldolDecoanate|Other/;

  // e.g. dose name 'Deltoid_234mg.json'
  const nameParts = dose.name.substring(0, dose.name.indexOf('.')).split('_');
  const location = nameParts.find(item => locationRegex.test(item));

  let amount = nameParts.find(item => amountRegex.test(item)) || '';

  // handle floating point amounts (e.g. '37.5mg')
  amount = amount.replace('-', '.');

  // RI = renal impairment, scenarios 233 & 182
  const RI = nameParts.find(item => item === 'RI');
  const key = RI ? `${RI}:${location}:${amount}` : `${location}:${amount}`;

  return {
    ...dose,
    amount,
    location,
    key,
    renalImpairment: RI,
  };
};

export const getDoseOriginalStartDay = dose => {
  return typeof dose.originalStart === 'number' ? dose.originalStart : dose.xstart;
};

export const isOralDose = dose => {
  const conditions = [
    dose => dose.formulation === 'ER',
  ];
  return conditions.some(condition => condition(dose) === true);
};

export const isIndividualDose = dose => !dose.isCumulative;

export const isDoseEditable = dose => {
  return dose.doseEditable
    || dose.typeEditable
    || !dose.disableDoseChanging
    || !dose.disableTypeChanging;
};

/**
 * Can the start day for the given dose be updated/shifted
 * @param {object} dose
 * @param {object} metadata
 */
export const isDoseDayShiftable = (dose, metadata) => {
  if (!dose || !metadata) return false;

  /**
   * Note: in pp3m scenario data, the doseDayIsEditable prop is used to enable dose day shifting.
   * @TODO this prop is redundant; the pp3m data should be normalized with pp1m props.
   */
  return metadata.pp3mScenario
    ? dose.doseDayIsEditable && dose.extremes
    : !dose.disablePositionChanging && dose.extremes;
};

/**
 * Returns an updated name property for the given dose,
 * based on the provided amount and location values
 *
 * @param {object} { name, json }
 * @param {string|null} amount e.g. "39mg"
 * @param {string|null} location e.g. "Deltoid"
 */
export const getUpdatedDoseNameProps = (dose, amount, location) => {
  const { renalImpairment } = dose;

  // fallback to existing dose values
  if (!amount) amount = dose.amount;
  if (!location) location = dose.location;

  // handle floats e.g. 37.5
  amount = amount.replace('.', '-');

  const name = renalImpairment
    ? `${location}_${renalImpairment}_${amount}.json`
    : `${location}_${amount}.json`;

  return { name };
};

/**
 * Does the given dose have data provided in hourly levels
 * @param {object} dose
 */
export const doseHasHourlyLevels = dose => {
  return dose.location === 'OralRisperdal';
};

/**
 * Does the given dose have data provided in daily (24 hour interval) levels
 * @param {object} dose
 */
export const doseHasDailyLevels = dose => {
  return !doseHasHourlyLevels(dose);
};

export const addUnitToDoseAmount = amount => `${amount}mg`;

/**
 * Get maximum allowed shift in days (in one direction) for the given dose
 * @param {object} dose
 */
export const getDoseMaximumShift = dose => {
  const extremes = dose.extremes || { min: 0, max: 0 };
  return (extremes.max - extremes.min) / 2;
};

/**
 * Get the relative extremes for a dose's allowed shift range, based on its original start day
 * Note: this is different from dose.extremes, which contains the absolute extremes based on the original start day
 * @param {object} dose
 * @returns {{ min, max }}
 */
export const getRelativeExtremes = dose => {
  const { originalStart, xstart } = dose;
  const start = typeof originalStart === 'number' ? originalStart : xstart;
  const extremes = dose.extremes || { min: 0, max: 0 };
  const relativeExtremes = { min: extremes.min - start, max: extremes.max - start };
  return relativeExtremes;
};

/**
 * Get the root start day for the given dose,
 * this value is only updated when a linked previous dose day is changed,
 * and is used to determine the allowed shiftable range for a dose
 * @param {object} dose
 */
export const getRootStart = dose => {
  const { rootStart, xstart } = dose;
  return typeof rootStart === 'number' ? rootStart : xstart;
};

/**
 * Get the allowed shift amount for the given dose and direction
 * @param {object} dose
 * @param {object} metadata
 * @param {int} direction (1 for forwards, -1 for backwards)
 */
export const getDoseShiftAmount = (dose, metadata, direction) => {
  const { sliderRange, xstart } = dose;
  let amount = metadata.pp3mScenario ? 7 : 1;

  if (sliderRange) {
    const currentIndex = sliderRange.indexOf(xstart);
    const nextIndex = currentIndex + direction;
    amount = sliderRange[nextIndex]
      ? Math.abs(xstart - sliderRange[nextIndex])
      : 0;
  }

  return amount * direction;
};

/**
 * Is the provided location an oral dose?
 * @param {string} location
 * @returns bool
 */
export const isOralLocation = location => {
  return [
    ORAL_INVEGA,
    ORAL_RISPERDAL
  ].includes(location);
};

/**
 * Gets the display location for the given dose
 * @param {object} dose
 * @returns string
 */
export const getDoseDisplayLocation = dose => {
  const { formulation, location, pp3mLocation } = dose;

  let displayLocation = location;

  if (isOralLocation(location)) {
    displayLocation = 'Oral';
  } else if (formulation === 'ER') {
    displayLocation = pp3mLocation;
  }

  return displayLocation;
};

/**
 * Get the draggable bounds for the given dose based on the number of pixels per day
 * @param {object} dose
 * @param {float} pixelsPerDay
 * @returns {object}
 */
export const getDoseDraggableBounds = (dose, pixelsPerDay) => {
  const { extremes, relativeExtremes, rootStart, xstart } = dose;
  if (!extremes) return {};

  const maxDay = rootStart + relativeExtremes.max;
  const minDay = rootStart + relativeExtremes.min;
  const min = (minDay - xstart) * pixelsPerDay;
  const max = (maxDay - xstart) * pixelsPerDay;
  return { left: min, right: max };
};

export const getDoseBorderColor = (dose, metadata) => {
  const { color, formulation, key } = dose;
  if (metadata.singleDoseCurveView) {
    return singleDoseCurveColors[key];
  } else if (formulation) {
    return formulationColors[formulation];
  }
  return color;
};

export const getDoseFormulationColor = formulation => {
  return formulationColors[formulation] || formulationColors['1M'];
};

/**
 * Find a dose in an array by its original start day
 * @param {[{}]} doses
 * @param {number} day
 * @returns {}
 */
export const getDoseByOriginalStartDay = (doses, day) => {
  return doses.find(dose => dose.originalStart === day);
};
