import React from 'react';
import { getDoseShiftAmount, isDoseDayShiftable } from '../../modules/doseUtils';
import DoseShiftButton from './DoseShiftButton';

const DoseShiftButtons = ({ dose, isActive, metadata, onDoseShift }) => {
  const isShiftable = isDoseDayShiftable(dose, metadata);
  const showShiftButtons = isActive && isShiftable;

  if (!showShiftButtons) {
    return null;
  }

  return (
    <>
        <DoseShiftButton
          dose={dose}
          onClick={() => onDoseShift(dose, getDoseShiftAmount(dose, metadata, -1))}
        >
          {`‹`}
        </DoseShiftButton>
        <DoseShiftButton
          dose={dose}
          isNext
          onClick={() => onDoseShift(dose, getDoseShiftAmount(dose, metadata, 1))}
        >
          {`›`}
        </DoseShiftButton>
    </>
  );
}

export default DoseShiftButtons;