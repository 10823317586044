import React from 'react';
import PropTypes from 'prop-types';

class ToggleAllDoseCurves extends React.Component {

  static propTypes = {
    doses: PropTypes.array,
    onDoseChanges: PropTypes.func,
  };

  static defaultProps = {
    doses: [],
    onDoseChanges: doses => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showAllDoseCurves: true,
    };
  }

  toggleAllDoseCurves = () => {
    const { doses, onDoseChanges } = this.props;

    const showAllDoseCurves = !this.state.showAllDoseCurves;

    this.setState({ showAllDoseCurves }, () => {
      onDoseChanges(
        doses.map(dose => {
          if (!dose.isCumulative) {
            dose.isCurveVisible = showAllDoseCurves;
          }
          return dose;
        })
      );
    });
  };

  render() {
    const { showAllDoseCurves } = this.state;

    return (
      <label htmlFor="dose-curves" className="btn edi-toggle-dose-curves">
        <input
          type="checkbox"
          id="dose-curves"
          onChange={this.toggleAllDoseCurves}
          checked={showAllDoseCurves}
        />
        Individual Dose Curves
      </label>
    );
  }
}

export default ToggleAllDoseCurves;
